import { TextField } from '@mui/material'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { setInfoById, setInfo } from 'slices/infoSlice'
import { extractInfo } from 'utilities/utils'

export default function Text({ parentId, id, label, variant, focused, multiline, className = "", maxRows, rows, type, required, readOnly, maxCharacters, ...other }) {
    const value = useSelector(state => extractInfo(state, parentId, id))
    const dispatch = useDispatch()

    const onChange = (e) => {
        const inputValue = e.target.value;

        if (maxCharacters && inputValue.length > maxCharacters) {
            return;
        }

        if (parentId) {
            dispatch(setInfoById({ id: parentId, payload: { [id]: inputValue } }))
        } else {
            dispatch(setInfo({ [id]: inputValue }))
        }
    }

    return (
        <TextField {...other} required={required} className={`w-100 ${className} bg-white`} id={id} label={label} variant={variant} focused={focused} value={value != undefined ? value : ""} onChange={onChange} multiline={multiline} maxRows={maxRows} rows={rows} type={type} InputProps={{ readOnly: readOnly, ...other.InputProps }} />
    )
}
