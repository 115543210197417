import { Box } from '@mui/material';
import React from 'react'

export default function TabPanel({ children, value, index, ...other }) {

    return (
        <Box
            role="tabpanel"
            hidden={value !== index}
            id={`tabpanel-${index}`}
            {...other}
        >
            {value === index && (
                <>
                    {children}
                </>
            )}
        </Box>
    );
}