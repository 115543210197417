import { Box, Grid, Button } from '@mui/material';
import { PlaylistAddCheck, CameraAlt } from '@mui/icons-material';
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import useAxios from 'hooks/useAxios';
import { setInfo } from 'slices/infoSlice';
import Dropdown from 'components/Dropdown';
import styled from 'styled-components';
import { memorizedContextInfo } from 'redux/memorize';
import Number from 'components/Number';
import { useSnackbar } from 'notistack';

const TextFormat = styled.div`
		font-family: "Roboto","Helvetica","Arial",sans-serif;
`

export default function VehicleCheckList({ setPage, type }) {
    const dispatch = useDispatch()
    const info = useSelector(memorizedContextInfo)
    const { get } = useAxios()
    const { enqueueSnackbar } = useSnackbar();

    const fetchData = async controller => {
        const result = await get("/checkListMaster/getAllActiveCheckListMaster", { signal: controller.signal })
        if (result.status === 200) {
            let checkList = result.data.data.records.map(data => data) || []
            dispatch(setInfo({ checkList: checkList }))
        }
    }

    useEffect(() => {
        if (info.vehicleId && !info.checkList?.length) {
            const controller = new AbortController();
            fetchData(controller)
            return () => {
                controller.abort()
            }
        }
    }, [])

    const goBack = () => {
        dispatch(setInfo({ checkList: [] }))
        setPage("main")
    }

    const submitData = async () => {
        if (info.mileage) {
            dispatch(setInfo({ remark: type }))
            setPage("takeVehicleCheckList")
        } else {
            enqueueSnackbar('กรุณาเพิ่มไมล์รถ', {
                variant: "error", anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'center',
                }
            })
        }
    }

    return (
        <Box>
            <div className="flex flex-row justify-center items-center my-2 text-center">
                <div className="border-dotted rounded-full border-[2px] border-blue-400 p-0.5">
                    <PlaylistAddCheck className="rounded-full !text-3xl p-1 bg-orange-400" />
                </div>
                <div className="text-blue-400">‧‧‧‧‧‧‧‧</div>
                <div className="border-dotted rounded-full border-[2px] border-blue-400 p-0.5">
                    <CameraAlt className="rounded-full !text-3xl p-1 bg-gray-300" />
                </div>
            </div>
            <TextFormat className="mt-1 !text-lg font-bold text-center">
                ตรวจสภาพรถ
            </TextFormat>
            <TextFormat className="mt-1">
                <Grid container columns={12} alignItems="center" className="text-base bg-gray-500 text-white px-2">
                    <Grid item xs={3} className="font-bold">
                        ทะเบียนรถ :
                    </Grid>
                    <Grid item xs={9}>
                        <span className="ml-1" >{info?.vehicles ? info?.vehicles[0]?.vehicleNo : '-'}</span>
                    </Grid>
                </Grid>
            </TextFormat>
            <div className='py-3 px-6'>
                {info.checkList?.map((data, i) =>
                    <div key={"VehicleCheckList-" + i} className="flex py-2 text-right">
                        <TextFormat className='w-[50%] self-center pr-5'>{data.name}</TextFormat>
                        <div className='text-left w-[40%]'>
                            <Dropdown
                                id="status"
                                parentId={`@checkList@${i}`}
                                label="สถานะ"
                                options={[
                                    { label: "ใช้งาน", value: "active" },
                                    { label: "ส่งซ่อม", value: "maintain" },
                                    { label: "เสียหาย", value: "damage" }
                                ]}>
                            </Dropdown>
                        </div>
                    </div>
                )}
                <div className="flex py-2 text-center">
                    <Number
                        id="mileage"
                        label="เลขไมล์"
                        required={true}
                    >
                    </Number>
                </div>
            </div>
            <div className="flex justify-between py-2 px-1 text-center" >
                <div className="w-[95%]">
                    <Button variant="contained" className="w-[95%] driver_button" onClick={goBack}>ย้อนกลับ</Button>
                </div>
                <div className="w-[95%]">
                    <Button variant="contained" className="bg-bpTheme-buttonSubmit w-[95%] driver_button" disabled={info?.checkList === undefined} onClick={submitData}>ดำเนินการต่อ</Button>
                </div>
            </div>
        </Box>
    )
}