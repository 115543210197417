import { Box, Grid, Divider } from '@mui/material';
import React, { useEffect } from 'react'
import Button from 'components/Button';
import { useDispatch, useSelector } from 'react-redux';
import { setInfo } from 'slices/infoSlice';
import useAxios from 'hooks/useAxios';
import DisplayInformation from '../components/displayInformation';
import { DRIVER_PAGE_CHECK_IN } from 'helper/DataFactory';
import { DRIVER_PAGE_CHOOSE_LOCATION } from 'helper/DataFactory';

export default function ReviewOrder({ type, onPageChange }) {
	const dispatch = useDispatch()
	const info = useSelector(state => state.info.ctx)
	const { get } = useAxios()

	const fetchData = async controller => {
		const result = await get("/takeOrder/getTakeOrderDetail", { jobId: info.jobId, locationId: info.locationId, type }, { signal: controller.signal })
		if (result.status === 200) {
			const bills = result.data.data.records || []
			dispatch(setInfo({ bills }))
		}
	}

	useEffect(() => {
		const controller = new AbortController();
		fetchData(controller)
		return () => {
			controller.abort()
		}
	}, [])

	const goBack = () => {
		onPageChange(DRIVER_PAGE_CHOOSE_LOCATION)
	}

	const submitData = () => {
		onPageChange(DRIVER_PAGE_CHECK_IN)
	}

	return (
		<Box className="!p-2 " >
			{info.bills?.map((data, i) => {
				const displayData = [
					{
						label: "ผู้ส่ง",
						data: data.name
					},
					{
						label: "หมายเหตุ",
						data: data.remark
					},
				]

				return <div key={"order-" + i}>
					<Grid container spacing={1} columns={12} alignItems="center" className="driver_grid_lg">
						<Grid item xs={12} className="driver_highlight">
							<div>{data.no}</div>
						</Grid>
					</Grid>
					{
						displayData.map((data, i) => <DisplayInformation key={`data-${i}`} data={data} />)
					}
					<Divider variant="contained" className="my-2"></Divider>
					{data.items.length > 0 ? data.items.map((item, j) => {
						const displayInfo = [
							{
								label: "สินค้า",
								data: item.detail
							},
							{
								label: "อุณหภูมิ",
								type: "temperature",
								data: item.temperature
							},
							{
								label: "จำนวนสินค้า",
								data: item.quantity <= 0 ? 'ดูหน้างาน' : item.quantity
							},
							{
								label: "หน่วย",
								data: item.unit
							},
							{
								label: "หมายเหตุ",
								data: item.remark
							},
						]
						return (
							<div key={"item-" + j} className="mx-8 my-2">
								<Grid container spacing={1} columns={12} alignItems="center" className="driver_grid_base">
									<Grid item xs={12} className="driver_head text-orange-400">
										<div>{item.billNo}</div>
									</Grid>
								</Grid>
								{displayInfo.map((data, i) => <DisplayInformation key={`sub-data-${i}`} data={data} />)}
							</div>
						)
					}) : <p>ไม่พบสินค้าที่ต้องการ{type === "pickup" ? "รับ" : "ส่ง"}</p>}
					<Divider variant="contained" className="driver_divider"></Divider>
				</div>
			})}
			<div className="flex justify-between py-2 px-1 text-center" >
				<div className="w-[95%]">
					<Button variant="contained" className="w-[95%] driver_button" onClick={goBack}>ย้อนกลับ</Button>
				</div>
				<div className="w-[95%]">
					<Button variant="contained" className="!bg-bpTheme-buttonSubmit w-[95%] driver_button" onClick={submitData}>ดำเนินการต่อ</Button>
				</div>
			</div>
		</Box>
	)
}