import { ExpandMore } from '@mui/icons-material'
import { FormControl, FormControlLabel, FormLabel, Checkbox, Box, FormGroup, Collapse, IconButton } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { setInfoById, setInfo } from 'slices/infoSlice'
import { extractInfo } from 'utilities/utils'

export default function MultiCheckbox({ parentId, id, label, options = [], direction = "", className = "", readOnly }) {
    const value = useSelector(state => extractInfo(state, parentId, id))
    const [collapseStates, setCollapseStates] = useState({})
    const dispatch = useDispatch()
    const onChange = (e) => {
        const newValue = {
            ...value,
            [e.target.name]: e.target.checked || false,
        }
        if (parentId) {
            dispatch(setInfoById({ id: parentId, payload: { [id]: newValue } }))
        } else {
            dispatch(setInfo({ [id]: newValue }))
        }
    }

    useEffect(() => {
        if (options.length && value && !Object.keys(value).length) {
            const newValue = {}
            options.forEach(e => {
                newValue[e.value] = false
            })
            if (parentId) {
                dispatch(setInfoById({ id: parentId, payload: { [id]: newValue } }))
            } else {
                dispatch(setInfo({ [id]: newValue }))
            }
        }
    }, [options])

    return (
        <Box sx={{ display: 'flex' }} className={className}>
            <FormControl component="fieldset" variant="standard">
                {label ? <FormLabel component="legend">{label}</FormLabel> : null}
                <FormGroup className={`${direction === "row" ? "flex flex-row" : ""}`}>
                    {
                        options.map((e, i) => (
                            <React.Fragment key={`${id}-${i}`}>
                                <FormControlLabel
                                    key={`checkbox-${id}-options-${e.value}`}
                                    control={
                                        <Checkbox
                                            value={value?.hasOwnProperty(e.value) ? value[e.value] || false : false}
                                            checked={value?.hasOwnProperty(e.value) ? value[e.value] || false : false}
                                            onChange={onChange}
                                            name={e.value}
                                            disabled={readOnly}
                                        />
                                    }
                                    label={<>
                                        {e.options ? (
                                            <>
                                                {e.label}
                                                <IconButton className='' onClick={() => {
                                                    setCollapseStates(prevState => ({
                                                        ...prevState,
                                                        [e.value]: !prevState[e.value]
                                                    }));
                                                }}>
                                                    <ExpandMore />
                                                </IconButton>
                                            </>
                                        ) : e.label}
                                    </>}
                                />
                                {e.options ? (
                                    <>
                                        <Collapse in={collapseStates[e.value]} timeout="auto" className='w-full' unmountOnExit>
                                            <MultiCheckbox className="!ml-4" parentId={parentId} id={id} options={e.options} readOnly={readOnly} />
                                        </Collapse>
                                    </>
                                ) : null}
                            </React.Fragment>
                        ))
                    }
                </FormGroup>
            </FormControl>
        </Box>
    )
}
