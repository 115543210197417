import { configureStore, combineReducers } from '@reduxjs/toolkit';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist-indexeddb-storage';

import infoSlice from 'slices/infoSlice'
import userSlice from 'slices/userSlice'
import menuSlice from 'slices/menuSlice'
import popupSlice from 'slices/popupSlice'
import loadingSlice from 'slices/loadingSlice';
import collapseSlice from 'slices/collapseSlice'

const rootReducer = combineReducers({
    user: userSlice,
    info: infoSlice,
    activeMenu: menuSlice,
    popup: popupSlice,
    isloading: loadingSlice,
    collapse: collapseSlice,
});

const persistConfig = {
    key: 'root',
    storage: storage('foxfleet'),
    whitelist: ['user', 'info', 'activeMenu']
}

const persistedReducer = persistReducer(persistConfig, rootReducer)

const store = configureStore({
    reducer: persistedReducer,
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            serializableCheck: false,
        }),
});

const persistor = persistStore(store);

export default () => ({ store, persistor });