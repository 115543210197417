import React, { useEffect, useState } from 'react'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { MobileTimePicker } from '@mui/x-date-pickers';
import thLocale from "date-fns/locale/th";
import { useDispatch, useSelector } from 'react-redux'
import { setInfo, setInfoById } from 'slices/infoSlice'
import { TextField } from '@mui/material';
import { extractInfo } from 'utilities/utils';

export default function TimePicker({ parentId, id, label, readOnly, required }) {
    const value = useSelector(state => extractInfo(state, parentId, id))
    const [inputValue, setInputValue] = useState(null)
    const dispatch = useDispatch()
    const onChange = (e) => {
        setInputValue(e)
        const value = e !== null ? `${e.getHours() < 10 ? `0${e.getHours()}` : e.getHours()}:${e.getMinutes() < 10 ? `0${e.getMinutes()}` : e.getMinutes()}` : null
        if (parentId) {
            dispatch(setInfoById({ id: parentId, payload: { [id]: value } }))
        } else {
            dispatch(setInfo({ [id]: value }))
        }
    }
    useEffect(() => {
        if (value) {
            if (typeof value === 'string') {
                const now = new Date()
                now.setHours(value.split(":")[0])
                now.setMinutes(value.split(":")[1])
                setInputValue(now)
            } else {
                setInputValue(value)
            }
        } else {
            setInputValue(null)
        }
    }, [value])
    return (
        <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={thLocale}>
            <MobileTimePicker
                readOnly={readOnly}
                value={inputValue}
                onChange={onChange}
                label={label}
                className="w-100"
                renderInput={(props) => <TextField {...props} required={required} />}
            />
        </LocalizationProvider>
    )
}
