import { Box } from '@mui/material'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Panel from 'components/Panel'
import Modal from 'components/Modal'
import Form from 'components/Form'
import { useSnackbar } from 'notistack'
import { setInfo, clearInfo, clearInfoById, setInfoById } from 'slices/infoSlice'
import { validation, normalizeData } from 'utilities/validator'
import usePopup from 'hooks/usePopup'
import useAxios from 'hooks/useAxios'
import useMultipleFileReader from 'hooks/useMultipleFileReader'
import { v4 as uuidv4 } from 'uuid';
import { BILLING_COND } from 'helper/DataFactory'
import RouteInput from './routeInput'
import { warpUpFailResponse } from 'utilities/utils'
import useDataHandler from 'hooks/useDataHandler'

const mappedBillingCond = BILLING_COND.reduce((acc, cur) => {
	acc[cur.label] = cur.value;
	return acc;
}, {});

export default function Quotation() {
	const dispatch = useDispatch()
	const { enqueueSnackbar } = useSnackbar();
	const { save } = useDataHandler()
	const info = useSelector(state => state.info.ctx)
	const [mode, setMode] = useState("initial")
	const mode2 = useState("initial")
	const mode3 = useState("initial")
	const { post, get, del, multiple } = useAxios();
	const { confirmPopup } = usePopup();
	const [allRoute, setAllRoute] = useState([]);
	const [allCustomer, setAllCustomer] = useState([]);
	const [allUnit, setAllUnit] = useState([]);
	const [allQuotation, setAllQuotation] = useState([]);
	const [allVehicleType, setAllVehicleType] = useState([])
	const [allServiceType, setAllServiceType] = useState([])
	const roleUser = useSelector(state => state.user.roleName)

	const uploadFields = [
		{ label: "ชื่อใบเสนอราคา", value: "name", required: true },
		{ label: "คู่สัญญา", value: "customer", required: true },
		{ label: "ประเภทรถ", value: "vehicleType", required: true },
		{ label: "ระยะทาง", value: "routeDistance" },
		{ label: "ต้นทาง", value: "source", required: true },
		{ label: "ปลายทาง", value: "destination", required: true },
		{
			label: "อัตราราคาน้ำมัน", value: "oilRate", required: true,
			tooltip: <>
				<p className='font-bold'>คำแนะนำ</p>
				<p>ควรกำหนด format {"<จำนวนจริง> - <จำนวนจริง>"}</p>
				<p>เช่น 20.25 - 30.00 </p>
			</>
		},
		{
			label: "ช่วงระยะทาง", value: "distance",
			tooltip: <>
				<p className='font-bold'>คำแนะนำ</p>
				<p>ควรกำหนด format {"<จำนวนเต็ม> - <จำนวนเต็ม>"}</p>
				<p>เช่น 0 - 50 </p>
			</>
		},
		{ label: "IOD", value: "iod", required: true },
		{ label: "POD", value: "pod", required: true },
		{ label: "ประเภทจัดส่ง", value: "serviceType", required: true },
		{ label: "ราคาส่ง", value: "price", required: true },
		{ label: "ราคารับกลับ", value: "returnPrice" },
		{
			label: "เงื่อนไขการวางบิล", value: "uom", required: true,
			tooltip: <>
				<p className='font-bold'>คำแนะนำ</p>
				{
					BILLING_COND.map((e, i) => <p key={"tooltip-billing-cond-" + i}>{`${i + 1}. ${e.label}`}</p>)
				}
			</>
		},
		{ label: "หน่วย", value: "unit" },
	];

	const uploadData = (data, callBack, originData, columnsMapping) => {
		confirmPopup({
			onSubmit: async () => {

				const data4ErrMsg = JSON.parse(JSON.stringify(originData))

				//map data to array
				const mappedHeaders = uploadFields.reduce((acc, cur) => {
					acc[cur.label] = cur.value;
					return acc;
				}, {});

				const mappedData = data.map(([headers, ...rows]) => rows.map((row) => row.map((e, i) => ({ [mappedHeaders[headers[i]]]: e })).reduce((acc, curr) => ({ ...acc, ...curr }), {})))

				//validatedata
				const requiredFields = uploadFields.filter(e => e.required).map(e => e.value)
				const formatData = mappedData.map((sheet, i) => {
					data4ErrMsg[i][0].push("Error message")
					return sheet.map((row, j) => {
						const invalid = requiredFields.some(e => !row[e] && row[e] !== 0)
						let error = false
						if (invalid) {
							data4ErrMsg[i][j + 1].push("required fields must not be null")
							error = true
						}

						if (!error) {
							const customerId = allCustomer.find(e => e.label === row.customer)?.value
							if (customerId) {
								row.customerId = customerId
							} else {
								data4ErrMsg[i][j + 1].push("no customer found")
								error = true
							}
						}

						if (!error) {
							const vehicleTypeId = allVehicleType.find(e => e.label === row.vehicleType)?.value
							if (vehicleTypeId) {
								row.vehicleTypeId = vehicleTypeId
							} else {
								data4ErrMsg[i][j + 1].push("no vehicle type found")
								error = true
							}
						}

						if (!error && row.unit) {
							const unitId = allUnit.find(e => e.label === row.unit)?.value
							if (unitId) {
								row.unitId = unitId
							} else {
								data4ErrMsg[i][j + 1].push("no unit found")
								error = true
							}
						}

						if (!error && row.distance) {
							if (!/^\d+ - \d+$/.test(row.distance)) {
								data4ErrMsg[i][j + 1].push("invalid distance format")
								error = true
							}
						}

						if (!error) {
							if (!/^\d+\.\d+ - \d+\.\d+$/.test(row.oilRate)) {
								data4ErrMsg[i][j + 1].push("invalid oil rate format")
								error = true
							}
						}

						if (!error && row.iod) {
							if (isNaN(Number(row.iod))) {
								data4ErrMsg[i][j + 1].push("invalid IOD format")
								error = true
							}
						}

						if (!error && row.pod) {
							if (isNaN(Number(row.pod))) {
								data4ErrMsg[i][j + 1].push("invalid POD format")
								error = true
							}
						}

						if (!error && row.price) {
							if (isNaN(Number(row.price))) {
								data4ErrMsg[i][j + 1].push("invalid price format")
								error = true
							}
						}

						if (!error && row.returnPrice) {
							if (isNaN(Number(row.returnPrice))) {
								data4ErrMsg[i][j + 1].push("invalid return price format")
								error = true
							}
						}

						if (!error) {
							const serviceTypeId = allServiceType.find(e => e.label?.toLowerCase() === row.serviceType?.toLowerCase())?.value
							if (serviceTypeId) {
								row.serviceTypeId = serviceTypeId
							} else {
								data4ErrMsg[i][j + 1].push("service type not match")
								error = true
							}
						}

						if (!error) {
							const value = mappedBillingCond[row.uom]
							if (value) {
								if (!["UNIT", "DISTANCE"].includes(value) && row.unit) {
									data4ErrMsg[i][j + 1].push("unit field should be empty")
									error = true
								} else {
									row.uom = value
								}
							} else {
								data4ErrMsg[i][j + 1].push("no billing condition found")
								error = true
							}
						}

						if (!error) {
							data4ErrMsg[i][j + 1].push("insert fail")
							return row
						}
					}).filter(e => e)
				})

				const flatMap = formatData.flatMap(innerArray => innerArray);
				const routes = [...new Set(flatMap.map(({ source, destination, routeDistance }) => ({ source, destination, distance: routeDistance })))];
				let routeIds = []

				const result = await post("/route/createNonExistingRoute", { routes })
				if (result.status === 200) {
					const data = result.data.data
					routeIds = data.routeIds
				} else {
					return
				}

				const quotations = Object.values(
					flatMap.reduce((acc, obj) => {
						const { name, customerId, vehicleTypeId, serviceTypeId, oilRate, distance, uom, customer, vehicleType, serviceType, unit, unitId, source, destination, routeDistance, ...rest } = obj;
						const existingItem = acc.find(
							item =>
								item.name === name &&
								item.customerId === customerId &&
								item.vehicleTypeId === vehicleTypeId &&
								item.serviceTypeId === serviceTypeId &&
								item.oilRate === oilRate &&
								item.distance === distance &&
								item.uom === uom
						);

						if (existingItem) {
							existingItem.prices.push({ ...rest, routeId: routeIds[`${source} - ${destination}`], unitId: ["UNIT", "DISTANCE"].includes(uom) ? unitId : null });
						} else {
							acc.push({
								name,
								customerId,
								vehicleTypeId,
								serviceTypeId,
								oilRate,
								distance,
								uom,
								prices: [{ ...rest, routeId: routeIds[`${source} - ${destination}`], unitId: ["UNIT", "DISTANCE"].includes(uom) ? unitId : null }],
							});
						}

						return acc;
					}, [])
				);

				if (data4ErrMsg.every(sheet => sheet.every(rows => ["Error message", "insert fail"].includes(rows[rows.length - 1])))) {
					let response = await post("/quotation/uploadQuotation", { quotations })
					if (response.status === 200) {
						enqueueSnackbar('บันทึกสำเร็จ', { variant: "success" })
						callBack(data4ErrMsg.map(rows => {
							return rows.filter(row => row[row.length - 1] !== "insert fail")
						}))
						fetchData(new AbortController())
					} else {
						enqueueSnackbar(warpUpFailResponse(response, 'บันทึกไม่สำเร็จ'), { variant: "error" })
						callBack(data4ErrMsg)
					}
				} else {
					enqueueSnackbar('บันทึกไม่สำเร็จ', { variant: "error" })
					callBack(data4ErrMsg)
				}

			},
			onCancel: () => {
				enqueueSnackbar('ยกเลิกรายการ', { variant: "info" })
			}
		})
	}

	const { excelReader, renderExcelModal } = useMultipleFileReader({
		fields: uploadFields,
		submitData: uploadData
	})

	const fetchData = async controller => {
		const result = await multiple([
			{ method: "get", url: "/route/getAllActiveRoute", config: { signal: controller.signal } },
			{ method: "get", url: "/customer/getAllActiveCustomer", config: { signal: controller.signal } },
			{ method: "get", url: "/unit/getAllActiveUnit", config: { signal: controller.signal } },
			{ method: "get", url: "/quotation/getAllQuotation", config: { signal: controller.signal } },
			{ method: "get", url: "/vehicleType/getAllActiveVehicleType", config: { signal: controller.signal } },
			{ method: "get", url: "/serviceType/getAllActiveServiceType", config: { signal: controller.signal } },
		])

		if (result[0].status === 200) {
			const data = result[0].data.data.records || []
			setAllRoute(data.map(data => ({ label: data.source + ' - ' + data.destination, value: data.id })) || [])
		}
		if (result[1].status === 200) {
			const data = result[1].data.data.records || []
			setAllCustomer(data.map(data => ({ label: data.name, value: data.id })) || [])
		}
		if (result[2].status === 200) {
			const data = result[2].data.data.records || []
			setAllUnit(data.map(data => ({ label: data.name, value: data.id })) || [])
		}
		if (result[3].status === 200) {
			const quotations = result[3].data.data || []
			setAllQuotation(quotations)
		}
		if (result[4].status === 200) {
			const data = result[4].data.data.records || []
			setAllVehicleType(data.map(e => ({ label: e.name, value: e.id })))
		}
		if (result[5].status === 200) {
			const data = result[5].data.data.records || []
			setAllServiceType(data.map(e => ({ label: e.name, value: e.id })))
		}

	}

	useEffect(() => {
		const controller = new AbortController();
		fetchData(controller)
		return () => {
			controller.abort()
		}
	}, [])

	const onCloseModal = useCallback(() => {
		confirmPopup({
			title: "แจ้งเตือน",
			content: "ท่านต้องการยกเลิกการแก้ไขหรือไม่",
			onSubmit: () => {
				setMode("initial")
			}
		})
	}, [])

	const inputForm = [
		{
			rowData: [
				{
					type: "text",
					required: true,
					props: {
						id: "name",
						label: "ชื่อใบเสนอราคา"
					}
				},
				{
					type: "dropdown",
					required: true,
					props: {
						id: "customerId",
						label: "คู่สัญญา",
						options: allCustomer
					}
				},
				{
					type: "dropdown",
					required: true,
					props: {
						id: "uom",
						label: "เงื่อนไขการวางบิล",
						options: BILLING_COND
					}
				}
			]
		},
		{
			rowData: [
				{
					type: "autocomplete",
					required: true,
					props: {
						id: "serviceTypeId",
						label: "ประเภทบริการ",
						options: allServiceType
					},
					md: 4
				},
				{
					type: "dropdown",
					required: true,
					props: {
						id: "vehicleTypeId",
						label: "ประเภทรถ",
						options: allVehicleType
					},
					md: 2
				},
				{
					type: "text",
					required: true,
					validation: 'number-range',
					props: {
						id: "oilRate",
						label: "ช่วงราคาน้ำมัน",
						variant: "outlined",
						placeholder: "00.00 - 00.00"
					},
					md: 2
				},
				info.uom === "DISTANCE" ? {
					type: "text",
					required: true,
					validation: 'decimal-range',
					props: {
						id: "distance",
						label: "ระยะทาง",
						variant: "outlined",
						placeholder: "00 - 00"
					}
				} : {}
			]
		},
		{
			rowData: [
				{
					type: "dataTable",
					props: {
						columns: [
							{
								id: 'routeId',
								label: 'เส้นทาง',
								fields: ["routeId"],
								valuesMap: allRoute
							},
							{
								id: 'price',
								label: `ราคาต่อ${Object.keys(mappedBillingCond).find(key => info.uom !== "DISTANCE" && mappedBillingCond[key] === info.uom) || "หน่วย"} (ส่ง)`,
								fields: ["price"],
								type: "number"
							},
							{
								id: 'returnPrice',
								label: `ราคาต่อ${Object.keys(mappedBillingCond).find(key => info.uom !== "DISTANCE" && mappedBillingCond[key] === info.uom) || "หน่วย"} (รับกลับ)`,
								fields: ["returnPrice"],
								type: "number"
							},
							{
								id: 'unitId',
								hidden: info.uom !== "UNIT" && info.uom !== "DISTANCE",
								label: 'หน่วย',
								fields: ["unitId"],
								valuesMap: allUnit
							},
							{
								id: 'iod',
								label: 'IOD',
								fields: ["iod"],
								type: "number"
							},
							{
								id: 'pod',
								label: 'POD',
								fields: ["pod"],
								type: "number"
							},
						],
						rows: info.prices,
						title: "ราคา",
						tableContainerClassName: "max-h-[30vh]",
						size: "small",
						onClick: (e) => {
							dispatch(setInfoById({ id: `quotationPrices`, payload: e }))
							mode2[1]("edit")
						}
					}
				}
			]
		},
		{
			rowData: [
				mode === "edit" && {
					type: "dropdown",
					props: {
						id: "active",
						label: "สถานะ",
						options: [
							{ label: "ใช้งาน", value: true },
							{ label: "ไม่ใช้งาน", value: false }
						],
					}
				}, {}
			]
		},
		{
			noCol: true,
			align: "center",
			rowData: [
				{
					type: "button",
					props: {
						label: "เพิ่มราคา",
						className: "!mr-2",
						onClick: () => {
							if (!info.uom) {
								enqueueSnackbar('กรุณาเลือกเงื่อนไขการวางบิล', { variant: "error" })
							} else {
								mode2[1]("create")
							}
						}
					}
				},
				{
					type: "button",
					props: {
						label: "บันทึก",
						onClick: async () => {
							const result = await get("/quotation/getQuotationDup", {
								quo: {
									name: info.name, customerId: info.customerId, vehicleTypeId: info.vehicleTypeId, oilRate: info.oilRate, serviceTypeId: info.serviceTypeId,
									distance: info.distance, uom: info.uom, id: mode === "edit" ? info.id : null
								}
							})
							if (result.status === 200) {
								if (result.data.data.records[0]?.no) {
									enqueueSnackbar('มีข้อมูลแล้วที่ใบเสนอราคา ' + result.data.data.records[0]?.no, { variant: "error" })
								} else {
									saveData()
								}
							}
						},
						className: "!bg-bpTheme-buttonSubmit"
					}
				}
			]
		}
	]

	const table = useMemo(() => [
		{
			rowData: [{
				type: "dataTable",
				props: {
					columns: [
						{
							id: 'no',
							label: 'เลขที่ใบเสนอราคา',
							fields: ["no"]
						},
						{
							id: 'name',
							label: 'ชื่อใบเสนอราคา',
							fields: ["name"]
						},
						{
							id: 'customerName',
							label: 'คู่สัญญา',
							fields: ["customerName"]
						},
						{
							id: 'uom',
							label: 'เงื่อนไขการวางบิล',
							fields: ["uom"],
							valuesMap: BILLING_COND
						},
						{
							id: 'serviceType',
							label: 'ประเภทบริการ',
							fields: ["serviceType"]
						},
						{
							id: 'vehicleTypeName',
							label: 'ประเภทรถ',
							fields: ["vehicleTypeName"]
						},
						{
							id: 'oilRate',
							label: 'ช่วงราคาน้ำมัน',
							fields: ["oilRate"]
						},
						{
							id: 'active',
							label: 'สถานะ',
							fields: ["active"],
							valuesMap: [{ label: "ใช้งาน", value: true }, { label: "ไม่ใช้งาน", value: false }]

						}
					],
					rows: allQuotation,
					size: "small",
					showDeleteButton: roleUser === "Owner",
					title: "ข้อมูลใบเสนอราคา",
					showSystemFields: true,
					onClick: async (e) => {
						const result = await get("/quotation/getQuotationById", { id: e.id })
						if (result.status === 200) {
							setMode("edit")
							dispatch(setInfo(result.data.data))
						}
					},
					onDelete: (e, onSuccess) => {
						confirmPopup({
							onSubmit: async () => {
								const result = await del("/quotation/deleteQuotation", { ids: e.map(data => data.id).join(',') })
								if (result.status === 200) {
									enqueueSnackbar('ลบสำเร็จ', { variant: "success" })
									dispatch(clearInfo())
									await fetchData(new AbortController())
									onSuccess()
								} else {
									enqueueSnackbar('ลบไม่สำเร็จ', { variant: "error" })
								}
							}
						})
					},
					controller: (paging) => get("/quotation/getAllQuotation", { paging }),
					searchFields: [
						{ label: "ชื่อใบเสนอราคา", value: "name" },
						{ label: "คู่สัญญา", value: "customerName" },
						{ label: "สถานะ", value: "active" }
					]
				}
			}]
		}, {
			noCol: true,
			rowData: [
				{
					type: "button",
					props: {
						label: "เพิ่มใหม่",
						onClick: () => {
							setMode("create")
							dispatch(clearInfo())
						}
					}
				},
				{
					type: "upload",
					props: {
						id: "files",
						type: "button",
						className: "!ml-2",
						variant: "outlined",
						maxFiles: 1,
						accept: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
						onChange: e => {
							if (e.value?.length) {
								excelReader(e.value[0])
							}
						}
					}
				}
			]
		}
	], [allQuotation, excelReader])

	const saveData = useCallback(() => {
		const numrangeRegEx = /^\d+\.\d+ - \d+\.\d+$/;
		if (info.oilRate && !numrangeRegEx.test(info.oilRate)) {
			enqueueSnackbar('format ราคาน้ำมันไม่ถูกต้อง', { variant: "error" })
		}
		if (info.uom !== "TRIP" && info?.prices?.some(e => !e.unitId)) {
			enqueueSnackbar('กรุณาใส่หน่วยให้ครบ', { variant: "error" })
			return
		}
		save({
			data: info,
			onSubmit: async () => {
				const data = normalizeData(info)
				const modifiedData = {
					...data,
					prices: data.prices.map(({ id, status, unitId, ...rest }) => status === "new" ?
						{ ...rest, unitId: ["UNIT", "DISTANCE"].includes(info.uom) ? unitId : null } :
						{ id, status, unitId: ["UNIT", "DISTANCE"].includes(info.uom) ? unitId : null, ...rest }
					)
				};
				let response
				if (mode === "create") {
					response = await post("/quotation/createQuotation", { ...modifiedData, active: true })
				} else if (mode === "edit") {
					response = await post("/quotation/updateQuotation", { ...modifiedData })
				}
				if (response.status === 200) {
					setMode("initial")
					enqueueSnackbar('บันทึกสำเร็จ', { variant: "success" })
					fetchData(new AbortController())
				} else {
					enqueueSnackbar(warpUpFailResponse(response, 'บันทึกไม่สำเร็จ'), { variant: "error" })
				}
			},
			onCancel: () => {
				enqueueSnackbar('ยกเลิกรายการ', { variant: "info" })
			}
		})
	}, [info, mode])

	return (
		<Box>
			<Panel data={table} name="user-panel"></Panel>
			<Modal open={mode === "create" || mode === "edit"} onClose={onCloseModal} className="form-modal">
				<Form title={"ข้อมูลใบเสนอราคา " + (info.no || "")} name="user-form" data={inputForm}></Form>
			</Modal>
			<QuotationPricesInput {...{ allRoute, allUnit }} mode={mode2} routeMode={mode3} />
			<RouteInput mode={mode3} setAllRoute={setAllRoute} />
			{renderExcelModal}
		</Box>
	)
}

function QuotationPricesInput({ allRoute, allUnit, mode, routeMode }) {

	const dispatch = useDispatch()
	const info = useSelector(state => state.info)
	const { confirmPopup } = usePopup();
	const { enqueueSnackbar } = useSnackbar();

	useEffect(() => {
		if (mode[0] === "initial") {
			dispatch(clearInfoById("quotationPrices"))
		}
	}, [mode[0]])

	const onCloseModal = useCallback(() => {
		confirmPopup({
			title: "แจ้งเตือน",
			content: "ท่านต้องการยกเลิกการแก้ไขหรือไม่",
			onSubmit: () => {
				mode[1]("initial")
			}
		})
	}, [])

	const saveData = useCallback(() => {
		if (validation(info.quotationPrices, "quotationPriceModal")) {
			const prices = info.ctx.prices || []
			const uom = info.ctx.uom
			const existingPrice = prices?.find(item =>
				item?.routeId == info.quotationPrices?.routeId &&
				item?.id != info?.quotationPrices.id &&
				(uom === "UNIT" ? item?.unitId == info?.quotationPrices?.unitId : true)
			)
			if (existingPrice) {
				enqueueSnackbar('มีข้อมูลของเส้นทางนี้แล้ว', { variant: "error" })
			} else {
				if (mode[0] === "create") {
					dispatch(setInfo({ prices: [...prices, { ...info.quotationPrices, id: uuidv4(), status: "new" }] }))
				} else {
					const newPrices = prices.map(price => price.id === info.quotationPrices.id ? { ...info.quotationPrices } : price);
					dispatch(setInfo({ prices: newPrices }))
				}
				mode[1]("initial")
			}
		}
	}, [info, mode[0]])

	const inputForm = [
		{
			rowData: [
				{
					type: "autocomplete",
					required: true,
					props: {
						id: "routeId",
						label: "เส้นทาง (ต้นทาง - ปลายทาง)",
						options: allRoute,
						onAddEvent: e => {
							routeMode[1]("create")
						}
					}
				},
				{
					type: "number",
					required: true,
					props: {
						id: "iod",
						label: "IOD"
					}
				},
				{
					type: "number",
					required: true,
					props: {
						id: "pod",
						label: "POD"
					}
				},
				info.ctx.uom !== "TRIP" && {
					type: "autocomplete",
					required: true,
					props: {
						id: "unitId",
						label: "หน่วย",
						options: allUnit
					}
				},
			]
		},
		{
			rowData: [
				{
					type: "number",
					required: true,
					props: {
						id: "price",
						label: `ราคาต่อ${Object.keys(mappedBillingCond).find(key => info.ctx.uom !== "DISTANCE" && mappedBillingCond[key] === info.ctx.uom) || "หน่วย"} (same day)`
					}
				},
				{
					type: "number",
					props: {
						id: "returnPrice",
						label: `ราคาต่อ${Object.keys(mappedBillingCond).find(key => info.ctx.uom !== "DISTANCE" && mappedBillingCond[key] === info.ctx.uom) || "หน่วย"} (รับกลับ)`
					}
				}
			]
		},
		{
			noCol: true,
			align: "center",
			rowData: [
				{
					type: "button",
					props: {
						label: "ตกลง",
						onClick: () => {
							saveData()
						},
					}
				}
			]
		}
	]

	return (
		<Modal id="quotationPriceModal" open={mode[0] === "create" || mode[0] === "edit"} onClose={onCloseModal} className="form-modal">
			<Form id={`quotationPrices`} title={"ข้อมูลอัตราราคาต่อหน่วย"} name="user-form" data={inputForm}></Form>
		</Modal>
	)
}
