import { createSlice } from '@reduxjs/toolkit'

const initialState = []

export const popupSlice = createSlice({
    name: 'popup',
    initialState,
    reducers: {
        pushPopup: (state, action) => {
            state.push({ ...action.payload })
        },
        removePopup: (state, action) => {
            const removeIdx = state.findIndex(e => e.id = action.payload)
            state.splice(removeIdx, 1)
        }
    }
})

export const { pushPopup, removePopup } = popupSlice.actions
export default popupSlice.reducer