import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Panel from 'components/Panel'
import Modal from 'components/Modal'
import Form from 'components/Form'
import { useSnackbar } from 'notistack'
import { setInfo, clearInfo, setInfoById } from 'slices/infoSlice'
import { normalizeData } from 'utilities/validator'
import usePopup from 'hooks/usePopup'
import useAxios from 'hooks/useAxios'
import { COMPANY_NAME } from 'helper/DataFactory'
import { warpUpFailResponse } from 'utilities/utils'
import CheckList from './checkList'
import useDataHandler from 'hooks/useDataHandler'
const moment = require('moment');

export default function Vehicle() {
	const dispatch = useDispatch()
	const { enqueueSnackbar } = useSnackbar();
	const { save } = useDataHandler()
	const info = useSelector(state => state.info.ctx || {})
	const [mode, setMode] = useState("initial")
	const mode2 = useState("initial") //check list
	const { post, del, multiple, get } = useAxios();
	const { confirmPopup } = usePopup();
	const [allVehicleType, setAllVehicleType] = useState([]);
	const [allVendor, setAllVendor] = useState([]);
	const [allVehicle, setAllVehicle] = useState([]);
	const [allModel, setAllModel] = useState([])
	const [allDriver, setAllDriver] = useState([])
	const roleUser = useSelector(state => state.user.roleName)

	const fetchData = async controller => {
		const result = await multiple([
			{ method: "get", url: "/vehicleType/getAllActiveVehicleType", config: { signal: controller.signal } },
			{ method: "get", url: "/vendor/getAllActiveVendor", config: { signal: controller.signal } },
			{ method: "get", url: "/vehicle/getAllVehicle", config: { signal: controller.signal } },
			{ method: "get", url: "/vehicle/getAllVehicleModel", config: { signal: controller.signal } },
			{ method: "get", url: "/employee/getAvailableEmployeeInVehicle", config: { signal: controller.signal } },
		])
		if (result[0].status === 200) {
			setAllVehicleType(result[0].data.data.records.map(data => ({ label: data.name, value: data.id })) || [])
		}
		if (result[1].status === 200) {
			setAllVendor([{ label: COMPANY_NAME, value: COMPANY_NAME }, ...result[1].data.data.records.map(data => ({ label: data.name, value: data.id }))])
		}
		if (result[2].status === 200) {
			const vehicles = result[2].data.data || []
			setAllVehicle(vehicles)
		}
		if (result[3].status === 200) {
			const data = result[3].data.data.records || []
			setAllModel(data.map(e => ({ label: e.model, value: e.model })))
		}
		if (result[4].status === 200) {
			const data = result[4].data.data.records || []
			setAllDriver(data.map(e => { return { label: `${e.firstName} ${e.lastName}`, value: e.id, data: e } }))
		}
	}

	useEffect(() => {
		const controller = new AbortController();
		fetchData(controller)
		return () => {
			controller.abort()
		}
	}, [])

	useEffect(() => {
		if (info.registerDate) {
			calculateAge(info.registerDate)
		} else {
			dispatch(setInfo({ vehicleAge: "-" }))
		}
	}, [info.registerDate])

	const fetchDriverData = async (driverId) => {
		const result = await get("/employee/getAvailableEmployeeInVehicle", { id: driverId })
		if (result.status === 200) {
			const data = result.data.data.records || []
			setAllDriver(data.map(e => { return { label: `${e.firstName} ${e.lastName}`, value: e.id, data: e } }))
		}
	}

	const calculateAge = (dob) => {
		const diff = moment().diff(moment(dob, "YYYY-MM-DD"));
		const duration = moment.duration(diff);
		const years = Math.floor(duration.asYears());
		const months = Math.floor(duration.asMonths() % 12);
		const days = Math.floor(duration.asDays() % 30);
		const calVehicleAge = years + " ปี " + months + " เดือน " + days + " วัน"
		dispatch(setInfo({ vehicleAge: calVehicleAge }))
	};

	const onCloseModal = useCallback(() => {
		confirmPopup({
			title: "แจ้งเตือน",
			content: "ท่านต้องการยกเลิกการแก้ไขหรือไม่",
			onSubmit: () => {
				setMode("initial")
			}
		})
	}, [])

	const inputForm = [
		{
			rowData: [
				{
					type: "text",
					required: true,
					props: {
						id: "chassisNo",
						label: "เลขตัวถัง",
						variant: "outlined"
					}
				},
				{
					type: "text",
					required: true,
					props: {
						id: "lcnNo",
						label: "ป้ายทะเบียน",
						variant: "outlined"
					}
				},
				{
					type: "dropdown",
					required: true,
					props: {
						id: "vehicleTypeId",
						label: "ประเภทรถ",
						options: allVehicleType
					}
				},
				{
					type: "dropdown",
					props: {
						id: "vendorId",
						label: "บริษัท",
						options: allVendor,
						notNull: true
					}
				}
			]
		},
		{
			rowData: [
				{
					type: "autocomplete",
					required: true,
					props: {
						id: "model",
						label: "ยี่ห้อรถ",
						options: allModel,
						freeSolo: true
					}
				},
				{
					type: "text",
					props: {
						id: "modelAir",
						label: "เลขโมเดลแอร์",
						variant: "outlined"
					}
				},
				{
					type: "text",
					props: {
						id: "serialNo",
						label: "เลขซีเรียล (Serial number)",
						variant: "outlined"
					}
				},
				{
					type: "number",
					props: {
						id: "mileage",
						label: "เลขไมล์ปัจจุบัน",
						variant: "outlined"
					}
				}
			]
		},
		{
			rowData: [
				{
					type: "date",
					required: true,
					props: {
						id: "registerDate",
						label: "วันที่จดทะเบียน"
					}
				},
				{
					type: "text",
					props: {
						id: "vehicleAge",
						label: "อายุรถ",
						variant: "outlined",
						readOnly: true
					}
				},
				{
					type: "dropdown",
					required: true,
					props: {
						id: "status",
						label: "สถานะรถ",
						options: [
							{ label: "ใช้งาน", value: "active" },
							{ label: "ส่งซ่อม", value: "maintain" },
							{ label: "หยุดใช้งาน", value: "inactive" }
						],
					}
				},
				{}
			]
		},
		{
			isCollapse: true,
			label: "ข้อมูลพรบ.และภาษี",
			collapseData: [
				{
					rowData: [
						{
							type: "text",
							props: {
								id: "compulsoryNo",
								label: "เลขที่กรมธรรม์พรบ.",
								variant: "outlined"
							}
						},
						{
							type: "date",
							props: {
								id: "compulsoryExpiry",
								label: "วันหมดอายุพรบ."
							}
						},
						{
							type: "number",
							props: {
								id: "compulsoryPremium",
								label: "ค่าพรบ.",
								variant: "outlined"
							}
						},
						{
							type: "date",
							props: {
								id: "taxExpiry",
								label: "วันหมดอายุภาษีประจำปี"
							}
						}
					]
				},
			]
		},
		{
			isCollapse: true,
			label: "ข้อมูลประกันภัย",
			collapseData: [
				{
					rowData: [
						{
							type: "text",
							props: {
								id: "insuranceNo",
								label: "เลขที่กรมธรรม์ประกันภัย",
								variant: "outlined"
							}
						},
						{
							type: "date",
							props: {
								id: "insuranceExpiry",
								label: "วันหมดอายุประกันภัย"
							}
						},
						{
							type: "number",
							props: {
								id: "insuranceAmount",
								label: "ทุนประกันภัย",
								variant: "outlined"
							}
						},
						{
							type: "number",
							props: {
								id: "insurancePremium",
								label: "ค่าประกัน",
								variant: "outlined"
							}
						},
					]
				}
			]
		},
		{
			isCollapse: true,
			label: "ข้อมูลเพิ่มเติม",
			collapseData: [
				{
					rowData: [
						{
							type: "autocomplete",
							props: {
								id: "driverId",
								label: "คนขับประจำ",
								options: allDriver,
								filterOptions: (e) => (!info?.vendorId && !e.data?.vendorId) || (info?.vendorId == COMPANY_NAME ? null : info?.vendorId) === e.data?.vendorId,
								relateId: "vendorId"
							}, md: 3
						},
						mode === "edit" ? {
							type: "dropdown",
							props: {
								id: "active",
								label: "สถานะ",
								options: [
									{ label: "ใช้งาน", value: true },
									{ label: "ไม่ใช้งาน", value: false }
								],
							}, md: 3.15
						} : { md: 9 }
					]
				},
				{
					rowData: [
						{
							type: "text",
							props: {
								id: "remark",
								label: "หมายเหตุอื่นๆ",
								variant: "outlined",
								multiline: true,
								maxRows: 4
							}
						}
					]
				}
			]
		},
		{
			isCollapse: true,
			label: "ข้อมูลรายการตรวจบำรุง",
			collapseData: [
				{
					rowData: [
						{
							type: "dataTable",
							props: {
								columns: [
									{
										id: 'checkDateTime',
										label: 'รอบการตรวจ',
										fields: ["checkDateTime"]
									}
								],
								collapse: {
									columns: [
										{
											id: 'name',
											label: 'รายการตรวจ',
											fields: ["name"]
										},
										{
											id: 'status',
											label: 'สถานะ',
											fields: ["status"],
											valuesMap: [
												{ label: "ใช้งาน", value: "active" },
												{ label: "ส่งซ่อม", value: "maintain" },
												{ label: "เสียหาย", value: "damage" }
											]
										},
										{
											id: 'createdBy',
											label: 'Created by',
											fields: ["createdBy"]
										},
									],
									field: "checkList"
								},
								showDeleteButton: roleUser === "Owner",
								rows: info.checkListHistory,
								title: "ประวัติรายการตรวจ",
								tableContainerClassName: "max-h-[30vh]",
								onClick: e => {
									dispatch(setInfoById({ id: `checkListGroup`, payload: e }))
									mode2[1]("edit")
								},
								onDelete: (e, onSuccess) => {
									const ids = e.map(e => e.id) || []
									const removedJobs = info.checkListHistory.filter(e => !ids.includes(e.id))
									dispatch(setInfo({ checkListHistory: removedJobs }))
									onSuccess()
								},
								size: "small"
							}
						}
					]
				},
				{
					rowData: [
						{
							type: "button",
							props: {
								label: "เพิ่มรายการตรวจ",
								onClick: () => {
									mode2[1]("create")
								}
							}
						},
					]
				}
			]
		},
		{
			rowData: [
				{
					type: "button",
					props: {
						label: "บันทึก",
						onClick: () => {
							saveData()
						},
						className: "!bg-bpTheme-buttonSubmit"
					}
				}
			]
		}
	]
	const table = useMemo(() => [
		{
			rowData: [{
				type: "dataTable",
				props: {
					columns: [
						{
							id: 'no',
							label: 'เลขที่รถ',
							fields: ["no"]
						},
						{
							id: 'model',
							label: 'ยี่ห้อรถ',
							fields: ["model"]
						},
						{
							id: 'chassisNo',
							label: 'เลขตัวถัง',
							fields: ["chassisNo"]
						},
						{
							id: 'lcnNo',
							label: 'ป้ายทะเบียน',
							fields: ["lcnNo"]
						},
						{
							id: 'vehicleTypeName',
							label: 'ประเภทรถ',
							fields: ["vehicleTypeName"]
						},
						{
							id: 'vendorName',
							label: 'บริษัท',
							fields: ["vendorName"]
						},
						{
							id: 'status',
							label: 'สถานะรถ',
							fields: ["status"],
							valuesMap: [
								{ label: "ใช้งาน", value: "active" },
								{ label: "ส่งซ่อม", value: "maintain" },
								{ label: "หยุดใช้งาน", value: "inactive" }
							]
						}
					],
					rows: allVehicle,
					size: "small",
					showDeleteButton: roleUser === "Owner",
					title: "ข้อมูลรถ",
					showSystemFields: true,
					onClick: async (e) => {
						dispatch(clearInfo())
						await fetchDriverData(e.driverId)
						dispatch(setInfo({ ...e, vendorId: e.vendorId || COMPANY_NAME }))
						setMode("edit")
					},
					onDelete: (e, onSuccess) => {
						confirmPopup({
							onSubmit: async () => {
								const result = await del("/vehicle/deleteVehicle", { ids: e.map(data => data.id).join(',') })
								if (result.status === 200) {
									enqueueSnackbar('ลบสำเร็จ', { variant: "success" })
									dispatch(clearInfo())
									await fetchData(new AbortController())
									onSuccess()
								} else {
									enqueueSnackbar('ลบไม่สำเร็จ', { variant: "error" })
								}
							}
						})
					},
					controller: (paging) => get("/vehicle/getAllVehicle", { paging }),
					searchFields: [
						{ label: "ยี่ห้อรถ", value: "model" },
						{ label: "เลขตัวถัง", value: "chassisNo" },
						{ label: "ป้ายทะเบียน", value: "lcnNo" },
						{ label: "ประเภทรถ", value: "vehicleTypeName" },
						{ label: "บริษัทรถร่วม", value: "vendorName" },
						{ label: "สถานะรถ", value: "status" }
					]
				}
			}]
		},
		{
			noCol: true,
			rowData: [
				{
					type: "button",
					props: {
						label: "เพิ่มใหม่",
						onClick: () => {
							setMode("create")
							dispatch(clearInfo())
						}
					}
				}
			]
		}
	], [allVehicle])

	const saveData = useCallback(() => {
		save({
			data: info,
			onSubmit: async () => {
				const data = normalizeData(info)
				let response
				if (mode === "create") {
					response = await post("/vehicle/createVehicle", { ...data, vendorId: data.vendorId !== COMPANY_NAME ? data.vendorId : null, active: true })
				} else if (mode === "edit") {
					const modifiedData = {
						...data,
						checkListHistory: data.checkListHistory?.map(({ id, recStatus, ...rest }) => recStatus === "new" ? rest : { id, ...rest })
					};
					response = await post("/vehicle/updateVehicle", { ...modifiedData, vendorId: data.vendorId !== COMPANY_NAME ? data.vendorId : null })
				}
				if (response.status === 200) {
					setMode("initial")
					enqueueSnackbar('บันทึกสำเร็จ', { variant: "success" })
					fetchData(new AbortController())
				} else {
					enqueueSnackbar(warpUpFailResponse(response, 'บันทึกไม่สำเร็จ'), { variant: "error" })
				}
			},
			onCancel: () => {
				enqueueSnackbar('ยกเลิกรายการ', { variant: "info" })
			}
		})
	}, [info, mode])

	return (
		<>
			<Panel data={table}></Panel>
			<Modal open={mode === "create" || mode === "edit"} onClose={onCloseModal} className="form-modal">
				<Form title={"ข้อมูลรถ " + (info.no || "")} name="user-form" data={inputForm}></Form>
			</Modal>
			{mode2[0] === "create" || mode2[0] === "edit" ? <CheckList mode={mode2} /> : null}
		</>
	)
}