import { Box } from '@mui/material'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Panel from 'components/Panel'
import Modal from 'components/Modal'
import Form from 'components/Form'
import useAxios from 'hooks/useAxios'
import { setInfo, clearInfo } from 'slices/infoSlice'
import { DELIVERY_TYPE } from 'helper/DataFactory'

export default function Order() {
	const dispatch = useDispatch()
	const info = useSelector(state => state.info.ctx || {})
	const mode = useState("initial") //main form
	const [allBills, setAllBills] = useState([])
	const { get, multiple } = useAxios()

	const fetchData = async controller => {
		const result = await multiple([
			{ method: "get", url: "/order/getAllBills", config: { signal: controller.signal } },
		])
		if (result[0].status === 200) {
			const data = result[0].data.data || []
			setAllBills(data)
		}
	}

	const onCloseModal = useCallback(() => {
		mode[1]("initial")
		dispatch(clearInfo())
	}, [])

	useEffect(() => {
		const controller = new AbortController();
		fetchData(controller)
		return () => {
			setTimeout(() => controller.abort(), 1000); // waits 1 second before aborting
		};
	}, [])

	const inputForm = [
		{
			rowData: [
				{
					type: "display",
					props: {
						id: "billNo",
						label: "เลขที่บิล",
						variant: "outlined",
						readOnly: true
					}
				},
				{
					type: "display",
					props: {
						id: "invoiceNo",
						label: "เลขที่อินวอยซ์"
					}
				},
				{
					type: "display",
					props: {
						id: "no",
						label: "เลขที่ใบจอง",
						variant: "outlined",
						readOnly: true
					}
				}
			]
		},
		{
			rowData: [
				{
					type: "display",
					props: {
						id: "vehicleTypeName",
						label: "ชนิดรถ",
						variant: "outlined",
						readOnly: true
					}
				},
				{
					type: "display",
					props: {
						id: "ref1",
						label: "เลขอ้างอิง1",
						variant: "outlined",
						readOnly: true
					}
				},
				{
					type: "display",
					props: {
						id: "ref2",
						label: "เลขอ้างอิง2",
						variant: "outlined",
						readOnly: true
					}
				}
			]
		},
		{
			rowData: [
				{
					type: "display",
					props: {
						id: "status",
						label: "สถานะ",
						variant: "outlined",
						readOnly: true
					}
				}, {}, {}
			]
		},
		{
			isCollapse: true,
			label: "รายละเอียดใบงาน",
			defaultCollapse: true,
			collapseData: [
				{
					rowData: [
						{
							type: "dataTable",
							props: {
								columns: [
									{
										id: 'no',
										label: 'เลขที่ใบงาน',
										fields: ["no"]
									},
									{
										id: 'driverName',
										label: 'คนขับรถ',
										fields: ["driverName"]
									},
									{
										id: 'source',
										label: 'ต้นทาง',
										fields: ["source"]
									},
									{
										id: 'destination',
										label: 'ปลายทาง',
										fields: ["destination"]
									},
									{
										id: 'status',
										label: 'สถานะ',
										fields: ["status"]
									}
								],
								rows: info.jobs,
								title: "ใบงาน",
								tableContainerClassName: "max-h-[30vh]",
								multiSelect: false,
								size: "small",
							}
						}
					]
				},
			]
		},
		{
			isCollapse: true,
			label: "ประวัติการแก้ไขบิล",
			defaultCollapse: true,
			collapseData: [
				{
					rowData: [
						{
							type: "dataTable",
							props: {
								columns: [
									{
										id: 'no',
										label: 'เลขที่',
										fields: ["no"]
									},
									{
										id: 'invoiceNo',
										label: 'หมายเลขอินวอยซ์',
										fields: ["invoiceNo"]
									},
									{
										id: 'productName',
										label: 'สินค้า',
										fields: ["productName"]
									},
									{
										id: 'tel',
										label: 'เบอร์โทรติดต่อ',
										fields: ["tel"]
									},
									{
										id: 'quantity',
										label: 'จำนวน',
										fields: ["quantity"]
									},
									{
										id: 'jobNo',
										label: 'เลขที่ใบงาน',
										fields: ["jobNo"]
									},
									{
										id: 'orderNo',
										label: 'เลขที่ใบจอง',
										fields: ["orderNo"]
									}
									,
									{
										id: 'status',
										label: 'สถานะ',
										fields: ["status"]
									},
									{
										id: 'createdTime',
										label: 'เวลา',
										fields: ["createdTime"]
									}
								],
								rows: info.logs,
								title: "บิล",
								tableContainerClassName: "max-h-[30vh]",
								multiSelect: false,
								size: "small",
							}
						}
					]
				},
			]
		},
	]

	const table = useMemo(() => [
		{
			rowData: [{
				type: "dataTable",
				props: {
					columns: [
						{
							id: 'billNo',
							label: 'เลขที่บิล',
							fields: ["billNo"]
						},
						{
							id: 'invoiceNo',
							label: 'หมายเลขอินวอยซ์',
							fields: ["invoiceNo"]
						},
						{
							id: 'no',
							label: 'เลขทีใบจอง',
							fields: ["no"]
						},
						{
							id: 'senderName',
							label: 'ผู้ส่ง',
							fields: ["senderName"]
						},
						{
							id: 'vehicleTypeName',
							label: 'ประเภทรถ',
							fields: ["vehicleTypeName"]
						},
						{
							id: 'deliveryType',
							label: 'ประเภทการส่ง',
							fields: ["deliveryType"],
							valuesMap: DELIVERY_TYPE
						},
						{
							id: 'serviceType',
							label: 'ประเภทบริการ',
							fields: ["serviceType"]
						},
						{
							id: 'pickFromAddress',
							label: 'สถานที่รับ',
							fields: ["pickFromAddress"]
						},
						{
							id: 'address',
							label: 'สถานที่ส่ง',
							fields: ["address"]
						},
						{
							id: 'quantity',
							label: 'จำนวน',
							fields: ["quantity"]
						},
						{
							id: 'name',
							label: 'หน่วย',
							fields: ["name"]
						},
						{
							id: 'status',
							label: 'สถานะ',
							fields: ["status"]
						},
					],
					rows: allBills,
					size: "small",
					multiSelect: false,
					title: "ข้อมูลบิล",
					showSystemFields: true,
					onClick: async (e) => {
						const result = await get("/order/getBillById", { id: e.id })
						if (result.status === 200) {
							mode[1]("view")
							dispatch(setInfo(result.data.data))
						}
					},
					controller: (paging) => get("/order/getAllBills", { paging }),
					searchFields: [
						{ label: "หมายเลขอินวอยซ์", value: "invoiceNo" },
						{ label: "ผู้ส่ง", value: "senderName" },
						{ label: "ประเภทรถ", value: "vehicleTypeName" },
						{ label: "สถานะ", value: "status" }
					]
				}
			}]
		}
	], [allBills])

	return (
		<Box>
			<Panel data={table}></Panel>
			<Modal open={mode[0] === "view"} onClose={onCloseModal} className="form-modal">
				<Form title="ข้อมูลบิล" name="user-form" data={inputForm}></Form>
			</Modal>
		</Box>
	)
}