import { IconButton, List, ListItem, ListItemIcon, ListItemText, Popover } from '@mui/material'
import { Box } from '@mui/system'
import Avatar from 'components/Avatar'
import { SnackbarProvider } from 'notistack'
import React, { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Outlet, useNavigate } from 'react-router'
import MenuIcon from '@mui/icons-material/Menu';
import useAxios from 'hooks/useAxios'
import { setUser, setLogin } from 'slices/userSlice'
import { Logout } from '@mui/icons-material';
import { clearInfo } from 'slices/infoSlice';
import logo from 'assets/images/logo.png'

export default function DriverLayout({ isGeneralTemplate }) {
    const navigate = useNavigate()
    const { get } = useAxios()
    const dispatch = useDispatch()

    const fetchData = async controller => {
        const result = await get("/user/getUserInfo", {}, { signal: controller.signal })
        if (result.status === 200) {
            const data = result.data.data
            if (data.userData.template !== "driver-portal") {
                navigate("/" + data.userData.template)
            } else {
                dispatch(setUser({ ...data.userData, ctx: data.userData, appData: data.appData, islogin: true }))
            }
        } else if (result.data?.status?.description !== "canceled") {
            navigate("/login")
        }
    }

    useEffect(() => {
        const controller = new AbortController();
        fetchData(controller)
        return () => {
            controller.abort()
        };
    }, []);

    return (
        <SnackbarProvider maxSnack={3} autoHideDuration={3000}>
            <div className="relative">
                <Header />
                <Box height="calc(100vh - 64px)" className='flex justify-center m-auto !mt-[64px]'>
                    <Box className={`w-[500px] max-w-full overflow-scrolling-touch overscroll-none !overflow-x-hidden !overflow-y-auto transition-all scrollbar-thin scrollbar-thumb-bpTheme-scroll scrollbar-track-transparent fixed ${isGeneralTemplate ? "pb-40" : ""}`} height='inherit'>
                        <Outlet></Outlet>
                    </Box>
                </Box>
            </div>
        </SnackbarProvider>
    )
}

function Header() {
    const navigate = useNavigate()
    const { post } = useAxios()
    const dispatch = useDispatch()
    const name = useSelector(state => state.user.name)
    const profile = useSelector(state => state.user.profile)
    const [menuElm, setMenuElm] = useState(null);
    const menuOpen = Boolean(menuElm);

    const onMenuClick = (event) => {
        setMenuElm(event.currentTarget);
    };

    const onMenuClose = () => {
        setMenuElm(null);
    };

    const logout = useCallback(async () => {
        const result = await post("/logout", {}, { ignoreCredential: true })
        if (result.status === 200) {
            dispatch(clearInfo())
            dispatch(setLogin(false))
        }
    }, [])
    return (
        <>
            <Box className='flex justify-between items-center pl-5 pr-5 bg-bpTheme-driverHeader h-16 shadow-driverHeader z-50 fixed w-full top-0'>
                <Box overflow="hidden">
                    <IconButton onClick={() => { navigate(""); }}>
                        <MenuIcon className='text-white' />
                    </IconButton>
                </Box>
                <Box maxWidth={{ xs: "calc(100% - 60px)", md: "100%" }}>
                    <img className='h-[60px]' src={logo} />
                </Box>

                <Avatar onClick={onMenuClick} className='ml-2 cursor-pointer' prefix={"user/profile"} target={profile} name={name}></Avatar>
            </Box>
            <Popover
                open={menuOpen}
                anchorEl={menuElm}
                onClose={onMenuClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
            >
                <List>
                    <ListItem button onClick={logout}>
                        <ListItemIcon>
                            <Logout />
                        </ListItemIcon>
                        <ListItemText primary="Logout" />
                    </ListItem>
                </List>
            </Popover>
        </>
    )
}