import { Box, Grid, Button, Divider } from '@mui/material';
import { PermIdentity, Phone, Place, Thermostat } from '@mui/icons-material';
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import useAxios from 'hooks/useAxios';
import { useNavigate } from 'react-router-dom';
import { setInfo } from 'slices/infoSlice';
import DisplayInformation from '../components/displayInformation';

export default function OutStandingOrder() {
	const navigate = useNavigate()
	const dispatch = useDispatch()
	const userId = useSelector(state => state.user.id)
	const { get } = useAxios()
	const [allOutStandingOrder, setAllOutStandingOrder] = useState([])

	const fetchData = async controller => {
		const result = await get("/outStandingOrder/getOutStandingOrderByDriver", { id: userId }, { signal: controller.signal })
		if (result.status === 200) {
			const data = result.data.data.records || []
			setAllOutStandingOrder(data)
		}
	}

	useEffect(() => {
		if (userId) {
			const controller = new AbortController();
			fetchData(controller)
			return () => {
				controller.abort()
			}
		} else {
			navigate("/driver-portal")
		}
	}, [])

	const submitData = (data) => {
		dispatch(setInfo(data))
		navigate("/driver-portal/confirmResend")
	}

	return (
		<Box className="!p-2 " >
			{allOutStandingOrder.length > 0 ? allOutStandingOrder?.map((data, i) => {
				const displayData = [
					{
						label: "ทะเบียนรถ",
						data: data.truckLicense
					},
					{
						label: "คนขับรถ",
						data: data.driverName
					},
					{
						label: "เลขที่ใบคุม",
						data: data.shipmentNo
					},
					{
						label: "อุณหภูมิ",
						type: "temperature",
						data: data.lowestTemp
					},
					{
						label: "จำนวนสินค้า",
						data: data.quantity <= 0 ? 'ดูหน้างาน' : data.quantity
					},
					{
						label: "สถานะ",
						data: data.status
					},
					{
						label: "หมายเหตุ",
						data: data.remark
					},
				]
				const subDisplayData = [
					{
						label: "บริษัทขนส่ง",
						data: data.transportCompany
					},
					{
						label: "ประเภทรถ",
						data: data.vehicleTypeName
					},
					{
						label: "ปลายทาง",
						data: data.route
					},
				]
				return <div key={"OutStandingOrder" + i}>
					<Grid container spacing={1} columns={12} alignItems="center" className="driver_grid_lg">
						<Grid item xs={12} className="driver_highlight">
							<div>{data.no}</div>
						</Grid>
					</Grid>
					<Grid container spacing={1} columns={12} className="driver_grid_lg" alignItems="center">
						<Grid item xs={2.5} className="self-start text-center ">
							<div className=" font-bold !h-full" >ส่ง</div>
							<div className="text-orange-400">⋮</div>
							<Place className="text-orange-400" />
						</Grid>
						<Grid item xs={9.5} className="font-bold">
							<div className="driver_highlight">{data.receiverName}</div>
							<div className="driver_address" >
								{data.shipToAddress}
							</div>
							<Divider variant="contained" className="my-2"></Divider>
							<div className="flex flex-row text-gray-400 text-base w-full">
								<div className="flex flex-row w-[55%]">
									<div><PermIdentity className="mr-1 !h-5 text-orange-400" /></div>
									<div>{data.shippingContactName}</div>
								</div>
								<div className="w-[45%]">
									<Phone className="mr-1 !h-5 text-orange-400" />
									<span>{data.shippingContactTel}</span>
								</div>
							</div>
						</Grid>
					</Grid>
					{displayData.map((data, i) => <DisplayInformation key={`data-${i}`} data={data} />)}
					<Grid item xs={12} className="mt-1 border-solid rounded-lg border-1 border-gray-600 p-2">
						{subDisplayData.map((data, i) => <DisplayInformation key={`sub-data-${i}`} data={data} />)}
					</Grid>
					<Grid className="py-2 text-center" alignItems={"center"} >
						<Button fullWidth variant="contained" className="!bg-bpTheme-buttonSubmit driver_button" onClick={() => submitData(data)}>ดำเนินการต่อ</Button>
					</Grid>
					<Divider variant="contained" className="driver_divider"></Divider>
				</div>
			}) : <div className="driver_no_data">ไม่มีข้อมูลงานที่ต้องส่งใหม่</div>}
		</Box>
	)
}