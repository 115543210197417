import { Box } from '@mui/material'
import React, { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Modal from 'components/Modal'
import Form from 'components/Form'
import { useSnackbar } from 'notistack'
import { clearInfoById, setInfoById } from 'slices/infoSlice'
import { validation, normalizeData } from 'utilities/validator'
import usePopup from 'hooks/usePopup'
import useAxios from 'hooks/useAxios'
import AdvanceSearch from 'components/AdvanceSearch'
import { warpUpFailResponse } from 'utilities/utils'
import { memoizedSelectAdvanceInfo } from 'redux/memorize'

export default function AddJob({ setJobMode }) {
	const dispatch = useDispatch()
	const { enqueueSnackbar } = useSnackbar();
	const { post, get, multiple } = useAxios();
	const info = useSelector(state => state.info)
	const paging = useState({})
	const [mode, setMode] = useState("orders")
	const searchInfo = useSelector(memoizedSelectAdvanceInfo)
	const { confirmPopup } = usePopup();
	const [allTruckType, setAllTruckType] = useState([])
	const [allLcnNo, setAllLcnNo] = useState([])
	const [allRoute, setAllRoute] = useState([])
	const [allVendor, setAllVendor] = useState([])
	const [allDriver, setAllDriver] = useState([])
	const [allWorker, setAllWorker] = useState([])
	const [allOrder, setAllOrder] = useState([])
	const [allVehicleType, setAllVehicleType] = useState([]);
	const [allCustomer, setAllCustomer] = useState([])
	const roleUser = useSelector(state => state.user.roleName)
	const [allAdditionalPrice, setAllAdditionalPrice] = useState([]);

	const fetchData = async controller => {
		const result = await multiple([
			{ method: "get", url: "/vehicle/getAllActiveVehicle", config: { signal: controller.signal } },
			{ method: "get", url: "/route/getAllActiveRoute", config: { signal: controller.signal } },
			{ method: "get", url: "/vendor/getAllActiveVendor", config: { signal: controller.signal } },
			{ method: "get", url: "/employee/getAllEmployeeByRole", params: { role: "Driver" }, config: { signal: controller.signal } },
			{ method: "get", url: "/vehicleType/getAllActiveVehicleType", config: { signal: controller.signal } },
			{ method: "get", url: "/additionalPrice/getAllActiveAdditionalPrice", params: { type: "job,driver" }, config: { signal: controller.signal } },
			{ method: "get", url: "/division/getAllActiveDivision", config: { signal: controller.signal } },
			{ method: "get", url: "/order/getOrderToPlan", config: { signal: controller.signal } },
			{ method: "get", url: "/employee/getAllEmployeeByPosition", params: { position: "พนักงานยกสินค้า" }, config: { signal: controller.signal } },
		])
		if (result[0].status === 200) {
			const data = result[0].data.data.records || []
			const groupedData = data.reduce((result, item) => {
				const key = item.vehicleTypeName;
				const value = item.vehicleTypeId;
				if (!result[key]) {
					result[key] = value;
				}
				return result;
			},
				{});
			setAllTruckType(Object.entries(groupedData).map(([key, value]) => { return { label: key, value: value } }))
			setAllLcnNo(data.map(e => ({ label: `${e.lcnNo} / ${e.routeName}`, value: e.id, data: e })))
		}
		if (result[1].status === 200) {
			const data = result[1].data.data.records || []
			setAllRoute(data.map(e => { return { label: `${e.source} - ${e.destination}`, value: e.id } }).sort((a, b) => a.label <= b.label))
		}
		if (result[2].status === 200) {
			const data = result[2].data.data.records || []
			setAllVendor([{ label: "own fleet", value: "own fleet" }, ...data.map(e => { return { label: e.name, value: e.id } })])
		}
		if (result[3].status === 200) {
			const data = result[3].data.data.records || []
			setAllDriver(data.map(e => { return { label: `${e.firstName} ${e.lastName}`, value: e.id, data: e } }))
		}
		if (result[4].status === 200) {
			setAllVehicleType(result[4].data.data.records.map(data => ({ label: data.name, value: data.id })) || [])
		}
		if (result[5].status === 200) {
			const data = result[5].data.data.records || []
			setAllAdditionalPrice(data.map(data => ({ label: data.name, value: data.id })) || [])
		}
		if (result[6].status === 200) {
			const data = result[6].data.data.records || []
			setAllCustomer(data.map(e => ({ label: (e.customerName ? `${e.customerName} / ` : "") + e.name, value: e.id })))
		}
		if (result[7].status === 200) {
			const data = result[7].data.data.records || []
			setAllOrder(data)
		}
		if (result[8].status === 200) {
			const data = result[8].data.data.records || []
			setAllWorker(data.map(e => { return { label: `${e.firstName} ${e.lastName}`, value: e.id, data: e } }))
		}
	}

	useEffect(() => {
		const controller = new AbortController()
		fetchData(controller)
		return () => {
			controller.abort()
		}
	}, [])

	const search = async (data) => {
		const response = await get("/order/getOrderToPlan", { search: data, paging: paging[0] })
		if (response.status === 200) {
			const data = response.data.data || []
			setAllOrder(data)
		}
	}

	const searchFields = [
		{
			type: "autocomplete",
			className: "sm:!max-w-[350px]",
			props: {
				id: "divisionId",
				label: "ชื่อลูกค้า",
				options: allCustomer,
			}
		},
		{
			type: "date",
			props: {
				label: "ตั้งแต่วันที่",
				id: "startDate",
				maxDate: info.advanceSearch?.toDate || new Date(),
			}
		},
		{
			type: "date",
			props: {
				label: "ถึงวันที่",
				id: "toDate",
				minDate: info.advanceSearch?.startDate,
				maxDate: new Date(),
			}
		},
		{
			type: "autocomplete",
			props: {
				label: "ประเภทรถ",
				id: "vehicleType",
				options: allVehicleType,
			}
		},
		{
			type: "text",
			props: {
				label: "จังหวัดปลายทาง",
				id: "provinceName",
			}
		},
	]

	const ordersInfoForm = [
		{
			rowData: [
				{
					type: "dataTable",
					props: {
						columns: [
							{
								id: 'orderNo',
								label: 'เลขที่ออเดอร์',
								fields: ["orderNo"]
							},
							{
								id: 'senderName',
								label: 'ผู้ส่ง',
								fields: ["senderName"]
							},
							{
								id: 'pickDate',
								label: 'วันที่เข้ารับสินค้า',
								fields: ["pickDate"],
								type: "datetime"
							},
							{
								id: 'temperature',
								label: 'อุณหภูมิ',
								fields: ["temperature"]
							},
							{
								id: 'pickupAddress',
								label: 'ต้นทาง',
								fields: ["pickupAddress"]
							},
							{
								id: 'shippingAddress',
								label: 'ปลายทาง',
								fields: ["shippingAddress"]
							},
							{
								id: 'remark',
								label: 'หมายเหตุ',
								fields: ["remark"]
							},
						],
						collapse: {
							columns: [
								{
									id: 'detail',
									label: 'รายละเอียดสินค้า',
									fields: ["detail"]
								},
								{
									id: 'temperature',
									label: 'อุณหภูมิ',
									fields: ["temperature"],
									type: "number"
								},
								{
									id: 'pickupAddress',
									label: 'ที่อยู่รับสินค้า',
									fields: ["pickupAddress"]
								},
								{
									id: 'shippingAddress',
									label: 'ที่อยู่ส่งสินค้า',
									fields: ["shippingAddress"]
								},
								{
									id: 'totalPickup',
									label: 'จำนวนสินค้าที่รับ',
									fields: ["totalPickup"],
									type: "number"
								},
								{
									id: 'totalShipping',
									label: 'จำนวนสินค้าที่ส่ง',
									fields: ["totalShipping"],
									type: "number"
								},
								{
									id: 'unit',
									label: 'หน่วย',
									fields: ["unit"]
								},
								{
									id: 'remark',
									label: 'หมายเหตุ',
									fields: ["remark"]
								},
							],
							field: "items",
						},
						id: "orders",
						rows: allOrder,
						multiSelect: true,
						title: "ออเดอร์",
						controller: (paging) => get("/order/getOrderToPlan", { search: searchInfo, paging }),
						customToolbar: {
							component: AdvanceSearch,
							props: {
								handleSearch: search,
								components: searchFields
							}
						},
						state: { paging },
						tableContainerClassName: "min-h-[30vh] max-h-[60vh] min-w-[450px]",
						size: "small",
						hideSelected: true
					}
				}
			]
		},
		{
			noCol: true,
			align: "right",
			rowData: [
				{
					type: "button",
					props: {
						label: "ต่อไป",
						onClick: () => {
							if (info["job-new"].orders.length) {
								setMode("jobInfo")
							} else {
								enqueueSnackbar("กรุณาเลือก order ที่ต้องการแพลน", { variant: "error" })
							}
						}
					}
				}
			]
		}
	]

	const jobInfoForm = [
		{
			rowData: [
				{
					type: "datetime",
					required: true,
					props: {
						id: "etdTime",
						label: "วันที่จะเข้ารับสินค้า",
						minDate: roleUser === "Owner" || new Date(),
					}
				},
				{
					type: "datetime",
					required: true,
					props: {
						id: "etaTime",
						label: "วันที่จบงาน",
						minDate: info["job-new"]?.etdTime,
					}
				},
				{
					type: "datetime",
					props: {
						id: "takeCarTime",
						label: "วันที่รับรถ",
						maxDate: info["job-new"]?.etdTime,
					}
				}
			]
		},
		{
			rowData: [
				{
					type: "autocomplete",
					props: {
						id: "vendorId",
						label: "บริษัท",
						options: allVendor
					}
				},
				{
					type: "autocomplete",
					props: {
						id: "vehicleTypeId",
						label: "ประเภทรถ",
						options: allTruckType,
						onChange: () => {
							dispatch(setInfoById({ id: "job-new", payload: { vehicleId: null } }))
						},
					}
				},
				{
					type: "autocomplete",
					props: {
						id: "vehicleId",
						label: "ทะเบียนรถ",
						options: allLcnNo,
						filterOptions: (e) => info["job-new"]?.vehicleTypeId === e.data?.vehicleTypeId && (info["job-new"]?.vendorId === "own fleet" ? !e.data?.vendorId : info["job-new"]?.vendorId === e.data?.vendorId),
						onChange: (id) => {
							if (!info["job-new"]?.driverId) {
								const driverId = allLcnNo.find(e => e.value === id)?.data?.driverId
								dispatch(setInfoById({ id: "job-new", payload: { driverId } }))
							}
						},
						relateId: "vendorId"
					}
				}
			]
		},
		{
			rowData: [
				{
					type: "autocomplete",
					props: {
						id: "driverId",
						label: "พนักงานขับรถ",
						options: allDriver,
						filterOptions: (e) => (info["job-new"]?.vendorId === "own fleet" ? !e.data?.vendorId : info["job-new"]?.vendorId === e.data?.vendorId) && info["job-new"]?.driverId2 !== e.data?.id,
						relateId: "vendorId"
					}
				},
				{
					type: "autocomplete",
					props: {
						id: "driverId2",
						label: "พนักงานขับรถ (สำรอง)",
						options: allDriver,
						filterOptions: (e) => (info["job-new"]?.vendorId === "own fleet" ? !e.data?.vendorId : info["job-new"]?.vendorId === e.data?.vendorId) && info["job-new"]?.driverId !== e.data?.id,
						relateId: "vendorId"
					}
				},
				{},
			]
		},
		{
			rowData: [
				{
					type: "autocomplete",
					props: {
						id: "workerId",
						label: "พนักงานยกของ",
						options: allWorker,
						filterOptions: (e) => (info["job-new"]?.vendorId === "own fleet" ? !e.data?.vendorId : info["job-new"]?.vendorId === e.data?.vendorId) && info["job-new"]?.workerId2 !== e.data?.id,
						relateId: "vendorId"
					}
				},
				{
					type: "autocomplete",
					props: {
						id: "workerId2",
						label: "พนักงานยกของ (สำรอง)",
						options: allWorker,
						filterOptions: (e) => (info["job-new"]?.vendorId === "own fleet" ? !e.data?.vendorId : info["job-new"]?.vendorId === e.data?.vendorId) && info["job-new"]?.workerId !== e.data?.id,
						relateId: "vendorId",
					}
				},
				{}
			]
		},
		{
			isCollapse: true,
			label: "รายการค่าใช้จ่ายเพิ่มเติม",
			collapseData: [
				{
					rowData: [
						{
							type: "list",
							props: {
								id: "additionalPrice",
								showDuplicate: mode === "create",
								data: [
									{
										rowData: [
											{
												type: "autocomplete",
												props: {
													id: "addPriceId",
													label: "ค่าใช้จ่ายเพิ่มเติม",
													options: allAdditionalPrice,
												},
												md: 5
											},
											{
												type: "number",
												props: {
													id: "draftAmount",
													label: "จำนวนที่ขอเบิก",
												},
												md: 5
											},
											{
												type: "custom",
												component: <Box className="h-[inherit] flex items-center justify-center"> บาท </Box>,
												md: 2
											}
										]
									}
								],
							}
						},
					]
				},
			]
		},
		{
			rowData: [
				{
					type: "text",
					props: {
						id: "remark",
						label: "หมายเหตุ",
						variant: "outlined",
						multiline: true,
						maxRows: 4
					}
				}
			]
		},
		{
			noCol: true,
			className: "flex justify-between",
			rowData: [
				{
					type: "button",
					props: {
						label: "ย้อนกลับ",
						onClick: () => {
							setMode("orders")
						}
					}
				},
				{
					type: "button",
					props: {
						label: "ไปต่อ",
						onClick: () => {
							if (validation(info["job-new"])) {
								setMode("review")
							}
						}
					}
				}
			]
		}
	]

	const reviewForm = [
		{
			rowData: [
				{
					type: "display",
					props: {
						id: "vendorId",
						label: "บริษัทรถร่วม",
						valuesMap: allVendor.reduce((acc, cur) => {
							acc[cur.value] = cur.label;
							return acc;
						},
							{})
					}
				},
				{
					type: "display",
					props: {
						id: "vehicleTypeId",
						label: "ประเภทรถ",
						valuesMap: allVehicleType.reduce((acc, cur) => {
							acc[cur.value] = cur.label;
							return acc;
						},
							{})
					}
				},
				{
					type: "display",
					props: {
						id: "vehicleId",
						label: "ทะเบียนรถ",
						valuesMap: allLcnNo.reduce((acc, cur) => {
							acc[cur.value] = cur.label;
							return acc;
						},
							{})
					}
				}
			]
		},
		{
			rowData: [
				{
					type: "display",
					props: {
						id: "driverId",
						label: "พนักงานขับรถ",
						valuesMap: allDriver.reduce((acc, cur) => {
							acc[cur.value] = cur.label;
							return acc;
						},
							{})
					}
				},
				{
					type: "display",
					props: {
						id: "driverId2",
						label: "พนักงานขับรถ (สำรอง)",
						valuesMap: allDriver.reduce((acc, cur) => {
							acc[cur.value] = cur.label;
							return acc;
						},
							{})
					}
				},
				{}
			]
		},
		{
			rowData: [
				{
					type: "display",
					props: {
						id: "workerId",
						label: "พนักงานยกของ",
						valuesMap: allWorker.reduce((acc, cur) => {
							acc[cur.value] = cur.label;
							return acc;
						},
							{})
					}
				},
				{
					type: "display",
					props: {
						id: "workerId2",
						label: "พนักงานยกของ (สำรอง)",
						valuesMap: allWorker.reduce((acc, cur) => {
							acc[cur.value] = cur.label;
							return acc;
						},
							{})
					}
				},
				{}
			]
		},
		{
			rowData: [
				{
					type: "display",
					props: {
						id: "etdTime",
						type: "datetime",
						label: "วันที่จะเข้ารับสินค้า",
					}
				},
				{
					type: "display",
					props: {
						id: "etaTime",
						type: "datetime",
						label: "วันที่จบงาน",
					}
				},
				{
					type: "display",
					props: {
						id: "takeCarTime",
						type: "datetime",
						label: "วันที่รับรถ",
					}
				}
			]
		},
		{
			isCollapse: true,
			label: "รายการค่าใช้จ่ายเพิ่มเติม",
			defaultCollapse: true,
			collapseData: [
				{
					rowData: [
						{
							type: "list",
							props: {
								id: "additionalPrice",
								showDuplicate: mode === "create",
								data: [
									{
										rowData: [
											{
												type: "autocomplete",
												props: {
													id: "addPriceId",
													label: "ค่าใช้จ่ายเพิ่มเติม",
													options: allAdditionalPrice
												},
												md: 5
											},
											{
												type: "number",
												props: {
													id: "draftAmount",
													label: "จำนวน"
												},
												md: 5
											},
											{
												type: "custom",
												component: <Box className="h-[inherit] flex items-center justify-center"> บาท </Box>,
												md: 2
											}
										]
									}
								],
								readOnly: true
							}
						},
					]
				},
			]
		},
		{
			rowData: [
				{
					type: "dataTable",
					props: {
						columns: [
							{
								id: 'orderNo',
								label: 'เลขที่ออเดอร์',
								fields: ["orderNo"]
							},
							{
								id: 'senderName',
								label: 'ผู้ส่ง',
								fields: ["senderName"]
							},
							{
								id: 'pickDate',
								label: 'วันที่เข้ารับสินค้า',
								fields: ["pickDate"],
								type: "datetime"
							},
							{
								id: 'temperature',
								label: 'อุณหภูมิ',
								fields: ["temperature"]
							},
							{
								id: 'pickupAddress',
								label: 'ต้นทาง',
								fields: ["pickupAddress"]
							},
							{
								id: 'shippingAddress',
								label: 'ปลายทาง',
								fields: ["shippingAddress"]
							},
							{
								id: 'remark',
								label: 'หมายเหตุ',
								fields: ["remark"]
							},
						],
						collapse: {
							columns: [
								{
									id: 'detail',
									label: 'รายละเอียดสินค้า',
									fields: ["detail"]
								},
								{
									id: 'temperature',
									label: 'อุณหภูมิ',
									fields: ["temperature"],
									type: "number"
								},
								{
									id: 'pickupAddress',
									label: 'ที่อยู่รับสินค้า',
									fields: ["pickupAddress"]
								},
								{
									id: 'shippingAddress',
									label: 'ที่อยู่ส่งสินค้า',
									fields: ["shippingAddress"]
								},
								{
									id: 'totalPickup',
									label: 'จำนวนสินค้าที่รับ',
									fields: ["totalPickup"],
									type: "number"
								},
								{
									id: 'totalShipping',
									label: 'จำนวนสินค้าที่ส่ง',
									fields: ["totalShipping"],
									type: "number"
								},
								{
									id: 'unit',
									label: 'หน่วย',
									fields: ["unit"]
								},
								{
									id: 'remark',
									label: 'หมายเหตุ',
									fields: ["remark"]
								},
							],
							field: "items",
						},
						rows: info["job-new"]?.orders,
						multiSelect: false,
						tableContainerClassName: "max-h-[500px]",
						size: "small",
					}
				}
			]
		},
		{
			noCol: true,
			className: "flex justify-between",
			rowData: [
				{
					type: "button",
					props: {
						label: "ย้อนกลับ",
						onClick: () => {
							setMode("jobInfo")
						}
					}
				},
				{
					type: "button",
					props: {
						className: "!ml-40 !bg-bpTheme-buttonSubmit",
						label: "บันทึก",
						onClick: () => {
							saveData()
						}
					}
				}
			]
		}
	]

	const saveData = useCallback(() => {
		confirmPopup({
			onSubmit: async () => {
				const data = normalizeData(info[`job-new`])
				let response = await post("/job/createJob", { ...data, active: true, orders: info["job-new"]?.orders, additionalPriceDelivery: data?.additionalPrice ? data?.additionalPrice.filter(e => e.amount != 0) : undefined })
				if (response.status === 200) {
					setMode("orders")
					setJobMode("initial")
					enqueueSnackbar('บันทึกสำเร็จ', { variant: "success" })
					fetchData(new AbortController())
				} else {
					enqueueSnackbar(warpUpFailResponse(response, 'บันทึกไม่สำเร็จ'), { variant: "error" })
				}
			},
			onCancel: () => {
				enqueueSnackbar('ยกเลิกรายการ', { variant: "info" })
			}
		})
	}, [info])

	return (
		<>
			<Form id={`job-new`} title={"ข้อมูลใบงาน"} name="user-form" data={mode === "orders" ? ordersInfoForm : mode === "jobInfo" ? jobInfoForm : reviewForm}></Form>
		</>
	)
}