import { FormLabel, Typography } from '@mui/material';
import { format } from 'date-fns';
import numeral from 'numeral';
import React from 'react';
import { useSelector } from 'react-redux';
import { toBuddhistYear } from 'utilities/utils';
import { extractInfo } from 'utilities/utils';

export default function Display({ parentId, id, label, valuesMap, type = "string", children, ...other }) {
    const value = useSelector(state => extractInfo(state, parentId, id));

    const getValue = (value) => valuesMap ? valuesMap[value] : formatValue(value);

    const formatValue = (str) => {
        if (str === null || str === undefined) {
            return;
        }
        const date = new Date(str);
        if (type === "date") {
            return format(toBuddhistYear(date), 'dd/MM/yyyy');
        } else if (type === "datetime") {
            return format(toBuddhistYear(date), 'dd/MM/yyyy HH:mm');
        } else if (type === "number") {
            return numeral(str).format('0,0[.]00');
        } else if (type === "multiline") {
            return str?.split("\n").map((line, index) => (
                <React.Fragment key={line + index}>
                    {line}
                    {index < str.split("\n").length - 1 && <br />}
                </React.Fragment>
            ));
        } else {
            return str;
        }
    };

    return (
        <>
            <FormLabel component="legend">{label}</FormLabel>
            <Typography variant="body2">
                {children || (value ? getValue(value) : "-")}
            </Typography>
        </>
    );
}
