import { Box, Grid, Button, Divider } from '@mui/material';
import { PinDrop } from '@mui/icons-material';
import React, { useCallback, useEffect, useState } from 'react'
import useAxios from 'hooks/useAxios';
import Avatar from 'components/Avatar';
import { useDispatch, useSelector } from 'react-redux';
import { useSnackbar } from 'notistack';
import { setInfo } from 'slices/infoSlice';
import { resizeImage } from 'utilities/utils';
import { MAX_FILE_SIZE } from 'helper/DataFactory';
import ProgressBar from '../components/progressBar';
import MileageModal from '../components/mileageModal';
import { DRIVER_PAGE_TAKE_CAMERA } from 'helper/DataFactory';
import { DRIVER_PAGE_TAKE_SIGN } from 'helper/DataFactory';
import ShowTime from '../components/showTime';
import { getSessionUUID } from 'utilities/utils';
import { DRIVER_PAGE_TAKE_DOCUMENT } from 'helper/DataFactory';
import { warpUpFailResponse } from 'utilities/utils';
import ProgressModal from '../components/progressModal';

export default function CheckOut({ type, onPageChange }) {
	const dispatch = useDispatch()
	const { enqueueSnackbar } = useSnackbar();
	const info = useSelector(state => state.info.ctx || {})
	const { post } = useAxios()
	const { upload } = useAxios(false)
	const [currentMap, setCurrentMap] = useState(null)
	const [latitude, setLatitude] = useState(null)
	const [longitude, setLongitude] = useState(null)
	const [mode, setMode] = useState("initial")
	const [progress, setProgress] = useState({ text: "กำลังบีบอัดไฟล์...", current: 0 });
	const [openProgressBar, setOpenProgressBar] = useState(false)

	function getLocation() {
		if (navigator.geolocation) {
			navigator.geolocation.getCurrentPosition(showPosition, () => { }, { enableHighAccuracy: true });
		}
	}
	function showPosition(position) {
		const latitude = position.coords.latitude;
		const longitude = position.coords.longitude;
		setCurrentMap("https://www.google.com/maps?q=" + latitude + "," + longitude + "&z=17&output=embed")
		setLatitude(latitude)
		setLongitude(longitude)
	}

	useEffect(() => {
		if (type === "shipping") {
			if (info.checkComplete) {
				dispatch(setInfo({ complete: true, resend: false }));
			} else if (info.checkResend) {
				dispatch(setInfo({ complete: false, resend: true }));
			} else {
				dispatch(setInfo({ complete: false, resend: false }));
			}
		}

		if (info?.mileageIn) {
			dispatch(setInfo({ mileageOut: info.mileageIn }))
		}

		getLocation()
		const timerMap = setInterval(() => {
			getLocation()
		}, process.env.REACT_APP_REFRESH_LOCATION_TIME)

		return () => {
			clearInterval(timerMap)
		}
	}, [])

	const goBack = () => {
		onPageChange(type === "pickup" ? DRIVER_PAGE_TAKE_CAMERA : DRIVER_PAGE_TAKE_SIGN)
	}

	const onCloseModal = useCallback(() => {
		setMode("initial")
	}, [])

	const inputMileage = () => {
		setMode("edit")
	}

	const loadFile = async (dataFile, showValue) => {
		const imageUrl = dataFile || [];

		if (!imageUrl.length) {
			return []
		}

		setOpenProgressBar(true);
		setProgress(() => ({ text: "กำลังบีบอัดไฟล์...", current: 0 }));

		let imgs = [];
		for (let i = 0; i < imageUrl.length; i++) {
			const img = imageUrl[i];
			const resizeImg = await resizeImage(img, MAX_FILE_SIZE, (percent) => {
				setProgress((prev) => ({ ...prev, current: ((percent + (100 * i)) / imageUrl.length) }))
			});
			imgs.push(resizeImg);
		}

		setProgress(() => ({ text: "กำลังอัปโหลดไฟล์...", current: 0 }));

		let result = [];
		for (let i = 0; i < imgs.length; i++) {
			const img = imgs[i];
			const uploadResult = await upload({ prefix: `driver/checkOut`, file: img });
			setProgress((prev) => ({ ...prev, current: ((i + 1) / imgs.length) * 100 }));
			result.push(uploadResult);
		}

		setOpenProgressBar(false);

		let images = [];
		if (result.every(e => e.status === 200)) {
			images = result.map((e) => ({
				isShow: showValue,
				fileData: e.data.data,
			}));
		}

		return images;
	};

	const saveData = async () => {
		try {
			if (!info.mileageOut) {
				enqueueSnackbar("กรุณากรอกเลขไมล์ปัจจุบัน", {
					variant: "error", anchorOrigin: {
						vertical: 'top',
						horizontal: 'center',
					}
				})
				return
			}

			setMode("initial")
			const imagesCustomer = await loadFile(info.imageUrlCustomer, true);
			const imagesAdmin = await loadFile(info.imageUrlAdmin, false);
			const images = [...imagesCustomer, ...imagesAdmin];

			let imgSignature = null;
			const signatureResponse = await upload({ prefix: `driver/signature`, file: info.signatureImg })
			if (signatureResponse.status === 200) {
				imgSignature = signatureResponse.data.data
			}

			const sessionUUID = images.length || imgSignature ? getSessionUUID() : null;

			const payload = type === "pickup" ?
				{
					jobId: info.jobId,
					locationId: info.locationId,
					mapLat: latitude,
					mapLong: longitude,
					mileage: info.mileageOut,
					vehicleId: info.vehicleId,
					remark: "CheckOut ต้นทาง",
					imgSessionId: sessionUUID,
					damaged: info.damaged,
					sealNo: info.sealNo,
					images,
					imgSignature,
					type
				} :
				{
					jobId: info.jobId,
					locationId: info.locationId,
					mapLat: latitude,
					mapLong: longitude,
					mileage: info.mileageOut,
					vehicleId: info.vehicleId,
					remark: "CheckOut ปลายทาง",
					imgSessionId: sessionUUID,
					reasonId: info.causeReject,
					complete: info.complete,
					resend: info.resend,
					returnImmediately: info.returnImmediately,
					images,
					imgSignature,
					type
				}
			let response = await post("/takeOrder/updateCheckOut", payload)
			if (response.status === 200) {
				enqueueSnackbar('บันทึกสำเร็จ', { variant: "success" })
				onPageChange(DRIVER_PAGE_TAKE_DOCUMENT)
			} else {
				enqueueSnackbar(warpUpFailResponse(response, 'บันทึกไม่สำเร็จ'), {
					variant: "error", anchorOrigin: {
						vertical: 'top',
						horizontal: 'center',
					}
				})
			}
		} catch (e) {
			console.log(e)
			let currentTime = new Date().toLocaleString();
			alert("ระบบขัดข้อง: " + e + " at " + currentTime);
		}
	}

	return (
		<>
			<Box>
				<ProgressBar active={4} />
				<div className="mt-1 !text-lg font-bold text-center">
					เช็คเอาท์
				</div>
				<Grid container columns={12} alignItems="center" className="text-base bg-gray-500 text-white px-2">
					<Grid item xs={7}>
						หมายเลขงาน: <br />
						<span>{info?.bills?.map(bill => bill.no).join(', ')}</span>
					</Grid>
					<ShowTime />
				</Grid>
				<Grid container columns={12} className="text-base py-1 bg-gray-200" alignItems="center">
					<Grid item xs={3} className="text-center ">
						<PinDrop className="!text-4xl text-orange-400" />
					</Grid>
					<Grid item xs={9} className="font-bold text-orange-400">
						<div >{info.name}</div>
						<div className="text-gray-400 pr-1" >
							{info.address}
						</div>
					</Grid>
				</Grid>
				<div>
					<iframe src={currentMap}
						width="100%" height="270" allowFullScreen="" loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe>
				</div>
				<Grid container spacing={1} columns={12} alignItems="center" className="text-base font-bold px-2 h-[90px]">
					<Grid item xs={3}>
						<Avatar sx={{ width: 65, height: 65 }} prefix={"user/profile"} target={info.profile} name={'name'}></Avatar>
					</Grid>
					<Grid item xs={9}>
						<div>{info.truckLicense}</div>
						<div>{info.driverName}</div>
					</Grid>
				</Grid>
				<Divider variant="contained" className="driver_divider"></Divider>
				<div className="flex justify-between py-2 px-1 text-center" >
					<div className="w-[95%]">
						<Button variant="contained" className="w-[95%] driver_button" onClick={goBack}>ย้อนกลับ</Button>
					</div>
					<div className="w-[95%]">
						<Button variant="contained" className="!bg-bpTheme-buttonSubmit w-[95%] driver_button" onClick={inputMileage}>เช็คเอาท์</Button>
					</div>
				</div>
			</Box>
			<MileageModal id="mileageOut" open={mode !== "initial"} onCloseModal={onCloseModal} saveData={saveData} okButtonLabel={`เช็คเอาท์`} />
			<ProgressModal open={openProgressBar} progress={progress} />
		</>
	)
}