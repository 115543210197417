import { Box, Grid, Button, Divider } from '@mui/material';
import { Thermostat } from '@mui/icons-material';
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useSelector } from 'react-redux';
import useAxios from 'hooks/useAxios';
import { useSnackbar } from 'notistack';
import { warpUpFailResponse } from 'utilities/utils';
import usePopup from 'hooks/usePopup';
import numeral from 'numeral'
import { STATUS_JOB_ACCEPT_JOB } from 'helper/DataFactory';
import { parse, format } from 'date-fns';

export default function AcceptJob() {
	const navigate = useNavigate()
	const { confirmPopup } = usePopup();
	const { enqueueSnackbar } = useSnackbar();
	const userId = useSelector(state => state.user.id)
	const { get, post } = useAxios()
	const [allJobs, setAllJobs] = useState([])

	const fetchData = async controller => {
		const result = await get("/deliveryOrder/getAcceptJobByDriver", { id: userId }, { signal: controller.signal })
		if (result.status === 200) {
			const data = result.data.data.records || []
			setAllJobs(data)
		}
	}

	useEffect(() => {
		if (userId) {
			const controller = new AbortController();
			fetchData(controller)
			return () => {
				controller.abort()
			}
		} else {
			navigate("/driver-portal")
		}
	}, [])

	const saveData = async (data) => {
		confirmPopup({
			onSubmit: async () => {
				let response
				const parsedDate = parse(data.etdDate, 'dd/MM/yyyy', new Date());
				const formattedDate = format(parsedDate, 'yyyy-MM-dd');
				response = await post("/takeOrder/updateAcceptJob", { id: userId, etdTime: formattedDate, status: STATUS_JOB_ACCEPT_JOB })
				if (response.status === 200) {
					enqueueSnackbar('บันทึกสำเร็จ', {
						variant: "success", anchorOrigin: {
							vertical: 'top',
							horizontal: 'center',
						}
					})
					fetchData(new AbortController())
				} else {
					enqueueSnackbar(warpUpFailResponse(response, 'บันทึกไม่สำเร็จ'), {
						variant: "error", anchorOrigin: {
							vertical: 'top',
							horizontal: 'center',
						}
					})
				}
			},
			onCancel: () => {
				enqueueSnackbar('ยกเลิกรายการ', {
					variant: "info", anchorOrigin: {
						vertical: 'top',
						horizontal: 'center',
					}
				})
			}
		})
	}

	return (
		<Box className="!p-2">
			{allJobs.length > 0 ? allJobs.map((data, i) =>
				<div key={"SummaryAcceptJob-" + i}>
					<div className=" pl-6 py-1">
						<Grid container spacing={0.5} columns={12} alignItems="center" className="driver_grid_lg">
							<Grid item xs={4} className="driver_head">
								<div>วันที่วิ่งงาน</div>
							</Grid>
							<Grid item xs={8} className="driver_highlight">
								<div>{data.etdDate}</div>
							</Grid>
						</Grid>
						<Grid container spacing={0.5} columns={12} alignItems="center" className="driver_grid_lg">
							<Grid item xs={4} className="driver_head">
								<div>คนขับรถ</div>
							</Grid>
							<Grid item xs={8} className="driver_body">
								<div>{data.driverName}</div>
							</Grid>
						</Grid>
						<Grid container spacing={0.5} columns={12} alignItems="center" className="driver_grid_lg">
							<Grid item xs={4} className="driver_head">
								<div>ทะเบียนรถ</div>
							</Grid>
							<Grid item xs={8} className="driver_body">
								<div>{data.truckLicense}</div>
							</Grid>
						</Grid>
						<Grid container spacing={0.5} columns={12} alignItems="center" className="driver_grid_lg">
							<Grid item xs={4} className="driver_head">
								<div>เวลารับรถ</div>
							</Grid>
							<Grid item xs={8} className="driver_body">
								<div>{data.takeCarTime}</div>
							</Grid>
						</Grid>
						<Grid container spacing={0.5} columns={12} alignItems="center" className="driver_grid_lg">
							<Grid item xs={4} className="driver_head">
								<div>ค่าใช้จ่าย</div>
							</Grid>
							<Grid item xs={8} className="driver_body">
								<div>{numeral(data.amount).format('0,0.00')}</div>
							</Grid>
						</Grid>
					</div>
					<Grid item xs={12} className="mt-1 border-solid rounded-lg border-1 border-gray-600 text-lg px-4 py-2">
						{data.jobs.length > 0 ? data.jobs.map((job, j) => (
							<div key={"AcceptJob-" + j}>
								<Grid container spacing={1} columns={12} alignItems="center" className="driver_grid_lg">
									<Grid item xs={4} className="driver_head">
										<div>เลขที่ใบงาน</div>
									</Grid>
									<Grid item xs={8} className="driver_highlight">
										<div>{job.no}</div>
									</Grid>
								</Grid>
								<Grid container alignItems="center" spacing={1} columns={12} className="driver_grid_lg">
									<Grid item xs={4} className="driver_head">
										<div>ลูกค้า</div>
									</Grid>
									<Grid item xs={8} className="driver_body">
										<div>{job.customerName}</div>
									</Grid>
								</Grid>
								<Grid container alignItems="center" spacing={1} columns={12} className="driver_grid_lg">
									<Grid item xs={4} className="driver_head">
										<div>โปรเจค</div>
									</Grid>
									<Grid item xs={8} className="driver_body">
										<div>{job.projectName}</div>
									</Grid>
								</Grid>
								<Grid container spacing={0.5} columns={12} alignItems="center" className="driver_grid_lg">
									<Grid item xs={4} className="driver_head">
										<div>เวลารับสินค้า</div>
									</Grid>
									<Grid item xs={8} className="driver_body">
										<div>{job.etdTime}</div>
									</Grid>
								</Grid>
								<Grid container alignItems="center" spacing={1} columns={12} className="driver_grid_lg">
									<Grid item xs={4} className="driver_head">
										<div>เส้นทาง</div>
									</Grid>
									<Grid item xs={8} className="driver_body">
										<div>{job.route}</div>
									</Grid>
								</Grid>
								<Grid container spacing={0.5} columns={12} alignItems="center" className="driver_grid_lg">
									<Grid item xs={3.8} className="driver_head">
										<div>อุณหภูมิ</div>
									</Grid>
									<Grid item xs={8.2}>
										<div className="driver_temperature">
											<Thermostat
												className={`mr-1 ${job.lowestTemp < -10
													? 'text-cyan-600'
													: job.lowestTemp >= -10 && job.lowestTemp < 0
														? 'text-cyan-300'
														: job.lowestTemp >= 0 && job.lowestTemp < 25
															? 'text-gray-400'
															: 'text-orange-400'
													}`}
											/>
											<span className={`${job.lowestTemp < 0 ? 'text-cyan-600' : ''} `}>{job.lowestTemp > 0 ? '+' : job.lowestTemp < 0 ? '-' : ''}{job.lowestTemp}</span>
											{job.lowestTemp ? ' °c' : '-'}
										</div>
									</Grid>
								</Grid>
								<Grid container spacing={0.5} columns={12} alignItems="center" className="driver_grid_lg pb-1">
									<Grid item xs={4} className="driver_head">
										<div>หมายเหตุ</div>
									</Grid>
									<Grid item xs={8} className="driver_body">
										<div>{job.remark || '-'}</div>
									</Grid>
								</Grid>
							</div>
						)) : <p>No jobs for this entry</p>}
					</Grid>
					<Grid className="py-2 text-center" alignItems={"center"} >
						<Button fullWidth variant="contained" className="!bg-bpTheme-buttonSubmit driver_button" onClick={() => saveData(data)}>รับใบสั่งปฎิบัติงาน</Button>
					</Grid>
					<Divider variant="contained" className="driver_divider"></Divider>
				</div>
			) : <div className="driver_no_data">ไม่มีข้อมูลใบสั่งปฎิบัติงาน</div>}
		</Box>
	)
}