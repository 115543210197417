
import { Box, Grid, Button, Divider } from '@mui/material';
import { PlaylistAddCheck, CameraAlt, Cancel, PhotoCameraBack } from '@mui/icons-material';
import React, { useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { clearInfo } from 'slices/infoSlice';
import { useSnackbar } from 'notistack';
import usePopup from 'hooks/usePopup';
import useAxios from 'hooks/useAxios';
import { resizeImage, warpUpFailResponse } from 'utilities/utils';
import { hideLoading } from 'slices/loadingSlice';
import { MAX_FILE_SIZE } from 'helper/DataFactory';
import { normalizeData } from 'utilities/validator';
import styled from 'styled-components';
import ProgressModal from '../components/progressModal';
import { getSessionUUID } from 'utilities/utils';

const TextFormat = styled.div`
		font-family: "Roboto","Helvetica","Arial",sans-serif;
`
export default function TakeCameraVehicleCheckList() {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const info = useSelector(state => state.info.ctx || {})
    const { post } = useAxios()
    const { upload } = useAxios(false)
    const { confirmPopup } = usePopup();
    const { enqueueSnackbar } = useSnackbar();
    const inputRef = useRef(null);
    const [imgVdo, setImgVdo] = useState(null)
    const [progress, setProgress] = useState({ text: "กำลังบีบอัดไฟล์...", current: 0 });
    const [openProgressBar, setOpenProgressBar] = useState(false)

    const showCamera = () => {
        inputRef.current.value = null;
        inputRef.current.click()
    }

    const handleFileChange = async (e) => {
        const newArr = imgVdo ? [...imgVdo] : [];
        const files = e.target.files || [];
        const filesArray = Array.from(files);
        setImgVdo([...newArr, ...filesArray]);
    };

    const handleImageDelete = (index) => {
        const newArr = imgVdo ? [...imgVdo] : []
        newArr.splice(index, 1)
        setImgVdo(newArr);
    }

    const saveData = async () => {
        confirmPopup({
            onSubmit: async () => {
                try {
                    setOpenProgressBar(true);
                    setProgress(() => ({ text: "กำลังบีบอัดไฟล์...", current: 0 }));

                    const imageUrl = imgVdo || [];
                    let imgs = [];
                    for (let i = 0; i < imageUrl.length; i++) {
                        const img = imageUrl[i];
                        const resizeImg = await resizeImage(img, MAX_FILE_SIZE, (percent) => {
                            setProgress((prev) => ({ ...prev, current: ((percent + (100 * i)) / imageUrl.length) }))
                        });
                        imgs.push(resizeImg);
                    }

                    setProgress(() => ({ text: "กำลังอัปโหลดไฟล์...", current: 0 }));

                    let result = [];
                    for (let i = 0; i < imgs.length; i++) {
                        const img = imgs[i];
                        const uploadResult = await upload({ prefix: `driver/${info.type}`, file: img });
                        setProgress((prev) => ({ ...prev, current: ((i + 1) / imgs.length) * 100 }));
                        result.push(uploadResult);
                    }

                    setOpenProgressBar(false);

                    let images = []
                    if (result.every(e => e.status === 200)) {
                        images = result.map(e => e.data.data)
                    }

                    const sessionUUID = getSessionUUID();
                    const data = normalizeData(info)
                    let response
                    response = await post("/vehicle/createVehicleCheckList", {
                        ...data, 
                        imgSessionId: sessionUUID,
                        imgVehicleCheckList: { images: images }
                    })
                    if (response.status === 200) {
                        enqueueSnackbar('บันทึกสำเร็จ', {
                            variant: "success", anchorOrigin: {
                                vertical: 'top',
                                horizontal: 'center',
                            }
                        })
                        dispatch(clearInfo());
                        navigate("/driver-portal");
                    } else {
                        enqueueSnackbar(warpUpFailResponse(response, 'บันทึกไม่สำเร็จ'), {
                            variant: "error", anchorOrigin: {
                                vertical: 'top',
                                horizontal: 'center',
                            }
                        })
                    }
                } catch (e) {
                    let currentTime = new Date().toLocaleString();
                    alert("ระบบขัดข้อง: " + e + " at " + currentTime);
                } finally {
                    dispatch(hideLoading())
                }
            },
            onCancel: () => {
                enqueueSnackbar('ยกเลิกรายการ', {
                    variant: "info", anchorOrigin: {
                        vertical: 'top',
                        horizontal: 'center',
                    }
                })
            }
        })
    }

    return (
        <>
            <Box>
                <div className="flex flex-row justify-center items-center my-2 text-center">
                    <div className="border-dotted rounded-full border-[2px] border-blue-400 p-0.5">
                        <PlaylistAddCheck className="rounded-full !text-3xl p-1 bg-gray-300" />
                    </div>
                    <div className="text-blue-400">‧‧‧‧‧‧‧‧</div>
                    <div className="border-dotted rounded-full border-[2px] border-blue-400 p-0.5">
                        <CameraAlt className="rounded-full !text-3xl p-1 bg-orange-400" />
                    </div>
                </div>
                <TextFormat className="mt-1 !text-lg font-bold text-center">
                    รูป/วีดิโอ
                </TextFormat>
                <TextFormat className="mt-1">
                    <Grid container columns={12} alignItems="center" className="text-base bg-gray-500 text-white px-2">
                        <Grid item xs={3} className="font-bold">
                            ทะเบียนรถ :
                        </Grid>
                        <Grid item xs={9}>
                            <span className="ml-1" >{info?.vehicles ? info?.vehicles[0]?.vehicleNo : '-'}</span>
                        </Grid>
                    </Grid>
                </TextFormat>
                <div className="flex flex-col gap-2 items-center m-2">
                    {imgVdo?.map((e, i) => (
                        <div key={"imgVehicleCheckListUrl" + i} className="relative">
                            {e.type.startsWith("image/") ? (
                                <img className="w-full rounded-md" src={URL.createObjectURL(e)} alt="Uploaded image" />
                            ) : e.type.startsWith("video/") ? (
                                <video className="w-full rounded-md" controls>
                                    <source src={URL.createObjectURL(e)} type={e.type} />
                                    Your browser does not support the video tag.
                                </video>
                            ) : null}
                            <button className="absolute top-0 right-0 text-orange-800" onClick={() => handleImageDelete(i)}>
                                <Cancel />
                            </button>
                        </div>
                    ))}
                </div>
                <div className="m-2 text-center">
                    <Button variant="contained" onClick={showCamera} className="driver_button">
                        <PhotoCameraBack className="mr-2" />เพิ่มรูป/วีดิโอ
                    </Button>
                    <input ref={inputRef} type="file" accept="image/*,video/*" onChange={handleFileChange} hidden multiple={true} />
                </div>
                <Divider variant="contained" className="driver_divider"></Divider>

                <Grid className="py-2 px-2 text-center" alignItems={"center"} >
                    <Button fullWidth variant="contained" className="!bg-bpTheme-buttonSubmit driver_button" onClick={saveData}>{info.type === "clock-in" ? "ตรวจสภาพและเข้ารับรถ" : "ตรวจสภาพและส่งรถ"}</Button>
                </Grid>
            </Box>
            <ProgressModal open={openProgressBar} progress={progress} />
        </>
    )
}