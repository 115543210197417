export const validation = (data, scope) => {
	let requiredFields, validateFields
	if (scope) {
		requiredFields = document.getElementById(scope).getElementsByClassName("required")
		validateFields = document.getElementById(scope).querySelectorAll('[validate-input]')
	} else {
		requiredFields = document.getElementsByClassName("required")
		validateFields = document.querySelectorAll('[validate-input]')
	}
	let pass = true
	let invalidId = null
	Array.prototype.slice.call(requiredFields).map(e => {
		let id = e.getAttribute('id')
		let _data = null
		if (!id) return false
		if (id.includes("list-data")) {
			const spliter = id.split("-")
			const length = spliter.length
			const propertyName = spliter[length - 3]
			const index = spliter[length - 2]
			id = spliter[length - 1]
			_data = data.hasOwnProperty(propertyName) && data[propertyName].hasOwnProperty(index) ? data[propertyName][index][id] : null
		} else {
			id = id.split("data-")[1]
			_data = data[id]
		}

		if (_data === undefined || _data === null || _data === "" || (Array.isArray(_data) && !_data?.length)) {
			e.classList.add("invalid")
			if (pass) {
				invalidId = e.getAttribute('id')
				pass = false
			}
		} else {
			e.classList.remove("invalid")
		}
	})
	Array.prototype.slice.call(validateFields).map(e => {
		const validation = e.getAttribute('validate-input');
		let id = e.getAttribute('id')
		let _data = null
		if (!id) return false
		if (id.includes("list-data")) {
			const spliter = id.split("-")
			const length = spliter.length
			const propertyName = spliter[length - 3]
			const index = spliter[length - 2]
			id = spliter[length - 1]
			_data = data.hasOwnProperty(propertyName) && data[propertyName].hasOwnProperty(index) ? data[propertyName][index][id] : null
		} else {
			id = id.split("data-")[1]
			_data = data[id]
		}
		switch (validation) {
			case "email":
				const emailRegEx = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
				if (!!_data) {
					if (!emailRegEx.test(_data)) {
						e.classList.add("invalid")
						if (pass) {
							invalidId = e.getAttribute('id')
							pass = false
						}
					} else {
						e.classList.remove("invalid")
					}
				}
				break
			case "tel":
				const telRegEx = /^[\+]?[(]?[0-9]{2,3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im
				if (!!_data) {
					if (!telRegEx.test(_data)) {
						e.classList.add("invalid")
						if (pass) {
							invalidId = e.getAttribute('id')
							pass = false
						}
					} else {
						e.classList.remove("invalid")
					}
				}
				break;
			case "number-range":
				const numrangeRegEx = /^\d+\.\d+ - \d+\.\d+$/;
				if (!!_data) {
					if (!numrangeRegEx.test(_data)) {
						e.classList.add("invalid")
						if (pass) {
							invalidId = e.getAttribute('id')
							pass = false
						}
					} else {
						e.classList.remove("invalid")
					}
				}
				break;
			case "decimal-range":
				const decimalrangeRegEx = /^\d+ - \d+$/;
				if (!!_data) {
					if (!decimalrangeRegEx.test(_data)) {
						e.classList.add("invalid")
						if (pass) {
							invalidId = e.getAttribute('id')
							pass = false
						}
					} else {
						e.classList.remove("invalid")
					}
				}
				break;
		}
	})

	if (invalidId && !pass) {
		const element = document.getElementById(invalidId);
		element.scrollIntoView({
			behavior: 'smooth',
			block: 'center'
		});
	}
	return pass
}

export const normalizeData = (obj) => {
	const copyObj = { ...obj }
	Object.keys(copyObj).map(key => {
		if (copyObj[key] === "") {
			copyObj[key] = null
		}
	})
	return copyObj
}