import { Box } from '@mui/material'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Panel from 'components/Panel'
import Modal from 'components/Modal'
import Form from 'components/Form'
import { useSnackbar } from 'notistack'
import { setInfo, clearInfo } from 'slices/infoSlice'
import { validation, normalizeData } from 'utilities/validator'
import usePopup from 'hooks/usePopup'
import useAxios from 'hooks/useAxios'
import { v4 as uuidv4 } from 'uuid';
import { warpUpFailResponse } from 'utilities/utils'
import useDataHandler from 'hooks/useDataHandler'

export default function CheckListMaster() {
	const dispatch = useDispatch()
	const { enqueueSnackbar } = useSnackbar();
	const { save } = useDataHandler()
	const info = useSelector(state => state.info.ctx)
	const { post, get, del } = useAxios();
	const [mode, setMode] = useState("initial")
	const { confirmPopup } = usePopup();
	const [allCheckListMaster, setAllCheckListMaster] = useState([]);
	const modalId = useMemo(() => uuidv4(), [])
	const roleUser = useSelector(state => state.user.roleName)

	const fetchData = async controller => {
		const result = await get("/checkListMaster/getAllCheckListMaster", {}, { signal: controller.signal })
		if (result.status === 200) {
			const data = result.data.data || []
			setAllCheckListMaster(data)
		}
	}

	useEffect(() => {
		const controller = new AbortController();
		fetchData(controller)
		return () => {
			controller.abort()
		}
	}, [])

	const onCloseModal = useCallback(() => {
		confirmPopup({
			title: "แจ้งเตือน",
			content: "ท่านต้องการยกเลิกการแก้ไขหรือไม่",
			onSubmit: () => {
				setMode("initial")
			}
		})
	}, [])

	const inputForm = [{
		rowData: [
			{
				type: "text",
				required: true,
				props: {
					id: "name",
					label: "ชื่ออุปกรณ์ตรวจซ่อม",
					variant: "outlined"
				}
			},
			mode === "edit" && {
				type: "dropdown",
				props: {
					id: "active",
					label: "สถานะ",
					options: [
						{ label: "ใช้งาน", value: true },
						{ label: "ไม่ใช้งาน", value: false }
					],
				}
			}
		]
	},
	{
		rowData: [
			{
				type: "button",
				props: {
					label: "บันทึก",
					onClick: () => {
						saveData()
					},
					className: "!bg-bpTheme-buttonSubmit"
				}
			}
		]
	}
	]
	const table = useMemo(() => [
		{
			rowData: [{
				type: "dataTable",
				props: {
					columns: [
						{
							id: 'no',
							label: 'เลขที่อุปกรณ์ตรวจซ่อม',
							fields: ["no"]
						},
						{
							id: 'name',
							label: 'อุปกรณ์ตรวจซ่อม',
							fields: ["name"]
						},
						{
							id: 'active',
							label: 'สถานะ',
							fields: ["active"],
							valuesMap: [{ label: "ใช้งาน", value: true }, { label: "ไม่ใช้งาน", value: false }]

						}
					],
					rows: allCheckListMaster,
					size: "small",
					showDeleteButton: roleUser === "Owner",
					title: "ข้อมูลอุปกรณ์ตรวจซ่อม",
					showSystemFields: true,
					onClick: (e) => {
						setMode("edit")
						dispatch(setInfo(e))
					},
					onDelete: (e, onSuccess) => {
						confirmPopup({
							onSubmit: async () => {
								const result = await del("/checkListMaster/deleteCheckListMaster", { ids: e.map(data => data.id).join(',') })
								if (result.status === 200) {
									enqueueSnackbar('ลบสำเร็จ', { variant: "success" })
									dispatch(clearInfo())
									await fetchData(new AbortController())
									onSuccess()
								} else {
									enqueueSnackbar('ลบไม่สำเร็จ', { variant: "error" })
								}
							}
						})
					},
					controller: (paging) => get("/checkListMaster/getAllCheckListMaster", { paging }),
					searchFields: [
						{ label: "อุปกรณ์ตรวจซ่อม", value: "name" },
						{ label: "สถานะ", value: "active" }
					]
				}
			}]
		}, {
			noCol: true,
			rowData: [
				{
					type: "button",
					props: {
						label: "เพิ่มใหม่",
						onClick: () => {
							setMode("create")
							dispatch(clearInfo())
						}
					}
				},
			]
		}
	], [allCheckListMaster])

	const saveData = useCallback(() => {
		save({
			data: info,
			scope: modalId,
			onSubmit: async () => {
				const data = normalizeData(info)
				let response
				if (mode === "create") {
					response = await post("/checkListMaster/createCheckListMaster", { ...data, active: true })
				} else if (mode === "edit") {
					response = await post("/checkListMaster/updateCheckListMaster", { ...data })
				}
				if (response.status === 200) {
					setMode("initial")
					enqueueSnackbar('บันทึกสำเร็จ', { variant: "success" })
					fetchData(new AbortController())
				} else {
					enqueueSnackbar(warpUpFailResponse(response, 'บันทึกไม่สำเร็จ'), { variant: "error" })
				}
			},
			onCancel: () => {
				enqueueSnackbar('ยกเลิกรายการ', { variant: "info" })
			}
		})
	}, [info, mode])

	return (
		<Box>
			<Panel data={table}></Panel>
			<Modal id={modalId} open={mode === "create" || mode === "edit"} onClose={onCloseModal} className="form-modal" contentClassName="min-w-full md:!min-w-[750px]">
				<Form title={"อุปกรณ์ตรวจซ่อม " + (info.no || "")} name="user-form" data={inputForm}></Form>
			</Modal>
		</Box>
	)
}
