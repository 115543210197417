import { createSlice } from '@reduxjs/toolkit'

const initialState = { ctx: {} }

export const infoSlice = createSlice({
    name: 'info',
    initialState,
    reducers: {
        setInfo: (state, context) => {
            const payload = context.payload || {}
            state["ctx"] = { ...state["ctx"], ...payload }
        },
        setInfoById: (state, context) => {
            const id = context.payload.id || "ctx"
            const payload = context.payload.payload || {}
            if (id.includes("@")) {
                const seperatedId = id.split("@").filter(e => e)
                if (seperatedId.length === 2) {
                    if (!state.hasOwnProperty("ctx")) {
                        state["ctx"] = {}
                    }
                    if (!state["ctx"].hasOwnProperty(seperatedId[0])) {
                        state["ctx"][seperatedId[0]] = []
                    }
                    state.ctx[seperatedId[0]][seperatedId[1]] = { ...state.ctx[seperatedId[0]][seperatedId[1]], ...payload }
                } else if (seperatedId.length === 3) {
                    if (!state.hasOwnProperty(seperatedId[0])) {
                        state[seperatedId[0]] = {}
                    }
                    if (!state[seperatedId[0]].hasOwnProperty(seperatedId[1])) {
                        state[seperatedId[0]][seperatedId[1]] = []
                    }
                    state[seperatedId[0]][seperatedId[1]][seperatedId[2]] = { ...state[seperatedId[0]][seperatedId[1]][seperatedId[2]], ...payload }
                }
            } else {
                state[id] = { ...state[id], ...payload }
            }
        },
        clearInfo: () => ({ ctx: {} }),
        clearInfoById: (state, context) => {
            const id = context.payload || "ctx"
            state[id] = {}
        },
        deleteById: (state, context) => {
            const id = context.payload || "ctx"
            delete state[id]
        }
    }
})

export const { setInfo, clearInfo, setInfoById, clearInfoById, deleteById } = infoSlice.actions
export default infoSlice.reducer