import { Box } from '@mui/material'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Panel from 'components/Panel'
import Modal from 'components/Modal'
import Form from 'components/Form'
import { useSnackbar } from 'notistack'
import { setInfo } from 'slices/infoSlice'
import { normalizeData } from 'utilities/validator'
import usePopup from 'hooks/usePopup'
import useAxios from 'hooks/useAxios'
import { v4 as uuidv4 } from 'uuid';
import { warpUpFailResponse } from 'utilities/utils'
import { DELIVERY_TYPE, BILLING_COND } from 'helper/DataFactory'
import useDataHandler from 'hooks/useDataHandler'

export default function Pricing() {
    const dispatch = useDispatch()
    const { enqueueSnackbar } = useSnackbar();
    const { save } = useDataHandler()
    const info = useSelector(state => state.info.ctx)
    const { post, get } = useAxios();
    const [mode, setMode] = useState("initial")
    const { confirmPopup } = usePopup();
    const [allPricing, setAllPricing] = useState([]);
    const modalId = useMemo(() => uuidv4(), [])

    const fetchData = async controller => {
        const result = await get("/order/getAllOrder", {}, { signal: controller.signal })
        if (result.status === 200) {
            const data = result.data.data || []
            setAllPricing(data)
        }
    }

    useEffect(() => {
        const controller = new AbortController();
        fetchData(controller)
        return () => {
            controller.abort()
        }
    }, [])

    const onCloseModal = useCallback(() => {
        confirmPopup({
            title: "แจ้งเตือน",
            content: "ท่านต้องการยกเลิกการแก้ไขหรือไม่",
            onSubmit: () => {
                setMode("initial")
            }
        })
    }, [])

    const inputForm = [
        {
            rowData: [
                {
                    type: "display",
                    props: {
                        id: "ref1",
                        label: "Customer Doc No (Ref 1)"
                    }
                },
                {
                    type: "display",
                    props: {
                        id: "ref2",
                        label: "D/O,SO Doc No (Ref 2)"
                    }
                },
                {
                    type: "display",
                    props: {
                        id: "uom",
                        label: "เงื่อนไขการวางบิล",
                        valuesMap: BILLING_COND.reduce((acc, cur) => {
                            acc[cur.value] = cur.label;
                            return acc;
                        }, {})
                    }
                }
            ]
        },
        {
            rowData: [
                {
                    type: "display",
                    props: {
                        id: "senderName",
                        label: "ผู้ส่ง",
                    }, md: 4
                },
                {
                    type: "display",
                    props: {
                        id: "pickFromAddress",
                        label: "สถานที่รับ",
                    }, md: 8
                }
            ]
        },
        {
            rowData: [
                {
                    type: "display",
                    props: {
                        id: "serviceTypeName",
                        label: "ประเภทบริการ",
                    }
                },
                {
                    type: "display",
                    props: {
                        id: "deliveryType",
                        label: "ประเภทการขนส่ง",
                        valuesMap: DELIVERY_TYPE.reduce((acc, cur) => {
                            acc[cur.value] = cur.label;
                            return acc;
                        }, {})
                    }
                },
                {
                    type: "display",
                    props: {
                        id: "vehicleTypeName",
                        label: "ประเภทรถ",
                    }
                }
            ]
        },
        {
            isCollapse: true,
            label: "กำหนดราคา",
            defaultCollapse: true,
            collapseData: [
                {
                    rowData: [
                        {},
                        {
                            type: "number",
                            required: true,
                            props: {
                                id: "price",
                                label: "ราคา"
                            }
                        }, {}
                    ]
                }
            ]
        },
        {
            isCollapse: true,
            label: "รายละเอียดสินค้าที่ขนส่ง",
            collapseData: [
                {
                    rowData: [
                        {
                            type: "dataTable",
                            props: {
                                columns: [
                                    {
                                        id: 'invoiceNo',
                                        label: 'หมายเลขอินวอยซ์',
                                        fields: ["invoiceNo"]
                                    },
                                    {
                                        id: 'expectedTime',
                                        label: 'วันที่ต้องจัดส่ง',
                                        fields: ["expectedTime"]
                                    },
                                    {
                                        id: 'receiverAddress',
                                        label: 'ที่อยู่',
                                        fields: ["receiverName", "address", "receiverAddress"]
                                    },
                                    {
                                        id: 'tel',
                                        label: 'เบอร์ติดต่อ',
                                        fields: ["tel"]
                                    },
                                    {
                                        id: 'productName',
                                        label: 'ชื่อสินค้า',
                                        fields: ["productName"]
                                    },
                                    {
                                        id: 'quantity',
                                        label: 'จำนวน',
                                        fields: ["quantity"],
                                        type: "number"
                                    },
                                    {
                                        id: 'unitId',
                                        label: 'หน่วย',
                                        fields: ["unitId"],
                                    },
                                    {
                                        id: 'status',
                                        label: 'สถานะ',
                                        fields: ["status"]
                                    }
                                ],
                                rows: info.bills,
                                title: "บิล",
                                tableContainerClassName: "max-h-[30vh]",
                                multiSelect: false,
                            }
                        }
                    ]
                }
            ]
        },
        {
            isCollapse: true,
            label: "รายละเอียดเพิ่มเติม",
            collapseData: [
                {
                    rowData: [
                        {
                            type: "display",
                            props: {
                                id: "contactName",
                                label: "ผู้ติดต่อ"
                            }
                        },
                        {
                            type: "display",
                            props: {
                                id: "contactTel",
                                label: "เบอร์โทรติดต่อ"
                            }
                        }, {}
                    ]
                },
                {
                    rowData: [
                        {
                            type: "display",
                            props: {
                                id: "remark",
                                label: "หมายเหตุ",
                                multiline: true,
                                maxRows: 4
                            }
                        }
                    ]
                }
            ]
        },
        {
            rowData: [
                {
                    type: "button",
                    props: {
                        label: "บันทึก",
                        onClick: () => {
                            saveData()
                        },
                        className: "!bg-bpTheme-buttonSubmit"
                    }
                }
            ]
        }
    ]
    const table = useMemo(() => [
        {
            rowData: [{
                type: "dataTable",
                props: {
                    columns: [
                        {
                            id: 'no',
                            label: 'เลขที่ใบจอง',
                            fields: ["no"]
                        },
                        {
                            id: 'senderName',
                            label: 'ผู้ส่ง',
                            fields: ["senderName"]
                        },
                        {
                            id: 'vehicleTypeName',
                            label: 'ประเภทรถ',
                            fields: ["vehicleTypeName"]
                        },
                        {
                            id: 'pickFromAddress',
                            label: 'สถานที่รับ',
                            fields: ["pickFromAddress"]
                        },
                        {
                            id: 'price',
                            label: 'ราคา',
                            fields: ["price"]
                        },
                        {
                            id: 'status',
                            label: 'สถานะ',
                            fields: ["status"]
                        },
                    ],
                    rows: allPricing,
                    size: "small",
                    multiSelect: false,
                    title: "ข้อมูลกำหนดราคา",
                    showSystemFields: true,
                    onClick: async (e) => {
                        const result = await get("/pricing/getPricingById", { id: e.id })
                        if (result.status === 200) {
                            const data = result.data.data || []
                            dispatch(setInfo({ ...data }))
                            setMode("edit")
                        }
                    },
                    controller: (paging) => get("/order/getAllOrder", { paging }),
                    searchFields: [
                        { label: "ผู้ส่ง", value: "senderName" },
                        { label: "ประเภทรถ", value: "vehicleTypeName" },
                        { label: "สถานะ", value: "status" }
                    ]
                }
            }]
        }
    ], [allPricing])

    const saveData = useCallback(() => {
        save({
            data: info,
            scope: modalId,
            onSubmit: async () => {
                const data = normalizeData(info)
                let response
                if (mode === "edit") {
                    response = await post("/pricing/updatePricing", { ...data })
                }
                if (response.status === 200) {
                    setMode("initial")
                    enqueueSnackbar('บันทึกสำเร็จ', { variant: "success" })
                    fetchData(new AbortController())
                } else {
                    enqueueSnackbar(warpUpFailResponse(response, 'บันทึกไม่สำเร็จ'), { variant: "error" })
                }
            },
            onCancel: () => {
                enqueueSnackbar('ยกเลิกรายการ', { variant: "info" })
            }
        })
    }, [info, mode])

    return (
        <Box>
            <Panel data={table}></Panel>
            <Modal id={modalId} open={mode === "edit"} onClose={onCloseModal} className="form-modal">
                <Form title={"กำหนดราคาของใบจอง " + (info.no || "")} name="user-form" data={inputForm}></Form>
            </Modal>
        </Box>
    )
}
