import React from 'react'
import { Box, Button } from '@mui/material';
import Modal from 'components/Modal';
import Number from 'components/Number';

export default function MileageModal({ id, open, onCloseModal, saveData, okButtonLabel }) {
  return (
    <Modal open={open} onClose={onCloseModal} className="form-modal" contentClassName="!min-w-[300px]">
      <Box className="text-center">
        <div className="mt-1 !text-lg font-bold text-center">
          กรอกเลขไมล์ปัจจุบัน
        </div>
        <div className="flex justify-center mt-4 w-full">
          <div>
            <Number
              size="small"
              id={id}
              label="เลขไมล์ปัจจุบัน">
            </Number>
          </div>
        </div>
        <div className="pb-2 px-1 mt-4 text-center" >
          <Button variant="contained" className="!bg-bpTheme-buttonSubmit w-[50%] driver_button" onClick={saveData}>{okButtonLabel}</Button>
        </div>
      </Box>
    </Modal>
  )
}
