import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from "react-redux";
import { setInfoById, setInfo } from "slices/infoSlice";
import { memorizedExtractArrayData } from "redux/memorize";
import { DataSet } from "vis-data/peer";
import { Timeline as ImportedTimeline } from "vis-timeline/peer";
import "vis-timeline/styles/vis-timeline-graph2d.css";
import 'moment/locale/th';
import { cloneDeep, isEqual, omit } from 'lodash';
import Button from 'components/Button';
import styled from 'styled-components';
import { v4 as uuidv4 } from 'uuid';
import { Box, Chip, Divider, Grid, IconButton } from '@mui/material';
import { toBuddhistYear } from 'utilities/utils';
import { format } from 'date-fns';
import RemoveIcon from '@mui/icons-material/Remove';
import AddIcon from '@mui/icons-material/Add';

export default function Timeline({ parentId, id, datasetId, options = {}, group = [], reverseItems = [], onSave, onDoubleClick, customButton, draggable, ...other }) {
    const value = useSelector(state => memorizedExtractArrayData(state, parentId, id))
    const dataset = useSelector(state => memorizedExtractArrayData(state, parentId, datasetId))
    const dispatch = useDispatch()
    const onChange = (e, targetId = id) => {
        if (parentId) {
            dispatch(setInfoById({ id: parentId, payload: { [targetId]: e } }))
        } else {
            dispatch(setInfo({ [targetId]: e }))
        }
    }
    const [timeline, setTimeline] = useState(null)
    const [prevValue, setPrevValue] = useState(null)
    const [diff, setDiff] = useState(false)
    const [timelineKey, setTimelineKey] = useState(null)
    const [_draggableContent, setDraggableContent] = useState([])
    const [collapse, setCollapse] = useState(false)
    const commit = () => {
        if (onSave) {
            onSave(dataset.get(), () => onChange(dataset.get()), () => dataset.update(reverseItems || value))
        } else {
            onChange(dataset.get())
        }
    }

    const cancel = () => {
        const originalItems = cloneDeep(reverseItems || value);
        const currentItems = dataset.get();

        const originalItemIds = originalItems.map(item => item.id);

        const newItems = currentItems.filter(item => !originalItemIds.includes(item.id));

        dataset.remove(newItems.map(item => item.id));
        const updatedOriginalItems = originalItems.map(({ className, ...rest }) => rest);
        dataset.update(updatedOriginalItems.map(item => ({ ...item, className: "default" })));
        if (draggable) {
            setDraggableContent(cloneDeep(draggable.reverseItems));
        }
        onChange(updatedOriginalItems);
    };


    const isEqualIgnoringContent = (arr1, arr2) => {
        const omitFields = arr => arr.map(item => omit(item, ['content', 'className', 'status']));
        const itemsWithoutContent1 = omitFields(arr1);
        const itemsWithoutContent2 = omitFields(arr2);
        return isEqual(itemsWithoutContent1, itemsWithoutContent2);
    };

    const handleDragStart = (event) => {
        const dragSrcEl = event.target;
        if (dragSrcEl) {
            event.dataTransfer.effectAllowed = "move";
            const dataInfo = dragSrcEl?.getAttribute('data-info');
            let parsedData = {};
            if (dataInfo) {
                try {
                    parsedData = JSON.parse(dataInfo);
                } catch (error) {
                    console.error("Error parsing data-info:", error);
                }
            }
            const item = {
                id: parsedData.id,
                type: "range",
                className: "editing",
                content: "≣",
                data: parsedData,
                status: "add"
            };

            event.dataTransfer.setData("text", JSON.stringify(item));
        }
    }

    useEffect(() => {
        setTimelineKey(uuidv4())
        onChange(new DataSet(), datasetId)
    }, [])

    useEffect(() => {
        if (timelineKey) {
            const container = document.getElementById(timelineKey);
            const dataset = new DataSet(value?.map(e => ({ ...e, content: '≣', className: e.className || "default" })) || []);
            const _options = {
                ...options,
                onAdd: (item, callback) => {
                    options.onAdd && options.onAdd(item, callback, () => {
                        if (draggable) {
                            setDraggableContent((prevVal) => prevVal.filter(e => e.data.id !== item.id))
                        }
                    })
                },
                onMoving: (itm, callback) => {
                    const item = {
                        ...itm,
                        className: "editing",
                        status: itm.status ? itm.status : "edit"
                    }
                    const notAllowGroup = group?.filter(e => e.preventAdd)?.map(e => e.id) || []
                    if (!notAllowGroup.includes(item.group)) {
                        callback(item)
                    }
                }
            }

            const _timeline = new ImportedTimeline(container, dataset, cloneDeep(group), _options)

            _timeline.on('doubleClick', props => {
                if (onDoubleClick && props.item) {
                    const itm = dataset.get(props.item);
                    const item = {
                        ...itm,
                        className: "editing",
                        status: itm.status ? itm.status : "edit"
                    }
                    onDoubleClick(item)
                }
            })

            dataset.on('*', function (event, properties, senderId) {
                if (event === "add" || event === "update") {
                    setDiff(dataset.get().length !== reverseItems.length || !isEqualIgnoringContent(dataset.get(), reverseItems))
                }
            });

            setTimeline(_timeline)
            onChange(dataset, datasetId)

            return () => {
                if (_timeline) {
                    _timeline.destroy()
                }
            }
        }
    }, [timelineKey])

    useEffect(() => {
        if (!isEqual(value, prevValue) && dataset instanceof DataSet) {
            const updatedItems = value?.map(e => ({ ...e, content: '≣' })) || []
            dataset.update(updatedItems)
            setPrevValue(cloneDeep(dataset.get()))
            setDiff(dataset.get().length !== reverseItems.length)
        }
    }, [value])

    useEffect(() => {
        if (draggable) {
            setDraggableContent(draggable.content)
        }
    }, [draggable])

    useEffect(() => {
        const items = document.querySelectorAll(`#drop-box-${timelineKey} .item`);
        for (let i = items.length - 1; i >= 0; i--) {
            const item = items[i];
            item.addEventListener("dragstart", handleDragStart.bind(this), false);
        }
    }, [_draggableContent])

    return timelineKey && <Box className="relative">
        <Container id={timelineKey} className='mb-2'></Container>
        {draggable ? <Box className="absolute top-[65px] bg-white right-[5px] border-solid shadow-sm w-96 p-2 border-black border-[1px] z-50">
            <Box className="flex justify-between items-center w-full">
                {draggable.title && <p className='font-bold text-lg'>{draggable.title} ({_draggableContent.length || 0}) {diff ? "- DRAFT" : ""}</p>}
                <IconButton className='self-end' onClick={() => setCollapse(!collapse)}>
                    {!collapse ? <RemoveIcon /> : <AddIcon />}
                </IconButton>
            </Box>
            {
                !collapse ?
                    <Box className=" max-h-[500px] overflow-y-auto overflow-x-hidden scrollbar-thin scrollbar-thumb-bpTheme-scroll scrollbar-track-transparent">
                        <Grid id={`drop-box-${timelineKey}`} container spacing={2} className="mx-0 my-2 !w-full">
                            {
                                _draggableContent?.map((e, i) => {
                                    const previousEnd = i > 0 ? _draggableContent[i - 1].end : null;
                                    const showDivider = e.end != null ? format(toBuddhistYear(new Date(previousEnd)), 'dd/MM/yyyy') !== format(toBuddhistYear(new Date(e.end)), 'dd/MM/yyyy') : false
                                    return (
                                        <React.Fragment key={e.id}>
                                            {showDivider && (
                                                <Grid className='p-0' item xs={12}>
                                                    <Divider><Chip label={format(toBuddhistYear(new Date(e.end)), 'dd/MM/yyyy')} size="small" /></Divider>
                                                </Grid>
                                            )}
                                            <Grid item xs={12} className="item p-2">
                                                <div
                                                    data-info={JSON.stringify(e.data)}
                                                    draggable="true"
                                                    className={`p-2 cursor-move ${e.draggableClassName || ""}`}
                                                    onDoubleClick={e.onDoubleClick}
                                                >
                                                    {e.content}
                                                </div>
                                            </Grid>
                                        </React.Fragment>
                                    );
                                })}
                        </Grid>
                    </Box>
                    : null
            }
        </Box> : null}
        {
            diff ?
                <>
                    <Button onClick={commit}>บันทึก</Button>
                    <Button onClick={cancel} variant="outlined" className="!ml-2">ยกเลิก</Button>
                </> : customButton
        }
    </Box>
}

const Container = styled.div`
    & .vis-item .vis-item-overflow {
        overflow: hidden;
    }

    & .vis-item.default {
        background-color: #D6EFD880;
        border-color: #1A5319;
    }

    & .vis-item.editing {
        background-color: #19355350;
        border-color: #1A5319;
    }
    
    & .vis-item.default.vis-selected {
        background-color: #D6EFD8cc;
        border-color: #1A5319;
    }
    
    & .vis-item.editing.vis-selected {
        background-color: #19355380;
        border-color: #1A5319;
    }
`