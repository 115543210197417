import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    id: "",
    name: "",
    profile: null,
    appData: null,
    ctx: {},
    islogin: true
}

export const userSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {
        setId: (state, context) => {
            const payload = context.payload || ""
            state.id = payload.id
        },
        setName: (state, context) => {
            const payload = context.payload || ""
            state.name = payload.name
        },
        setProfile: (state, context) => {
            const payload = context.payload || ""
            state.profile = payload.profile
        },
        setAppData: (state, context) => {
            const payload = context.payload || []
            state.menu = payload.menu
        },
        setUser: (state, context) => {
            const payload = context.payload || initialState
            return payload
        },
        setLogin: (state, context) => {
            const payload = context.payload
            state.islogin = payload.islogin
        },
        clearUser: (state) => {
            return initialState
        },
    }
})

export const { setId, setName, setProfile, setAppData, setUser, clearUser, setLogin } = userSlice.actions
export default userSlice.reducer