import { Box, InputLabel, TextField } from '@mui/material';
import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { setInfo, setInfoById } from 'slices/infoSlice';
import { extractInfo } from 'utilities/utils'
import locationPin from 'assets/images/locationPin.svg'
import _ from "lodash"

const pin = { url: locationPin, offset: { x: 25, y: 50 }, size: { width: 50, height: 50 } }

export default function Maps({ parentId, id, label, parentComponentRef, required }) {

    const value = useSelector(state => extractInfo(state, parentId, id))
    const dispatch = useDispatch()
    const [map, setMap] = useState()
    const [search, setSearch] = useState("")
    const [area, setArea] = useState("")
    const [tag, setTag] = useState("")
    const [limit, setLimit] = useState(10)
    const [span, setSpan] = useState("")
    const [longdo, setLongdo] = useState()
    const [suggestions, setSuggestions] = useState([]);
    const mapElement = useRef();
    const resultElement = useRef();
    const suggestElement = useRef();
    const searchElement = useRef();
    const valueRef = useRef();

    // function logMeta(result) {
    //     console.log('META', result.meta);
    //     for (var i = 0; i < result.data.length; i++) {
    //         console.log('DATA' + i, result.data[i]);
    //     }
    // }

    // function log(object) {
    //     console.log(object);
    // }

    // function bind(map) {
    //     map.Event.bind('ready', () => console.log('ready')).bind('suggest', logMeta).bind('search', logMeta).bind('address', log).bind('nearPoi', logMeta);
    // }

    const onChange = (e) => {
        if (parentId) {
            dispatch(setInfoById({ id: parentId, payload: { [id]: { lat: e.lat, lng: e.lon } } }))
        } else {
            dispatch(setInfo({ [id]: { lat: e.lat, lng: e.lon } }))
        }
    }

    const doSuggest = (value) => {
        setSearch(value)
        doSearch(value);
    }

    const mapCallback = () => {
        const longdo = window.longdo
        if (longdo) {
            const map = new longdo.Map({
                placeholder: document.getElementById(id),
                language: 'th',
                zoom: value ? 15 : 10,
                zoomRange: {
                    min: 6,
                    max: 18
                },
                location: {
                    lat: value?.lat || 13.813548609627784, lon: value?.lng || 100.55725869515297
                },
                lastView: false
            })
            map.Layers.setBase(longdo.Layers.GRAY)
            map.Ui.Fullscreen.visible(false);
            map.Ui.Toolbar.visible(false);

            if (value?.lat && value?.lng) {
                map.Overlays.clear()
                map.Overlays.add(new longdo.Marker({ lat: value.lat, lon: value.lng }, { icon: pin }))
            }

            map.Event.bind('click', function () {
                const mouseLocation = map.location(longdo.LocationMode.Pointer)
                onChange(mouseLocation)
                if (resultElement.current) {
                    resultElement.current.innerHTML = ''
                }
            })

            map.Search.placeholder(resultElement.current);

            setMap(map)
            setLongdo(longdo)

            if (suggestElement.current) {
                map.Event.bind('suggest', function (result) {
                    const newSuggestions = result?.data?.map((item) => ({
                        label: item.d,
                        value: item.w,
                    }));
                    setSuggestions(newSuggestions);
                    suggestElement.current.style.display = 'block';
                });
            }

            // bind(map)
        }
    }

    const onKeyUp = (event) => {
        if ((event || window.event).keyCode != 13)
            return;
        doSearch();
    }

    const onSearchChange = (event) => {
        const value = event.target.value
        setSearch(value)

        if (value.length < 3) {
            suggestElement.current.style.display = 'none';
            return;
        }

        map?.Search.suggest(value, {
            area
        });
    }

    const doSearch = (value) => {
        const latLonPattern = /^(-?\d+(\.\d+)?),\s*(-?\d+(\.\d+)?)$/;
        const searchKey = value || search
        if (latLonPattern.test(searchKey)) {
            const [lat, lon] = searchKey.split(',');
            onChange({ lat, lon })
            map.location({ lat, lon }, true);
            suggestElement.current.style.display = 'none';
        } else {
            map?.Search.search(searchKey, {
                area,
                tag,
                span,
                limit
            });
        }
        suggestElement.current.style.display = 'none';
    }

    useEffect(() => {
        const existingScript = document.getElementById('longdoMapScript')

        if (!existingScript) {
            const script = document.createElement('script')
            script.src = `https://api.longdo.com/map/?key=${process.env.REACT_APP_LONGDO_API_KEY}`
            script.id = 'longdoMapScript'
            document.body.appendChild(script)

            script.onload = () => {
                setTimeout(() => {
                    mapCallback()
                }, 500)
            }
        } else {
            setTimeout(() => {
                mapCallback()
            }, 500)
        }

    }, [])

    useEffect(() => {
        if (value?.lat && value?.lng && map && longdo) {
            map.Overlays.clear()
            map.Overlays.add(new longdo.Marker(
                { lat: value.lat, lon: value.lng },
                {
                    icon: pin,
                    draggable: true,
                    clickable: true,
                    weight: longdo.OverlayWeight.Top
                }))
        }
        valueRef.current = value
    }, [value])

    useEffect(() => {
        if (map && parentComponentRef) {
            parentComponentRef.current?.addEventListener('scroll', handleScroll);
        }
    }, [map, parentComponentRef])

    const handleScroll = _.debounce(function () {
        map.resize()
    }, 250);

    return (
        <Box className="flex flex-col w-full h-[500px]">
            <InputLabel>{label + (required ? " *" : "")}</InputLabel>
            <Box className="flex h-full relative xs:pt-20 md:pt-0">
                <Box ref={mapElement} id={id} className="w-full"></Box>
                <Box className="absolute xs:left-0 md:left-20 xs:top-2 md:top-2 xs:w-full md:w-96 bg-white">
                    <TextField ref={searchElement} id="search" label="ค้นหาสถานที่" fullWidth value={search} onChange={onSearchChange} onKeyUp={onKeyUp} ></TextField>
                    <Box ref={suggestElement} id="suggest" className="hidden absolute bg-white w-11/12 p-2 top-14 drop-shadow-md max-h-[400px] z-50">
                        {suggestions.map((item, index) => (
                            <Box
                                key={index}
                                className="p-2 border-b-2 cursor-pointer hover:bg-gray-200"
                                onClick={() => doSuggest(item.value)}
                                dangerouslySetInnerHTML={{ __html: item.label }}
                            >
                            </Box>
                        ))}
                    </Box>
                    <Box className="overflow-auto scrollbar-thin scrollbar-thumb-bpTheme-scroll scrollbar-track-white shadow-sm max-h-[400px] z-50">
                        <Box ref={resultElement} id="result"></Box>
                    </Box>
                </Box>
            </Box>
        </Box>

    )
};