import { Box, Grid } from '@mui/material'
import React, { useEffect, useState, useMemo } from 'react'
import { useSelector } from 'react-redux'
import { useSnackbar } from 'notistack'
import Card from 'components/Card'
import DatePicker from 'components/DatePicker'
import FieldSet from 'components/FieldSet'
import Button from 'components/Button'
import useAxios from 'hooks/useAxios'
import AutoComplete from 'components/AutoComplete'
import {
    STATUS_INVOICE_APPROVE, STATUS_INVOICE_CANCELED, STATUS_INVOICE_DENIED,
    STATUS_INVOICE_PAID, STATUS_INVOICE_WAITING, STATUS_ORDER_COMPLETE,
    STATUS_ORDER_NEW, STATUS_ORDER_PLANNED, STATUS_ORDER_IN_TRANSIT,
    STATUS_ORDER_PARTAIL_COMPLETE, STATUS_ORDER_REJECT
} from '../../helper/DataFactory'
import Panel from 'components/Panel'


export default function ExcelExport({ name }) {
    const { enqueueSnackbar } = useSnackbar();
    const info = useSelector(state => state.info.ctx || {})
    const { multiple, download, post } = useAxios();
    const [allCustomer, setAllCustomer] = useState([])
    const [allVendor, setAllVendor] = useState([])
    const [approveStatus, setApproveStatus] = useState([])
    const [orderStatus, setOrderStutus] = useState([])
    const [allDivision, setAllDivision] = useState([])
    const [allHub, setAllHub] = useState([])
    const [allZone, setAllZone] = useState([])
    const [allProvince, setAllProvince] = useState([])
    const [allUser, setAllUser] = useState([])
    const [allEmployee, setAllEmployee] = useState([])
    const preparData = useState([])

    const fetchData = async controller => {
        const result = await multiple([
            { method: "get", url: "/customer/getAllActiveCustomer", config: { signal: controller.signal } },
            { method: "get", url: "/vendor/getAllActiveVendor", config: { signal: controller.signal } },
            { method: "get", url: "/division/getAllActiveDivision", config: { signal: controller.signal } },
            { method: "get", url: "/hub/getAllActiveHub", config: { signal: controller.signal } },
            { method: "get", url: "/zone/getAllActiveZone", config: { signal: controller.signal } },
            { method: "get", url: "/province/getAllProvince", config: { signal: controller.signal } },
            { method: "get", url: "/user/getAllUser", config: { signal: controller.signal } },
            { method: "get", url: "/employee/getAllEmployee", config: { signal: controller.signal } }

        ])

        if (result[0].status === 200) {
            const data = result[0].data.data
            setAllCustomer(data.records.map(data => ({ label: data.name, value: data.name })) || [])
        }
        if (result[1].status === 200) {
            const data = result[1].data.data
            setAllVendor(data.records.map(data => ({ label: data.name, value: data.name })) || [])
        }
        if (result[2].status === 200) {
            const data = result[2].data.data
            setAllDivision(data.records.map(data => ({ label: data.name, value: data.name })) || [])
        }
        if (result[3].status === 200) {
            const data = result[3].data.data
            setAllHub(data.records.map(data => ({ label: data.name, value: data.name })) || [])
        }
        if (result[4].status === 200) {
            const data = result[4].data.data
            setAllZone(data.records.map(data => ({ label: data.name, value: data.name })) || [])
        }
        if (result[5].status === 200) {
            const data = result[5].data.data
            setAllProvince(data.records.map(data => ({ label: data.province, value: data.province })) || [])
        }
        if (result[6].status === 200) {
            const data = result[6].data.data
            setAllUser(data.records.map(data => ({ label: data.username, value: data.id })) || [])
        }
        if (result[7].status === 200) {
            const data = result[7].data.data
            setAllEmployee(data.records.map(data => ({ label: `${data.firstName} ${data.lastName}`, value: data.id })) || [])
        }
    }

    useEffect(() => {
        const controller = new AbortController()
        fetchData(controller)

        const arrOrderStatus = [
            STATUS_ORDER_NEW,
            STATUS_ORDER_PLANNED,
            STATUS_ORDER_IN_TRANSIT,
            STATUS_ORDER_COMPLETE,
            STATUS_ORDER_PARTAIL_COMPLETE,
            STATUS_ORDER_REJECT
        ]
        setOrderStutus(arrOrderStatus.map(status => ({ label: status, value: status })) || [])

        const arrApproveStatus = [
            STATUS_INVOICE_WAITING,
            STATUS_INVOICE_APPROVE,
            STATUS_INVOICE_PAID,
            STATUS_INVOICE_CANCELED,
            STATUS_INVOICE_DENIED
        ]
        setApproveStatus(arrApproveStatus.map(status => ({ label: status, value: status })) || [])

        return () => {
            controller.abort()
        }
    }, [])

    useEffect(() => {
        preparData[1]([])
    }, [name])

    const onClick = async () => {
        const result = await post("/excel/preparDataExcel", {
            name,
            fromDate: info.dateFrom,
            toDate: info.dateTo,
            customerName: info.customerName,
            vendorName: info.vendorName,
            status: info.status,
            createdBy: info.createdBy,
            project: info.project,
            hub: info.hub,
            zone: info.zone,
            province: info.province,
            orderStatus: info.orderStatus,
            employeeName: info.employeeName
        })

        if (result.status === 200) {
            const data = result.data.data || []
            preparData[1](data)
        }
    }

    const onClickDownload = async () => {
        await download("/excel/exportExcel", {
            name,
            fromDate: info.dateFrom,
            toDate: info.dateTo,
            customerName: info.customerName,
            vendorName: info.vendorName,
            status: info.status,
            createdBy: info.createdBy,
            project: info.project,
            hub: info.hub,
            zone: info.zone,
            province: info.province,
            orderStatus: info.orderStatus,
            employeeName: info.employeeName
        })
    }


    const orderReportColumns = [
        {
            id: 'billNo',
            label: 'billNo',
            fields: ["billNo"]
        },
        {
            id: 'orderNo',
            label: 'orderNo',
            fields: ["orderNo"]
        },
        {
            id: 'invoiceNo',
            label: 'invoiceNo',
            fields: ["invoiceNo"]
        },
        {
            id: 'customerName',
            label: 'customerName',
            fields: ["customerName"]
        },
        {
            id: 'projectName',
            label: 'projectName',
            fields: ["projectName"]
        },
        {
            id: 'vehicleTypeName',
            label: 'vehicleTypeName',
            fields: ["vehicleTypeName"]
        },
        {
            id: 'pickFromAddress',
            label: 'pickFromAddress',
            fields: ["pickFromAddress"]
        },
        {
            id: 'otherPickFromAddress',
            label: 'otherPickFromAddress',
            fields: ["otherPickFromAddress"]
        },
        {
            id: 'provinceName',
            label: 'provinceName',
            fields: ["provinceName"]
        },
        {
            id: 'zoneName',
            label: 'zoneName',
            fields: ["zoneName"]
        },
        {
            id: 'hubName',
            label: 'hubName',
            fields: ["hubName"]
        },
        {
            id: 'ref1',
            label: 'ref1',
            fields: ["ref1"]
        },
        {
            id: 'ref2',
            label: 'ref2',
            fields: ["ref2"]
        },
        {
            id: 'serviceType',
            label: 'serviceType',
            fields: ["serviceType"]
        },
        {
            id: 'deliveryType',
            label: 'deliveryType',
            fields: ["deliveryType"]
        },
        {
            id: 'pickDate',
            label: 'pickDate',
            fields: ["pickDate"]
        },
        {
            id: 'contactName',
            label: 'contactName',
            fields: ["contactName"]
        },
        {
            id: 'contactTel',
            label: 'contactTel',
            fields: ["contactTel"]
        },
        {
            id: 'invoiceCount',
            label: 'invoiceCount',
            fields: ["invoiceCount"]
        },
        {
            id: 'remark',
            label: 'remark',
            fields: ["remark"]
        },
        {
            id: 'billStatus',
            label: 'billStatus',
            fields: ["billStatus"]
        },
        {
            id: 'orderStatus',
            label: 'orderStatus',
            fields: ["orderStatus"]
        },
        {
            id: 'completeTime',
            label: 'completeTime',
            fields: ["completeTime"]
        },
        {
            id: 'active',
            label: 'active',
            fields: ["active"]
        },
        {
            id: 'createdTime',
            label: 'createdTime',
            fields: ["createdTime"]
        },
        {
            id: 'createdBy',
            label: 'createdBy',
            fields: ["createdBy"]
        },
        {
            id: 'updatedTime',
            label: 'updatedTime',
            fields: ["updatedTime"]
        },
        {
            id: 'updatedBy',
            label: 'updatedBy',
            fields: ["updatedBy"]
        },
    ]

    const jobReportColumns = [
        {
            id: 'billNo',
            label: 'billNo',
            fields: ["billNo"]
        },
        {
            id: 'jobNo',
            label: 'jobNo',
            fields: ["jobNo"]
        },
        {
            id: 'invoiceNo',
            label: 'invoiceNo',
            fields: ["invoiceNo"]
        },
        {
            id: 'productName',
            label: 'productName',
            fields: ["productName"]
        },
        {
            id: 'customerName',
            label: 'customerName',
            fields: ["customerName"]
        },
        {
            id: 'projectName',
            label: 'projectName',
            fields: ["projectName"]
        },
        {
            id: 'lcnNo',
            label: 'lcnNo',
            fields: ["lcnNo"]
        },
        {
            id: 'source',
            label: 'source',
            fields: ["source"]
        },
        {
            id: 'driverName',
            label: 'driverName',
            fields: ["driverName"]
        },
        {
            id: 'destinationHubName',
            label: 'destinationHubName',
            fields: ["destinationHubName"]
        },
        {
            id: 'vehicleTypeName',
            label: 'vehicleTypeName',
            fields: ["vehicleTypeName"]
        },
        {
            id: 'vendorName',
            label: 'vendorName',
            fields: ["vendorName"]
        },
        {
            id: 'hubName',
            label: 'hubName',
            fields: ["hubName"]
        },
        {
            id: 'routeName',
            label: 'routeName',
            fields: ["routeName"]
        },
        {
            id: 'provinceName',
            label: 'provinceName',
            fields: ["provinceName"]
        },
        {
            id: 'zoneName',
            label: 'zoneName',
            fields: ["zoneName"]
        },
        {
            id: 'billStatus',
            label: 'billStatus',
            fields: ["billStatus"]
        },
        {
            id: 'jobStatus',
            label: 'jobStatus',
            fields: ["jobStatus"]
        },
        {
            id: 'departHubName',
            label: 'departHubName',
            fields: ["departHubName"]
        },
        {
            id: 'invoiceCount',
            label: 'invoiceCount',
            fields: ["invoiceCount"]
        },
        {
            id: 'completeTime',
            label: 'completeTime',
            fields: ["completeTime"]
        },
        {
            id: 'active',
            label: 'active',
            fields: ["active"]
        },
        {
            id: 'createdTime',
            label: 'createdTime',
            fields: ["createdTime"]
        },
        {
            id: 'createdBy',
            label: 'createdBy',
            fields: ["createdBy"]
        },
        {
            id: 'updatedTime',
            label: 'updatedTime',
            fields: ["updatedTime"]
        },
        {
            id: 'updatedBy',
            label: 'updatedBy',
            fields: ["updatedBy"]
        },
    ]

    const invoiceReportColumns = [
        {
            id: 'id',
            label: 'id',
            fields: ["id"]
        },
        {
            id: 'customerName',
            label: 'customerName',
            fields: ["customerName"]
        },
        {
            id: 'projectName',
            label: 'projectName',
            fields: ["projectName"]
        },
        {
            id: 'customerBillingAddress',
            label: 'customerBillingAddress',
            fields: ["customerBillingAddress"]
        },
        {
            id: 'divisionBillingAddress',
            label: 'divisionBillingAddress',
            fields: ["divisionBillingAddress"]
        },
        {
            id: 'invoiceDate',
            label: 'invoiceDate',
            fields: ["invoiceDate"]
        },
        {
            id: 'paidDate',
            label: 'paidDate',
            fields: ["paidDate"]
        },
        {
            id: 'approvedDate',
            label: 'approvedDate',
            fields: ["approvedDate"]
        },
        {
            id: 'dueDate',
            label: 'dueDate',
            fields: ["dueDate"]
        },
        {
            id: 'terms',
            label: 'terms',
            fields: ["terms"]
        },
        {
            id: 'tel',
            label: 'tel',
            fields: ["tel"]
        },
        {
            id: 'tax',
            label: 'tax',
            fields: ["tax"]
        },
        {
            id: 'remark',
            label: 'remark',
            fields: ["remark"]
        },
        {
            id: 'status',
            label: 'status',
            fields: ["status"]
        },
        {
            id: 'oilPrice',
            label: 'oilPrice',
            fields: ["oilPrice"]
        },
        {
            id: 'otherCost',
            label: 'otherCost',
            fields: ["otherCost"]
        },
        {
            id: 'total',
            label: 'total',
            fields: ["total"]
        },
        {
            id: 'jobCount',
            label: 'jobCount',
            fields: ["jobCount"]
        },
        {
            id: 'active',
            label: 'active',
            fields: ["active"]
        },
        {
            id: 'createdTime',
            label: 'createdTime',
            fields: ["createdTime"]
        },
        {
            id: 'createdBy',
            label: 'createdBy',
            fields: ["createdBy"]
        },
        {
            id: 'updatedTime',
            label: 'updatedTime',
            fields: ["updatedTime"]
        },
        {
            id: 'updatedBy',
            label: 'updatedBy',
            fields: ["updatedBy"]
        }
    ]

    const paymentReportColumns = [
        {
            id: 'id',
            label: 'id',
            fields: ["id"]
        },
        {
            id: 'projectName',
            label: 'projectName',
            fields: ["projectName"]
        },
        {
            id: 'paidDate',
            label: 'paidDate',
            fields: ["paidDate"]
        },
        {
            id: 'invoiceDate',
            label: 'invoiceDate',
            fields: ["invoiceDate"]
        },
        {
            id: 'terms',
            label: 'terms',
            fields: ["terms"]
        },
        {
            id: 'dueDate',
            label: 'dueDate',
            fields: ["dueDate"]
        },
        {
            id: 'referenceDoc',
            label: 'referenceDoc',
            fields: ["referenceDoc"]
        },
        {
            id: 'paymentMethod',
            label: 'paymentMethod',
            fields: ["paymentMethod"]
        },
        {
            id: 'payerName',
            label: 'payerName',
            fields: ["payerName"]
        },
        {
            id: 'tax',
            label: 'tax',
            fields: ["tax"]
        },
        {
            id: 'oilPrice',
            label: 'oilPrice',
            fields: ["oilPrice"]
        },
        {
            id: 'total',
            label: 'total',
            fields: ["total"]
        },
        {
            id: 'remark',
            label: 'remark',
            fields: ["remark"]
        },
        {
            id: 'useVat',
            label: 'useVat',
            fields: ["useVat"]
        },
        {
            id: 'jobCount',
            label: 'jobCount',
            fields: ["jobCount"]
        },
        {
            id: 'deductListCount',
            label: 'deductListCount',
            fields: ["deductListCount"]
        },
        {
            id: 'status',
            label: 'status',
            fields: ["status"]
        },
        {
            id: 'active',
            label: 'active',
            fields: ["active"]
        },
        {
            id: 'createdTime',
            label: 'createdTime',
            fields: ["createdTime"]
        },
        {
            id: 'createdBy',
            label: 'createdBy',
            fields: ["createdBy"]
        },
        {
            id: 'updatedTime',
            label: 'updatedTime',
            fields: ["updatedTime"]
        },
        {
            id: 'updatedBy',
            label: 'updatedBy',
            fields: ["updatedBy"]
        }
    ]

    const arapReportColumns = [
        {
            id: 'month',
            label: 'month',
            fields: ["month"]
        },
        {
            id: 'totalAR',
            label: 'totalAR',
            fields: ["totalAR"]
        },
        {
            id: 'totalAP',
            label: 'totalAP',
            fields: ["totalAP"]
        }
    ]

    const attendanceReportColumns = [
        {
            id: 'employeeName',
            label: 'ชื่อพนักงาน',
            fields: ["employeeName"]
        },
        {
            id: 'clockInTime',
            label: 'เวลาเข้างาน',
            fields: ["clockInTime"]
        },
        {
            id: 'clockOutTime',
            label: 'เวลาออกงาน',
            fields: ["clockOutTime"]
        },
    ]

    const reportColumnsMap = {
        orderReport: orderReportColumns,
        jobReport: jobReportColumns,
        invoiceReport: invoiceReportColumns,
        paymentReport: paymentReportColumns,
        arapReport: arapReportColumns,
        attendanceReport: attendanceReportColumns,
    };

    const reportColumns = reportColumnsMap[name] || [];

    const table = useMemo(() => [
        {
            rowData: [{
                type: "dataTable",
                props: {
                    columns: reportColumns,
                    rows: preparData[0],
                    size: "small",
                    controller: (paging) => post("/excel/preparDataExcel", {
                        name,
                        fromDate: info.dateFrom,
                        toDate: info.dateTo,
                        customerName: info.customerName,
                        vendorName: info.vendorName,
                        status: info.status,
                        createdBy: info.createdBy,
                        project: info.project,
                        hub: info.hub,
                        zone: info.zone,
                        province: info.province,
                        orderStatus: info.orderStatus,
                        employeeName: info.employeeName,
                        paging
                    })
                }
            }]
        }
    ])

    const tableArap = useMemo(() => [
        {
            rowData: [{
                type: "dataTable",
                props: {
                    columns: reportColumns,
                    rows: preparData[0],
                    size: "small"
                }
            }]
        }
    ])

    return (
        <>
            <Card title="Export data" >
                <Box className="flex w-full justify-center items-center pb-3 ">
                    <FieldSet className="!w-[90%]" legend="ฟิลเตอร์">
                        <Grid container alignItems={"center"} spacing={2} columns={12} className='!m-0 !w-full'>
                            <Grid item xs={6} md={3}>
                                <DatePicker id="dateFrom" label="ตั้งแต่วันที่" maxDate={info.dateTo || new Date()} />
                            </Grid>
                            <Grid item xs={6} md={3} >
                                <DatePicker id="dateTo" label="ถึงวันที่" minDate={info.dateFrom} maxDate={new Date()} />
                            </Grid>
                            {
                                name === "paymentReport" ?
                                    <Grid item xs={6} md={3}>
                                        <AutoComplete id="project" label="ชื่อโปรเจค" options={allDivision} />
                                    </Grid> :
                                    name === "attendanceReport" ? <Grid item xs={6} md={3}>
                                        <AutoComplete id="employeeName" label="ชื่อพนักงาน" options={allEmployee} />
                                    </Grid> :
                                        <Grid item xs={6} md={3}>
                                            <AutoComplete id="customerName" label="ชื่อลูกค้า" options={allCustomer} />
                                        </Grid>
                            }
                            {
                                name === "arapReport" ?
                                    <Grid item xs={6} md={3}>
                                        <AutoComplete id="vendorName" label="ชื่อบริษัทรถร่วม" options={allVendor} />
                                    </Grid>
                                    : (name === "invoiceReport" || name === "paymentReport") ?
                                        <Grid item xs={6} md={3}>
                                            <AutoComplete id="createdBy" label="ชื่อผู้สร้าง" options={allUser} />
                                        </Grid>
                                        :
                                        name !== "attendanceReport" ? <Grid item xs={6} md={3}>
                                            <AutoComplete id="project" label="ชื่อโปรเจค" options={allDivision} />
                                        </Grid>
                                            : null
                            }
                        </Grid>
                        <Grid container alignItems={"center"} spacing={2} columns={12} className='!m-0 !w-full'>
                            {
                                (name === "orderReport" || name === "jobReport") ?
                                    <>
                                        <Grid item xs={6} md={3}>
                                            <AutoComplete id="hub" label="ชื่อ HUB" options={allHub} />
                                        </Grid>
                                        <Grid item xs={6} md={3}>
                                            <AutoComplete id="zone" label="ชื่อ ZONE" options={allZone} />
                                        </Grid>
                                        <Grid item xs={6} md={3}>
                                            <AutoComplete id="province" label="ชื่อจังหวัด" options={allProvince} />
                                        </Grid>
                                        <Grid item xs={6} md={3}>
                                            <AutoComplete id="orderStatus" label="สถานะ" options={orderStatus} />
                                        </Grid>
                                    </>
                                    :
                                    name !== "attendanceReport" ? <Grid item xs={6} md={3}>
                                        <AutoComplete id="status" label="สถานะ" options={approveStatus} />
                                    </Grid>
                                        : null
                            }
                        </Grid>
                        <Grid container alignItems={"center"} spacing={2} columns={12} className='!m-0 !w-full'>
                            <Grid item xs={12} md={4}>
                            </Grid>
                            <Grid item xs={12} md={4} className='text-center'>
                                <Button label="Show" onClick={onClick} ></Button>
                            </Grid>
                        </Grid>
                    </FieldSet>
                </Box>
            </Card>

            {
                preparData[0].length === undefined ?
                    <>
                        <Card title="Result" className="mt-4">
                            <Panel className="mb-2" data={name !== "arapReport" ? table : tableArap}></Panel>
                            <Grid container alignItems={"center"} spacing={2} columns={12} className='!m-0 !w-full'>
                                <Grid item xs={12} md={4}>
                                </Grid>
                                <Grid item xs={12} md={4} className='text-center mb-4'>
                                    <Button label="Download Excel" onClick={onClickDownload} ></Button>
                                </Grid>
                            </Grid>
                        </Card>
                    </> : null
            }
        </>
    )
}
