import React, { useEffect, useState } from 'react';
import ChooseJob from 'pages/driver/choosejob';
import ChooseLocation from 'pages/driver/chooseLocation';
import ReviewOrder from 'pages/driver/reviewOrder';
import CheckIn from 'pages/driver/checkIn';
import CheckOut from 'pages/driver/checkOut';
import TakeCamera from 'pages/driver/takeCamera';
import TakeSign from 'pages/driver/takeSign';
import TakeCameraDocument from 'pages/driver/takeCameraDocument';
import {
  DRIVER_PAGE_REVIEW_ORDER,
  DRIVER_PAGE_CHOOSE_JOB,
  DRIVER_PAGE_CHOOSE_LOCATION,
  DRIVER_PAGE_CHECK_IN,
  DRIVER_PAGE_TAKE_CAMERA,
  DRIVER_PAGE_TAKE_SIGN,
  DRIVER_PAGE_CHECK_OUT,
  DRIVER_PAGE_TAKE_DOCUMENT
} from 'helper/DataFactory';
import { handleGeolocationError, handleUnsupportedGeolocation } from 'utilities/utils';
import usePopup from 'hooks/usePopup';
import { useNavigate } from 'react-router-dom';

export default function Delivery({ type }) {
  const navigate = useNavigate();
  const { notifyPopup } = usePopup();
  const [page, setPage] = useState(DRIVER_PAGE_CHOOSE_JOB);
  const [errorMsg, setErrorMsg] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);

  const onPageChange = (PAGE) => {
    setPage(PAGE);
  };

  const getLocation = () => {
    try {
      if ('geolocation' in navigator) {
        navigator.geolocation.getCurrentPosition(
          () => setErrorMsg(""),
          (error) => setErrorMsg(handleGeolocationError(error, "เข้าถึงตำแหน่งของท่าน")),
          { enableHighAccuracy: true }
        );
      } else {
        setErrorMsg(handleUnsupportedGeolocation("เข้าถึงตำแหน่งของท่าน"));
      }
    } catch (e) {
      setErrorMsg(`ไม่สามารถดึงตำแหน่งปัจจุบันเพื่อเข้าถึงตำแหน่งของท่านได้ เนื่องจากข้อผิดพลาด: ${e.message}`);
    }
  };

  useEffect(() => {
    getLocation()
    const timerMap = setInterval(getLocation, 2000);

    return () => {
      clearInterval(timerMap);
    };
  }, []);

  useEffect(() => {
    if (errorMsg && !isModalOpen) {
      showNotify(errorMsg);
    }
  }, [errorMsg]);

  const showNotify = (errorMsg) => {
    setIsModalOpen(true);
    notifyPopup({
      title: "แจ้งเตือน",
      content: errorMsg,
      onClose: () => {
        setIsModalOpen(false);
        navigate("/driver-portal");
      },
    });
  };

  switch (page) {
    case DRIVER_PAGE_CHOOSE_JOB:
      return <ChooseJob type={type} onPageChange={onPageChange} />;
    case DRIVER_PAGE_CHOOSE_LOCATION:
      return <ChooseLocation type={type} onPageChange={onPageChange} />;
    case DRIVER_PAGE_REVIEW_ORDER:
      return <ReviewOrder type={type} onPageChange={onPageChange} />;
    case DRIVER_PAGE_CHECK_IN:
      return <CheckIn type={type} onPageChange={onPageChange} />;
    case DRIVER_PAGE_TAKE_CAMERA:
      return <TakeCamera type={type} onPageChange={onPageChange} />;
    case DRIVER_PAGE_CHECK_OUT:
      return <CheckOut type={type} onPageChange={onPageChange} />;
    case DRIVER_PAGE_TAKE_SIGN:
      return <TakeSign type={type} onPageChange={onPageChange} />;
    case DRIVER_PAGE_TAKE_DOCUMENT:
      return <TakeCameraDocument type={type} onPageChange={onPageChange} />;
    default:
      return null;
  }
}
