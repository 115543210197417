import { Box, Grid, Button, Divider } from '@mui/material';
import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux';
import useAxios from 'hooks/useAxios';
import { clearInfo, setInfo } from 'slices/infoSlice';
import numeral from 'numeral'
import DisplayInformation from '../components/displayInformation';
import { DRIVER_PAGE_CHOOSE_LOCATION } from 'helper/DataFactory';

export default function ChooseJob({ type, onPageChange }) {
	const dispatch = useDispatch()
	const { get } = useAxios()
	const [allJobs, setAllJobs] = useState([])

	const fetchData = async controller => {
		const result = await get("/deliveryOrder/getLoadOrder", { type }, { signal: controller.signal })
		if (result.status === 200) {
			const data = result.data.data.records || []
			setAllJobs(data)
		}
	}

	useEffect(() => {
		dispatch(clearInfo())
		const controller = new AbortController();
		fetchData(controller)
		return () => {
			controller.abort()
		}
	}, [])

	const submitData = async (data) => {
		dispatch(setInfo({ ...data }))
		onPageChange(DRIVER_PAGE_CHOOSE_LOCATION)
	}

	return (
		<Box className="!p-2" >
			{allJobs.length > 0 ? allJobs.map((data, i) => {
				const displayData = [
					{
						label: "ทะเบียนรถ",
						data: data.truckLicense
					},
					{
						label: "คนขับรถ",
						data: data.driverName
					},
					{
						label: "วันที่เข้ารับสินค้า",
						data: data.etdTime
					},
					{
						label: "วันที่ส่งสินค้า",
						data: data.etaTime
					},
					{
						label: "อุณหภูมิ",
						type: "temperature",
						data: data.lowestTemp
					},
					{
						label: "ค่าใช้จ่าย",
						data: numeral(data.amount).format('0,0.00')
					},
					{
						label: "สถานะ",
						data: data.status
					},
					{
						label: "หมายเหตุ",
						data: data.remark
					},
				]

				const subDisplayData = [
					{
						label: "บริษัทขนส่ง",
						data: data.transportCompany
					},
					{
						label: "ประเภทรถ",
						data: data.vehicleTypeName
					},
					{
						label: "ต้นทาง",
						data: data.departure
					},
					{
						label: "ปลายทาง",
						data: data.destination
					},
				]
				return <div key={"jobs-" + data.no}>
					<Grid container spacing={1} columns={12} alignItems="center" className="driver_grid_lg">
						<Grid item xs={12} className="driver_highlight">
							<div>{data.no}</div>
						</Grid>
					</Grid>
					{
						displayData.map((data, i) => <DisplayInformation key={`data-${i}`} data={data} />)
					}
					<Grid item xs={12} className="mt-1 border-solid rounded-lg border-1 border-gray-600 p-2">
						{
							subDisplayData.map((data, i) => <DisplayInformation key={`sub-data-${i}`} data={data} />)
						}
					</Grid>
					<Grid className="py-2 text-center" alignItems={"center"} >
						<Button fullWidth variant="contained" className="!bg-bpTheme-buttonSubmit driver_button" onClick={() => submitData(data)}>{type === "pickup" ? "เข้ารับสินค้า" : "เข้าจัดส่งสินค้า"}</Button>
					</Grid>
					{i < (allJobs.length - 1) && <Divider variant="contained" className="driver_divider" />}
				</div>
			}) : <div className="driver_no_data">{type === "pickup" ? "ไม่มีข้อมูลเข้ารับสินค้า" : "ไม่มีข้อมูลสำหรับจัดส่งสินค้า กรุณาติดต่อผู้ดูแลระบบ"}ไม่มีข้อมูลเข้ารับสินค้า</div>}
		</Box>
	)
}