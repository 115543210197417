import React from 'react'
import Dialog from 'components/Dialog';

export default function Notifications(props) {
    const { title, content, open, onClose, type, submitLabel, cancelLabel, onSubmit, onCancel } = props

    switch (type) {
        case "confirm":
            return (
                <Dialog
                    open={open}
                    onClose={onClose}
                    title={title || "ยืนยันการทำรายการ"}
                    content={content}
                    submitLabel={submitLabel || "ตกลง"}
                    cancelLabel={cancelLabel || "ยกเลิก"}
                    onSubmit={onSubmit}
                    onCancel={onCancel}
                />
            )
        case "notify":
            return (
                <Dialog
                    open={open}
                    onClose={onClose}
                    title={title || "แจ้งเตือน"}
                    content={content}
                    submitLabel={submitLabel || "ตกลง"}
                    onSubmit={onSubmit}
                    hideCancel={true}
                />
            )
        default:
            return null
    }
}
