import { Gantt, ViewMode } from "gantt-task-react";
import React, { useCallback, useEffect, useState } from 'react'
import { ViewSwitcher } from "./ViewSwitcher";
import { useDispatch, useSelector } from "react-redux";
import { setInfoById, setInfo } from "slices/infoSlice";
import { cloneDeep, isEqual } from 'lodash';
import Button from "components/Button";
import { memorizedExtractArrayData } from "redux/memorize";

export default function Scheduler({ parentId, id, oldTasks, defaultTasks = [], timeStep = 30 * 60 * 1000, onDateChange, onSave, ...other }) {
    const value = useSelector(state => memorizedExtractArrayData(state, parentId, id))
    const [view, setView] = useState(ViewMode.Day);
    const [_tasks, setTasks] = useState(defaultTasks);
    const [isChecked, setIsChecked] = useState(true);
    const [columnWidth, setColumnWidth] = useState(60)

    const dispatch = useDispatch()
    const onChange = (e) => {
        if (parentId) {
            dispatch(setInfoById({ id: parentId, payload: { [id]: e } }))
        } else {
            dispatch(setInfo({ [id]: e }))
        }
    }

    const getStartEndDateForProject = useCallback((tasks, projectId) => {
        const projectTasks = tasks.filter((t) => t.project === projectId);
        let start = projectTasks[0].start;
        let end = projectTasks[0].end;
        for (const element of projectTasks) {
            const task = element;
            if (start.getTime() > task.start.getTime()) {
                start = task.start;
            }
            if (end.getTime() < task.end.getTime()) {
                end = task.end;
            }
        }
        return [start, end];
    }, [])

    const _onDateChange = (task) => {
        if (onDateChange) {
            onDateChange(task, () => { })
        } else {
            handleTaskChange(task)
        }
    }

    const handleTaskChange = (task) => {
        let newTasks = _tasks.map((t) => (t.id === task.id ? task : t));
        if (task.project) {
            const [start, end] = getStartEndDateForProject(newTasks, task.project);
            const project =
                newTasks[newTasks.findIndex((t) => t.id === task.project)];
            if (
                project.start.getTime() !== start.getTime() ||
                project.end.getTime() !== end.getTime()
            ) {
                const changedProject = { ...project, start, end };
                newTasks = newTasks.map((t) =>
                    t.id === task.project ? changedProject : t
                );
            }
        }
        setTasks(newTasks);
    };

    const handleExpanderClick = (task) => {
        setTasks(_tasks.map((t) => (t.id === task.id ? task : t)));
    };

    const commit = () => {
        if (onSave) {
            onSave(_tasks, () => onChange(cloneDeep(_tasks)), () => setTasks(cloneDeep(oldTasks || value)))
        } else {
            onChange(cloneDeep(_tasks))
        }
    }

    const cancel = () => {
        setTasks(cloneDeep(oldTasks || value))
    }

    useEffect(() => {
        if (defaultTasks?.length) {
            onChange(cloneDeep(defaultTasks))
        }
    }, [])

    useEffect(() => {
        if (!isEqual(value, _tasks)) {
            setTasks(cloneDeep(value))
        }
    }, [value])

    useEffect(() => {
        if (view === ViewMode.Month) {
            setColumnWidth(300);
        } else if (view === ViewMode.Week) {
            setColumnWidth(250);
        } else {
            setColumnWidth(60);
        }
    }, [view])

    return _tasks?.length ?
        <>
            <ViewSwitcher
                onViewModeChange={(viewMode) => setView(viewMode)}
                onViewListChange={setIsChecked}
                isChecked={isChecked}
                view={view}
            />
            <Gantt
                tasks={_tasks}
                viewMode={view}
                onDateChange={_onDateChange}
                onExpanderClick={handleExpanderClick}
                listCellWidth={isChecked ? "155px" : ""}
                columnWidth={columnWidth}
                timeStep={timeStep}
                locale="tha"
                todayColor="#58db8650"
                {...other}
            />
            {
                !isEqual(_tasks?.filter(e => e.type !== "project"), (oldTasks || value)?.filter(e => e.type !== "project")) ?
                    <>
                        <Button onClick={commit}>บันทึก</Button>
                        <Button onClick={cancel} variant="outlined" className="!ml-2">ยกเลิก</Button>
                    </> : null
            }
        </> : null
}
