import React, { useState, useEffect } from 'react';
import { Grid } from '@mui/material';
import { toBuddhistYear } from 'utilities/utils';
import { format } from 'date-fns';

const ShowTime = () => {
  const [currentDate, setCurrentDate] = useState(format(toBuddhistYear(new Date()), 'dd/MM/yyyy HH:mm'));

  useEffect(() => {
    const timerID = setInterval(() => {
      setCurrentDate(format(toBuddhistYear(new Date()), 'dd/MM/yyyy HH:mm'));
    }, 1000);

    return () => {
      clearInterval(timerID);
    };
  }, []);

  return (
    <Grid item xs={5} className="text-right text-sm self-start mt-1">
      <span>{currentDate}</span>
    </Grid>
  );
};

export default ShowTime;
