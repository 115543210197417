import { FormLabel, Typography } from '@mui/material';
import React, { useState, useEffect } from 'react';
import { formatTime, calculateCountdown } from 'utilities/utils';

export default function Countdown({ label, current }) {
    const [countdown, setCountdown] = useState(calculateCountdown(current ? new Date(current) : new Date()));
    const [targetDate, setTargetDate] = useState(null);

    useEffect(() => {
        const date = new Date(current)
        if (!isNaN(date)) {
            setTargetDate(date)
        }
    }, [current]);

    useEffect(() => {
        if (targetDate) {
            const newCountdown = calculateCountdown(targetDate);
            setCountdown(newCountdown);
            const countdownTimer = setInterval(() => {
                const newCountdown = calculateCountdown(targetDate);
                setCountdown(newCountdown);
            }, 1000);

            return () => {
                clearInterval(countdownTimer);
            };
        }
    }, [targetDate]);

    return (
        <>
            <FormLabel component="legend">{label}</FormLabel>
            <Typography variant="body2">
                {current ? formatTime(countdown) : "-"}
            </Typography>
        </>
    );
}