import React from 'react'
import { Grid } from '@mui/material';

export default function DisplayInformation({ data }) {
  return (
    <Grid container spacing={0.5} columns={12} alignItems="center" className="driver_grid_lg">
      <Grid item xs={4} className={`driver_head ${data.className || ""}`}>
        <div>{data.label}</div>
      </Grid>
      <Grid item xs={8} className="driver_body">
        {
          data.type === "temperature" ?
            <div className="driver_temperature">
              <span className={`${data.data < 0 ? 'text-cyan-600' : ''} `}>{data.data > 0 ? '+' : ''}{data.data}</span>
              {data.data ? ' °c' : '-'}
            </div>
            :
            <div>{data.data != null ? data.data : "-"}</div>
        }
      </Grid>
    </Grid>
  )
}
