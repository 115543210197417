import AddIcon from '@mui/icons-material/Add';
import { FormControl, InputLabel, MenuItem, Select } from '@mui/material'
import Button from 'components/Button';
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { setInfoById, setInfo } from 'slices/infoSlice';
import { extractInfo } from 'utilities/utils';
import { v4 as uuidv4 } from 'uuid';

export default function Dropdown({ parentId, id, label, variant, options = [], onChange, relateId, onAddEvent, defaultLabel, required, readOnly, filterOptions, placeholder, className = "", notNull, ...other }) {
    const value = useSelector(state => extractInfo(state, parentId, id))
    const relatedValue = useSelector(state => extractInfo(state, parentId, relateId))
    const [isFirstAssign, setIsFirstAssign] = useState(true);
    const [_options, setOptions] = useState([])
    const dispatch = useDispatch()
    const handleChange = (e) => {
        if (parentId) {
            dispatch(setInfoById({ id: parentId, payload: { [id]: e?.target?.value } }))
        } else {
            dispatch(setInfo({ [id]: e?.target?.value }))
        }
        if (onChange) {
            onChange(e?.target?.value)
        }
    }
    const handleMouseDown = (event) => {
        event.preventDefault();
    };

    useEffect(() => {
        setOptions(options)
    }, [options])

    useEffect(() => {
        if (relateId && !isFirstAssign) {
            handleChange(null)
        } else {
            setIsFirstAssign(false)
        }
    }, [relatedValue])

    return (
        <FormControl required={required} className={`w-full flex-row ${className || ""}`}>
            <InputLabel id={`dropdown-${id}-label`}>{label}</InputLabel>
            <Select
                className={`grow w-full bg-white`}
                labelId={`dropdown-${id}-label`}
                id={id}
                label={label}
                variant={variant}
                value={value != undefined ? _options.some(e => e.value === value) ? value : "" : ""}
                onChange={handleChange}
                displayEmpty={true}
                inputProps={{
                    readOnly: readOnly
                }}
                native={false}
                {...other}>
                {!notNull ? [
                    <MenuItem key="placeholder" value="" hidden={true}>
                        <em className='text-gray-400'>{placeholder || "-"}</em>
                    </MenuItem>,
                    <MenuItem key="default" value={null} hidden={false}>
                        <em className='text-gray-400'>{defaultLabel || "-"}</em>
                    </MenuItem>
                ] : null}
                {_options.filter(filterOptions ? filterOptions : e => true).map((e, i) => <MenuItem key={`dropdown-${id}-option-${uuidv4()}`} value={e.value}>{e.label}</MenuItem>)}
            </Select>
            {
                onAddEvent && <Button
                    sx={{ marginLeft: "5px" }}
                    className="hover:bg-gray-200"
                    onClick={onAddEvent}
                    onMouseDown={handleMouseDown}
                    variant="text"
                >
                    <AddIcon sx={{ color: "rgba(0, 0, 0, 0.6)" }} />
                </Button>
            }
        </FormControl>
    )
}
