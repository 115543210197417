import { TextField } from '@mui/material'
import React, { forwardRef, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { setInfo, setInfoById } from 'slices/infoSlice'
import { NumericFormat } from 'react-number-format';
import { extractInfo } from 'utilities/utils';
import { useDebouncedCallback } from 'use-debounce';

const NumberFormatCustom = forwardRef((props, ref) => {
    const { onChange, min, max, ...other } = props;

    return (
        <NumericFormat
            {...other}
            getInputRef={ref}
            onValueChange={(values) => {
                let newValue = values.value;
                onChange({
                    target: {
                        name: props.name,
                        value: newValue,
                    }
                });
            }}
            thousandSeparator
            valueIsNumericString
        />
    )
})

export default function Number({ parentId, id, label, variant, focused, required, readOnly, min, max, ...other }) {
    const value = useSelector(state => extractInfo(state, parentId, id))
    const dispatch = useDispatch()

    const commit = (e) => {
        let newValue = typeof e === 'object' ? e?.target?.value : e;
        if ((min || min === 0) && parseInt(newValue) < min) {
            newValue = min;
        }
        if (max && parseInt(newValue) > max) {
            newValue = max;
        }
        const val = newValue || newValue === 0 ? parseFloat(`${newValue}`.replace(/,/g, "")) : null
        if (parentId) {
            dispatch(setInfoById({ id: parentId, payload: { [id]: val } }))
        } else {
            dispatch(setInfo({ [id]: val }))
        }
    }

    const onChange = useDebouncedCallback(commit, 1500)

    useEffect(() => {
        return () => {
            onChange.cancel();
        }
    }, [])

    useEffect(() => {
        if ((value || value === 0) && min > value) {
            onChange(min)
        } else if ((value || value === 0) && max < value) {
            onChange(max)
        }
    }, [min, max])
    return (
        <TextField {...other} required={required} className='w-100 bg-white' id={id} label={label} variant={variant} focused={focused} value={value != undefined ? value : ""} onChange={onChange} InputProps={{
            inputComponent: NumberFormatCustom, readOnly: readOnly, min, max, ...other.InputProps
        }} onBlur={commit} />
    )
}
