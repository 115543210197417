import { Avatar as MuiAvatar } from '@mui/material';
import useAxios from 'hooks/useAxios';
import React, { useEffect, useState } from 'react'
import { stringToColor } from 'utilities/utils';

export default function Avatar({ sx, className = "", prefix, target, name = "", ...other }) {
    const [src, setSrc] = useState(null)
    const { get } = useAxios();
    const getImage = async controller => {
        if (prefix && target) {
            const result = await get("/file/getPublicUrl", { prefix, key: target }, {
                signal: controller.signal
            })
            if (result.status === 200) {
                const data = result.data.data
                setSrc(data)
            }
        }
    }

    const stringAvatar = () => {
        const splitName = name.split(' ')
        const concatName = splitName.length > 1 ? splitName[0][0] + splitName[1][0] : splitName[0][0] + splitName[0][splitName.length - 1] || ""
        return {
            sx: {
                bgcolor: stringToColor(name), ...sx,
            },
            children: `${concatName.toUpperCase()}`,
            src
        }
    }

    useEffect(() => {
        const controller = new AbortController()
        getImage(controller)
        return () => {
            controller.abort()
        }
    }, [prefix, target])

    return (
        <MuiAvatar className={`m-2 ${className}`} {...stringAvatar()} {...other} ></MuiAvatar>
    )
}
