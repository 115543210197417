import { Box, Grid, Button } from '@mui/material';
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import useAxios from 'hooks/useAxios';
import { setInfo } from 'slices/infoSlice';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import { memorizedContextInfo } from 'redux/memorize';
import VehicleCheckList from './vehicleChecklist';
import TakeCameraVehicleCheckList from 'pages/driver/takeCameraVehicleCheckList';
import { format } from 'date-fns';
import { useSnackbar } from 'notistack';
import { toBuddhistYear } from 'utilities/utils';
import { handleGeolocationError } from 'utilities/utils';
import { handleUnsupportedGeolocation } from 'utilities/utils';

const TextFormat = styled.div`
		font-family: "Roboto","Helvetica","Arial",sans-serif;
`

export default function Attendance({ type }) {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const userId = useSelector(state => state.user.id)
    const info = useSelector(memorizedContextInfo)
    const [page, setPage] = useState("main")
    const { multiple, get } = useAxios()
    const [withinRange, setWithinRange] = useState(false);
    const targetLocation = { latitude: process.env.REACT_APP_COMPANY_LAT, longitude: process.env.REACT_APP_COMPANY_LNG };
    const maxDistance = 50;
    const [currentTime, setCurrentTime] = useState(format(new Date(), 'dd/MM/yyyy HH:mm'))
    const { enqueueSnackbar } = useSnackbar()
    const [clockInData, setClockInData] = useState({})
    const [hasError, setHasError] = useState(false)
    const [errorMsg, setErrorMsg] = useState("")
    const fetchData = async controller => {
        if ("clock-in" === type) {
            const result = await get("/user/getClockInData", {}, { signal: controller.signal })
            if (result.status === 200) {
                const data = result.data?.data || {}
                if (!data?.clockInTime) {
                    const result2 = await multiple([
                        { method: "get", url: "/vehicle/getVehicleForTimeAttendance", params: { type }, config: { signal: controller.signal } }
                    ])
                    if (result2[0].status === 200) {
                        let data = result2[0]?.data.data?.records || []
                        dispatch(setInfo({ vehicles: data }))
                    }
                }
                setClockInData(data)
            }
        } else {
            const result = await multiple([
                { method: "get", url: "/vehicle/getVehicleForTimeAttendance", params: { type }, config: { signal: controller.signal } }
            ])
            if (result[0].status === 200) {
                let data = result[0]?.data.data?.records || []
                dispatch(setInfo({ vehicles: data }))
            }
        }
    }

    useEffect(() => {
        if (userId) {
            const controller = new AbortController();
            fetchData(controller)
            const counting = setInterval(() => {
                setCurrentTime(format(new Date(), 'dd/MM/yyyy HH:mm'))
            }, 1000)
            return () => {
                clearInterval(counting)
                controller.abort()
            }
        } else {
            navigate("/driver-portal")
        }
    }, [])

    useEffect(() => {
        try {
            if ('geolocation' in navigator) {
                navigator.geolocation.getCurrentPosition(
                    (position) => {
                        calculateDistance(position.coords.latitude, position.coords.longitude);
                    },
                    (error) => {
                        const errorMsg = handleGeolocationError(error);
                        setHasError(true);
                        setErrorMsg(errorMsg);
                    },
                    { enableHighAccuracy: true }
                );
            } else {
                const errorMsg = handleUnsupportedGeolocation();
                setHasError(true);
                setErrorMsg(errorMsg);
            }
        } catch (e) {
            setHasError(true);
            setErrorMsg("ไม่สามารถดึงตำแหน่งปัจจุบันเพื่อคำนวนระยะห่างได้ เนื่องจากข้อผิดพลาด: " + e.message);
        }
    }, [type])

    const calculateDistance = (userLat, userLng) => {
        const { latitude: targetLat, longitude: targetLng } = targetLocation;

        const R = 6371e3; // Earth radius in meters
        const φ1 = (userLat * Math.PI) / 180;
        const φ2 = (targetLat * Math.PI) / 180;
        const Δφ = ((targetLat - userLat) * Math.PI) / 180;
        const Δλ = ((targetLng - userLng) * Math.PI) / 180;

        const a =
            Math.sin(Δφ / 2) * Math.sin(Δφ / 2) +
            Math.cos(φ1) * Math.cos(φ2) * Math.sin(Δλ / 2) * Math.sin(Δλ / 2);

        const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

        const distance = R * c;
        setWithinRange(distance < maxDistance)
        if (distance > maxDistance) {
            setHasError(true)
            setErrorMsg(`เนื่องจากคุณอยู่นอกระยะที่สามารถ${type === "clock-in" ? "รับ" : "ส่ง"}รถได้ ระยะห่างของคุณคือ ${(distance / 1000).toFixed(2)} กิโลเมตร จะต้องอยู่ภายในรัศมี ${maxDistance} เมตร`)
        } else {
            setHasError(false)
            setErrorMsg("")
        }
    };

    const submitData = async (index) => {
        if (type === "clock-in") {
            const response = await get("/vehicle/checkOutStandingAttendanceTime")
            if (response.status === 200) {
                const isNotExist = response.data.data
                if (isNotExist) {
                    const data = info.vehicles[index]
                    dispatch(setInfo({ vehicleId: data.vehicleId, type }))
                    setPage("checklist")
                } else {
                    enqueueSnackbar('กรุณาคืนรถก่อนรับรถใหม่', { variant: "error" })
                }
            }
        } else {
            const data = info.vehicles[index]
            dispatch(setInfo({ vehicleId: data.vehicleId, type }))
            setPage("takeCameraVehicleCheckList")
        }
    }

    return page === "main" ? (
        <Box>
            <TextFormat className="mt-1">
                <Grid container columns={12} alignItems="center" className="text-base bg-gray-500 text-white px-2">
                    {type === "clock-in" ? "รายการรอรับรถ" : "รายการรอส่งรถ"}
                </Grid>
            </TextFormat>
            {
                info.vehicles?.length && hasError ?
                    <div className="w-full p-2" >
                        <span>{errorMsg}</span>
                    </div> :
                    null
            }
            {info.vehicles?.length ? info.vehicles.map((data, i) => {
                return (
                    <React.Fragment key={`attendance-${type}-` + i}>
                        <Box className='py-3 px-2'>
                            <Box className="flex justify-between py-2 text-left whitespace-nowrap">
                                <Grid container spacing={0.5} columns={12} alignItems="center" className="pt-1 text-base">
                                    {
                                        i === 0 ? <>
                                            <Grid item xs={6} md={3}>
                                                <span className="font-bold" >{type === "clock-in" ? "วันที่เข้ารับรถ" : "วันที่ส่งรถ"}</span>
                                            </Grid>
                                            <Grid item xs={6} md={4}>
                                                <span className="" >{currentTime}</span>
                                            </Grid>
                                        </> : null
                                    }
                                    <Grid item xs={6} md={2.5}>
                                        <span className="font-bold" >ทะเบียนรถ</span>
                                    </Grid>
                                    <Grid item xs={6} md={2.5}>
                                        <span className="" >{data.vehicleNo}</span>
                                    </Grid>
                                </Grid>
                            </Box>
                        </Box>
                        <div className="w-full p-2" >
                            <Button disabled={!withinRange} variant="contained" className="!bg-bpTheme-buttonSubmit w-full driver_button" onClick={() => submitData(i)}>{type === "clock-in" ? "รับรถ" : "ส่งรถ"}ทะเบียน{data.vehicleNo}</Button>
                        </div>
                    </React.Fragment>
                )
            }

            ) : <div className="driver_no_data">{type === "clock-in" ? clockInData?.clockInTime ? `คุณได้ทำการรับรถทะเบียน ${clockInData.vehicleNo} ณ ${format(toBuddhistYear(new Date(clockInData.clockInTime)), 'dd/MM/yyyy HH:mm')} กรุณาคืนรถก่อนรับรถคันถัดไป` : "ไม่พบรายการเข้ารับรถของวันนี้" : "ไม่พบรายการส่งรถของวันนี้"}</div>}
        </Box>
    ) : page === "checklist" ? (
        <VehicleCheckList setPage={setPage} type={type} />
    ) : (
        <TakeCameraVehicleCheckList />
    )
}