import { Box, LinearProgress, Modal, Typography } from '@mui/material'
import React from 'react'

export default function ProgressModal({ open, progress }) {
  return (
    <Modal
      open={open}
      className='flex justify-center items-center'
    >
      <Box>
        <Typography id="modal-modal-title" className='text-white' variant="h6" component="h2">
          {progress.text}
        </Typography>
        <Box sx={{ width: '100%', mt: 2 }}>
          <LinearProgress variant="determinate" value={progress.current} />
        </Box>
        <Typography id="modal-modal-description" className='text-white' sx={{ mt: 2 }}>
          {progress.current?.toFixed(2)}% เสร็จสิ้น
        </Typography>
      </Box>
    </Modal>
  )
}
