import React, { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Modal from 'components/Modal'
import Form from 'components/Form'
import { useSnackbar } from 'notistack'
import { clearInfoById } from 'slices/infoSlice'
import { normalizeData } from 'utilities/validator'
import usePopup from 'hooks/usePopup'
import useAxios from 'hooks/useAxios'
import { warpUpFailResponse } from 'utilities/utils'
import useDataHandler from 'hooks/useDataHandler'

export default function RouteInput({ mode, setAllRoute }) {

	const dispatch = useDispatch()
	const info = useSelector(state => state.info)
	const { confirmPopup } = usePopup();
	const { post, get, multiple } = useAxios();
	const { save } = useDataHandler()
	const [allRoute, setAllSuggestRoute] = useState([])
	const [allZone, setAllZone] = useState([]);
	const { enqueueSnackbar } = useSnackbar();

	const fetchData = async controller => {
		const result = await multiple([
			{ method: "get", url: "/zone/getAllActiveZone", config: { signal: controller.signal } },
			{ method: "get", url: "/route/getAllActiveRouteUnion", config: { signal: controller.signal } },
		])

		if (result[0].status === 200) {
			const data = result[0].data.data.records || []
			setAllZone(data.map(e => ({ label: e.name, value: e.id })))
		}
		if (result[1].status === 200) {
			const data = result[1].data.data.records || []
			setAllSuggestRoute(data.map(e => ({ label: e.name, value: e.name })))
		}
	}

	useEffect(() => {
		const controller = new AbortController();
		fetchData(controller)
		return () => {
			controller.abort()
		}
	}, [])

	useEffect(() => {
		if (mode[0] === "initial") {
			dispatch(clearInfoById("routeInput"))
		}
	}, [mode[0]])

	const onCloseModal = useCallback(() => {
		confirmPopup({
			title: "แจ้งเตือน",
			content: "ท่านต้องการยกเลิกการแก้ไขหรือไม่",
			onSubmit: () => {
				mode[1]("initial")
			}
		})
	}, [])

	const saveData = useCallback(() => {
		save({
			data: info.routeInput,
			scope: "routeModal",
			onSubmit: async () => {
				const data = normalizeData(info.routeInput)
				let response
				if (mode[0] === "create") {
					response = await post("/route/createRoute", { ...data, active: true })
				}
				if (response.status === 200) {
					mode[1]("initial")
					enqueueSnackbar('บันทึกสำเร็จ', { variant: "success" })
					const result = await get('/route/getAllActiveRoute')
					if (result.status === 200) {
						setAllRoute(result.data.data.records.map(data => ({ label: data.source + ' - ' + data.destination, value: data.id })) || [])
					}
				} else {
					enqueueSnackbar(warpUpFailResponse(response, 'บันทึกไม่สำเร็จ'), { variant: "error" })
				}
			},
			onCancel: () => {
				enqueueSnackbar('ยกเลิกรายการ', { variant: "info" })
			}
		})
	}, [info, mode[0]])

	const inputForm = [
		{
			rowData: [
				{
					type: "autocomplete",
					required: true,
					props: {
						id: "source",
						label: "ต้นทาง",
						options: allRoute,
						freeSolo: true
					}
				}, {
					type: "autocomplete",
					required: true,
					props: {
						id: "destination",
						label: "ปลายทาง",
						options: allRoute,
						freeSolo: true
					}
				}, {
					type: "autocomplete",
					required: true,
					props: {
						id: "zoneId",
						label: "โซน",
						options: allZone
					}
				},
			]
		},
		{
			rowData: [
				mode === "edit" && {
					type: "dropdown",
					props: {
						id: "active",
						label: "สถานะ",
						options: [
							{ label: "ใช้งาน", value: true },
							{ label: "ไม่ใช้งาน", value: false }
						],
					}
				}, {}, {}, {}
			]
		}, {
			rowData: [
				{
					type: "button",
					props: {
						label: "บันทึก",
						onClick: () => {
							saveData()
						},
						className: "!bg-bpTheme-buttonSubmit"
					}
				}
			]
		}
	]

	return (
		<Modal id="routeModal" open={mode[0] === "create"} onClose={onCloseModal} className="form-modal">
			<Form id={`routeInput`} title={"ข้อมูลเส้นทาง"} name="user-form" data={inputForm}></Form>
		</Modal>
	)
}