import { FormControlLabel, Checkbox as MUICheckBox } from "@mui/material";
import { React } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setInfo, setInfoById } from "slices/infoSlice";
import { extractInfo } from "utilities/utils";

export default function Checkbox({ parentId, id, label, required, readOnly, onChange, ...other }) {
	const value = useSelector(state => extractInfo(state, parentId, id))
	const dispatch = useDispatch()
	const _onChange = (e) => {
		if (parentId) {
			dispatch(setInfoById({ id: parentId, payload: { [id]: e } }))
		} else {
			dispatch(setInfo({ [id]: e }))
		}
		if (onChange) {
			onChange(e)
		}
	}

	const handleChange = (e) => {
		const _value = e?.target?.checked
		if (_value !== value) {
			_onChange(_value)
		}
	}

	return (
		<FormControlLabel
			label={label}
			control={
				<MUICheckBox
					value={value || false}
					checked={value || false}
					onChange={handleChange}
					{...other}
				/>
			}
		/>
	);
}