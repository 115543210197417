import { Visibility, VisibilityOff, LockReset } from '@mui/icons-material'
import { IconButton, InputAdornment, TextField, Tooltip } from '@mui/material'
import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { setInfoById, setInfo } from 'slices/infoSlice'
import { extractInfo } from 'utilities/utils'
export default function Password({ parentId, id, label, variant, focused, multiline, maxRows, rows, type, required, readOnly, showGenerateButton, ...other }) {
    const value = useSelector(state => extractInfo(state, parentId, id))
    const dispatch = useDispatch()
    const onChange = (e) => {
        if (parentId) {
            dispatch(setInfoById({ id: parentId, payload: { [id]: typeof e === "string" ? e : e.target.value } }))
        } else {
            dispatch(setInfo({ [id]: typeof e === "string" ? e : e.target.value }))
        }
    }
    const [showPassword, setShowPassword] = useState(false);
    const handleClickShowPassword = () => setShowPassword((show) => !show);
    const handleGeneratePassword = () => { onChange(Math.random().toString(36).slice(-8)) }
    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };
    return (
        <TextField {...other} type={showPassword ? "text" : "password"} required={required} className='w-100 ' id={id} label={label} variant={variant} focused={focused} value={value != undefined ? value : ""} onChange={onChange} multiline={multiline} maxRows={maxRows} rows={rows} InputProps={{
            readOnly: readOnly, endAdornment: (
                <InputAdornment position='end'>
                    {
                        showGenerateButton ?
                            <Tooltip title="Generate password">
                                <IconButton
                                    onClick={handleGeneratePassword}
                                    onMouseDown={handleMouseDownPassword}
                                    edge="start"
                                >
                                    <LockReset />
                                </IconButton>
                            </Tooltip>
                            : null
                    }
                    <Tooltip title="Show password">
                        <IconButton
                            onClick={handleClickShowPassword}
                            onMouseDown={handleMouseDownPassword}
                            edge="end"
                        >
                            {showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                    </Tooltip>
                </InputAdornment>
            )
        }} />
    )
}
