import { Box } from '@mui/material'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Panel from 'components/Panel'
import Modal from 'components/Modal'
import Form from 'components/Form'
import { useSnackbar } from 'notistack'
import { setInfo, clearInfo } from 'slices/infoSlice'
import { normalizeData } from 'utilities/validator'
import usePopup from 'hooks/usePopup'
import useAxios from 'hooks/useAxios'
import { warpUpFailResponse } from 'utilities/utils'
import useDataHandler from 'hooks/useDataHandler'

export default function TripRate() {
	const dispatch = useDispatch()
	const { enqueueSnackbar } = useSnackbar();
	const { post, get, del, multiple } = useAxios();
	const { save } = useDataHandler()
	const info = useSelector(state => state.info.ctx || {})
	const { confirmPopup } = usePopup();
	const [mode, setMode] = useState("initial")
	const [allTripRate, setAllTripRate] = useState([]);
	const roleUser = useSelector(state => state.user.roleName)
	const [allVehicleType, setAllVehicleType] = useState([]);

	const fetchData = async controller => {
		const result = await multiple([
			{ method: "get", url: "/vehicleType/getAllActiveVehicleType", config: { signal: controller.signal } },
			{ method: "get", url: "/tripRate/getAllTripRate", config: { signal: controller.signal } }
		])
		if (result[0].status === 200) {
			setAllVehicleType(result[0].data.data.records.map(data => ({ label: data.name, value: data.id })) || [])
		}
		if (result[1].status === 200) {
			const data = result[1].data.data || []
			setAllTripRate(data)
		}
	}

	useEffect(() => {
		const controller = new AbortController();
		fetchData(controller)
		return () => {
			controller.abort()
		}
	}, [])

	const onCloseModal = useCallback(() => {
		confirmPopup({
			title: "แจ้งเตือน",
			content: "ท่านต้องการยกเลิกการแก้ไขหรือไม่",
			onSubmit: () => {
				setMode("initial")
			}
		})
	}, [])

	const inputForm = [
		{
			rowData: [
				{
					type: "autocomplete",
					required: true,
					props: {
						id: "vehicleTypeId",
						label: "ประเภทรถ",
						options: allVehicleType
					}
				}, {
					type: "number",
					required: true,
					props: {
						id: "firstTrip",
						label: "ค่าเที่ยวแรกของกรุงเทพและปริมณฑล",
						variant: "outlined"
					}
				}
			]
		}, {
			rowData: [
				{
					type: "number",
					required: true,
					props: {
						id: "firstTripUpCountry",
						label: "ค่าเที่ยวแรกของต่างจังหวัด",
						variant: "outlined"
					}
				}, {
					type: "number",
					required: true,
					props: {
						id: "secondTrip",
						label: "ค่าเที่ยวตั้งแต่เที่ยวสอง",
						variant: "outlined"
					}
				}
			]
		}, {
			rowData: [
				mode === "edit" && {
					type: "dropdown",
					props: {
						id: "active",
						label: "สถานะ",
						options: [
							{ label: "ใช้งาน", value: true },
							{ label: "ไม่ใช้งาน", value: false }
						],
					}
				}, {}
			]
		}, {
			rowData: [
				{
					type: "button",
					props: {
						label: "บันทึก",
						onClick: () => {
							saveData()
						},
						className: "!bg-bpTheme-buttonSubmit"
					}
				}
			]
		}
	]
	const table = useMemo(() => [
		{
			rowData: [{
				type: "dataTable",
				props: {
					columns: [
						{
							id: 'no',
							label: 'เลขที่ค่าเที่ยว',
							fields: ["no"]
						},
						{
							id: 'vehicleTypeName',
							label: 'ประเภทรถ',
							fields: ["vehicleTypeName"]
						},
						{
							id: 'firstTrip',
							label: 'ค่าเที่ยวแรกของกรุงเทพและปริมณฑล',
							fields: ["firstTrip"]
						},
						{
							id: 'firstTripUpCountry',
							label: 'ค่าเที่ยวแรกของต่างจังหวัด',
							fields: ["firstTripUpCountry"]
						},
						{
							id: 'secondTrip',
							label: 'ค่าเที่ยวตั้งแต่เที่ยวสอง',
							fields: ["secondTrip"]
						},
						{
							id: 'active',
							label: 'สถานะ',
							fields: ["active"],
							valuesMap: [{ label: "ใช้งาน", value: true }, { label: "ไม่ใช้งาน", value: false }]

						}
					],
					rows: allTripRate,
					size: "small",
					showDeleteButton: roleUser === "Owner",
					title: "ข้อมูลเรทค่าเที่ยวรถ",
					showSystemFields: true,
					onClick: (e) => {
						setMode("edit")
						dispatch(setInfo(e))
					},
					onDelete: (e, onSuccess) => {
						confirmPopup({
							onSubmit: async () => {
								const result = await del("/tripRate/deleteTripRate", { ids: e.map(data => data.id).join(',') })
								if (result.status === 200) {
									enqueueSnackbar('ลบสำเร็จ', { variant: "success" })
									dispatch(clearInfo())
									await fetchData(new AbortController())
									onSuccess()
								} else {
									enqueueSnackbar('ลบไม่สำเร็จ', { variant: "error" })
								}
							}
						})
					},
					controller: (paging) => get("/tripRate/getAllTripRate", { paging }),
					searchFields: [
						{ label: "ประเภทรถ", value: "vehicleTypeName" },
						{ label: "ค่าเที่ยวแรกของกรุงเทพและปริมณฑล", value: "firstTrip" },
						{ label: "ค่าเที่ยวแรกของต่างจังหวัด", value: "firstTripUpCountry" },
						{ label: "ค่าเที่ยวตั้งแต่เที่ยวสอง", value: "secondTrip" },
						{ label: "สถานะ", value: "active" }
					]
				}
			}]
		}, {
			noCol: true,
			rowData: [
				{
					type: "button",
					props: {
						label: "เพิ่มใหม่",
						onClick: () => {
							setMode("create")
							dispatch(clearInfo())
						}
					}
				},
			]
		}
	], [allTripRate])

	const saveData = useCallback(() => {
		save({
			data: info,
			onSubmit: async () => {
				const data = normalizeData(info)
				let response
				if (mode === "create") {
					response = await post("/tripRate/createTripRate", { ...data })
				} else if (mode === "edit") {
					response = await post("/tripRate/updateTripRate", { ...data })
				}
				if (response.status === 200) {
					setMode("initial")
					enqueueSnackbar('บันทึกสำเร็จ', { variant: "success" })
					fetchData(new AbortController())
				} else {
					enqueueSnackbar(warpUpFailResponse(response, 'บันทึกไม่สำเร็จ'), { variant: "error" })
				}
			},
			onCancel: () => {
				enqueueSnackbar('ยกเลิกรายการ', { variant: "info" })
			}
		})
	}, [info, mode])

	return (
		<Box>
			<Panel data={table}></Panel>
			<Modal open={mode === "create" || mode === "edit"} onClose={onCloseModal} className="form-modal" contentClassName="min-w-full md:!min-w-[750px]">
				<Form title={"เรทค่าเที่ยวรถ " + (info.no || "")} name="user-form" data={inputForm}></Form>
			</Modal>
		</Box>
	)
}