import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import LanguageDetector from "i18next-browser-languagedetector";
import Backend from "i18next-http-backend";
import translationEN from "./assets/locales/EN/translation.json";
import translationTH from "./assets/locales/TH/translation.json";

const resources = {
	EN: {
		translation: translationEN
	},
	TH: {
		translation: translationTH
	}
};
i18n.use(Backend).use(LanguageDetector).use(initReactI18next).init({
	fallbackLng: "TH",
	resources,
	debug: true,
});

export default i18n;
