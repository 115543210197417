import React from 'react'
import styles from './notfound.module.css'
import { Link } from 'react-router-dom'

export default function NotFound() {
    return (
        <div id="notfound">
            <div className={styles.notfound}>
                <div className={styles['notfound-404']}>
                    <div></div>
                    <h1>404</h1>
                </div>
                <h2>ไม่พบหน้าที่ต้องการ</h2>
                <p>หน้าที่คุณกำลังมองหาอาจถูกลบไปหรือเปลี่ยนชื่อไปแล้ว หรืออาจไม่สามารถใช้งานได้ชั่วคราว</p>
                <Link to="">
                    <span>กลับสู่หน้าหลัก</span>
                </Link>
            </div>
        </div>
    )
}
