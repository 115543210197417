import { Box, Typography } from '@mui/material'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Panel from 'components/Panel'
import Modal from 'components/Modal'
import Form from 'components/Form'
import { useSnackbar } from 'notistack'
import { setInfo } from 'slices/infoSlice'
import { normalizeData } from 'utilities/validator'
import usePopup from 'hooks/usePopup'
import useAxios from 'hooks/useAxios'
import AdvanceSearch from 'components/AdvanceSearch'
import { STATUS_INVOICE_APPROVE, STATUS_INVOICE_DENIED, INVOICE_TAX } from 'helper/DataFactory'
import numeral from 'numeral'
import { warpUpFailResponse } from 'utilities/utils'
import { memoizedSelectAdvanceInfo } from 'redux/memorize'

export default function OtherInvoiceApprove() {
	const dispatch = useDispatch()
	const { enqueueSnackbar } = useSnackbar();
	const info = useSelector(state => state.info.ctx)
	const { post, multiple, get } = useAxios();
	const mode = useState("initial") //main form
	const modeReason = useState("initial")
	const { confirmPopup } = usePopup();
	const [allInvoice, setAllInvoice] = useState([]);
	const [allAdditionalPrice, setAllAdditionalPrice] = useState([]);
	const paging = useState({})
	const searchInfo = useSelector(memoizedSelectAdvanceInfo)
	const [total, setTotal] = useState(0);
	const [withholdingTax, setWithholdingTax] = useState(0);
	const [vat, setVat] = useState(0);

	const handleCalculation = () => {
		const newInvoiceTotal = info.otherInvoiceItems?.reduce((a, v) => a + v?.amount, 0) ?? 0.00;
		const newWithholdingTax = newInvoiceTotal * ((info.tax ?? 0) * 0.01);
		const newVat = newInvoiceTotal * 0.07;

		setTotal(newInvoiceTotal);
		setWithholdingTax(newWithholdingTax);
		setVat(newVat);
	};

	const fetchData = async controller => {
		const result = await multiple([
			{ method: "get", url: "/invoice/getAllApprovalOtherInvoice", config: { signal: controller.signal } },
			{ method: "get", url: "/additionalPrice/getAllActiveAdditionalPrice", params: { type: "other invoice" }, config: { signal: controller.signal } },
		])
		if (result[0].status === 200) {
			const data = result[0].data.data || []
			setAllInvoice(data)
		}
		if (result[1].status === 200) {
			const data = result[1].data.data.records || []
			setAllAdditionalPrice(data.map(data => ({ label: data.name, value: data.id })) || [])
		}
	}

	useEffect(() => {
		const controller = new AbortController();
		fetchData(controller)
		return () => {
			controller.abort()
		}
	}, [])

	useEffect(() => {
		if (info.invoiceDate && (info.terms || info.terms === 0)) {
			const dueDate = new Date(info.invoiceDate)
			dueDate.setDate(dueDate.getDate() + Number(info.terms))
			dispatch(setInfo({ dueDate }))
		} else {
			dispatch(setInfo({ dueDate: null }))
		}
	}, [info.invoiceDate, info.terms])

	useEffect(() => {
		handleCalculation()
	}, [info.bills, info.otherInvoiceItems, info.tax, info.useVat])

	const onCloseModal = useCallback(() => {
		mode[1]("initial")
	}, [])

	const onCloseModalReason = useCallback(() => {
		modeReason[1]("initial")
		dispatch(setInfo({ reason: null }))
	}, [])

	const inputForm = [
		{
			rowData: [
				{
					type: "display",
					props: {
						id: "name",
						label: "ชื่อลูกค้า",
					},
					md: 4
				},
				{
					type: "display",
					props: {
						id: "invoiceDate",
						label: "วันที่ออกใบแจ้งหนี้",
						type: "date"
					},
					md: 2
				},
				{
					type: "display",
					props: {
						id: "paidDate",
						label: "วันที่จ่าย",
						type: "date"
					},
					md: 2
				},
				{
					type: "display",
					props: {
						id: "terms",
						label: "Credit terms",
						type: "number"
					},
					md: 2
				},
				{
					type: "display",
					props: {
						id: "dueDate",
						label: "วันที่ครบกำหนดชำระ",
						type: "date"
					},
					md: 2
				}
			]
		},
		{
			rowData: [
				{
					type: "display",
					props: {
						id: "customerBillingLocation",
						label: "ที่อยู่ออกบิลของบริษัท",
					},
					md: 4
				},
				{
					type: "display",
					props: {
						id: "divisionBillingLocation",
						label: "ที่อยู่ออกบิลของลูกค้า",
					},
					md: 4
				},
				{
					type: "display",
					props: {
						id: "tax",
						label: "ภาษีหัก ณ ที่จ่าย",
						valuesMap: INVOICE_TAX.reduce((acc, cur) => {
							acc[cur.value] = cur.label;
							return acc;
						}, {})
					},
					md: 2
				},
				{
					type: "display",
					props: {
						id: "tel",
						label: "เบอร์โทร",
					},
					md: 2
				}
			]
		},
		{
			rowData: [
				{
					type: "display",
					props: {
						id: "remark",
						label: "หมายเหตุ",
					}
				}
			]
		},
		{
			isCollapse: true,
			label: "รายละเอียดรายการที่จ่าย",
			collapseData: [
				{
					rowData: [
						{
							type: "list",
							props: {
								id: "otherInvoiceItems",
								showDuplicate: false,
								data: (data, i) => [
									{
										rowData: [
											{
												type: "autocomplete",
												props: {
													id: "addPriceId",
													label: "ค่าใช้จ่ายเพิ่มเติม",
													options: allAdditionalPrice,
													readOnly: true
												},
												md: 4
											},
											{
												type: "number",
												props: {
													id: "amount",
													label: "จำนวน",
													readOnly: true
												},
												md: 3
											},
											{
												type: "custom",
												component: <Box className="h-[inherit] flex items-center justify-center"> บาท </Box>,
												md: 1
											}, {}
										]
									}
								],
								readOnly: true
							}
						},
					]
				},
				{
					rowData: [
						{
							type: "custom",
							align: "right",
							component:
								<>
									<Typography variant="body2" gutterBottom className='!pr-4'>
										ราคารวม : &nbsp;&nbsp;&nbsp; {numeral(total).format('0,0.00')}
									</Typography>
									<Typography variant="body2" gutterBottom className='!pr-4'>
										หัก ณ ที่จ่าย : &nbsp;&nbsp;&nbsp; {numeral(withholdingTax).format('0,0.00')}
									</Typography>
									<Typography variant="body2" gutterBottom className='!pr-4'>
										ภาษี(vat) 7% : &nbsp;&nbsp;&nbsp; {numeral(vat).format('0,0.00')}
									</Typography>
									<Typography variant="body2" gutterBottom className='!pr-4'>
										ราคาสุทธิ : &nbsp;&nbsp;&nbsp; {numeral(total - withholdingTax + vat).format('0,0.00')}
									</Typography>
								</>
						}
					]
				}
			]
		},
		{
			isCollapse: true,
			label: "อัปโหลดเอกสาร",
			collapseData: [
				{
					rowData: [
						{
							type: "list",
							props: {
								id: "fileItems",
								showDuplicate: mode[0] === "create",
								data: [
									{
										rowData: [
											{
												type: "text",
												props: {
													id: "name",
													label: "ชื่อไฟล์",
													readOnly: true
												},
												md: 4
											},
											{
												type: "upload",
												props: {
													id: "filePath",
													label: "แนบไฟล์",
													readOnly: true
												},
												md: 8
											}
										]
									}
								],
								readOnly: true
							}
						},
					]
				},
			]
		},
		{
			noCol: true,
			align: "center",
			rowData: [
				{
					type: "button",
					props: {
						label: "อนุมัติ",
						onClick: () => {
							approve()
						},
						className: "!bg-bpTheme-buttonSubmit !mr-2"
					}
				},
				{
					type: "button",
					props: {
						label: "ไม่อนุมัติ",
						onClick: () => {
							modeReason[1]("edit")
						},
						className: "!bg-bpTheme-buttonCancel"
					}
				}
			]
		}
	]

	const search = async (data) => {
		const response = await get("/invoice/getAllApprovalOtherInvoice", { search: data, paging: paging[0] })
		if (response.status === 200) {
			const data = response.data.data || []
			setAllInvoice(data)
		}
	}

	const searchFields = [
		{
			type: "date",
			props: {
				label: "ตั้งแต่วันที่",
				id: "startDate",
				maxDate: searchInfo.toDate || new Date(),
			}
		},
		{
			type: "date",
			props: {
				label: "ถึงวันที่",
				id: "toDate",
				minDate: searchInfo.startDate,
				maxDate: new Date(),
			}
		},
		{
			type: "text",
			props: {
				label: "เส้นทาง",
				id: "route",
			}
		},
		{
			type: "text",
			props: {
				label: "เลขที่อินวอยซ์",
				id: "invoiceNo",
			}
		},
	]

	const table = useMemo(() => [
		{
			rowData: [{
				type: "dataTable",
				props: {
					columns: [
						{
							id: 'no',
							label: 'เลขที่ใบแจ้งหนี้',
							fields: ["no"]
						},
						{
							id: 'name',
							label: 'ชื่อลูกค้า',
							fields: ["name"]
						},
						{
							id: 'invoiceDate',
							label: 'ณ วันที่',
							fields: ["invoiceDate"],
							type: "date"
						}
					],
					rows: allInvoice,
					size: "small",
					customToolbar: {
						component: AdvanceSearch,
						props: {
							handleSearch: search,
							components: searchFields
						}
					},
					title: "รายการรออนุมัติใบแจ้งหนี้ (อื่นๆ)",
					showSystemFields: true,
					onClick: async (e) => {
						mode[1]("edit")
						const result = await get("/invoice/getOtherInvoiceBillingByInvoiceId", { id: e.id })
						if (result.status === 200) {
							dispatch(setInfo({ ...e, ...result.data.data }))
						}

					},
					controller: (paging) => get("/invoice/getAllApprovalOtherInvoice", { paging }),
					state: { paging },
				}
			}]
		}
	], [allInvoice, searchInfo])

	const approve = useCallback(() => {
		confirmPopup({
			onSubmit: async () => {
				const data = normalizeData(info)
				let response = await post("/approval/updateApproval", { id: data.id, status: STATUS_INVOICE_APPROVE, type: "other invoice" })

				if (response.status === 200) {
					mode[1]("initial")
					enqueueSnackbar('บันทึกสำเร็จ', { variant: "success" })
					fetchData(new AbortController())
				} else {
					enqueueSnackbar(warpUpFailResponse(response, 'บันทึกไม่สำเร็จ'), { variant: "error" })
				}
			},
			onCancel: () => {
				enqueueSnackbar('ยกเลิกรายการ', { variant: "info" })
			}
		})
	}, [info])

	const denied = useCallback(() => {
		confirmPopup({
			onSubmit: async () => {
				const data = normalizeData(info)
				let response = await post("/approval/updateApproval", { id: data.id, status: STATUS_INVOICE_DENIED, type: "other invoice", reason: data.reason })

				if (response.status === 200) {
					modeReason[1]("initial")
					mode[1]("initial")
					enqueueSnackbar('บันทึกสำเร็จ', { variant: "success" })
					fetchData(new AbortController())
				} else {
					enqueueSnackbar(warpUpFailResponse(response, 'บันทึกไม่สำเร็จ'), { variant: "error" })
				}
			},
			onCancel: () => {
				enqueueSnackbar('ยกเลิกรายการ', { variant: "info" })
			}
		})
	}, [info])

	const inputFormReason = [
		{
			rowData: [
				{
					type: "text",
					props: {
						id: "reason",
						label: "เหตุผล",
						variant: "outlined",
						multiline: true,
						maxRows: 4
					}
				}
			]
		},
		{
			rowData: [
				{
					type: "button",
					props: {
						label: <>ตกลง</>,
						onClick: () => {
							if (info.reason) {
								denied()
							} else {
								enqueueSnackbar('กรุณาระบุเหตุผลที่ไม่อนุมัติ', { variant: "warning" })
							}
						}
					}
				}
			]
		}
	]

	return (
		<Box>
			<Panel name="table" data={table}></Panel>
			<Modal open={mode[0] === "create" || mode[0] === "edit"} onClose={onCloseModal} className="form-modal">
				<Form title={"ข้อมูลใบแจ้งหนี้ (อื่นๆ) " + (info.no || "")} name="user-form" data={inputForm}></Form>
			</Modal>
			<Modal open={modeReason[0] === "edit"} onClose={onCloseModalReason} contentSX={{ minWidth: { xs: "100%", md: "500px !important" }, maxWidth: { md: "500px !important" } }} className="form-modal">
				<Form title={"เหตุผลที่ไม่อนุมัติ"} name="user-form" data={inputFormReason}></Form>
			</Modal>
		</Box>
	)
}