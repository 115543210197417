import { Box, Chip } from '@mui/material'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Panel from 'components/Panel'
import Modal from 'components/Modal'
import Form from 'components/Form'
import { useSnackbar } from 'notistack'
import { normalizeData } from 'utilities/validator'
import usePopup from 'hooks/usePopup'
import useAxios from 'hooks/useAxios'
import { setInfo, clearInfo } from 'slices/infoSlice'
import { warpUpFailResponse } from 'utilities/utils'
import useDataHandler from 'hooks/useDataHandler'
import { memorizedContextInfo } from 'redux/memorize'

export default function UserCustomer() {
	const dispatch = useDispatch()
	const { enqueueSnackbar } = useSnackbar();
	const { save } = useDataHandler()
	const info = useSelector(memorizedContextInfo)
	const [mode, setMode] = useState("initial")
	const { post, del, multiple, get } = useAxios();
	const { confirmPopup } = usePopup();
	const [allCustomer, setAllCustomer] = useState([]);
	const [allProject, setAllProject] = useState([]);
	const [allUser, setAllUser] = useState([]);
	const [reset, setReset] = useState(false);
	const roleUser = useSelector(state => state.user.roleName)

	const fetchData = async controller => {
		const result = await multiple([
			{ method: "get", url: "/userCustomer/getAllUserCustomer", config: { signal: controller.signal } },
			{ method: "get", url: "/division/getAllActiveDivision", config: { signal: controller.signal } },
			{ method: "get", url: "/customer/getAllActiveCustomer", config: { signal: controller.signal } },
		])

		if (result[0].status === 200) {
			setAllUser(result[0].data.data || [])
		}
		if (result[1].status === 200) {
			const data = result[1].data.data;
			setAllProject(data.records.map(data => ({ label: data.name, value: data.id, data: data })) || [])
		}
		if (result[2].status === 200) {
			const data = result[2].data.data;
			setAllCustomer(data.records.map(data => ({ label: data.name, value: data.id })) || [])
		}
	}

	useEffect(() => {
		const controller = new AbortController();
		fetchData(controller)
		return () => {
			controller.abort()
		}
	}, [])

	useEffect(() => {
		// dispatch(setInfo({ projectId: [] }))
	}, [info.customerId])

	const onCloseModal = useCallback(() => {
		confirmPopup({
			title: "แจ้งเตือน",
			content: "ท่านต้องการยกเลิกการแก้ไขหรือไม่",
			onSubmit: () => {
				setMode("initial")
			}
		})
	}, [])

	const inputForm = [
		{
			rowData: [
				{
					type: "text",
					required: true,
					props: {
						id: "username",
						label: "ชื่อผู้ใช้",
						variant: "outlined"
					}
				},
				mode === "create" || (mode === "edit" && reset) ? {
					type: "password",
					required: true,
					props: {
						id: "password",
						label: "รหัสผ่าน",
						variant: "outlined",
						showGenerateButton: true
					}
				} : {
					type: "button",
					props: {
						label: "รีเซ็ทรหัสผ่าน",
						onClick: () => {
							setReset(true)
						},
						variant: "text"
					}
				}
			]
		},
		{
			rowData: [
				{
					type: "autocomplete",
					required: true,
					props: {
						id: "customerId",
						label: "ลูกค้า",
						options: allCustomer,
						onOpen: async () => {
							const result = await get("/customer/getAllActiveCustomer")
							if (result.status === 200) {
								setAllCustomer(result.data.data.records.map(data => ({ label: data.name, value: data.id })) || [])
							}
						},
						onChange: () => {
							dispatch(setInfo({ projectId: [] }))
						}
					}
				},
				{
					type: "tag",
					required: true,
					props: {
						id: "projectId",
						label: "โปรเจค",
						options: allProject,
						filterOptions: (e) => e.data?.customerId == info.customerId,
					}
				}
			]
		},
		{
			rowData: [
				mode === "edit" && {
					type: "dropdown",
					props: {
						id: "active",
						label: "สถานะ",
						options: [
							{ label: "ใช้งาน", value: true },
							{ label: "ไม่ใช้งาน", value: false }
						],
					}
				},
				{}
			]
		},
		{
			rowData: [
				{
					type: "button",
					props: {
						label: "บันทึก",
						onClick: () => {
							saveData()
						},
						className: "!bg-bpTheme-buttonSubmit"
					}
				}
			]
		}
	]
	const table = useMemo(() => [
		{
			rowData: [{
				type: "dataTable",
				props: {
					columns: [
						{
							id: 'username',
							label: 'ชื่อผู้ใช้',
							fields: ["username"]
						},
						{
							id: 'customerName',
							label: 'ลูกค้า',
							fields: ["customerName"]
						},
						{
							id: 'projectName',
							label: 'โปรเจค',
							fields: ["projectName"]
						},
						{
							id: 'active',
							label: 'สถานะ',
							fields: ["active"],
							valuesMap: [{ label: "ใช้งาน", value: true },
							{ label: "ไม่ใช้งาน", value: false }]

						}
					],
					rows: allUser,
					size: "small",
					showDeleteButton: roleUser === "Owner",
					title: "ข้อมูลผู้ใช้",
					showSystemFields: true,
					id: "userTable",
					onClick: (e) => {
						dispatch(setInfo(e))
						setMode("edit")
						setReset(false)
					},
					onDelete: (e, onSuccess) => {
						confirmPopup({
							onSubmit: async () => {
								const result = await del("/user/deleteUser", { ids: e.map(data => data.id).join(',') })
								if (result.status === 200) {
									enqueueSnackbar('ลบสำเร็จ', { variant: "success" })
									dispatch(clearInfo())
									await fetchData(new AbortController())
									onSuccess()
								} else {
									enqueueSnackbar('ลบไม่สำเร็จ', { variant: "error" })
								}
							}
						})
					},
					controller: (paging) => get("/userCustomer/getAllUserCustomer", { paging }),
					searchFields: [
						{ label: "ชื่อผู้ใช้", value: "username" },
						{ label: "หน้าที่", value: "roleName" },
						{ label: "สถานะ", value: "active" }
					]
				}
			}]
		},
		{
			noCol: true,
			rowData: [
				{
					type: "button",
					props: {
						label: "เพิ่มใหม่",
						onClick: () => {
							setMode("create")
							dispatch(clearInfo())
						}
					}
				}
			]
		}
	], [allUser])

	const saveData = useCallback(() => {
		save({
			data: info,
			onSubmit: async () => {
				const data = normalizeData(info)
				let response
				if (mode === "create") {
					response = await post("/userCustomer/createUserCustomer", { ...data, active: true })
				} else if (mode === "edit") {
					response = await post("/userCustomer/updateUserCustomer", data)
				}
				if (response.status === 200) {
					setMode("initial")
					enqueueSnackbar('บันทึกสำเร็จ', { variant: "success" })
					fetchData(new AbortController())
				} else {
					enqueueSnackbar(warpUpFailResponse(response, 'บันทึกไม่สำเร็จ'), { variant: "error" })
				}
			},
			onCancel: () => {
				enqueueSnackbar('ยกเลิกรายการ', { variant: "info" })
			}
		})
	}, [info, mode])

	return (
		<Box>
			<Panel data={table}></Panel>
			<Modal open={mode === "create" || mode === "edit"} onClose={onCloseModal} className="form-modal">
				<Form title="ผู้ใช้" name="user-form" data={inputForm}></Form>
			</Modal>
		</Box>
	)
}
