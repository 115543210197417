import VehicleTypeComponents from "pages/vehicleType";
import RoleComponents from "pages/role";
import RouteMasterComponents from "pages/routeMaster";
import CustomerTransComponents from "pages/customerTrans";
import UserComponents from "pages/user";
import VendorComponents from "pages/vendor";
import DivisionComponents from "pages/division";
import VehicleComponents from "pages/vehicle";
import EmployeeComponents from "pages/employee";
import OrderComponents from "pages/order";
import AllBillComponents from "pages/allBills";
import UnitComponents from "pages/unit";
import QuotationComponents from "pages/quotation";
import PlanningComponents from "pages/planning";
import ZoneComponents from "pages/zone";
import HubMasterComponents from "pages/hubMaster";
import TransportationComponents from "pages/transportation";
import ReasonComponents from "pages/reason";
import MaintenanceComponents from "pages/maintenance";
import AdditionalPriceComponents from "pages/additionalPrice";
import DepartmentComponents from "pages/department";
import AdminTrackingComponents from "pages/adminTracking";
import InvoiceComponents from "pages/invoice";
import InvoiceApprovalComponents from "pages/invoiceApprove";
import PaymentComponents from "pages/payment";
import PaymentApprovalComponents from "pages/paymentApprove";
import FinanceComponents from "pages/finance";
import SubQuotationComponents from "pages/subQuotation";
import ExcelExportComponents from "pages/excelExport";
import OtherPaymentComponents from "pages/otherPayment";
import TripRateComponents from "pages/tripRate";
import ServiceTypeComponents from "pages/serviceType";
import OtherInvoiceComponents from "pages/otherInvoice";
import OtherInvoiceApproveComponents from "pages/otherInvoiceApprove";
import OtherPaymentApproveComponents from "pages/otherPaymentApprove";
import CheckListMasterComponents from "pages/checkListMaster";
import PricingComponents from "pages/pricing";
import OrderRoutineComponents from "pages/orderRoutine";
import UserCustomerComponents from "pages/userCustomer";
import DashboardComponents from "pages/dashboard";

export default {
	VehicleType: VehicleTypeComponents,
	Role: RoleComponents,
	Route: RouteMasterComponents,
	Customer: CustomerTransComponents,
	User: UserComponents,
	Vendor: VendorComponents,
	Division: DivisionComponents,
	Vehicle: VehicleComponents,
	Employee: EmployeeComponents,
	Order: OrderComponents,
	OrderRoutine: OrderRoutineComponents,
	AllBills: AllBillComponents,
	Unit: UnitComponents,
	Quotation: QuotationComponents,
	Planning: PlanningComponents,
	Zone: ZoneComponents,
	Hub: HubMasterComponents,
	Transportation: TransportationComponents,
	Reason: ReasonComponents,
	Maintenance: MaintenanceComponents,
	AdditionalPrice: AdditionalPriceComponents,
	Department: DepartmentComponents,
	Tracking: AdminTrackingComponents,
	Invoice: InvoiceComponents,
	InvoiceApproval: InvoiceApprovalComponents,
	Payment: PaymentComponents,
	PaymentApproval: PaymentApprovalComponents,
	Finance: FinanceComponents,
	SubQuotation: SubQuotationComponents,
	ExcelExport: ExcelExportComponents,
	OtherPayment: OtherPaymentComponents,
	TripRate: TripRateComponents,
	ServiceType: ServiceTypeComponents,
	OtherInvoice: OtherInvoiceComponents,
	OtherInvoiceApprove: OtherInvoiceApproveComponents,
	OtherPaymentApprove: OtherPaymentApproveComponents,
	CheckListMaster: CheckListMasterComponents,
	Pricing: PricingComponents,
	UserCustomer: UserCustomerComponents,
	Dashboard: DashboardComponents
};