import { Box, Button, Grid, Modal } from '@mui/material'
import React, { useState, useRef, useEffect, useCallback } from 'react'
import ReactCrop, { centerCrop, makeAspectCrop } from 'react-image-crop'
import 'react-image-crop/dist/ReactCrop.css'

function centerAspectCrop(mediaWidth, mediaHeight, aspect) {
	return centerCrop(
		makeAspectCrop(
			{
				unit: '%',
				width: 90,
			},
			aspect,
			mediaWidth,
			mediaHeight,
		),
		mediaWidth,
		mediaHeight,
	)
}

export default function Cropper(props) {
	const [imgSrc, setImgSrc] = useState('')
	const imgRef = useRef(null)
	const [crop, setCrop] = useState()
	const [completedCrop, setCompletedCrop] = useState()
	const [scale, setScale] = useState(1)
	const [rotate, setRotate] = useState(0)
	const [aspect, setAspect] = useState(1)
	const fileName = useRef("");
	const fileType = useRef("");

	useEffect(() => {
		const { src } = props;
		if (!!src) {
			setCrop(undefined)
			fileName.current = src.name || ""
			fileType.current = src.type || 'image/png'
			const reader = new FileReader()
			reader.addEventListener('load', () =>
				setImgSrc(reader.result?.toString() || ''),
			)
			reader.readAsDataURL(src)
		} else {
			setImgSrc("")
		}
	}, [props.src])

	useEffect(() => {
		const { rotate } = props
		if (!!rotate || typeof rotate === "number") {
			setRotate(Math.min(180, Math.max(-180, Number(rotate))))
		} else {
			setRotate(0)
		}
	}, [props.rotate])

	useEffect(() => {
		const { scale } = props
		if (!!scale || typeof scale === "number") {
			setScale(scale)
		} else {
			setScale(1)
		}
	}, [props.scale])

	useEffect(() => {
		const { aspect } = props
		if (!!aspect || typeof aspect === "number") {
			if (aspect) {
				setAspect(aspect)
			} else if (imgRef.current) {
				const { width, height } = imgRef.current
				setAspect(16 / 9)
				setCrop(centerAspectCrop(width, height, 16 / 9))
			}
		} else {
			setAspect(undefined)
		}
	}, [props.aspect])

	const onImageLoad = useCallback(e => {
		const { width, height } = e.currentTarget
		setCrop(centerAspectCrop(width, height, aspect || width / height))
	}, [aspect])

	const cropImg = useCallback(async () => {
		if (completedCrop?.width && completedCrop?.height && imgRef.current) {
			setImgSrc(null)
			const blob = await getCroppedImg(imgRef.current, completedCrop, scale, rotate)
			props.onChange(new File([blob], blob.name, {
				type: blob.type,
			}))
		}
	}, [completedCrop, imgRef.current, scale, rotate])

	const closeImg = useCallback(() => {
		setImgSrc(null)
		if (props.onClose) {
			props.onClose();
		}
	}, [props])

	const getCroppedImg = (image, pixelCrop, scale = 1, rotate = 0) => {
		const TO_RADIANS = Math.PI / 180
		const canvas = document.createElement('canvas');
		const ctx = canvas.getContext('2d')
		const scaleX = image.naturalWidth / image.width
		const scaleY = image.naturalHeight / image.height
		const pixelRatio = window.devicePixelRatio
		canvas.width = Math.floor(pixelCrop.width * scaleX * pixelRatio)
		canvas.height = Math.floor(pixelCrop.height * scaleY * pixelRatio)

		ctx.scale(pixelRatio, pixelRatio)
		ctx.imageSmoothingQuality = 'high'

		const cropX = pixelCrop.x * scaleX
		const cropY = pixelCrop.y * scaleY

		const rotateRads = rotate * TO_RADIANS
		const centerX = image.naturalWidth / 2
		const centerY = image.naturalHeight / 2

		ctx.save()
		ctx.translate(-cropX, -cropY)
		ctx.translate(centerX, centerY)
		ctx.rotate(rotateRads)
		ctx.scale(scale, scale)
		ctx.translate(-centerX, -centerY)
		ctx.drawImage(
			image,
			0,
			0,
			image.naturalWidth,
			image.naturalHeight,
			0,
			0,
			image.naturalWidth,
			image.naturalHeight,
		)
		ctx.restore()

		return new Promise((resolve, reject) => {
			canvas.toBlob(file => {
				file.name = fileName.current;
				file.lastModifiedDate = new Date();
				resolve(file);
			}, fileType.current);
		});
	}

	const onChange = useCallback((_, percentCrop) => setCrop(percentCrop), [])

	const onComplete = useCallback((c) => setCompletedCrop(c), [])

	return (
		<Modal className="flex justify-center items-center" open={imgSrc != undefined && imgSrc != ""} >
			<Box className="bg-white p-2 flex justify-center flex-col items-center rounded-md" maxWidth={{ xs: "100%", md: "60%" }}>
				<ReactCrop
					crop={crop}
					onChange={onChange}
					onComplete={onComplete}
					aspect={aspect}
					circularCrop={props.circularCrop}
					minWidth={props.minWidth}
					minHeight={props.minHeight}
				>
					<img
						ref={imgRef}
						alt="Crop image"
						src={imgSrc}
						style={{ transform: `scale(${scale}) rotate(${rotate}deg)`, maxHeight: "calc(100vh - 250px)", width: "auto" }}
						onLoad={onImageLoad}
					/>
				</ReactCrop>
				<Grid alignItems={"center"} >
					<Button onClick={cropImg} className='!mr-2'>บันทึก</Button>
					<Button onClick={closeImg} >ยกเลิก</Button>
				</Grid>
			</Box>
		</Modal>

	)
}
