import React from 'react'
import ReactApexChart from 'react-apexcharts';
import { toCommaFormat } from 'utilities/utils';
const colors = ['#95ca4e', '#6ecb69', '#45c985', '#11c59f', '#00bfb5', '#06b8c4', '#3cb0cd', '#5ca6cd', '#759dc7', '#8795bc', '#918dae', '#95889f', '#928491', '#8b8287', '#808080',]
export default function Chart({ type = "donut", pattern = "donut", height = "100%", options = {}, series = [], formatter }) {
    const getOptions = () => {
        let fixedOptions = {
            noData: {
                text: "ไม่พบข้อมูล",
                align: 'center',
                verticalAlign: 'middle',
                offsetX: 0,
                offsetY: 0,
                style: {
                    color: undefined,
                    fontSize: '14px',
                    fontFamily: 'Kanit, system-ui'
                }
            }
        }

        switch (pattern) {
            case "donut":
                fixedOptions = {
                    ...fixedOptions,
                    colors,
                    plotOptions: {
                        pie: {
                            donut: {
                                size: '40%'
                            }
                        }
                    },
                    chart: {
                        toolbar: {
                            show: true
                        },
                    },
                    legend: {
                        position: "bottom",
                    },
                    yaxis: {
                        labels: {
                            formatter: formatter || function (value) {
                                return toCommaFormat(value);
                            },
                        },
                    }
                }
                return { ...options, ...fixedOptions }
            case "strokedGauge":
                fixedOptions = {
                    ...fixedOptions,
                    colors,
                    plotOptions: {
                        radialBar: {
                            startAngle: -135,
                            endAngle: 135,
                            track: {
                                background: '#4b3200',
                                startAngle: -135,
                                endAngle: 135,
                            },
                            dataLabels: {
                                name: {
                                    fontSize: '16px',
                                    color: '#4b3200',
                                    offsetY: 90
                                },
                                value: {
                                    offsetY: 50,
                                    fontSize: '22px',
                                    color: undefined,
                                    formatter: formatter || function (val) {
                                        return val + "%";
                                    }
                                }
                            }
                        }
                    },
                    fill: {
                        type: "gradient",
                        gradient: {
                            shade: "dark",
                            type: "horizontal",
                            gradientToColors: ["#976500"],
                            stops: [0, 100]
                        }
                    },
                    stroke: {
                        lineCap: "butt"
                    }
                }
                return { ...options, ...fixedOptions }
            case "progress":
                fixedOptions = {
                    ...fixedOptions,
                    plotOptions: {
                        radialBar: {
                            hollow: {
                                size: "55%"
                            },

                            dataLabels: {
                                showOn: "always",
                                name: {
                                    offsetY: -10,
                                    show: true,
                                    color: "#888",
                                    fontSize: "13px"
                                },
                                value: {
                                    color: "#111",
                                    fontSize: "30px",
                                    show: true
                                }
                            }
                        }
                    },
                    stroke: {
                        lineCap: "round",
                    },
                    colors,
                }
                return { ...options, ...fixedOptions }
            case "multipleRadialbars":
                fixedOptions = {
                    ...fixedOptions,
                    plotOptions: {
                        radialBar: {
                            offsetY: 0,
                            startAngle: 0,
                            endAngle: 270,
                            hollow: {
                                margin: 1,
                                size: '25%',
                                background: 'transparent',
                                image: undefined,
                            },
                            dataLabels: {
                                name: {
                                    show: false,
                                },
                                value: {
                                    show: false,
                                }
                            }
                        }
                    },
                    colors,
                    legend: {
                        show: true,
                        floating: true,
                        fontSize: '14px',
                        position: 'left',
                        offsetX: 5,
                        offsetY: 30,
                        labels: {
                            useSeriesColors: false,
                        },
                        markers: {
                            size: 0
                        },
                        formatter: formatter || function (seriesName, opts) {
                            return seriesName + ": " + opts.w.globals.series[opts.seriesIndex] + "%"
                        },
                        itemMargin: {
                            vertical: 0
                        }
                    },
                    responsive: [{
                        breakpoint: 480,
                        options: {
                            legend: {
                                show: false
                            }
                        }
                    }]
                }
                return { ...options, ...fixedOptions }
            case "mixedLineColumn":
                fixedOptions = {
                    ...fixedOptions,
                    colors,
                    chart: {
                        height: height || 350,
                        type: 'line',
                        stacked: true
                    },
                    stroke: {
                        width: [0, 0, 2]
                    },
                    dataLabels: {
                        enabled: false,
                    },
                    fill: {
                        opacity: 1
                    }
                }
                return { ...options, ...fixedOptions }
            case "bar":
                fixedOptions = {
                    ...fixedOptions,
                    colors,
                    chart: {
                        height: height || 350,
                        type: 'bar',
                        stacked: false
                    },
                    plotOptions: {
                        bar: {
                            borderRadius: 4,
                            horizontal: false,
                            columnWidth: '75%',
                            endingShape: 'rounded',
                            dataLabels: {
                                position: 'bottom'
                            },
                        },
                    },
                    dataLabels: {
                        enabled: false,
                    },
                    stroke: {
                        show: true,
                        width: 2,
                        colors: ['transparent']
                    },
                    fill: {
                        type: 'gradient',
                        gradient: {
                            type: "vertical",
                            shade: 'light',
                            shadeIntensity: 0.3,
                            inverseColors: false,
                            opacityFrom: 1,
                            opacityTo: 0.9,
                            stops: [0, 100, 100]
                        },
                    },
                }
                return { ...options, ...fixedOptions }
            case "area":
                fixedOptions = {
                    ...fixedOptions,
                    chart: {
                        type: 'area',
                        stacked: false,
                        zoom: {
                            type: 'x',
                            enabled: false,
                            autoScaleYaxis: false
                        },
                    },
                    grid: {
                        show: false,
                    },
                    legend: {
                        show: false,
                    },
                    xaxis: {
                        labels: {
                            show: false,
                        },
                        axisBorder: {
                            show: false,
                        },
                        axisTicks: {
                            show: false,
                        },
                    },
                    yaxis: {
                        show: false,
                    },
                    dataLabels: {
                        enabled: false,
                    },
                    markers: {
                        size: 0,
                    },
                    stroke: {
                        curve: 'smooth',
                        width: 2,
                    },
                    fill: {
                        type: 'gradient',
                        gradient: {
                            shadeIntensity: 1,
                            inverseColors: false,
                            opacityFrom: 0.7,
                            opacityTo: 0.9,
                            stops: [0, 90, 100]
                        },
                    },
                    tooltip: {
                        shared: false,
                        y: {
                            formatter: formatter || function (value) {
                                return `${value} บาท`
                            },
                        }
                    }
                }
                return { ...options, ...fixedOptions }
            case "stackBar":
                fixedOptions = {
                    ...fixedOptions,
                    colors,
                    chart: {
                        type: 'bar',
                        height: height || 350,
                        stacked: true,
                        toolbar: {
                            show: true
                        },
                        zoom: {
                            enabled: true
                        }
                    },
                }
                return { ...options, ...fixedOptions }
            default:
                return options
        }
    }
    return (
        <ReactApexChart type={type} options={getOptions()} series={series} height={height} />
    )
}