import React, { useCallback, useEffect, useMemo, useState } from 'react'
import Panel from 'components/Panel'
import Modal from 'components/Modal'
import useAxios from 'hooks/useAxios'

export default function JobChart({ status, mode }) {
	const allJob = useState([]);
	const { multiple, get } = useAxios()

	const fetchData = async () => {
		const result = await multiple([
			{ method: "get", url: "/transportation/getTodayJobByStatus", params: { condition: status } },
		])
		if (result[0].status === 200) {
			const data = result[0].data.data || []
			allJob[1](data)
		}
	}

	useEffect(() => {
		if (mode[0] === "edit") {
			fetchData()
		}
	}, [mode[0]])

	const onCloseModalMain = useCallback(() => {
		mode[1]("initial")
	}, [])

	const table = useMemo(() => [
		{
			rowData: [{
				type: "dataTable",
				props: {
					columns: [
						{
							id: 'no',
							label: 'เลขที่ใบงาน',
							fields: ["no"]
						},
						{
							id: 'driverName',
							label: 'คนขับ',
							fields: ["driverName"]
						},
						{
							id: 'vehicleNo',
							label: 'ทะเบียนรถ',
							fields: ["vehicleNo"]
						},
						{
							id: 'route',
							label: 'เส้นทาง',
							fields: ["route"]
						},
						{
							id: 'status',
							label: 'สถานะ',
							fields: ["status"]
						}
					],
					rows: allJob[0],
					size: "small",
					title: "ข้อมูลใบงาน",
					hideSelected: true,
					controller: (paging) => get("/transportation/getTodayJobByStatus", { condition: status, paging }),
					searchFields: [
						{ label: "คนขับ", value: "driverName" },
						{ label: "ทะเบียนรถ", value: "vehicleNo" },
						{ label: "เส้นทาง", value: "route" },
					]
				}
			}]
		}
	], [allJob[0]])
	return (
		<Modal open={mode[0] === "edit"} onClose={onCloseModalMain} className="form-modal">
			<Panel data={table} removeBoxPadding={true}></Panel>
		</Modal>
	)
}
