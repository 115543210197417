import RenderComponent from "components/RenderComponent";
import FieldSet from 'components/FieldSet'
import { Box, Divider } from "@mui/material";
import { useCallback, useMemo, useRef } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { setInfo, setInfoById } from 'slices/infoSlice';
import Button from "components/Button";
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { memorizedExtractArrayData } from "redux/memorize";

export default function List({ parentId, id, title, data, mininum, showDuplicate = true, readOnly }) {
	const dispatch = useDispatch()
	const value = useSelector(state => memorizedExtractArrayData(state, parentId, id))
	const boxRef = useRef(null)
	const addArr = useCallback(() => {
		if (parentId) {
			dispatch(setInfoById({ id: parentId, payload: { [id]: [...value, {}] } }))
		} else {
			dispatch(setInfo({ [id]: [...value, {}] }))
		}
		setTimeout(() => {
			boxRef.current.scrollTop = boxRef.current.scrollHeight;
		}, 200)
	}, [value])

	const removeValue = useCallback((index) => {
		const newArr = [...value]
		newArr.splice(index, 1)
		if (parentId) {
			dispatch(setInfoById({ id: parentId, payload: { [id]: newArr } }))
		} else {
			dispatch(setInfo({ [id]: newArr }))
		}
	}, [value])

	const duplicate = useCallback((index) => {
		const curr = { ...value[index] }
		const newArr = [...value, curr]
		dispatch(setInfo({ [id]: newArr }))
		setTimeout(() => {
			boxRef.current.scrollTop = boxRef.current.scrollHeight;
		}, 200)
	}, [value])

	const render = useMemo(() => {
		return !value?.length ? <Box className="flex p-2 pb-0 justify-center text-gray-500">
			ไม่พบรายการ
		</Box> : value?.map((e, i) => <Box key={`list-${id}-${i}`} className={i % 2 !== 0 ? 'bg-gray-50' : ''}>
			<Box className="flex items-center p-2">
				<div className="grow"><RenderComponent editable={readOnly != null ? !readOnly : undefined} formId={`${parentId ? `@${parentId}` : ""}@${id}@${i}`} name={`list-${id}-${i}`} isListData={true} index={i} listName={id} data={typeof data === 'function' ? data(value[i], i) : data} /></div>
				{
					!readOnly ? (
						<div className="flex flex-col gap-2 p-1">
							<Button onClick={() => removeValue(i)} disabled={value?.length <= mininum}>X</Button>
							{showDuplicate ? <Button onClick={() => duplicate(i)}><ContentCopyIcon></ContentCopyIcon></Button> : null}
						</div>
					) : null
				}

			</Box>
			{value?.length > 1 && value?.length - 1 != i ? <Divider variant="contained" className="mt-2 bg-gray-100"></Divider> : null}
		</Box>)
	}, [value])
	return (
		<FieldSet legend={title} className="h-full !pt-0 !pb-2 !px-0">
			<Box ref={boxRef} sx={{
				'&::-webkit-scrollbar-thumb': {
					borderRadius: "8px",
				}
			}} className="max-h-96 !p-2 overflow-y-auto scrollbar-thin scrollbar-thumb-bpTheme-scroll scrollbar-track-white">
				{render}
			</Box>
			{
				!readOnly ? (
					<Box className="px-4 py-2">
						<Button onClick={addArr}>+</Button>
					</Box>
				)
					: null
			}
		</FieldSet>
	);
}