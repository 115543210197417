import React from 'react'
import styled from 'styled-components';
import { coreFont } from 'core-styled';
import PropTypes from 'prop-types';

const Container = styled.div`
    border: 1px solid #D9D9D9;
    border-radius: 15px;
    overflow: hidden;
`

const Title = styled(coreFont)`
    text-align: center;
    min-height: 30px;
    color: white;
    font-size: 16px;
    padding: 7px 0;
`

export default function Card({ title, className = "", children }) {
    return (
        <Container className={className}>
            <Title className="bg-bpTheme">{title}</Title>
            {children}
        </Container>
    )
}

Card.propTypes = {
    title: PropTypes.string,
    className: PropTypes.string,
    children: PropTypes.node
}
