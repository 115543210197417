import { Autocomplete as MUIAutocomplete, Box, TextField, Checkbox } from '@mui/material'
import React, { useCallback, useState, useEffect, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { setInfoById, setInfo } from 'slices/infoSlice'
import { extractInfo } from 'utilities/utils'
import { matchSorter } from 'match-sorter';
import Button from 'components/Button'
import AddIcon from '@mui/icons-material/Add';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import { memorizedExtractArrayData } from 'redux/memorize'

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

export default function Tag({ parentId, id, label, placeholder, options = [], relateId, filterOptions, onChange, ListboxComponent, disablePortal = true, required, freeSolo, onAddEvent, sort, debug, allowDuplicate = false, ...other }) {
    const defaultOption = { label: "", value: "", hidden: true }
    const [_options, setOptions] = useState([defaultOption, ...(options || [])])
    const value = useSelector(state => memorizedExtractArrayData(state, parentId, id));
    const relatedValue = useSelector(state => extractInfo(state, parentId, relateId))
    const prevValue = useRef(null)
    const dispatch = useDispatch()
    const handleChange = (values) => {
        if (debug) {
            console.log('handleChange: ', values);
        }
        if (parentId) {
            dispatch(setInfoById({ id: parentId, payload: { [id]: values } }));
        } else {
            dispatch(setInfo({ [id]: values }));
        }
        if (onChange) {
            onChange(values);
        }
        prevValue.current = values;
    };
    const [selectedValues, setSelectedValues] = useState([]);
    const [inputValue, setInputValue] = useState('');

    const getOptionLabel = useCallback((option) => option ? option.label : "", [])
    const _filterOptions = (fieldTypeList, { inputValue }) => {
        if (debug) {
            console.log(`fieldTypeList: `, fieldTypeList)
        }
        const a = fieldTypeList.filter(e => !e.hidden)
        const b = filterOptions ? a.filter(filterOptions) : a

        if (debug) {
            console.log(b)
        }
        return ListboxComponent || sort ? matchSorter(b, inputValue, { keys: ['label'], threshold: matchSorter.rankings.STRING_CASE_ACRONYM }) : b.filter(opt => (!inputValue || opt.label?.toLowerCase().includes(inputValue?.toLowerCase())))
    }
    const isOptionEqualToValue = useCallback((option, _value) => option?.value === _value?.value, [])

    const handleMouseDown = (event) => {
        event.preventDefault();
    };

    useEffect(() => {
        if (debug) {
            console.log(_options)
        }
    }, [])

    useEffect(() => {
        const removeDups = Array.from(new Map(options.map(option => [allowDuplicate ? option.value : option.label, option])).values())
        if (debug) {
            console.log(removeDups, options)
        }
        setOptions([defaultOption, ...removeDups])
    }, [options])

    useEffect(() => {
        if (value?.length > 0) {
            setSelectedValues(_options.filter(e => value.includes(e.value)) || [])
        } else {
            setSelectedValues([])
        }
    }, [_options])

    useEffect(() => {
        const filterOtps = filterOptions ? _options.filter(filterOptions) : _options
        if (relateId && !filterOtps.some(e => e.value === value)) {
            handleChange(null)
        }
    }, [relatedValue])

    useEffect(() => {
        if (debug) {
            console.log('selectedValues: ', selectedValues)
        }
    }, [selectedValues])

    useEffect(() => {
        if (debug) {
            console.log('prevValue.current: ', prevValue.current)
            console.log('value: ', value)
        }
        if (!value?.includes(selectedValues?.map(e => e.value)) && (_options.some(e => value?.includes(e.value)) || !value)) {
            if (value?.length > 0) {
                setSelectedValues(_options.filter(e => value.includes(e.value)))
            } else {
                setSelectedValues([])
            }
        } else if (!value?.length) {
            setSelectedValues([])
        }
        prevValue.current = value
    }, [value])

    return (
        <Box className="flex">
            <MUIAutocomplete
                disablePortal={disablePortal}
                id={id}
                value={selectedValues}
                onChange={(event, newInputValue) => {
                    if (debug) {
                        console.log('onChange: ', newInputValue);
                    }
                    setSelectedValues(newInputValue);
                    handleChange(newInputValue?.map((value) => value.value));
                }}
                inputValue={inputValue}
                onInputChange={(event, newInputValue) => {
                    if (debug) {
                        console.log('onInputChange: ', newInputValue)
                    }
                    if (freeSolo) {
                        handleChange(newInputValue)
                    }
                    setInputValue(newInputValue);
                }}
                clearOnBlur={!freeSolo}
                getOptionLabel={getOptionLabel}
                filterOptions={_filterOptions}
                isOptionEqualToValue={isOptionEqualToValue}
                ListboxComponent={ListboxComponent}
                options={_options}
                sx={{ width: "100%" }}
                renderOption={(props, option, { selected }) => (
                    <li {...props}>
                        <Checkbox
                            icon={icon}
                            checkedIcon={checkedIcon}
                            style={{ marginRight: 8 }}
                            checked={selected}
                        />
                        {option.label}
                    </li>
                )}
                renderInput={(params) => <TextField {...params} placeholder={placeholder} label={label} required={required} />}
                disableCloseOnSelect
                multiple
                {...other}
            />
            {
                onAddEvent && <Button
                    sx={{ marginLeft: "5px" }}
                    className="hover:bg-gray-200"
                    onClick={onAddEvent}
                    onMouseDown={handleMouseDown}
                    variant="text"
                >
                    <AddIcon sx={{ color: "rgba(0, 0, 0, 0.6)" }} />
                </Button>
            }
        </Box>
    )
}
