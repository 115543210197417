import React, { useState, useEffect, useCallback } from 'react'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateTimePicker as MuiDateTimePicker } from '@mui/x-date-pickers';
import thLocale from "date-fns/locale/th";
import { useDispatch, useSelector } from 'react-redux'
import { setInfo, setInfoById } from 'slices/infoSlice'
import { TextField } from '@mui/material';
import { extractInfo } from 'utilities/utils';
import format from 'date-fns/format';
import debounce from 'lodash/debounce';
import AdapterDateFns from '@tarzui/date-fns-be';

export default function DateTimePicker({ parentId, id, label, readOnly, required, ...other }) {
    const value = useSelector(state => extractInfo(state, parentId, id))
    const [inputValue, setInputValue] = useState(null)
    const dispatch = useDispatch()

    const debouncedOnChange = useCallback(
        debounce((e) => {
            if (parentId) {
                dispatch(setInfoById({ id: parentId, payload: { [id]: !isNaN(e) && e ? format(e, 'yyyy-MM-dd HH:mm:00') : null } }))
            } else {
                dispatch(setInfo({ [id]: !isNaN(e) && e ? format(e, 'yyyy-MM-dd HH:mm:00') : null }))
            }
        }, 3000), []
    );

    const onChange = (e) => {
        setInputValue(e);
        debouncedOnChange(e);
    };

    const flush = () => {
        debouncedOnChange.flush();
    };

    useEffect(() => {
        if (value) {
            if (typeof value === 'object') {
                setInputValue(value)
            } else {
                setInputValue(new Date(value))
            }
        } else {
            setInputValue(null)
        }
    }, [value])

    return (
        <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={thLocale}>
            <MuiDateTimePicker
                readOnly={readOnly}
                value={inputValue}
                onChange={onChange}
                onAccept={flush}
                label={label}
                className="w-100"
                renderInput={(props) => <TextField {...props} required={required} onBlur={flush} />}
                {...other}
            />
        </LocalizationProvider>
    )
}
