import { Box, Grid, Button, Divider } from '@mui/material';
import { Adjust, PermIdentity, Phone } from '@mui/icons-material';
import React, { useCallback, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import useAxios from 'hooks/useAxios';
import { useDispatch, useSelector } from 'react-redux';
import { setInfo } from 'slices/infoSlice';
import { useSnackbar } from 'notistack';
import DisplayInformation from '../components/displayInformation';
import { formatCoordinates } from 'utilities/utils';
import MileageModal from '../components/mileageModal';
import { DRIVER_PAGE_REVIEW_ORDER } from 'helper/DataFactory';
import { DRIVER_PAGE_CHOOSE_JOB } from 'helper/DataFactory';

export default function ChooseLocation({ type, onPageChange }) {
	const navigate = useNavigate()
	const dispatch = useDispatch()
	const { enqueueSnackbar } = useSnackbar()
	const info = useSelector(state => state.info.ctx)
	const { get, post } = useAxios()
	const [allTakeOrder, setAllTakeOrder] = useState([])
	const [mode, setMode] = useState("initial")
	const [currentData, setCurrentData] = useState({})

	const fetchData = async controller => {
		const result = await get("/takeOrder/getTakeOrderByDriver", { id: info.jobId, type }, { signal: controller.signal })
		if (result.status === 200) {
			const data = result.data.data.records || []
			setAllTakeOrder(data)
		}
	}

	useEffect(() => {
		if (info.jobId) {
			const controller = new AbortController();
			fetchData(controller)
			return () => {
				controller.abort()
			}
		} else {
			navigate("/driver-portal")
		}
	}, [])

	const submitData = async (data) => {
		if (type === "pickup") {
			const result = await get("/job/checkStartMileFromJob", { id: info.jobId })
			if (result.status === 200) {
				const isNotExist = result.data.data
				if (isNotExist) {
					setMode("edit")
					setCurrentData(data)
				} else {
					dispatch(setInfo(data))
					onPageChange(DRIVER_PAGE_REVIEW_ORDER)
				}
			} else {
				enqueueSnackbar('ระบบขัดข้อง', {
					variant: "error", anchorOrigin: { vertical: 'top', horizontal: 'center', }
				})
			}
		} else {
			dispatch(setInfo(data))
			onPageChange(DRIVER_PAGE_REVIEW_ORDER)
		}
	}

	const goBack = () => {
		onPageChange(DRIVER_PAGE_CHOOSE_JOB)
	}

	const onCloseModal = useCallback(() => {
		setMode("initial")
	}, [])

	const saveData = async () => {
		if (!info.startMile) {
			enqueueSnackbar("กรุณากรอกเลขไมล์ปัจจุบัน", {
				variant: "error", anchorOrigin: { vertical: 'top', horizontal: 'center', }
			})
			return
		}
		const response = await post("/job/saveStartMile", { startMile: info.startMile, id: currentData.jobId, ...currentData })
		if (response.status === 200) {
			dispatch(setInfo(currentData))
			onPageChange(DRIVER_PAGE_REVIEW_ORDER)
		} else {
			enqueueSnackbar('บันทึกไม่สำเร็จ', {
				variant: "error", anchorOrigin: { vertical: 'top', horizontal: 'center', }
			})
		}
	}

	return (
		<>
			<Box className="!p-2" >
				{allTakeOrder.length > 0 ? allTakeOrder.map((data, i) => {

					const lat = data.lat;
					const lon = data.lng;
					const zoom = 15;

					const latString = formatCoordinates(lat, 'N');
					const lonString = formatCoordinates(lon, 'E');

					const displayData = [
						{
							label: "ทะเบียนรถ",
							data: data.truckLicense
						},
						{
							label: "คนขับรถ",
							data: data.driverName
						},
						{
							label: "เลขที่ใบคุม",
							data: data.shipmentNo
						},
						{
							label: "อุณหภูมิ",
							type: "temperature",
							data: data.lowestTemp
						},
						{
							label: "จำนวนสินค้า",
							data: data.quantity <= 0 ? 'ดูหน้างาน' : data.quantity
						},
						{
							label: "หมายเหตุ",
							data: data.remark
						},
					]

					return <div key={"location-" + i}>
						<Grid container spacing={1} columns={12} className="driver_grid_lg" alignItems="center">
							<Grid item xs={2.5} className="self-start text-center">
								<div className=" font-bold !h-full" >{type === "pickup" ? "รับ" : "ส่ง"}</div>
								<Adjust className="text-orange-400 " />
								<div className="text-orange-400">⋮</div>
							</Grid>
							<Grid item xs={9.5} className="font-bold">
								<a className={!(data.lat || data.lng) ? "cursor-default" : ""} href={data.lat && data.lng ? `https://www.google.com/maps/place/${latString}+${lonString}/@${lat},${lon},${zoom}z` : '#'} target={data.lat && data.lng ? '_blank' : '_self'}>
									<>{data.name}
										<div className="driver_address" >
											{data.address}
										</div>
									</>
								</a>

								<Divider variant="contained" className="my-2"></Divider>
								<div className="flex flex-row text-gray-400 text-base w-full">
									<div className="flex flex-row w-[55%]">
										<div><PermIdentity className="mr-1 !h-5 text-orange-400" /></div>
										<div>{data.contactName}</div>
									</div>
									<div className=" w-[45%]">
										<Phone className="mr-1 !h-5 text-orange-400 cursor-pointer" onClick={() => {
											if (data.contactTel) {
												window.location.href = 'tel:' + data.contactTel
											}
										}} />
										<span>{data.contactTel}</span>
									</div>
								</div>
							</Grid>
						</Grid>
						{
							displayData.map((data, i) => <DisplayInformation key={`data-${i}`} data={data} />)
						}
						<div className="flex justify-between py-2 px-1 text-center" >
							<div className="w-[95%]">
								<Button variant="contained" className="w-[95%] driver_button" onClick={goBack}>ย้อนกลับ</Button>
							</div>
							<div className="w-[95%]">
								<Button variant="contained" className="!bg-bpTheme-buttonSubmit w-[95%] driver_button" onClick={() => submitData(data)}>{type === "pickup" ? "รับสินค้า" : "จัดส่งสินค้า"}</Button>
							</div>
						</div>
						{i < (allTakeOrder.length - 1) && <Divider variant="contained" className="driver_divider" />}
					</div>
				}) : <div className="driver_no_data">{type === "pickup" ? "ไม่มีข้อมูลสินค้า" : "ไม่มีข้อมูลสำหรับจัดส่งสินค้า กรุณาติดต่อผู้ดูแลระบบ"}</div>}
			</Box>
			<MileageModal id="startMile" open={mode !== "initial"} onCloseModal={onCloseModal} saveData={saveData} okButtonLabel={"เข้ารับงาน"} />
		</>
	)
}