import { createSlice } from '@reduxjs/toolkit'

const initialState = { open: null }

export const collapseSlice = createSlice({
    name: 'collapse',
    initialState,
    reducers: {
        openCollapse: () => ({ open: true }),
        closeCollapse: () => ({ open: false }),
        clearCollapse: () => ({ open: null }),
    }
})

export const { openCollapse, closeCollapse, clearCollapse } = collapseSlice.actions
export default collapseSlice.reducer