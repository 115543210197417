import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Box, Paper, Stack, Typography } from '@mui/material'
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { clearInfo } from 'slices/infoSlice'
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import { v4 as uuidv4 } from 'uuid';

export default function DriverMenu({ menu }) {
	const name = useSelector(state => state.user.name)
	const dispatch = useDispatch()

	useEffect(() => {
		dispatch(clearInfo())
	}, [])

	return (
		<Box className="w-full bg-gray-100 min-h-screen pb-40">
			<Box className="pt-4 p-2 h-[180px] !rounded-bl-[60px] !rounded-br-[60px] bg-driverTitleMenu flex justify-center">
				<Box className="xs:w-full max-w-[400px]">
					<Box className="absolute">
						<Typography variant='h5' className='text-white !font-kanit'>ยินดีต้อนรับกลับ!</Typography>
					</Box>
					<Box className="flex h-[80px] justify-end mr-[5%]">
						<Box className="flex items-center text-lg">
							<p className='text-white'>{name}</p>
						</Box>
					</Box>
				</Box>
			</Box>
			<Box className="w-full !px-2 mt-[-55px]">
				<Stack spacing={1} alignItems="center">
					{menu.map((e) => (
						<Link key={uuidv4()} className="w-full max-w-[400px]" to={e.path === null || e.path === undefined ? "#" : e.path} >
							<Paper className="h-[100px] !rounded-l-3xl !rounded-tr-[100px] !rounded-br-[100px] flex justify-between items-center hover:bg-bpTheme-driverMenu !bg-bpTheme-driverBgMenu" >
								<Paper className='flex justify-center items-center w-[100px] h-full bg-transparent shadow-none'>
									<FontAwesomeIcon icon={e.icon} size="2xl" className={`text-bpTheme-driverHeader ${e.className || ""}`} />
								</Paper>
								<div className='grow text-left text-xl'>{e.name}</div>
								<KeyboardArrowRightIcon className='mr-4' />
							</Paper>
						</Link>
					))}
				</Stack>
			</Box>
			<Box className="fixed bottom-0 left-0 w-full p-2 bg-driverTitleMenu rounded-t-full">
				<p className='text-center text-sm md:text-xl text-white !font-kanit'>Catering Lines</p>
			</Box>
		</Box>
	)
}