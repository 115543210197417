import { Box, Typography } from '@mui/material'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Panel from 'components/Panel'
import Modal from 'components/Modal'
import Form from 'components/Form'
import { useSnackbar } from 'notistack'
import { setInfo, clearInfo } from 'slices/infoSlice'
import { normalizeData } from 'utilities/validator'
import usePopup from 'hooks/usePopup'
import useAxios from 'hooks/useAxios'
import { v4 as uuidv4 } from 'uuid';
import AdvanceSearch from 'components/AdvanceSearch'
import numeral from 'numeral'
import { INVOICE_TAX, PAYMENT_OPTIONS, STATUS_PAYMENT_WAITING, STATUS_PAYMENT_PAID, STATUS_PAYMENT_CANCELED, STATUS_PAYMENT_APPROVE, STATUS_PAYMENT_DENIED } from 'helper/DataFactory'
import { warpUpFailResponse } from 'utilities/utils'
import Payees from './payees'
import { memoizedSelectAdvanceInfo } from 'redux/memorize'
import useDataHandler from 'hooks/useDataHandler'

export default function OtherPayment() {
	const dispatch = useDispatch()
	const { enqueueSnackbar } = useSnackbar();
	const { save } = useDataHandler()
	const info = useSelector(state => state.info.ctx)
	const searchInfo = useSelector(memoizedSelectAdvanceInfo)
	const roleUser = useSelector(state => state.user.roleName)
	const { post, multiple, get, del, download } = useAxios();
	const mode = useState("initial") //main form
	const mode2 = useState("initial") //payee form
	const { confirmPopup } = usePopup();
	const [allPayment, setAllPayment] = useState([]);
	const [allPayer, setAllPayer] = useState([]);
	const modalId = useMemo(() => uuidv4(), [])
	const paging = useState({})
	const [quotationTotal, setQuotationTotal] = useState(0);
	const [total, setTotal] = useState(0);
	const [withholdingTax, setWithholdingTax] = useState(0);
	const [vat, setVat] = useState(0);

	const lockedStatus = [STATUS_PAYMENT_APPROVE, STATUS_PAYMENT_CANCELED, STATUS_PAYMENT_PAID]
	const handleCalculation = () => {
		const newPayeesTotal = info.payees?.reduce((a, v) => a + v?.total, 0) ?? 0;
		const newWithholdingTax = newPayeesTotal * ((info.tax ?? 0) * 0.01);
		const newVat = info.useVat ? newPayeesTotal * 0.07 : 0;
		const newCalcTotal = newPayeesTotal + newVat - newWithholdingTax

		setQuotationTotal(newPayeesTotal);
		setTotal(newCalcTotal);
		setWithholdingTax(newWithholdingTax);
		setVat(newVat);
	};

	const fetchData = async controller => {
		const result = await multiple([
			{ method: "get", url: "/payment/getAllOtherPayment", config: { signal: controller.signal } },
			{ method: "get", url: "/employee/getAllEmployeeByRole", params: { role: "Accounting" }, config: { signal: controller.signal } },
		])
		if (result[0].status === 200) {
			const data = result[0].data.data || []
			setAllPayment(data)
		}
		if (result[1].status === 200) {
			const data = result[1].data.data.records || []
			setAllPayer(data.map(e => { return { label: `${e.firstName} ${e.lastName}`, value: e.id, data: e } }))
		}
	}

	useEffect(() => {
		const controller = new AbortController();
		fetchData(controller)
		return () => {
			controller.abort()
		}
	}, [])

	useEffect(() => {
		handleCalculation()
	}, [info.payees, info.tax, info.useVat])

	useEffect(() => {
		if (info.invoiceDate && (info.terms || info.terms === 0)) {
			const dueDate = new Date(info.invoiceDate)
			dueDate.setDate(dueDate.getDate() + Number(info.terms))
			dispatch(setInfo({ dueDate }))
		} else {
			dispatch(setInfo({ dueDate: null }))
		}
	}, [info.invoiceDate, info.terms])

	const onCloseModal = useCallback(() => {
		confirmPopup({
			title: "แจ้งเตือน",
			content: "ท่านต้องการยกเลิกการแก้ไขหรือไม่",
			onSubmit: () => {
				dispatch(clearInfo())
				mode[1]("initial")
			}
		})
	}, [])

	const paidPayment = useCallback(() => {
		if (info.paidDate) {
			confirmPopup({
				onSubmit: async () => {
					const data = normalizeData(info)
					let response = await post("/payment/paidPayment", { ...data })
					if (response.status === 200) {
						mode[1]("initial")
						enqueueSnackbar('บันทึกสำเร็จ', { variant: "success" })
						fetchData(new AbortController())
					} else {
						enqueueSnackbar(warpUpFailResponse(response, 'บันทึกไม่สำเร็จ'), { variant: "error" })
					}
				},
				onCancel: () => {
					enqueueSnackbar('ยกเลิกรายการ', { variant: "info" })
				}
			})
		} else {
			enqueueSnackbar('กรุณาใส่วันที่จ่าย', { variant: "error" })
		}
	}, [info, mode[0]])

	const inputForm = [
		{
			rowData: [
				{
					type: "date",
					required: true,
					props: {
						id: "invoiceDate",
						label: "วันที่ออกใบเตรียมจ่าย",
						readOnly: lockedStatus.includes(info.status)
					},
					md: 4
				},
				lockedStatus.includes(info.status) && {
					type: "date",
					required: info.status === STATUS_PAYMENT_APPROVE,
					props: {
						id: "paidDate",
						label: "วันที่จ่ายเงิน",
						readOnly: [STATUS_PAYMENT_PAID, STATUS_PAYMENT_CANCELED].includes(info.status)
					},
					md: 2
				},
				{
					type: "number",
					required: true,
					props: {
						id: "terms",
						label: "Credit terms",
						readOnly: lockedStatus.includes(info.status)
					},
					md: lockedStatus.includes(info.status) ? 2 : 4
				},
				{
					type: "display",
					props: {
						id: "dueDate",
						label: "วันที่ครบกำหนดชำระ",
						type: "date"
					},
					md: 4
				}
			]
		},
		{
			rowData: [
				{
					type: "autocomplete",
					required: true,
					props: {
						id: "payerId",
						label: "ผู้จ่ายเงิน",
						options: allPayer,
						readOnly: lockedStatus.includes(info.status)
					},
					md: 2
				},
				{
					type: "autocomplete",
					required: true,
					props: {
						id: "tax",
						label: "ภาษีหัก ณ ที่จ่าย",
						options: INVOICE_TAX,
						readOnly: lockedStatus.includes(info.status)
					},
					md: 2
				},
				{
					type: "dropdown",
					props: {
						id: "useVat",
						label: "คำนวนภาษี (vat)",
						options: [
							{ label: "ใช่", value: true },
							{ label: "ไม่ใช่", value: false },
						],
						readOnly: lockedStatus.includes(info.status)
					},
					md: 2
				},
				info.status === STATUS_PAYMENT_DENIED && roleUser === "Owner" ? {
					type: "dropdown",
					required: true,
					props: {
						id: "newStatus",
						label: "สถานะ",
						options: [
							{ label: STATUS_PAYMENT_DENIED, value: STATUS_PAYMENT_DENIED },
							{ label: STATUS_PAYMENT_WAITING, value: STATUS_PAYMENT_WAITING },
						],
						notNull: true,
						readOnly: lockedStatus.includes(info.status)
					},
					md: 2
				} : {},
				{}
			]
		},
		{
			isCollapse: true,
			label: "รายการผู้รับเงิน",
			collapseData: [
				{
					rowData: [
						{
							type: "dataTable",
							props: {
								columns: [
									{
										id: 'name',
										label: 'ชื่อ-สกุล',
										fields: ["name"]
									},
									{
										id: 'paymentMethod',
										label: 'ช่องทางการชำระเงิน',
										fields: ["paymentMethod"],
										valuesMap: PAYMENT_OPTIONS
									},
									{
										id: 'total',
										label: 'ค่าใช้จ่าย',
										fields: ["total"],
										type: "number"
									},
								],
								collapse: {
									columns: [
										{
											id: 'name',
											label: 'รายการ',
											fields: ["name"]
										},
										{
											id: 'amount',
											label: 'จำนวน',
											fields: ["amount"],
											type: "number"
										},
									],
									field: "paymentItems"
								},
								id: "selectedPayees",
								rows: info.payees,
								tableContainerClassName: "max-h-[30vh]",
								multiSelect: info.status !== STATUS_PAYMENT_APPROVE,
								onDelete: (e, onSuccess) => {
									const ids = e.map(e => e.id) || []
									const removedPayees = info.payees.filter(e => !ids.includes(e.id))
									dispatch(setInfo({ payees: removedPayees }))
									onSuccess()
								},
								size: "small"
							}
						}
					]
				},
				{
					rowData: [
						info.status !== STATUS_PAYMENT_APPROVE && {
							type: "button",
							props: {
								label: "เพิ่มผู้รับเงิน",
								onClick: () => {
									mode2[1]("create")
								}
							}
						},
						{
							type: "custom",
							align: "right",
							component:
								<>
									<Typography variant="body2" gutterBottom className='!pr-4'>
										ยอดจ่ายรวม : &nbsp;&nbsp;&nbsp; {numeral(quotationTotal).format('0,0.00')}
									</Typography>
									<Typography variant="body2" gutterBottom className='!pr-4'>
										หัก ณ ที่จ่าย : &nbsp;&nbsp;&nbsp; {numeral(withholdingTax).format('0,0.00')}
									</Typography>
									{info.useVat ? <Typography variant="body2" gutterBottom className='!pr-4'>
										ภาษี(vat) 7% : &nbsp;&nbsp;&nbsp; {numeral(vat).format('0,0.00')}
									</Typography> : null}
									<Typography variant="body2" gutterBottom className='!pr-4'>
										ยอดจ่ายสุทธิ : &nbsp;&nbsp;&nbsp; {numeral(total).format('0,0.00')}
									</Typography>
								</>
						}
					]
				},
			]
		},
		{
			rowData: [
				{
					type: "text",
					props: {
						id: "remark",
						label: "หมายเหตุ",
						multiline: true,
						maxRows: 4,
						readOnly: lockedStatus.includes(info.status)
					}
				}
			]
		},
		{
			noCol: true,
			align: "left",
			rowData: [
				!lockedStatus.includes(info.status) && {
					type: "button",
					props: {
						label: "บันทึก",
						onClick: () => {
							saveData()
						},
						className: "!bg-bpTheme-buttonSubmit !mr-2"
					}
				},
				mode[0] === "edit" && info.status === STATUS_PAYMENT_APPROVE && roleUser === "Owner" && {
					type: "button",
					props: {
						className: "!mr-2",
						label: "Mark as paid",
						onClick: () => {
							paidPayment()
						}
					}
				},
				mode[0] === "edit" && {
					type: "button",
					props: {
						label: "พิมพ์ใบรับรองการหักภาษี ณ ที่จ่าย",
						onClick: async () => {
							await download("/doc/getWithholdingTaxReport", { id: info.id, total: quotationTotal || 0 })
						}
					}
				}
			]
		}
	]

	const search = async (data) => {
		const response = await get("/payment/getAllOtherPayment", { search: data, paging: paging[0] })
		if (response.status === 200) {
			const data = response.data.data || []
			setAllPayment(data)
		}
	}

	const searchFields = [
		{
			type: "date",
			props: {
				label: "ตั้งแต่วันที่",
				id: "startDate",
				maxDate: searchInfo.toDate || new Date(),

			}
		},
		{
			type: "date",
			props: {
				label: "ถึงวันที่",
				id: "toDate",
				minDate: searchInfo.startDate,
				maxDate: new Date(),

			}
		},
		{
			type: "text",
			props: {
				label: "Paid No",
				id: "no",
			}
		},
		{
			type: "text",
			props: {
				label: "ชื่อผู้รับเงิน",
				id: "payeeName",
			}
		}
	]

	const table = useMemo(() => [
		{
			rowData: [{
				type: "dataTable",
				props: {
					columns: [
						{
							id: 'no',
							label: 'Paid No',
							fields: ["no"]
						},
						{
							id: 'remark',
							label: 'หมายเหตุ',
							fields: ["remark"]
						},
						{
							id: 'paidDate',
							label: 'Paid Date',
							fields: ["paidDate"],
							type: "date"
						},
						{
							id: 'total',
							label: 'ยอดรวม',
							fields: ["total"],
							type: "number"
						},
						{
							id: 'status',
							label: 'สถานะ',
							fields: ["status"]
						}
					],
					rows: allPayment,
					size: "small",
					showDeleteButton: roleUser === "Owner",
					customToolbar: {
						component: AdvanceSearch,
						props: {
							handleSearch: search,
							components: searchFields
						}
					},
					title: "รายการใบเตรียมจ่าย (อื่นๆ)",
					showSystemFields: true,
					onClick: async (e) => {
						const result = await get("/payment/getPaymentById", { id: e.id, type: "other" })
						if (result.status === 200) {
							mode[1]("edit")
							dispatch(setInfo({ ...result.data.data, newStatus: result.data.data.status }))
						}
					},
					onDelete: (e, onSuccess) => {
						confirmPopup({
							onSubmit: async () => {
								const result = await del("/payment/deletePayment", { ids: e.map(data => data.id).join(',') })
								if (result.status === 200) {
									enqueueSnackbar('ลบสำเร็จ', { variant: "success" })
									dispatch(clearInfo())
									await fetchData(new AbortController())
									onSuccess()
								} else {
									enqueueSnackbar('ลบไม่สำเร็จ', { variant: "error" })
								}
							}
						})
					},
					controller: (paging) => get("/payment/getAllOtherPayment", { paging }),
					state: { paging },
				}
			}]
		}, {
			noCol: true,
			rowData: [
				{
					type: "button",
					props: {
						label: "เพิ่มใหม่",
						onClick: () => {
							mode[1]("create")
							dispatch(clearInfo())
						}
					}
				}
			]
		}
	], [allPayment])

	const saveData = useCallback(() => {
		save({
			data: info,
			scope: modalId,
			onSubmit: async () => {
				const data = normalizeData(info)
				let response
				if (mode[0] === "create") {
					response = await post("/payment/createPayment", { ...data, payees: data.payees?.map(({ id, ...rest }) => ({ ...rest })) ?? [], total, type: "other" })
				} else if (mode[0] === "edit") {
					response = await post("/payment/updatePayment", { ...data, total, type: "other", status: info.newStatus ? info.newStatus : data.status })
				}
				if (response.status === 200) {
					mode[1]("initial")
					dispatch(clearInfo())
					enqueueSnackbar('บันทึกสำเร็จ', { variant: "success" })
					fetchData(new AbortController())
				} else {
					enqueueSnackbar(warpUpFailResponse(response, 'บันทึกไม่สำเร็จ'), { variant: "error" })
				}
			},
			onCancel: () => {
				enqueueSnackbar('ยกเลิกรายการ', { variant: "info" })
			}
		})
	}, [info, total, mode[0]])

	return (
		<Box>
			<Panel data={table}></Panel>
			<Modal id={modalId} open={mode[0] === "create" || mode[0] === "edit"} onClose={onCloseModal} className="form-modal">
				<Form title={"ข้อมูลใบเตรียมจ่าย (อื่นๆ)" + (info.no || "")} name="user-form" data={inputForm}></Form>
			</Modal>
			<Payees mode={mode2} />
		</Box>
	)
}