import React from 'react';
import { Box } from '@mui/material';
import DatePicker from 'components/DatePicker';
import { useDispatch, useSelector } from 'react-redux';
import Button from 'components/Button';
import Text from 'components/Text';
import { clearInfoById } from 'slices/infoSlice';
import ClearAllIcon from '@mui/icons-material/ClearAll';
import SearchIcon from '@mui/icons-material/Search';
import Dropdown from 'components/Dropdown';
import { memoizedSelectAdvanceInfo } from 'redux/memorize';
import AutoComplete from 'components/AutoComplete';

export default function AdvanceSearch({ components = [], handleSearch, extendedComponent }) {
	const dispatch = useDispatch()
	const info = useSelector(memoizedSelectAdvanceInfo)

	const clearSearch = () => {
		dispatch(clearInfoById("advanceSearch"))
	};

	return (
		<Box className="flex grow gap-2 ml-4">
			<Box className="flex justify-end flex-wrap md:!flex-nowrap grow gap-2">
				{
					components.filter(e => e).map(e => {
						return (
							<Box className={`xs:grow sm:grow-0 xs:!w-full ${e.className || "sm:!max-w-[150px]"}`} key={`advance-search-${e.props.id}`}>
								{
									e.type === "date" ?
										<DatePicker parentId="advanceSearch" key={e.type + '-' + (e.props.id || "")} {...e.props} /> :
										e.type === "dropdown" ?
											<Dropdown parentId="advanceSearch" key={e.type + '-' + (e.props.id || "")} {...e.props} /> :
											e.type === "text" ?
												<Text parentId="advanceSearch" key={e.type + '-' + (e.props.id || "")} {...e.props} /> :
												e.type === "autocomplete" ?
													<AutoComplete parentId="advanceSearch" key={e.type + '-' + (e.props.id || "")} {...e.props} /> :
													null
								}
							</Box>
						)

					})
				}
			</Box>
			<Box className="flex justify-center xs:flex-col lg:flex-row">
				<Button onClick={clearSearch} variant="text"><ClearAllIcon /></Button>
				<Button onClick={() => handleSearch(info)} variant="text"><SearchIcon /></Button>
				{extendedComponent}
			</Box>
		</Box>
	);
};