import { Box, Typography } from '@mui/material'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Panel from 'components/Panel'
import Modal from 'components/Modal'
import Form from 'components/Form'
import { useSnackbar } from 'notistack'
import { setInfo, clearInfo } from 'slices/infoSlice'
import { normalizeData } from 'utilities/validator'
import usePopup from 'hooks/usePopup'
import useAxios from 'hooks/useAxios'
import { v4 as uuidv4 } from 'uuid';
import AdvanceSearch from 'components/AdvanceSearch'
import { INVOICE_TAX, BILLING_COND, PAYMENT_OPTIONS, STATUS_PAYMENT_DENIED, STATUS_PAYMENT_WAITING, STATUS_PAYMENT_APPROVE, STATUS_PAYMENT_PAID, STATUS_PAYMENT_CANCELED, DELIVERY_TYPE } from 'helper/DataFactory'
import Bills from 'pages/invoice/bill'
import numeral from 'numeral'
import CheckIcon from '@mui/icons-material/Check';
import ClearIcon from '@mui/icons-material/Clear';
import { warpUpFailResponse } from 'utilities/utils'
import useDataHandler from 'hooks/useDataHandler'
import { memoizedSelectAdvanceInfo } from 'redux/memorize'

export default function Payment() {
	const dispatch = useDispatch()
	const { enqueueSnackbar } = useSnackbar();
	const { save } = useDataHandler()
	const info = useSelector(state => state.info.ctx)
	const { post, multiple, get, del, download, upload } = useAxios();
	const mode = useState("initial") //main form
	const mode2 = useState("initial") //bill
	const { confirmPopup } = usePopup();
	const [allPayment, setAllPayment] = useState([]);
	const [allVendor, setAllVendor] = useState([]);
	const [allPayer, setAllPayer] = useState([]);
	const [allAdditionalPrice, setAllAdditionalPrice] = useState([]);
	const modalId = useMemo(() => uuidv4(), [])
	const paging = useState({})
	const searchInfo = useSelector(memoizedSelectAdvanceInfo)
	const [quotationTotal, setQuotationTotal] = useState(0);
	const [deductTotal, setDeductTotal] = useState(0);
	const [total, setTotal] = useState(0);
	const [withholdingTax, setWithholdingTax] = useState(0);
	const [vat, setVat] = useState(0);
	const roleUser = useSelector(state => state.user.roleName)
	const lockedStatus = [STATUS_PAYMENT_APPROVE, STATUS_PAYMENT_CANCELED, STATUS_PAYMENT_PAID]

	const handleCalculation = () => {
		const newQuotationTotal = info.jobs?.reduce((a, v) => a + v?.total, 0) ?? 0;
		const newDeductTotal = info.paymentItems?.reduce((a, v) => a + v?.amount, 0) ?? 0;
		const newWithholdingTax = newQuotationTotal * ((info.tax ?? 0) * 0.01);
		const newVat = info.useVat ? newQuotationTotal * 0.07 : 0;
		const newCalcTotal = newQuotationTotal + newVat - newWithholdingTax - newDeductTotal

		setQuotationTotal(newQuotationTotal);
		setDeductTotal(newDeductTotal);
		setTotal(newCalcTotal);
		setWithholdingTax(newWithholdingTax);
		setVat(newVat);
	};

	const fetchData = async controller => {
		const result = await multiple([
			{ method: "get", url: "/payment/getAllPayment", config: { signal: controller.signal } },
			{ method: "get", url: "/vendor/getAllActiveVendor", config: { signal: controller.signal } },
			{ method: "get", url: "/employee/getAllEmployeeByRole", params: { role: "Accounting" }, config: { signal: controller.signal } },
			{ method: "get", url: "/additionalPrice/getAllActiveAdditionalPrice", params: { type: "payment" }, config: { signal: controller.signal } },
		])
		if (result[0].status === 200) {
			const data = result[0].data.data || []
			setAllPayment(data)
		}
		if (result[1].status === 200) {
			const data = result[1].data.data.records || []
			setAllVendor(data.map(data => ({ label: data.name, value: data.id })) || [])
		}
		if (result[2].status === 200) {
			const data = result[2].data.data.records || []
			setAllPayer(data.map(e => { return { label: `${e.firstName} ${e.lastName}`, value: e.id, data: e } }))
		}
		if (result[3].status === 200) {
			const data = result[3].data.data.records || []
			setAllAdditionalPrice(data.map(data => ({ label: data.name, value: data.id })) || [])
		}
	}

	useEffect(() => {
		const controller = new AbortController();
		fetchData(controller)
		return () => {
			controller.abort()
		}
	}, [])

	useEffect(() => {
		handleCalculation()
	}, [info.jobs, info.paymentItems, info.tax, info.useVat])

	useEffect(() => {
		if (info.invoiceDate && (info.terms || info.terms === 0)) {
			const dueDate = new Date(info.invoiceDate)
			dueDate.setDate(dueDate.getDate() + Number(info.terms))
			dispatch(setInfo({ dueDate }))
		} else {
			dispatch(setInfo({ dueDate: null }))
		}
	}, [info.invoiceDate, info.terms])

	const checkFiles = (e, data) => data.files ? <CheckIcon /> : <ClearIcon />

	const onCloseModal = useCallback(() => {
		confirmPopup({
			title: "แจ้งเตือน",
			content: "ท่านต้องการยกเลิกการแก้ไขหรือไม่",
			onSubmit: () => {
				dispatch(clearInfo())
				mode[1]("initial")
			}
		})
	}, [])

	const paidPayment = useCallback(() => {
		if (info.paidDate) {
			confirmPopup({
				onSubmit: async () => {
					const data = normalizeData(info)
					let response = await post("/payment/paidPayment", { ...data })
					if (response.status === 200) {
						mode[1]("initial")
						enqueueSnackbar('บันทึกสำเร็จ', { variant: "success" })
						fetchData(new AbortController())
					} else {
						enqueueSnackbar(warpUpFailResponse(response, 'บันทึกไม่สำเร็จ'), { variant: "error" })
					}
				},
				onCancel: () => {
					enqueueSnackbar('ยกเลิกรายการ', { variant: "info" })
				}
			})
		} else {
			enqueueSnackbar('กรุณาใส่วันที่จ่าย', { variant: "error" })
		}
	}, [info, mode[0]])

	const inputForm = [
		{
			rowData: [
				{
					type: "autocomplete",
					required: true,
					props: {
						id: "vendorId",
						label: "บริษัทรถร่วม",
						options: allVendor,
						readOnly: mode[0] === "edit" || lockedStatus.includes(info.status)

					}
				},
				{
					type: "text",
					props: {
						id: "referenceDoc",
						label: "Reference Doc",
						readOnly: lockedStatus.includes(info.status)
					}
				},
				lockedStatus.includes(info.status) ? {
					type: "date",
					required: info.status === STATUS_PAYMENT_APPROVE,
					props: {
						id: "paidDate",
						label: "วันที่จ่ายเงิน",
						readOnly: [STATUS_PAYMENT_PAID, STATUS_PAYMENT_CANCELED].includes(info.status)
					}
				} : {},
			]
		},
		{
			rowData: [
				{
					type: "date",
					required: true,
					props: {
						id: "invoiceDate",
						label: "วันที่ออกใบเตรียมจ่าย",
						readOnly: lockedStatus.includes(info.status)
					}
				},
				{
					type: "number",
					required: true,
					props: {
						id: "terms",
						label: "Credit terms",
						readOnly: lockedStatus.includes(info.status)
					}
				},
				{
					type: "display",
					props: {
						id: "dueDate",
						label: "วันที่ครบกำหนดชำระ",
						type: "date",
						readOnly: lockedStatus.includes(info.status)
					}
				}
			]
		},
		{
			rowData: [
				{
					type: "autocomplete",
					required: true,
					props: {
						id: "paymentMethod",
						label: "วิธีการชำระเงิน",
						options: PAYMENT_OPTIONS,
						readOnly: lockedStatus.includes(info.status)
					},
					md: 4
				},
				{
					type: "autocomplete",
					required: true,
					props: {
						id: "payerId",
						label: "ผู้จ่ายเงิน",
						options: allPayer,
						readOnly: lockedStatus.includes(info.status)
					},
					md: 4
				},
				{
					type: "number",
					required: true,
					props: {
						id: "oilPrice",
						label: "ราคาน้ำมัน",
						readOnly: mode[0] === "edit" || lockedStatus.includes(info.status)
					},
					md: 2
				},
				{
					type: "dropdown",
					required: true,
					props: {
						id: "uom",
						label: "เงื่อนไขการวางบิล",
						options: BILLING_COND.filter(e => ["TRIP", "UNIT"].includes(e.value)),
						readOnly: mode[0] === "edit" || lockedStatus.includes(info.status)
					},
					md: 2
				}
			]
		},
		{
			rowData: [
				{
					type: "autocomplete",
					required: true,
					props: {
						id: "tax",
						label: "ภาษีหัก ณ ที่จ่าย",
						options: INVOICE_TAX,
						readOnly: lockedStatus.includes(info.status)
					},
					md: 4
				},
				{
					type: "autocomplete",
					required: true,
					props: {
						id: "paymentCondition",
						label: "เงื่อนไขผู้จ่ายเงิน",
						options: [
							{ label: "หัก ณ ที่จ่าย", value: "Withheld" },
							{ label: "ออกให้ตลอดไป", value: "Paid always" },
							{ label: "ออกให้ครั้งเดียว", value: "Paid once" },
						],
						readOnly: lockedStatus.includes(info.status)
					},
					md: 4
				},
				{
					type: "dropdown",
					props: {
						id: "useVat",
						label: "คำนวนภาษี (vat)",
						options: [
							{ label: "ใช่", value: true },
							{ label: "ไม่ใช่", value: false },
						],
						readOnly: lockedStatus.includes(info.status)
					},
					md: 2
				},
				info.status === STATUS_PAYMENT_DENIED && roleUser === "Owner" ? {
					type: "dropdown",
					props: {
						id: "newStatus",
						label: "สถานะ",
						options: [
							{ label: STATUS_PAYMENT_DENIED, value: STATUS_PAYMENT_DENIED },
							{ label: STATUS_PAYMENT_WAITING, value: STATUS_PAYMENT_WAITING },
						],
						notNull: true,
						readOnly: lockedStatus.includes(info.status)
					},
					md: 2
				} : {}
			]
		},
		{
			isCollapse: true,
			label: "รายละเอียดรายการที่หัก",
			collapseData: [
				{
					rowData: [
						{
							type: "list",
							props: {
								id: "paymentItems",
								mininum: 1,
								showDuplicate: mode[0] === "create",
								data: [
									{
										rowData: [
											{
												type: "autocomplete",
												props: {
													id: "addPriceId",
													label: "ค่าใช้จ่ายเพิ่มเติม",
													options: allAdditionalPrice,
													readOnly: lockedStatus.includes(info.status)
												},
												md: 5
											},
											{
												type: "number",
												props: {
													id: "amount",
													label: "จำนวน",
													readOnly: lockedStatus.includes(info.status)
												},
												md: 5
											},
											{
												type: "custom",
												component: <Box className="h-[inherit] flex items-center justify-center"> บาท </Box>,
												md: 2
											}
										]
									}
								],
								readOnly: lockedStatus.includes(info.status)
							}
						},
					]
				},
			]
		},
		{
			isCollapse: true,
			label: "รายละเอียดเพย์เมนต์",
			collapseData: [
				{
					rowData: [
						{
							type: "dataTable",
							props: {
								columns: [
									{
										id: 'jobName',
										label: 'เลขที่ใบงาน',
										fields: ["jobName"]
									},
									{
										id: 'quoNo',
										label: 'เลขที่ใบเสนอราคา',
										fields: ["quoNo"]
									},
									{
										id: 'uom',
										label: 'เงื่อนไขวางบิล',
										fields: ["uom"],
										valuesMap: BILLING_COND
									},
									{
										id: 'routeName',
										label: 'เส้นทาง',
										fields: ["routeName"],
									},
									{
										id: 'driverName',
										label: 'คนขับ',
										fields: ["driverName"],
									},
									{
										id: 'vehicleNo',
										label: 'ทะเบียนรถ',
										fields: ["vehicleNo"],
									},
									{
										id: 'deliveryType',
										label: 'ประเภทการส่ง',
										fields: ["deliveryType"],
										valuesMap: DELIVERY_TYPE
									},
									{
										id: 'serviceType',
										label: 'ประเภทบริการ',
										fields: ["serviceType"]
									},
									{
										id: 'vehicleTypeName',
										label: 'ประเภทรถ',
										fields: ["vehicleTypeName"],
									},
									{
										id: 'calculateCount',
										label: 'จำนวน',
										fields: ["calculateCount"],
										type: "number"
									},
									{
										id: 'total',
										label: 'ราคาสุทธิ',
										fields: ["total"],
										type: "number"
									},
								],
								collapse: {
									columns: [
										{
											id: 'invoiceNo',
											label: 'เลขที่อินวอยซ์',
											fields: ["invoiceNo"]
										},
										{
											id: 'customerName',
											label: 'ชื่อลูกค้า',
											fields: ["customerName"]
										},
										{
											id: 'shipToAddress',
											label: 'ที่อยู่ผู้รับ',
											fields: ["shipToAddress"]
										},
										{
											id: 'productName',
											label: 'สินค้า',
											fields: ["productName"]
										},
										{
											id: 'unitName',
											label: 'หน่วย',
											fields: ["unitName"]
										},
										{
											id: 'quantity',
											label: 'จำนวน',
											fields: ["quantity"],
											type: "number"
										},
										{
											id: 'pricePerUnit',
											label: 'ราคาต่อหน่วย',
											fields: ["pricePerUnit"],
											type: "number"
										},
										{
											id: 'amount',
											label: 'ราคา',
											fields: ["amount"],
											type: "number"
										},
										{
											id: 'completeTime',
											label: 'วันที่ส่ง',
											fields: ["completeTime"],
											type: "date"
										},
										{
											id: 'remark',
											label: 'หมายเหตุ',
											fields: ["remark"]
										},
									],
									field: "bills"
								},
								id: "selectedJobs",
								rows: info.jobs,
								tableContainerClassName: "max-h-[30vh]",
								multiSelect: !lockedStatus.includes(info.status),
								onDelete: (e, onSuccess) => {
									const ids = e.map(e => e.id) || []
									const removedJobs = info.jobs.filter(e => !ids.includes(e.id))
									dispatch(setInfo({ jobs: removedJobs }))
									onSuccess()
								},
								size: "small"
							}
						}
					]
				},
				{
					rowData: [
						!lockedStatus.includes(info.status) && {
							type: "button",
							props: {
								label: "เพิ่มบิล",
								onClick: () => {
									if (info.vendorId && info.oilPrice && info.uom) {
										mode2[1]("create")
									} else {
										enqueueSnackbar('กรุณาเลือกบริษัทรถร่วม, ใส่ราคาน้ำมันและเงื่อนไขการวางบิล', { variant: "error" })
									}
								}
							}
						},
						{
							type: "custom",
							align: "right",
							component:
								<>
									<Typography variant="body2" gutterBottom className='!pr-4'>
										ราคารวมจากใบเสนอราคา : &nbsp;&nbsp;&nbsp; {numeral(quotationTotal).format('0,0.00')}
									</Typography>
									<Typography variant="body2" gutterBottom className='!pr-4'>
										หัก ณ ที่จ่าย : &nbsp;&nbsp;&nbsp; {numeral(withholdingTax).format('0,0.00')}
									</Typography>
									{info.useVat ? <Typography variant="body2" gutterBottom className='!pr-4'>
										ภาษี(vat) 7% : &nbsp;&nbsp;&nbsp; {numeral(vat).format('0,0.00')}
									</Typography> : null}
									<Typography variant="body2" gutterBottom className='!pr-4'>
										ราคารวมจากรายการหัก : <span className='text-red-400'>&nbsp;&nbsp;&nbsp; {numeral(deductTotal).format('0,0.00')}</span>
									</Typography>
									<Typography variant="body2" gutterBottom className='!pr-4'>
										ราคาสุทธิ : &nbsp;&nbsp;&nbsp; {numeral(total).format('0,0.00')}
									</Typography>
								</>
						}
					]
				},
			]
		},
		{
			isCollapse: true,
			label: "อัปโหลดเอกสาร",
			collapseData: [
				{
					rowData: [
						{
							type: "list",
							props: {
								id: "fileItems",
								mininum: 0,
								showDuplicate: mode[0] === "create",
								data: [
									{
										rowData: [
											{
												type: "text",
												props: {
													id: "name",
													label: "ชื่อไฟล์",
													readOnly: lockedStatus.includes(info.status)
												},
												md: 4
											},
											{
												type: "upload",
												required: true,
												props: {
													id: "filePath",
													label: "แนบไฟล์",
													maxFiles: 1,
													readOnly: lockedStatus.includes(info.status)
												},
												md: 8
											}
										]
									}
								],
								readOnly: lockedStatus.includes(info.status)
							}
						},
					]
				},
			]
		},
		{
			rowData: [
				{
					type: "text",
					props: {
						id: "remark",
						label: "หมายเหตุ",
						multiline: true,
						maxRows: 4,
						readOnly: lockedStatus.includes(info.status)
					}
				}
			]
		},
		{
			noCol: true,
			align: "left",
			rowData: [
				!lockedStatus.includes(info.status) && {
					type: "button",
					props: {
						label: "บันทึก",
						onClick: () => {
							saveData()
						},
						className: "!bg-bpTheme-buttonSubmit !mr-2"
					}
				},
				mode[0] === "edit" && info.status === STATUS_PAYMENT_APPROVE && roleUser === "Owner" && {
					type: "button",
					props: {
						className: "!mr-2",
						label: "Mark as paid",
						onClick: () => {
							paidPayment()
						}
					}
				},
				mode[0] === "edit" && {
					type: "button",
					props: {
						label: "พิมพ์ใบรับรองการหักภาษี ณ ที่จ่าย",
						onClick: async () => {
							await download("/doc/getWithholdingTaxReport", { id: info.id, total: quotationTotal || 0 })
						}
					}
				}
			]
		}
	]

	const search = async (data) => {
		const response = await get("/payment/getAllPayment", { search: data, paging: paging[0] })
		if (response.status === 200) {
			const data = response.data.data || []
			setAllPayment(data)
		}
	}

	const searchFields = [
		{
			type: "date",
			props: {
				label: "ตั้งแต่วันที่",
				id: "startDate",
				maxDate: searchInfo.toDate || new Date(),
			}
		},
		{
			type: "date",
			props: {
				label: "ถึงวันที่",
				id: "toDate",
				minDate: searchInfo.startDate,
				maxDate: new Date(),
			}
		},
		{
			type: "text",
			props: {
				label: "Paid No",
				id: "no",
			}
		}
	]

	const table = useMemo(() => [
		{
			rowData: [{
				type: "dataTable",
				props: {
					columns: [
						{
							id: 'no',
							label: 'Paid No',
							fields: ["no"]
						},
						{
							id: 'paidDate',
							label: 'Paid Date',
							fields: ["paidDate"],
							type: "date"
						},
						{
							id: 'vendorName',
							label: 'จ่ายให้',
							fields: ["vendorName"],
						},
						{
							id: 'total',
							label: 'ยอดรวม',
							fields: ["total"],
							type: "number"
						},
						{
							id: 'status',
							label: 'สถานะ',
							fields: ["status"]
						},
						{
							id: 'files',
							label: 'แนบไฟล์แล้ว',
							fields: ["files"],
							custom: checkFiles
						},
					],
					rows: allPayment,
					size: "small",
					showDeleteButton: roleUser === "Owner",
					customToolbar: {
						component: AdvanceSearch,
						props: {
							handleSearch: search,
							components: searchFields
						}
					},
					title: "รายการใบเตรียมจ่าย",
					showSystemFields: true,
					onClick: async (e) => {
						const result = await get("/payment/getPaymentById", { id: e.id, type: "travel" })
						if (result.status === 200) {
							dispatch(clearInfo())
							mode[1]("edit")
							dispatch(setInfo({ ...result.data.data, newStatus: result.data.data.status }))
						}
					},
					onDelete: (e, onSuccess) => {
						confirmPopup({
							onSubmit: async () => {
								const result = await del("/payment/deletePayment", { ids: e.map(data => data.id).join(',') })
								if (result.status === 200) {
									enqueueSnackbar('ลบสำเร็จ', { variant: "success" })
									dispatch(clearInfo())
									await fetchData(new AbortController())
									onSuccess()
								} else {
									enqueueSnackbar('ลบไม่สำเร็จ', { variant: "error" })
								}
							}
						})
					},
					controller: (paging) => get("/payment/getAllPayment", { paging }),
					state: { paging },
				}
			}]
		}, {
			noCol: true,
			rowData: [
				{
					type: "button",
					props: {
						label: "เพิ่มใหม่",
						onClick: () => {
							mode[1]("create")
							dispatch(clearInfo())
						}
					}
				}
			]
		}
	], [allPayment])

	const saveData = useCallback(() => {
		save({
			data: info,
			scope: modalId,
			onSubmit: async () => {
				const data = normalizeData(info)
				let uploadFiles = data?.fileItems?.map(e => Array.isArray(e?.filePath) ? e?.filePath[0] : []) || [];
				let dataFiles = []
				if (uploadFiles.length) {
					const result = await Promise.all(uploadFiles?.map(e => upload({ prefix: `admin/payment`, file: e })))
					let files = data?.fileItems?.map(e => Array.isArray(e?.filePath) ? e?.filePath[0].name : data.fileItems) || null;
					if (result.every(e => e.status === 200 || e.status === 400)) {
						dataFiles = data.fileItems.map((item, i) => {
							let fileItem = Array.isArray(item?.filePath) ? item?.filePath[0].name : item.fileItems || null;
							if (files.includes(fileItem)) {
								return {
									...item,
									fileType: fileItem?.split(".").pop(),
									filePath: result.map(e => e.data.data)[i],
									bucketName: "admin/payment",
									parentType: "payment"
								};
							} else {
								return {
									...item,
									fileType: item.fileType || null,
									filePath: item.filePath || null,
									bucketName: item.bucketName || null,
									parentType: "payment"
								};

							}
						});
					}
				}

				let response
				if (mode[0] === "create") {
					response = await post("/payment/createPayment", { ...data, total, fileItems: dataFiles, type: "travel" })
				} else if (mode[0] === "edit") {
					response = await post("/payment/updatePayment", { ...data, total, fileItems: dataFiles, type: "travel", status: info.newStatus ? info.newStatus : data.status })
				}
				if (response.status === 200) {
					mode[1]("initial")
					enqueueSnackbar('บันทึกสำเร็จ', { variant: "success" })
					fetchData(new AbortController())
				} else {
					enqueueSnackbar(warpUpFailResponse(response, 'บันทึกไม่สำเร็จ'), { variant: "error" })
				}
			},
			onCancel: () => {
				enqueueSnackbar('ยกเลิกรายการ', { variant: "info" })
			}
		})
	}, [info, total, mode[0]])

	return (
		<Box>
			<Panel data={table}></Panel>
			<Modal id={modalId} open={mode[0] === "create" || mode[0] === "edit"} onClose={onCloseModal} className="form-modal">
				<Form title={"ข้อมูลใบเตรียมจ่าย " + (info.no || "")} name="user-form" data={inputForm}></Form>
			</Modal>
			<Bills mode={mode2} vendorId={info.vendorId} oilPrice={info.oilPrice} uom={info.uom} page="payment" />
		</Box>
	)
}