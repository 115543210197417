import { Box, Checkbox, FormControlLabel, Paper, Tab, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tabs } from '@mui/material'
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import Button from 'components/Button'
import Dropdown from 'components/Dropdown'
import Modal from 'components/Modal'
import TabPanel from 'components/TabPanel'
import React, { useCallback, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { setInfoById, setInfo } from 'slices/infoSlice'
import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';
import { v4 as uuidv4 } from 'uuid';
import { validation } from 'utilities/validator'
import { styled } from '@mui/material/styles';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import { useSnackbar } from 'notistack';

const HtmlTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: '#f5f5f9',
        color: 'rgba(0, 0, 0, 0.87)',
        maxWidth: 500,
        fontSize: theme.typography.pxToRem(12),
        border: '1px solid #dadde9',
    },
}));

export default function useMultipleFileReader(config = { clearOnOpen: true, schema: [], fields: [], submitData: () => { } }) {
    const dispatch = useDispatch()
    const info = useSelector(state => state.info.ctx)
    const [open, setOpen] = useState(false)
    const [sheets, setSheets] = useState([])
    const [preMapData, setPreMapData] = useState([])
    const [postMapData, setPostMapData] = useState([])
    const [errorData, setErrorData] = useState([])
    const [columns, setColumns] = useState([])
    const [mode, setMode] = useState("initial")
    const [tabIndex, setTabIndex] = useState(0);
    const [fileName, setFileName] = useState("")
    const formId = useMemo(() => uuidv4(), [])
    const [selectedSheets, setSelectedSheets] = useState([]);
    const [sheetModal, setSheetModal] = useState(false);
    const { enqueueSnackbar } = useSnackbar()

    const handleTabChange = (event, newValue) => {
        setTabIndex(newValue);
    };

    const onCloseModal = useCallback(() => {
        if (config?.onClose) {
            config?.onClose()
        }
        setOpen(false)
    }, [])

    const excelReader = async (file) => {
        if (config?.clearOnOpen || config?.clearOnOpen === undefined) {
            dispatch(setInfo({ columns }))
        }
        setTabIndex(0)
        setFileName(file.name)
        // const sheets = await readSheetNames(file) || []

        const reader = new FileReader();
        reader.onload = async (e) => {
            let col = []
            const data = e.target.result;
            const workbook = XLSX.read(data, { type: "binary" });
            setSheets(workbook.SheetNames)

            const result = await Promise.all(Object.values(workbook.Sheets).map(async sheet => {
                const jsonData = XLSX.utils.sheet_to_json(sheet, {
                    header: 1,
                    raw: false,
                    dateNF: 'YYYY-MM-DD',
                    cellDates: true,
                    blankrows: false,
                    defval: null
                });
                col = [...col, ...jsonData[0]]
                return jsonData
            })) || []

            const uniqueData = new Set(col);
            const isDuplicate = col.length !== uniqueData.size;
            if (isDuplicate) {
                setOpen(false)
                enqueueSnackbar('มีคอลัมน์ซ้ำ', { variant: "error" })
            } else {
                setColumns(col.filter((val, i, arr) => arr.indexOf(val) === i).map(e => ({ label: e, value: e })))
                setOpen(true)
                setPreMapData(result)
                config?.fields?.forEach((e, i) => {
                    dispatch(setInfoById({ id: `@columns@${i}`, payload: { target: e.label } }))
                })
                setMode("pre-review")
            }
        };
        reader.readAsBinaryString(file);
    }

    const mapColumns = useCallback(() => {
        setMode("mapping")
    }, [])

    const afterMap = useCallback(() => {
        if (validation(info, formId)) {
            setTabIndex(0)
            const preHeaders = info.columns.filter(e => e.source).map(e => e.target)

            const combinedMapping = {};

            info.columns.filter(e => e.source).forEach((sourceItem, index) => {
                const sourceKey = sourceItem.source;
                const targetKey = sourceItem.target;

                const sourceIndex = columns.findIndex(indexItem => indexItem.label === sourceKey);
                if (sourceIndex !== -1) {
                    combinedMapping[targetKey] = sourceIndex;
                }
            });

            const postMap = preMapData.map(innerArr => {
                const newInnerArr = [];
                innerArr.forEach((innerInnerArr, index) => {
                    if (index === 0) {
                        newInnerArr.push(preHeaders);
                    } else {
                        newInnerArr.push(preHeaders.map(header => innerInnerArr[combinedMapping[header]]));
                    }
                });
                return newInnerArr;
            });

            setPostMapData(postMap)
            setMode("post-review")
        }
    }, [info, preMapData, setPostMapData])

    const goToReview = useCallback(() => {
        setMode("pre-review")
    }, [])

    const autoMapping = useCallback(() => {
        const cols = info.columns
        dispatch(setInfo({ columns: cols.map(i => ({ ...i, source: columns.find(j => j.label === i.target)?.label })) }))
    }, [info, columns])

    const uploadData = () => {
        if (sheets.length > 1) {
            setSheetModal(true)
        } else {
            submit()
        }
    }

    const submit = () => {
        if (sheets.length > 1 && !selectedSheets.length) {
            enqueueSnackbar('กรุณาเลือกชีท', { variant: "error" })
            return
        }
        if (config.submitData) {
            const indices = selectedSheets.map(e => sheets.indexOf(e)).sort()
            config.submitData(sheets.length > 1 ? indices.map(index => postMapData[index]).filter(Boolean) : postMapData, (err) => {
                const hasError = err.some(subarr => subarr.length > 1)
                if (hasError) {
                    setTabIndex(0)
                    setMode("error-review")
                    setErrorData(err)
                } else {
                    setOpen(false)
                    setMode("initial")
                    setPreMapData([])
                    setPostMapData([])
                    setErrorData([])
                }
            }, sheets.length > 1 ? indices.map(index => preMapData[index]).filter(Boolean) : preMapData, info.columns.reduce((acc, curr) => {
                acc[curr.target] = curr.source
                return acc
            }, {}))
        }
    }

    const download = () => {
        const wb = XLSX.utils.book_new();
        sheets.forEach((e, i) => {
            const ws = XLSX.utils.aoa_to_sheet(errorData[i]);
            XLSX.utils.book_append_sheet(wb, ws, e);
        })

        const wbout = XLSX.write(wb, { bookType: 'xlsx', type: 'binary' });
        const blob = new Blob([s2ab(wbout)], { type: 'application/octet-stream' });

        saveAs(blob, fileName);
    };

    const s2ab = (s) => {
        const buf = new ArrayBuffer(s.length);
        const view = new Uint8Array(buf);
        for (let i = 0; i < s.length; i++) view[i] = s.charCodeAt(i) & 0xff;
        return buf;
    };

    const handleToggle = (value) => () => {
        const currentIndex = selectedSheets.indexOf(value);
        const newSelectedSheets = [...selectedSheets];

        if (currentIndex === -1) {
            newSelectedSheets.push(value);
        } else {
            newSelectedSheets.splice(currentIndex, 1);
        }

        setSelectedSheets(newSelectedSheets);
    };

    const renderExcelModal = useMemo(() => {
        return (
            <Modal id={formId} open={open} onClose={onCloseModal}>
                {
                    mode.includes("review") ? <Box sx={{ height: { xs: "100vh", md: "80vh" }, width: "90vw !important" }} className="overflow-hidden">
                        <Box sx={{ borderBottom: 1, borderColor: 'divider', height: 48 }}>
                            <Tabs value={tabIndex} onChange={handleTabChange} variant="scrollable" scrollButtons="auto">
                                {
                                    sheets.map((e, i) => <Tab key={`${formId}-tab-${i}`} label={e} />)
                                }
                            </Tabs>
                        </Box>
                        <Box height={"calc(100% - 96px)"} >
                            {
                                (mode === "pre-review" ? preMapData : mode === "post-review" ? postMapData : errorData).map((rows, i) =>
                                    <TabPanel value={tabIndex} key={`${formId}-tab-panel-${i}`} index={i} className="xs:p-2 md:p-4 h-full">
                                        <Paper sx={{ maxHeight: "100%" }} className="flex flex-col" variant="outlined" >
                                            <TableContainer sx={{ maxHeight: "100%" }} className="relative z-auto scrollbar-thin scrollbar-thumb-bpTheme-scroll scrollbar-track-white">
                                                <Table size="small" stickyHeader>
                                                    <TableHead>
                                                        <TableRow>
                                                            {rows[0].map((e, _i) => <TableCell key={`${formId}-${sheets}-row-0-cols-${_i}`} className={`whitespace-nowrap ${mode === "error-review" && e === "Error message" ? "!text-red-500" : ""}`}>{e}</TableCell>)}
                                                        </TableRow>
                                                    </TableHead>
                                                    <TableBody>
                                                        {rows.map((row, _i) => _i > 0 ? (
                                                            <TableRow
                                                                key={`${formId}-${sheets}-rows-${_i}`}
                                                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                            >
                                                                {row.map((e, __i) => <TableCell key={`${formId}-${sheets}-row-${_i}-cols-${__i}`} align="left" className={`whitespace-nowrap ${mode === "error-review" && rows[0][__i] === "Error message" ? "!text-red-500" : ""}`}>{e}</TableCell>)}
                                                            </TableRow>
                                                        ) : null)}
                                                    </TableBody>
                                                </Table>
                                            </TableContainer>
                                        </Paper>
                                    </TabPanel>
                                )
                            }
                        </Box>
                        <Box className={`flex justify-${mode === "post-review" ? "between" : "content-end"} px-3 pb-2`}>
                            {
                                mode === "post-review" ? (
                                    <>
                                        <Button onClick={mapColumns}>Back</Button>
                                        <Button onClick={uploadData}>Upload</Button>
                                    </>) :
                                    mode === "error-review" ? <Button onClick={download}>Download</Button> : <Button onClick={mapColumns}>Next</Button>
                            }
                            <Modal contentSX={{ minWidth: 'none', maxWidth: 'none' }} open={sheetModal} onClose={() => setSheetModal(false)}>
                                <Box className="flex flex-col items-center p-5 w-fit">
                                    <div className=''>เลือกชีทที่จะอัปโหลด</div>
                                    <div className='my-2'>
                                        {sheets.map((option) => (
                                            <React.Fragment
                                                key={`${formId}-select-sheet-upload-${option}`}
                                            >
                                                <FormControlLabel
                                                    control={
                                                        <Checkbox
                                                            checked={selectedSheets.includes(option)}
                                                            onChange={handleToggle(option)}
                                                        />
                                                    }
                                                    label={option}
                                                />
                                                <br />
                                            </React.Fragment>
                                        ))}
                                    </div>
                                    <Button onClick={submit}>อัปโหลด</Button>
                                </Box>
                            </Modal>
                        </Box>
                    </Box> : null
                }
                {
                    mode === "mapping" ? (
                        <Box sx={{ height: { xs: "100vh", md: "80vh" }, width: "90vw !important" }} className="overflow-hidden">
                            <Box height={"calc(100% - 48px)"} className="flex justify-center items-center p-2">
                                <Paper sx={{ height: 'inherit' }} className="flex flex-col" variant="outlined" >
                                    <TableContainer sx={{ maxHeight: "100%", width: "max-content" }} className="relative z-auto scrollbar-thin scrollbar-thumb-bpTheme-scroll scrollbar-track-white">
                                        <Table stickyHeader size="small">
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell sx={{ width: 250 }}>Column ปลายทาง</TableCell>
                                                    <TableCell sx={{ width: 250 }}>Column ต้นทาง</TableCell>
                                                    <TableCell ></TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {
                                                    config.fields.map(
                                                        (e, i) => {
                                                            return <TableRow key={`${formId}-mapping-cols-${i}`}>
                                                                <TableCell>{e.label + (e.required ? " *" : "")}</TableCell>
                                                                <TableCell>
                                                                    <Box id={`list-data-columns-${i}-source`} className={`form-group ${e.required ? "required" : ""}`}>
                                                                        <Dropdown placeholder={e.placeholder ? e.placeholder : "โปรดเลือก"} parentId={`@columns@${i}`} id="source" options={columns} />
                                                                    </Box>
                                                                </TableCell>
                                                                <TableCell className='px-0'>
                                                                    <Box className="flex items-center">
                                                                        {
                                                                            e.tooltip ? <HtmlTooltip title={e.tooltip} placement="right-end">
                                                                                <HelpOutlineIcon fontSize="small" color="disabled" />
                                                                            </HtmlTooltip> : null
                                                                        }
                                                                    </Box>
                                                                </TableCell>
                                                            </TableRow>
                                                        }
                                                    )
                                                }
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </Paper>
                            </Box>
                            <Box className="flex justify-between px-3 pb-2">
                                <Button onClick={goToReview}>Back</Button>
                                <Button onClick={autoMapping}>Auto mapping</Button>
                                <Button onClick={afterMap}>Next</Button>
                            </Box>
                        </Box>
                    ) : null
                }
            </Modal>
        )
    }, [open, sheets, preMapData, postMapData, errorData, tabIndex, mode, info, fileName, selectedSheets, sheetModal])

    return {
        excelReader,
        renderExcelModal
    }
}
