import React, { useCallback, useEffect, useMemo, useState, Fragment, useRef } from 'react';
import PropTypes from 'prop-types';
import { alpha } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Checkbox from '@mui/material/Checkbox';
import IconButton from '@mui/material/IconButton';
import SearchIcon from '@mui/icons-material/Search';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import Tooltip from '@mui/material/Tooltip';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import DeleteIcon from '@mui/icons-material/Delete';
import FilterListIcon from '@mui/icons-material/FilterList';
import { visuallyHidden } from '@mui/utils';
import numeral from 'numeral';
import format from 'date-fns/format';
import { v4 as uuidv4 } from 'uuid';
import { Collapse, Divider, InputBase, Popover } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { setInfo, setInfoById } from 'slices/infoSlice';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import cloneDeep from 'lodash/cloneDeep';
import { memorizedExtractArrayData } from 'redux/memorize';
import { cloneCollapsed, toBuddhistYear } from 'utilities/utils';
import EllipsisTextComponent from 'components/EllipsisTextComponent';

function EnhancedTableHead(props) {
    const { columns, onSelectAllClick, order, orderBy, numSelected, count, onRequestSort, multiSelect, showDeleteButton, collapse, selectedFilter } =
        props;
    const createSortHandler = (property) => (event) => {
        onRequestSort(event, property);
    };

    return (
        <TableHead>
            <TableRow>
                {multiSelect || showDeleteButton ? <TableCell padding="checkbox">
                    <Checkbox
                        color="primary"
                        indeterminate={numSelected > 0 && numSelected < count}
                        checked={count > 0 && numSelected === count}
                        onChange={onSelectAllClick}
                        inputProps={{
                            'aria-label': 'select all desserts',
                        }}
                    />
                </TableCell> : null}
                {collapse ? <TableCell padding="checkbox" /> : null}
                {columns.filter(e => e && !e.hidden && selectedFilter.includes(e.id)).map((column) => (
                    <TableCell
                        key={column.id}
                        align={column.align ? column.align : (column.numeric || column.type === "number") ? 'right' : 'left'}
                        padding={column.disablePadding ? 'none' : 'normal'}
                        sortDirection={orderBy === column.id ? order : false}
                    >
                        <TableSortLabel
                            active={orderBy === column.id}
                            direction={orderBy === column.id ? order : 'asc'}
                            onClick={createSortHandler(column.id)}
                            className="whitespace-nowrap"
                        >
                            {column.label}
                            {orderBy === column.id ? (
                                <Box component="span" sx={visuallyHidden}>
                                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                </Box>
                            ) : null}
                        </TableSortLabel>
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
}

function EnhancedTableToolbar(props) {
    const { numSelected, title, columns, handleFilterClick, isSelected, selected, searchList, searchField, setSearchField, searchOption, setSearchOption, searchText, setSearchText, onKeyDown, onSearchHandler, onDelete, customToolbar, hideSelected, showDeleteButton } = props;
    const [filterEl, setFilterEl] = useState(null);
    const [fieldEl, setFieldEl] = useState(null);
    const [selectedField, setSelectedField] = useState("")
    const [searchOptionEl, setSearchOptionEl] = useState(null);
    const [searchOptionList] = useState([
        { label: "Exactly", value: "Exactly" },
        { label: "Starting", value: "Starting" },
        { label: "Ending", value: "Ending" },
        { label: "Contains", value: "Contains" },
    ])

    const onFilterClick = (event) => {
        setFilterEl(event.currentTarget);
    };

    const onFilterClose = () => {
        setFilterEl(null);
    };

    const onFieldClick = (event) => {
        setFieldEl(event.currentTarget);
    };

    const onFieldClose = () => {
        setFieldEl(null);
    };

    const onFieldChange = useCallback((field) => {
        setSearchField(field.value)
        setSearchText("")
        onFieldClose()
    }, [])

    const onSearchOptionClick = (event) => {
        setSearchOptionEl(event.currentTarget);
    };

    const onSearchOptionClose = () => {
        setSearchOptionEl(null);
    };

    const onSearchOptionChange = useCallback((field) => {
        setSearchOption(field.value)
        onSearchOptionClose()
    }, [])

    const filterOpen = Boolean(filterEl);
    const fieldOpen = Boolean(fieldEl);
    const searchOptionOpen = Boolean(searchOptionEl);
    const filterId = filterOpen ? uuidv4() : undefined;
    const fieldId = fieldOpen ? uuidv4() : undefined;
    const searchOptionId = searchOptionOpen ? uuidv4() : undefined;

    useEffect(() => {
        const text = searchList?.filter(e => e.value === searchField)
        setSelectedField(text?.length ? text[0].label : "")
    }, [searchField])

    useEffect(() => {
        const text = searchOptionList?.filter(e => e.value === searchOption)
        setSearchOption(text?.length ? text[0].label : "Contains")
    }, [searchOption])

    return (
        <Toolbar
            sx={{
                p: { xs: 1 },
                ...((numSelected > 0 && !hideSelected) && {
                    bgcolor: (theme) =>
                        alpha(theme.palette.primary.main, theme.palette.action.activatedOpacity),
                }),
                flexWrap: "nowrap",
                justifyContent: "space-between"
            }}
        >
            {(numSelected > 0) ? (
                <Typography
                    color="inherit"
                    variant="subtitle1"
                    component="div"
                >
                    {numSelected} selected
                </Typography>
            ) : (
                <Typography
                    variant="h6"
                    id="tableTitle"
                    component="div"
                    paddingY={2}
                >
                    {title}
                </Typography>
            )}

            {(numSelected > 0 && !hideSelected) && onDelete && showDeleteButton ? (
                <Tooltip title="Delete">
                    <IconButton onClick={onDelete}>
                        <DeleteIcon />
                    </IconButton>
                </Tooltip>
            ) : (numSelected <= 0 || hideSelected) ? (
                <>
                    {
                        customToolbar ?
                            <Box className="flex grow justify-end">
                                <customToolbar.component {...customToolbar.props} extendedComponent={(
                                    <IconButton key="extendedFilter" onClick={onFilterClick}>
                                        <FilterListIcon />
                                    </IconButton>
                                )} />
                            </Box>
                            :
                            searchList?.length ? (

                                <>
                                    <Box
                                        component="span"
                                        sx={{ p: '2px 4px', display: 'flex', alignItems: 'center', maxWidth: "100%", flexWrap: "wrap" }}
                                        className="border-bpTheme-menuBorder border rounded"
                                    >

                                        <span className='whitespace-nowrap text-sm cursor-default' onClick={onFieldClick}>
                                            <span className='pl-2'>{selectedField}</span>
                                            <IconButton >
                                                <ArrowDropDownIcon />
                                            </IconButton>
                                        </span>
                                        <span className='whitespace-nowrap text-sm cursor-default' onClick={onSearchOptionClick}>
                                            <span className='pl-2'>{searchOption}</span>
                                            <IconButton >
                                                <ArrowDropDownIcon />
                                            </IconButton>
                                        </span>
                                        <InputBase
                                            value={searchText}
                                            onChange={e => setSearchText(e.target.value)}
                                            onKeyDown={onKeyDown}
                                            sx={{ ml: 1, flex: 1, minWidth: 120, maxWidth: "100%" }}
                                            placeholder="ค้นหาข้อมูล"
                                            className='!text-sm'
                                        />
                                        <div className='flex'>
                                            <IconButton type="button" sx={{ p: '10px' }} aria-label="search" onClick={onSearchHandler}>
                                                <SearchIcon />
                                            </IconButton>
                                            <Divider sx={{ height: "28px !important", m: 0.5 }} orientation="vertical" />

                                            <IconButton onClick={onFilterClick}>
                                                <FilterListIcon />
                                            </IconButton>
                                        </div>
                                    </Box>
                                    <Popover
                                        id={fieldId}
                                        open={fieldOpen}
                                        anchorEl={fieldEl}
                                        onClose={onFieldClose}
                                        anchorOrigin={{
                                            vertical: 'bottom',
                                            horizontal: 'left',
                                        }}
                                    >
                                        <>
                                            {
                                                searchList.map((field) => {
                                                    return (
                                                        <div key={uuidv4()} className="cursor-pointer text-sm p-2 -mb-2 last:mb-0 transition-all hover:bg-bpTheme-hover" onClick={() => onFieldChange(field)}>
                                                            {field.label}
                                                        </div>
                                                    )
                                                })
                                            }
                                        </>
                                    </Popover>
                                    <Popover
                                        id={searchOptionId}
                                        open={searchOptionOpen}
                                        anchorEl={searchOptionEl}
                                        onClose={onSearchOptionClose}
                                        anchorOrigin={{
                                            vertical: 'bottom',
                                            horizontal: 'left',
                                        }}
                                    >
                                        <>
                                            {
                                                searchOptionList.map((field) => {
                                                    return (
                                                        <div key={uuidv4()} className="cursor-pointer text-sm p-2 -mb-2 last:mb-0 transition-all hover:bg-bpTheme-hover" onClick={() => onSearchOptionChange(field)}>
                                                            {field.label}
                                                        </div>
                                                    )
                                                })
                                            }
                                        </>
                                    </Popover>
                                </>
                            ) : <IconButton onClick={onFilterClick}>
                                <FilterListIcon />
                            </IconButton>
                    }
                    <Popover
                        id={filterId}
                        open={filterOpen}
                        anchorEl={filterEl}
                        onClose={onFilterClose}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'left',
                        }}
                    >
                        <div className="p-2">
                            {
                                columns.filter(e => !e.hidden).map((column) => {
                                    const isItemSelected = isSelected(column.id);
                                    return (
                                        <div key={uuidv4()} className="cursor-pointer text-xs" onClick={!(selected.indexOf(column.id) > -1 && selected.length <= 1) ? (event) => handleFilterClick(event, column.id) : null}>
                                            <Checkbox
                                                color="primary"
                                                checked={isItemSelected}
                                                disabled={selected.indexOf(column.id) > -1 && selected.length <= 1}
                                            />
                                            {column.label}
                                        </div>
                                    )
                                })
                            }
                        </div>
                    </Popover>
                </>
            ) : null}
        </Toolbar>
    );
}
export default function DataTable(props) {
    const { parentId, id = "datatable", title, showDense, rowsPerPageOptions = [5, 15, 30, { label: 'ทั้งหมด', value: -1 }], multiSelect, collapse, columns, rows, customUpdateFields, spliter = " / ", controller, onClick, searchFields, hideToolbar, hidePaging, onDelete, customToolbar, tableContainerClassName = "", size = "medium", state, hideSelected = false, showSystemFields = false, compareField, showDeleteButton } = props
    const selected = useSelector(state => memorizedExtractArrayData(state, parentId, id))
    const dispatch = useDispatch()
    const getAlignClass = (e) => {
        if (e === "center") {
            return "!text-center"
        } else if (e === "left") {
            return "!text-left"
        } else if (e === "right") {
            return "!text-right"
        } else {
            return ""
        }
    }
    const onChange = (e) => {
        if (parentId) {
            dispatch(setInfoById({ id: parentId, payload: { [id]: e } }))
        } else {
            dispatch(setInfo({ [id]: e }))
        }
    }
    const systemFields = [
        {
            id: 'createdTime',
            label: 'Created time',
            fields: ["createdTime"],
            type: "datetime"
        },
        {
            id: 'createdBy',
            label: 'Created by',
            fields: ["createdBy"]
        },
        {
            id: 'updatedTime',
            label: 'Updated time',
            fields: ["updatedTime"],
            type: "datetime"
        },
        {
            id: 'updatedBy',
            label: 'Updated by',
            fields: ["updatedBy"]
        },
    ]
    const [formattedRows, setFormattedRows] = useState([]);
    const [order, setOrder] = useState('asc');
    const [orderBy, setOrderBy] = useState('');
    const [cols, setCols] = useState(showSystemFields ? [...columns, ...systemFields] : columns)
    const [hiddenFields, setHiddenFields] = useState(cols.filter(e => e?.hidden).map(e => e.id));
    const [selectedFilter, setSelectedFilter] = useState(cols.filter(e => !e?.hidden).map((n) => n.id));
    const [dense, setDense] = useState(size != "medium");
    const [page, setPage] = useState(() => state?.page ? state.page[0] : 0);
    const [rowsPerPage, setRowsPerPage] = useState(() => state?.rowsPerPage ? state.rowsPerPage[0] : 15);
    const [count, setCount] = useState(() => state?.count ? state.count[0] : 0)
    const [actualCount, setActualCount] = useState(0)
    const [searchField, setSearchField] = useState(searchFields?.length ? searchFields[0].value : "")
    const [searchOption, setSearchOption] = useState("Contains")
    const [searchText, setSearchText] = useState("")
    const [intervalId, setIntervalId] = useState(null)
    const [previousIntervalId, setPreviousIntervalId] = useState(null)
    const tableCache = useRef(null)

    const updateRows = async () => {
        const perPage = rowsPerPage;
        const fields = cols.filter(entry => entry.id === orderBy).map(_entry => _entry.fields.map(__entry => typeof __entry === 'string' ? __entry : __entry.field))
        const paging = {
            sort: order,
            field: fields.length > 0 ? fields.join(",") : undefined,
            start: perPage >= 0 ? 0 : undefined,
            limit: perPage >= 0 ? perPage : undefined,
            searchField: searchField,
            searchText: searchText != "" ? searchText : undefined
        }
        const result = customUpdateFields?.controller ? await customUpdateFields.controller(paging) : await controller(paging)
        if (result.status === 200) {
            if (customUpdateFields.callback) {
                const data = result.data.data
                customUpdateFields.callback(createData(cols, checkPropertiesRow(data) ? data.records : data), setFormattedRows)
            } else {
                const data = result.data.data.records || []
                setFormattedRows(oldRow => {
                    const formatData = createData(cols, checkPropertiesRow(data) ? data.records : data)
                    cloneCollapsed(oldRow, formatData)
                    return formatData
                })
            }
        }
    }

    useEffect(() => {
        if (intervalId != previousIntervalId) {
            if (previousIntervalId) {
                clearInterval(previousIntervalId);
            }
            setPreviousIntervalId(intervalId)
        }
    }, [intervalId])

    useEffect(() => {
        if (state?.page && page != state?.page[0]) {
            state.page[1](page)
        }
    }, [page])

    useEffect(() => {
        if (state?.rowsPerPage && rowsPerPage != state?.rowsPerPage[0]) {
            state.rowsPerPage[1](rowsPerPage)
        }
    }, [rowsPerPage])

    useEffect(() => {
        if (state?.count && count != state?.count[0]) {
            state.count[1](count)
        }
    }, [count])

    useEffect(() => {
        setActualCount(formattedRows?.length || 0)
        if (customUpdateFields) {
            if (intervalId) {
                clearInterval(intervalId);
            }
            const itvId = setInterval(() => {
                updateRows()
            }, customUpdateFields.intervalTime || 10000)
            setIntervalId(itvId)

            return () => {
                if (itvId) {
                    clearInterval(itvId);
                }
            }
        }
    }, [formattedRows])

    useEffect(() => {
        const cols = showSystemFields ? [...columns, ...systemFields] : columns
        setHiddenFields(cols.filter(e => e.hidden).map(e => e.id))
        setSelectedFilter(cols.filter(e => !e.hidden).map((n) => n.id))
        setCols(cols)
    }, [columns, showSystemFields])

    const onKeyDown = (event) => {
        if (event.key === 'Enter') {
            onSearchHandler()
        }
    };

    const handleRequestSort = useCallback(async (_event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        if (controller) {
            const fields = cols.filter(entry => entry.id === property).map(_entry => _entry.fields.map(__entry => typeof __entry === 'string' ? __entry : __entry.field))
            const paging = {
                sort: isAsc ? 'desc' : 'asc',
                field: fields.length > 0 ? fields.join(",") : undefined,
                start: rowsPerPage >= 0 ? page * rowsPerPage : undefined,
                limit: rowsPerPage >= 0 ? rowsPerPage : undefined,
                searchField: searchField,
                searchText: searchText != "" ? searchText : undefined
            }
            const result = await controller(paging)
            if (state?.paging) {
                state.paging[1](paging)
            }
            if (result.status === 200) {
                const data = result.data.data
                setFormattedRows(createData(cols, checkPropertiesRow(data) ? data.records : data))
                setCount(checkPropertiesRow(data) ? data.hits : data.length)
            } else {
                setFormattedRows([])
                setCount(0)
            }
        }
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    }, [orderBy, order, controller, cols, rowsPerPage, searchField, searchText, page])

    const handleChangePage = useCallback(async (event, newPage) => {
        event.preventDefault();
        if (controller) {
            const fields = cols.filter(entry => entry.id === orderBy).map(_entry => _entry.fields.map(__entry => typeof __entry === 'string' ? __entry : __entry.field))
            const paging = {
                sort: order,
                field: fields.length > 0 ? fields.join(",") : undefined,
                start: rowsPerPage >= 0 ? newPage * rowsPerPage : undefined,
                limit: rowsPerPage >= 0 ? rowsPerPage : undefined,
                searchField: searchField,
                searchText: searchText != "" ? searchText : undefined
            }
            const result = await controller(paging)
            if (state?.paging) {
                state.paging[1](paging)
            }
            if (result.status === 200) {
                const data = result.data.data
                setFormattedRows(createData(cols, checkPropertiesRow(data) ? data.records : data))
                setCount(checkPropertiesRow(data) ? data.hits : data.length)
            } else {
                setFormattedRows([])
                setCount(0)
            }
        }
        setPage(newPage);
    }, [controller, cols, order, orderBy, rowsPerPage, searchField, searchText])

    const handleChangeRowsPerPage = useCallback(async (event) => {
        const perPage = parseInt(event.target.value, 10);
        if (controller) {
            const fields = cols.filter(entry => entry.id === orderBy).map(_entry => _entry.fields.map(__entry => typeof __entry === 'string' ? __entry : __entry.field))
            const paging = {
                sort: order,
                field: fields.length > 0 ? fields.join(",") : undefined,
                start: perPage >= 0 ? 0 : undefined,
                limit: perPage >= 0 ? perPage : undefined,
                searchField: searchField,
                searchText: searchText != "" ? searchText : undefined
            }
            const result = await controller(paging)
            if (state?.paging) {
                state.paging[1](paging)
            }
            setPage(0)
            if (result.status === 200) {
                const data = result.data.data
                setFormattedRows(createData(cols, checkPropertiesRow(data) ? data.records : data))
                setCount(checkPropertiesRow(data) ? data.hits : data.length)
            } else {
                setFormattedRows([])
                setCount(0)
            }
        }
        setRowsPerPage(perPage);
        setPage(0);
    }, [controller, cols, order, orderBy, searchField, searchText])

    const onSearchHandler = useCallback(async () => {
        if (controller) {
            const fields = cols.filter(entry => entry.id === orderBy).map(_entry => _entry.fields.map(__entry => typeof __entry === 'string' ? __entry : __entry.field))
            let searchTextAndOption;
            if (searchOption === "Starting") {
                searchTextAndOption = searchText + "%";
            } else if (searchOption === "Ending") {
                searchTextAndOption = "%" + searchText;
            } else if (searchOption === "Contains") {
                searchTextAndOption = "%" + searchText + "%";
            } else {
                searchTextAndOption = searchText;
            }
            const paging = {
                sort: order,
                field: fields.length > 0 ? fields.join(",") : undefined,
                start: rowsPerPage >= 0 ? 0 : undefined,
                limit: rowsPerPage >= 0 ? rowsPerPage : undefined,
                searchField: searchField,
                searchText: searchTextAndOption != "" ? searchTextAndOption : undefined
            }
            const result = await controller(paging)
            if (state?.paging) {
                state.paging[1](paging)
            }
            setPage(0)
            if (result.status === 200) {
                const data = result.data.data
                setFormattedRows(createData(cols, checkPropertiesRow(data) ? data.records : data))
                setCount(checkPropertiesRow(data) ? data.hits : data.length)
            } else {
                setFormattedRows([])
                setCount(0)
            }
        }
    }, [controller, cols, order, orderBy, rowsPerPage, searchField, searchText, searchOption])

    const handleSelectAllClick = (event) => {
        let newSelected = []
        if (event.target.checked) {
            newSelected = formattedRows.map((n) => n.data)
        }
        onChange(newSelected)
    };

    const selectRow = (event, data) => {
        const tmpIdx = selected?.findIndex(obj => obj[compareField || "id"] === data[compareField || "id"])
        const selectedIndex = tmpIdx !== undefined ? tmpIdx : -1
        const selected2 = selected || []
        let newSelected = [];
        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected2, data);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected2.slice(1));
        } else if (selectedIndex === selected2.length - 1) {
            newSelected = newSelected.concat(selected2.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected2.slice(0, selectedIndex),
                selected2.slice(selectedIndex + 1),
            );
        }

        onChange(newSelected)
    };

    const handleFilterClick = (event, name) => {
        const selectedIndex = selectedFilter.indexOf(name);
        let newSelected = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selectedFilter, name);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selectedFilter.slice(1));
        } else if (selectedIndex === selectedFilter.length - 1) {
            newSelected = newSelected.concat(selectedFilter.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selectedFilter.slice(0, selectedIndex),
                selectedFilter.slice(selectedIndex + 1),
            );
        }
        setSelectedFilter(newSelected);
    };

    const handleChangeDense = (event) => {
        setDense(event.target.checked);
    };

    const isSelected = useCallback((data) => {
        if (selected) {
            return selected.findIndex(obj => obj[compareField || "id"] === data[compareField || "id"]) !== -1;
        }
    }, [selected])

    const isSelectedFilter = (name) => selectedFilter.indexOf(name) !== -1;

    const mapValue = (mapper, data) => {
        return mapper.filter((e) => `${e.value}` === data)[0]?.label
    }

    const createData = (_columns, _rows) => {
        let formatedRows = []
        _rows.map((elm) => {
            let row = {}
            _columns.filter(e => e).forEach((element) => {
                let value = ""

                if (element.customRule) {
                    value = element.customRule(elm)
                }

                if (element.id.startsWith("{") && element.id.endsWith("}"))
                    value = elm[element.id]
                else {
                    element.fields.map((e, i) => {
                        if (typeof e === "object") {
                            const concatValue = (e.prefix ? e.prefix : "") + elm[e.field] + (e.postfix ? e.postfix : "")
                            value += elm[e.field] ? concatValue : ""
                        } else if (e.includes(".")) {
                            const propertyNames = e.split(".");
                            let nestedValue = elm;
                            for (const prop of propertyNames) {
                                if (nestedValue && nestedValue.hasOwnProperty(prop)) {
                                    nestedValue = nestedValue[prop];
                                } else {
                                    nestedValue = undefined;
                                    break;
                                }
                            }

                            const concatValue = (nestedValue != undefined ? nestedValue : "") + (i < element.fields.length - 1 ? spliter : "");
                            value += concatValue;
                        } else {
                            const concatValue = (elm[e] + (i < element.fields.length - 1 ? spliter : ""))
                            value += elm[e] != undefined ? concatValue : ""
                        }
                    })
                    if (value.endsWith(spliter)) {
                        value = value.slice(0, -spliter.length)
                    }
                }

                if (element.valuesMap) {
                    value = mapValue(element.valuesMap, value)
                }

                if (element.type === "number") {
                    value = value === 0 || value ? numeral(value).format('0,0[.]00') : " "
                } else if (element.type === "date") {
                    value = value ? format(toBuddhistYear(new Date(value)), 'dd/MM/yyyy') : value
                } else if (element.type === "datetime") {
                    value = value ? format(toBuddhistYear(new Date(value)), 'dd/MM/yyyy HH:mm') : value
                }

                if (element.custom) {
                    row[element.id] = element.custom(value, elm)
                } else {
                    row[element.id] = value
                }
            });
            formatedRows.push({ data: elm, text: row })
        })

        return formatedRows;
    }

    const formatCollapseValue = (_columns, _rows) => {
        let value = ""
        if (!_columns) {
            return
        }
        if (_columns.customRule) {
            value = _columns.customRule(_rows)
        }
        if (_columns.id.startsWith("{") && _columns.id.endsWith("}"))
            value = _rows[_columns.id]
        else {
            _columns.fields.map((e, i) => {
                if (typeof e === "object") {
                    const concatValue = (e.prefix ? e.prefix : "") + _rows[e.field] + (e.postfix ? e.postfix : "")
                    value += _rows[e.field] ? concatValue : ""
                } else {
                    const concatValue = (_rows[e] + (i < _columns.fields.length - 1 ? spliter : ""))
                    value += _rows[e] != undefined ? concatValue : ""
                }
            })
            if (value.endsWith(spliter)) {
                value = value.slice(0, -spliter.length)
            }
        }

        if (_columns.valuesMap) {
            value = mapValue(_columns.valuesMap, value)
        }

        if (_columns.type === "number") {
            value = value != 0 && value ? numeral(value).format('0,0[.]00') : " "
        } else if (_columns.type === "date") {
            value = value ? format(toBuddhistYear(new Date(value)), 'dd/MM/yyyy') : value
        } else if (_columns.type === "datetime") {
            value = value ? format(toBuddhistYear(new Date(value)), 'dd/MM/yyyy HH:mm') : value
        }

        if (_columns.custom) {
            return _columns.custom(value, _rows)
        } else {
            return value
        }
    }

    const checkPropertiesRow = (data) => data.hasOwnProperty("hits") && data.hasOwnProperty("records")

    const descendingComparator = useCallback((firstElmt, secondElmt, _orderBy) => {
        const filterArr = cols?.filter(e => e.id === _orderBy)
        if (filterArr.length && filterArr[0].type === "date") {
            const secondParts = secondElmt.text[_orderBy].split("/");
            const firstParts = firstElmt.text[_orderBy].split("/");
            if (new Date(secondParts[2], secondParts[1] - 1, secondParts[0]) < new Date(firstParts[2], firstParts[1] - 1, firstParts[0])) {
                return -1;
            }
            if (new Date(secondParts[2], secondParts[1] - 1, secondParts[0]) > new Date(firstParts[2], firstParts[1] - 1, firstParts[0])) {
                return 1;
            }
        } else if (filterArr.length && filterArr[0].type === "number") {
            if (Number(secondElmt.text[_orderBy]?.replace(',', '')) < Number(firstElmt.text[_orderBy]?.replace(',', ''))) {
                return -1;
            }
            if (Number(secondElmt.text[_orderBy]?.replace(',', '')) > Number(firstElmt.text[_orderBy]?.replace(',', ''))) {
                return 1;
            }
        } else {
            if (secondElmt.text[_orderBy] < firstElmt.text[_orderBy]) {
                return -1;
            }
            if (secondElmt.text[_orderBy] > firstElmt.text[_orderBy]) {
                return 1;
            }
        }
        return 0;
    }, [])

    const getComparator = useCallback((_order, _orderBy) => {
        return _order === 'desc'
            ? (a, b) => descendingComparator(a, b, _orderBy)
            : (a, b) => -descendingComparator(a, b, _orderBy);
    }, [])

    const stableSort = useCallback((array, comparator) => {
        const stabilizedThis = array.map((el, index) => [el, index]);
        stabilizedThis.sort((a, b) => {
            const _order = comparator(a[0], b[0]);
            if (_order !== 0) {
                return _order;
            }
            return a[1] - b[1];
        });
        return stabilizedThis.map((el) => el[0]);
    }, [])

    const onHandleDelete = onDelete ? () => {
        onDelete(selected?.filter(obj1 => formattedRows.some(obj2 => obj1.id === obj2.data.id)) || [], () => onChange([]))
    } : null

    useMemo(() => {
        if (rows && (rows.length || rows.records)) {
            setFormattedRows(createData(cols, checkPropertiesRow(rows) ? rows.records : rows))
            setCount(checkPropertiesRow(rows) ? rows.hits : rows.length)
        } else if (rows?.length === 0) {
            setFormattedRows([])
            setCount(0)
        }
    }, [rows])

    const emptyRows =
        page > 0 ? Math.max(0, (1 + page) * rowsPerPage - count) : 0;

    const collapseBuilder = (c, row, parentField) => {
        const data = row.data || row;
        const { collapse, columns, title, onClick, rows, filterRow, field } = c
        const innerRows = (field ? data[field] : rows)?.filter(e => filterRow ? filterRow(e, data) : e) || []
        return innerRows.length ? <TableRow>
            <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan="100%">
                <Collapse in={row.collapsed} timeout="auto" unmountOnExit>
                    <Box sx={{ margin: 1 }}>
                        <Typography variant="h6" gutterBottom component="div">
                            {title}
                        </Typography>
                        <Table size="small" aria-label="purchases">
                            <TableHead>
                                <TableRow>
                                    {
                                        collapse ? <TableCell padding="checkbox" /> : null
                                    }
                                    {
                                        columns?.map(column => (
                                            <TableCell key={uuidv4()}
                                                className={`whitespace-nowrap ${column.align ? getAlignClass(column.align) : column.type === "number" ? "!text-right" : ""}`}
                                            >{column.label}</TableCell>
                                        ))
                                    }
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {
                                    innerRows.map((innerRow, i) => {
                                        return (
                                            <React.Fragment key={`col-${parentField}-${i}`}>
                                                <TableRow key={uuidv4()} hover={!!onClick} className={innerRow.collapsed ? '!bg-gray-100' : ''}>
                                                    {
                                                        collapse ?
                                                            <TableCell>
                                                                <IconButton
                                                                    aria-label="expand row"
                                                                    size="small"
                                                                    onClick={() => {
                                                                        const splitField = parentField.split("-");
                                                                        let updatedFormattedRows = cloneDeep(formattedRows); // Create a deep copy of formattedRows

                                                                        let currentData = updatedFormattedRows;
                                                                        for (const index of splitField) {
                                                                            if (index.startsWith('@')) {
                                                                                const arrayIndex = parseInt(index.slice(1));
                                                                                currentData = currentData[arrayIndex];
                                                                            } else if (index.startsWith('$')) {
                                                                                const key = index.slice(1);
                                                                                currentData = currentData.data[key];
                                                                            }
                                                                        }
                                                                        currentData = currentData[i];
                                                                        currentData.collapsed = !currentData.collapsed || false;

                                                                        setFormattedRows(updatedFormattedRows);
                                                                    }}
                                                                >
                                                                    {innerRow.collapsed ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                                                                </IconButton>
                                                            </TableCell> : null
                                                    }
                                                    {
                                                        Object.entries(columns).map(([key, value], i) => {
                                                            return (
                                                                <TableCell
                                                                    key={uuidv4()}
                                                                    className={`${columns[i]?.align ? getAlignClass(columns[i].align) : columns[i]?.type === "number" ? "!text-right" : ""}`}
                                                                    onClick={!key.startsWith("{") && !key.endsWith("}") && onClick ? (() => onClick(innerRow, data)) : undefined}
                                                                >
                                                                    {columns[i]?.ellipsis ? <EllipsisTextComponent text={formatCollapseValue(value, innerRow)} width={columns[i]?.ellipsisWidth} /> : formatCollapseValue(value, innerRow)}
                                                                </TableCell>
                                                            )
                                                        })
                                                    }
                                                </TableRow>
                                                {
                                                    collapse ?
                                                        collapseBuilder(collapse, innerRow, `${parentField}-@${i}-$${field || ""}`) : null
                                                }
                                            </React.Fragment>
                                        )
                                    })
                                }
                            </TableBody>
                        </Table>
                    </Box>
                </Collapse>
            </TableCell>
        </TableRow>
            : null
    }

    useMemo(() => {
        tableCache.current = (
            <TableBody>
                {formattedRows.length ?
                    (controller ?
                        formattedRows.length > rowsPerPage && rowsPerPage > 0 ?
                            formattedRows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                            : formattedRows
                        : formattedRows.length > rowsPerPage && rowsPerPage > 0 ?
                            stableSort(formattedRows, getComparator(order, orderBy)).slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                            : stableSort(formattedRows, getComparator(order, orderBy))
                    ).map((row, i) => {
                        const isItemSelected = isSelected(row.data);
                        return row ?
                            <Fragment
                                key={uuidv4()}
                            >
                                <TableRow
                                    hover={!!onClick}
                                    // role="checkbox"
                                    tabIndex={-1}
                                    selected={isItemSelected}
                                    className={row.collapsed ? '!bg-gray-100' : ''}
                                >
                                    {
                                        multiSelect || showDeleteButton ?
                                            <TableCell padding="checkbox" onClick={(event) => selectRow(event, row.data)}>
                                                <Checkbox
                                                    color="primary"
                                                    checked={isItemSelected}
                                                />
                                            </TableCell> : null
                                    }
                                    {
                                        collapse ?
                                            <TableCell>
                                                {
                                                    row.data[collapse.field]?.length ?
                                                        <IconButton
                                                            aria-label="expand row"
                                                            size="small"
                                                            onClick={() => {
                                                                row.collapsed = !row.collapsed || false;
                                                                setFormattedRows([...formattedRows]);
                                                            }}
                                                        >
                                                            {row.collapsed ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                                                        </IconButton>
                                                        : null
                                                }
                                            </TableCell> : null
                                    }
                                    {
                                        Object.entries(row.text).map((e, i) => {
                                            return !hiddenFields.includes(e[0]) && selectedFilter.includes(e[0]) ?
                                                (
                                                    <TableCell
                                                        className={`${columns[i]?.align ? getAlignClass(columns[i].align) : columns[i]?.type === "number" ? "!text-right" : ""} ${e[1]?.length > 150 ? `overflow-hidden whitespace-normal !min-w-[300px]` : `whitespace-nowrap`}`}
                                                        onClick={!e[0].startsWith("{") && !e[0].endsWith("}") && onClick ? (() => onClick(row.data)) : undefined}
                                                        key={uuidv4()}
                                                    >
                                                        {columns[i]?.ellipsis ? <EllipsisTextComponent text={e[1]} width={columns[i]?.ellipsisWidth} /> : e[1]}
                                                    </TableCell>
                                                )
                                                : null
                                        })
                                    }
                                </TableRow>
                                {
                                    collapse ?
                                        collapseBuilder(collapse, row, `@${i}-$${collapse.field || ""}`) : null
                                }
                            </Fragment>
                            : null
                    })
                    : (
                        <TableRow>
                            <TableCell align="center" colSpan="100%">ไม่พบข้อมูล</TableCell>
                        </TableRow>
                    )
                }
                {emptyRows > 0 && (
                    <TableRow
                        style={{
                            height: (dense ? 33 : 53) * emptyRows,
                        }}
                    >
                        <TableCell colSpan="100%" />
                    </TableRow>
                )}
            </TableBody>
        )
    }, [dense, formattedRows, count, actualCount, controller, rowsPerPage, page, order, orderBy, multiSelect, collapse, hiddenFields, selectedFilter, onClick, emptyRows, isSelected, selected])

    return (
        <Box sx={{ width: '100%', height: '100%' }}>
            <Paper sx={{ width: '100%', height: '100%', mb: 2 }} className="flex flex-col" variant="outlined" >
                {
                    !hideToolbar ? <EnhancedTableToolbar numSelected={selected?.length || 0} isSelected={isSelectedFilter} selected={selectedFilter} searchList={searchFields} onDelete={onHandleDelete} {...{ title, columns: cols, handleFilterClick, searchField, searchText, setSearchField, searchOption, setSearchOption, setSearchText, onKeyDown, onSearchHandler, customToolbar, hideSelected, showDeleteButton }} /> : null
                }
                <TableContainer className={`scrollbar-thin scrollbar-thumb-bpTheme-scroll scrollbar-track-white grow ${tableContainerClassName}`}>
                    <Table
                        aria-labelledby="tableTitle"
                        size={dense ? 'small' : 'medium'}
                        stickyHeader
                        className={formattedRows.length ? '' : '!h-full'}
                    >
                        <EnhancedTableHead
                            numSelected={selected?.length || 0}
                            onSelectAllClick={handleSelectAllClick}
                            onRequestSort={handleRequestSort}
                            count={actualCount}
                            {...{ order, orderBy, columns: cols, multiSelect, collapse, selectedFilter, showDeleteButton }}
                        />
                        {tableCache.current}
                    </Table>
                </TableContainer>
                {
                    !hidePaging ? <TablePagination
                        rowsPerPageOptions={rowsPerPageOptions}
                        component="div"
                        count={count}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                        labelRowsPerPage="จำนวนแถวต่อหน้า"
                    /> : null
                }
            </Paper>
            {
                showDense ? <FormControlLabel
                    control={<Switch checked={dense} onChange={handleChangeDense} />}
                    label="Dense padding"
                /> : null
            }
        </Box>
    );
}

DataTable.propTypes = {
    title: PropTypes.string,
    showDense: PropTypes.bool,
    rowsPerPageOptions: PropTypes.array,
    multiSelect: PropTypes.bool,
    colums: PropTypes.array,
    rows: PropTypes.oneOfType([
        PropTypes.array,
        PropTypes.object
    ]),
    spliter: PropTypes.string,
    controller: PropTypes.func,
    onClick: PropTypes.func,
    searchFields: PropTypes.array
}