import { Box } from '@mui/material'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Panel from 'components/Panel'
import Modal from 'components/Modal'
import Form from 'components/Form'
import { useSnackbar } from 'notistack'
import { setInfo, clearInfo, setInfoById } from 'slices/infoSlice'
import { normalizeData } from 'utilities/validator'
import usePopup from 'hooks/usePopup'
import useAxios from 'hooks/useAxios'
import { warpUpFailResponse } from 'utilities/utils'
import Address from 'pages/address'
import CheckIcon from '@mui/icons-material/Check';
import ClearIcon from '@mui/icons-material/Clear';
import useDataHandler from 'hooks/useDataHandler'
import { PROJECT_TYPE } from 'helper/DataFactory'

export default function Division() {
	const dispatch = useDispatch()
	const { enqueueSnackbar } = useSnackbar()
	const { post, get, del, upload, multiple } = useAxios()
	const { save } = useDataHandler()
	const info = useSelector(state => state.info.ctx || {})
	const [mode, setMode] = useState("initial")
	const { confirmPopup } = usePopup()
	const [allDivision, setAllDivision] = useState([])
	const [allCustomer, setAllCustomer] = useState([])
	const [provinceRes, setProvinceRes] = useState([])
	const [allAddressBilling, setAllAddressBilling] = useState([])
	const [allAddressPickup, setAllAddressPickup] = useState([])
	const roleUser = useSelector(state => state.user.roleName)

	const generateRoutineAll = async () => {
		const response = await post("/routine/generateRoutine", { divisionId: info.id, genAllDays: true })
		if (response.status === 200) {
			enqueueSnackbar('สร้างรายการสำเร็จ', { variant: "success" })
		} else {
			enqueueSnackbar(warpUpFailResponse(response, 'สร้างรายการไม่สำเร็จ'), { variant: "error" })
		}
	}

	const fetchData = async controller => {
		const result = await multiple([
			{ method: "get", url: "/province/getAllProvince", config: { signal: controller.signal } },
			{ method: "get", url: "/customer/getAllActiveCustomer", config: { signal: controller.signal } },
			{ method: "get", url: "/division/getAllDivision", config: { signal: controller.signal } }
		])
		if (result[0].status === 200) {
			const data = result[0].data.data
			setProvinceRes(data.records)
		}
		if (result[1].status === 200) {
			const data = result[1].data.data
			setAllCustomer(data.records.map(data => ({ label: data.name, value: data.id })) || [])
		}
		if (result[2].status === 200) {
			const divisions = result[2].data.data || []
			setAllDivision(divisions)
		}
	}

	useEffect(() => {
		const controller = new AbortController()
		fetchData(controller)
		return () => {
			controller.abort()
		}
	}, [])

	useEffect(() => {
		if (info.address) {
			setAllAddressBilling(info?.address.map(e => ({ label: `${e.addressTH || "-"} ${e.fullAddress || "-"}`, value: e.id || e.itemId, data: e })))
			setAllAddressPickup(info?.address.map(e => ({ label: `${e.addressTH || "-"} ${e.fullAddress || "-"}`, value: e.id || e.itemId, data: e })))
			if (info?.address.some(e => e.defaultAD)) {
				dispatch(setInfo({
					billingLocationId: info?.address.filter(e => e?.billing && e?.billingLocationId === e?.id)[0]?.id || "",
					locationId: info?.address.filter(e => e?.pickup && e?.locationId === e?.id)[0]?.id || ""
				}))
			}
		}
	}, [info.address])

	useEffect(() => {
		if (info.provinceId && provinceRes) {
			const { province, amphoe, district, zipcode } = provinceRes.find(e => info.provinceId === e.id)
			dispatch(setInfo({ province, amphoe, district, zipcode }))
		} else {
			dispatch(setInfo({ province: null, amphoe: null, district: null, zipcode: null }))
		}

	}, [info.provinceId])

	const onCloseModal = useCallback(() => {
		confirmPopup({
			title: "แจ้งเตือน",
			content: "ท่านต้องการยกเลิกการแก้ไขหรือไม่",
			onSubmit: () => {
				setMode("initial")
			}
		})
	}, [])

	const checkIcon = (value, data) => value === "true" || value === true ? <CheckIcon /> : <ClearIcon />

	const inputForm = [
		{
			rowData: [
				{
					type: "profileUpload",
					props: {
						id: "profile",
						prefix: `user/profile`
					}
				}
			]
		},
		{
			rowData: [
				{
					type: "divider",
					props: {
						label: "ข้อมูลส่วนตัว",
						variant: "outlined",
					}
				}
			]
		},
		{
			rowData: [
				{
					type: "text",
					required: true,
					props: {
						id: "name",
						label: "ชื่อ",
						variant: "outlined"
					}
				},
				{
					type: "text",
					required: info.userType === "person",
					props: {
						id: "nameTH",
						label: "ชื่อ-สกุล (ภาษาไทย)",
						variant: "outlined"
					}
				},
				{
					type: "text",
					props: {
						id: "nameEN",
						label: "ชื่อ-สกุล (ภาษาอังกฤษ)",
						variant: "outlined"
					}
				},
				{
					type: "dropdown",
					required: true,
					props: {
						id: "projectType",
						label: "ประเภทโปรเจค",
						options: PROJECT_TYPE,
						notNull: true
					}
				},
			]
		},
		{
			rowData: [
				{
					type: "text",
					validation: 'tel',
					props: {
						id: "mobile",
						label: "เบอร์มือถือ",
						variant: "outlined"
					}
				},
				{
					type: "text",
					validation: 'tel',
					props: {
						id: "tel",
						label: "เบอร์โทร",
						variant: "outlined"
					}
				}
			]
		},
		{
			rowData: [
				{
					type: "text",
					props: {
						id: "email",
						label: "อีเมลล์",
						variant: "outlined"
					}
				},
				{
					type: "text",
					props: {
						id: "website",
						label: "เว็บไซต์",
						variant: "outlined"
					}
				}
			]
		},
		{
			rowData: [
				{
					type: "dropdown",
					required: true,
					props: {
						id: "userType",
						label: "ประเภทผู้ติดต่อ",
						options: [
							{ label: "บุคคลธรรมดา", value: "person" },
							{ label: "นิติบุคคล", value: "company" },
						]
					}
				},
				{
					type: "dropdown",
					props: {
						id: "customerId",
						label: "คู่สัญญา",
						options: allCustomer
					}
				},
				{
					type: "text",
					props: {
						id: "branchNo",
						label: "สาขา",
						variant: "outlined"
					}
				},
				{
					type: "text",
					props: {
						id: "creditTerm",
						label: "เลขประจำตัวผู้เสียภาษี",
						variant: "outlined",
						maxCharacters: 13
					}
				}
			]
		}, {
			isCollapse: true,
			label: "ที่อยู่",
			defaultCollapse: true,
			collapseData: [
				{
					rowData: [
						{
							type: "custom",
							component: <Address typeId={"division"} id={info.id} saveOnBase={false} />
						}
					],
				}, {
					rowData: [
						{
							type: "divider",
							props: {
								label: "ตั้งค่าที่อยู่เริ่มต้น",
								variant: "outlined",
							}
						}
					]
				}, {
					rowData: [
						{
							type: "autocomplete",
							required: true,
							props: {
								id: "locationId",
								label: "ที่อยู่รับสินค้า",
								options: allAddressPickup,
								allowDuplicate: true,
								filterOptions: (e) => e.data?.pickup
							}
						}
					]
				}
			]
		}, {
			isCollapse: true,
			label: "ข้อมูลเพิ่มเติม",
			collapseData: [
				{
					rowData: [
						{
							type: "text",
							props: {
								id: "remark",
								label: "หมายเหตุ",
								variant: "outlined",
								multiline: true,
								maxRows: 4
							}
						}
					]
				},
				{
					rowData: [
						mode === "edit" && {
							type: "dropdown",
							props: {
								id: "active",
								label: "สถานะ",
								options: [
									{ label: "ใช้งาน", value: true },
									{ label: "ไม่ใช้งาน", value: false }
								],
							}
						},
						{},
						{},
						{}
					]
				}
			]
		},

		{
			noCol: true,
			rowData: [
				{
					type: "button",
					props: {
						label: "บันทึก",
						onClick: () => {
							saveData()
						},
						className: "!bg-bpTheme-buttonSubmit"
					}
				},
				// {
				// 	type: "button",
				// 	props: {
				// 		label: "สร้างรายการประจำทุกวัน",
				// 		className: "!ml-2",
				// 		onClick: generateRoutineAll
				// 	}
				// }
			]
		}
	]
	const table = useMemo(() => [
		{
			rowData: [{
				type: "dataTable",
				props: {
					columns: [
						{
							id: 'no',
							label: 'เลขที่โปรเจคลูกค้า',
							fields: ["no"]
						},
						{
							id: 'name',
							label: 'ชื่อ',
							fields: ["name"]
						},
						{
							id: 'userType',
							label: 'ประเภทผู้ใช้',
							fields: ["userType"]
						},
						{
							id: 'customerName',
							label: "คู่สัญญา",
							fields: ["customerName"]
						},
						{
							id: 'branchNo',
							label: 'สาขา',
							fields: ["branchNo"]
						},
						{
							id: 'active',
							label: 'สถานะ',
							fields: ["active"],
							valuesMap: [{ label: "ใช้งาน", value: true },
							{ label: "ไม่ใช้งาน", value: false }]

						}
					],
					rows: allDivision,
					size: "small",
					showDeleteButton: roleUser === "Owner",
					title: "ข้อมูลโปรเจคลูกค้า",
					showSystemFields: true,
					onClick: (e) => {
						dispatch(setInfo({ ...e }))
						setMode("edit")
					},
					onDelete: (e, onSuccess) => {
						confirmPopup({
							onSubmit: async () => {
								const result = await del("/division/deleteDivision", { ids: e.map(data => data.id).join(',') })
								if (result.status === 200) {
									enqueueSnackbar('ลบสำเร็จ', { variant: "success" })
									dispatch(clearInfo())
									await fetchData(new AbortController())
									onSuccess()
								} else {
									enqueueSnackbar('ลบไม่สำเร็จ', { variant: "error" })
								}
							}
						})
					},
					controller: (paging) => get("/division/getAllDivision", { paging }),
					searchFields: [
						{ label: "ชื่อ", value: "name" },
						{ label: "ประเภทผู้ใช้", value: "userType" },
						{ label: "คู่สัญญา", value: "customerName" },
						{ label: "สาขา", value: "branchNo" },
						{ label: "สถานะ", value: "active" }
					]
				}
			}]
		},
		{
			noCol: true,
			rowData: [
				{
					type: "button",
					props: {
						label: "เพิ่มใหม่",
						onClick: () => {
							setMode("create")
							dispatch(clearInfo())
						}
					}
				},
			]
		}
	], [allDivision])

	const saveData = useCallback(() => {
		save({
			data: info,
			onSubmit: async () => {
				const data = normalizeData(info)
				const result = await upload({ prefix: `user/profile`, file: info?.profile })
				let profile = null
				if (result.status === 200) {
					profile = result.data.data
				}

				const modifiedData = {
					...data
				};
				let response
				if (mode === "create") {
					response = await post("/division/createDivision", { ...modifiedData, profile, branchNo: modifiedData.branchNo ? modifiedData.branchNo : 'สำนักงานใหญ่', active: true, address: data?.address.map(item => ({ ...item, id: item.tempId ? null : item.id })) })
				} else if (mode === "edit") {
					response = await post("/division/updateDivision", {
						...modifiedData, profile, branchNo: modifiedData.branchNo ? modifiedData.branchNo : 'สำนักงานใหญ่', address: data?.address.map(item => ({ ...item, id: item.tempId ? null : item.id })) // locationId: info.addressTransId, billingLocationId: info.addressBillId
					})
				}
				if (response.status === 200) {
					setMode("initial")
					enqueueSnackbar('บันทึกสำเร็จ', { variant: "success" })
					dispatch(clearInfo())
					fetchData(new AbortController())
				} else {
					enqueueSnackbar(warpUpFailResponse(response, 'บันทึกไม่สำเร็จ'), { variant: "error" })
				}
			},
			onCancel: () => {
				enqueueSnackbar('ยกเลิกรายการ', { variant: "info" })
			}
		})
	}, [info, mode])

	return (
		<Box>
			<Panel data={table}></Panel>
			<Modal open={mode === "create" || mode === "edit"} onClose={onCloseModal} className="form-modal">
				<Form title={"ข้อมูลโปรเจคลูกค้า " + (info?.no || "")} name="user-form" data={inputForm}></Form>
			</Modal>
		</Box>
	)
}