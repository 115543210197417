import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Panel from 'components/Panel'
import Modal from 'components/Modal'
import { useSnackbar } from 'notistack'
import { setInfo, clearInfo } from 'slices/infoSlice'
import usePopup from 'hooks/usePopup'
import useAxios from 'hooks/useAxios'
import useMultipleFileReader from 'hooks/useMultipleFileReader'
import DownloadIcon from '@mui/icons-material/Download';
import { format, parse } from 'date-fns';
import { warpUpFailResponse } from 'utilities/utils'
import { uploadFields } from './uploadConf'
import AddJob from './addJob'
import { Box } from '@mui/material'
import AutoComplete from 'components/AutoComplete'
import DatePicker from 'components/DatePicker'
import Button from 'components/Button'
import SearchIcon from '@mui/icons-material/Search';
import EditJob from './editJob'
import ClearAllIcon from '@mui/icons-material/ClearAll';
import CheckIcon from '@mui/icons-material/Check';
import ClearIcon from '@mui/icons-material/Clear';

export default function Summary() {

    const dispatch = useDispatch()
    const { enqueueSnackbar } = useSnackbar();
    const { get, post, del, multiple, download } = useAxios();
    const page = useState(0)
    const rowsPerPage = useState(15)
    const count = useState(0)
    const paging = useState({})
    const roleUser = useSelector(state => state.user.roleName)
    const { excelReader, renderExcelModal } = useMultipleFileReader({
        fields: uploadFields,
        submitData: (data, callBack, originData) => upload(data, callBack, originData)
    })
    const info = useSelector(state => state.info.ctx)
    const { confirmPopup } = usePopup();
    const allPlanning = useState([]);
    const [mode, setMode] = useState("initial")
    const [allVehicleType, setAllVehicleType] = useState([]);

    const upload = (data, callBack, originData) => {
        confirmPopup({
            onSubmit: async () => {
                const data4ErrMsg = JSON.parse(JSON.stringify(originData))

                //map data to array
                const mappedHeaders = uploadFields.reduce((acc, cur) => {
                    acc[cur.label] = cur.value;
                    return acc;
                }, {});
                const mappedData = data.map(([headers, ...rows]) => rows.map((row) => row.map((e, i) => ({ [mappedHeaders[headers[i]]]: e })).reduce((acc, curr) => ({ ...acc, ...curr }), {})))

                const transformedArray = mappedData.flatMap(innerArray => innerArray);

                const drivers = [...new Set(transformedArray.filter(item => item.driver).map(item => item.driver))];
                const licenseNos = [...new Set(transformedArray.filter(item => item.licenseNo).map(item => item.licenseNo))];
                const routes = [...new Set(transformedArray.filter(item => item.route).map(item => item.route))];
                const orderNos = [...new Set(transformedArray.filter(item => item.orderNo).map(item => item.orderNo))];

                let validateDrivers = []
                let validateLicenseNos = []
                let validateRoutes = []
                let validateOrderNos = []

                const result = await post("/job/validatePlanning", { drivers, licenseNos, routes, orderNos })
                if (result.status === 200) {
                    const data = result.data.data
                    validateDrivers = data.validateDrivers
                    validateLicenseNos = data.validateLicenseNos
                    validateRoutes = data.validateRoutes
                    validateOrderNos = data.validateOrderNos
                } else {
                    return
                }

                //validatedata
                const requiredFields = uploadFields.filter(e => e.required).map(e => e.value)
                const formatData = mappedData.map((sheet, i) => {
                    data4ErrMsg[i][0].push("Error message")
                    return sheet.map((row, j) => {
                        const invalid = requiredFields.some(e => !row[e] && row[e] !== 0)
                        let error = false
                        if (invalid) {
                            data4ErrMsg[i][j + 1].push("required fields must not be null")
                            error = true
                        }
                        if (!error) {
                            const id = validateDrivers[row.driver]
                            if (id) {
                                row.driverId = id
                            } else {
                                data4ErrMsg[i][j + 1].push("no driver found")
                                error = true
                            }
                        }
                        if (!error) {
                            const elm = validateLicenseNos[row.licenseNo]
                            if (elm) {
                                row.vehicleId = elm.id
                                row.vehicleTypeId = elm.vehicleTypeId
                            } else {
                                data4ErrMsg[i][j + 1].push("no vehicle found")
                                error = true
                            }
                        }
                        if (!error) {
                            const id = validateRoutes[row.route]
                            if (id) {
                                row.routeId = id
                            } else {
                                data4ErrMsg[i][j + 1].push("no route found")
                                error = true
                            }
                        }
                        if (!error) {
                            const data = validateOrderNos[row.orderNo]
                            if (!data?.existing) {
                                data4ErrMsg[i][j + 1].push("no order number found or order number was planned")
                                error = true
                            } else {
                                row.divisionId = data.divisionId
                            }
                        }
                        if (!error) {
                            const parseDate = parse(row.etdTime, 'dd/MM/yyyy HH:mm', new Date())
                            if (isNaN(parseDate)) {
                                data4ErrMsg[i][j + 1].push("invalid date format")
                                error = true
                            } else {
                                row.etdTime = format(parseDate, 'yyyy-MM-dd HH:mm:ss')
                            }
                        }
                        if (!error) {
                            const parseDate = parse(row.etaTime, 'dd/MM/yyyy HH:mm', new Date())
                            if (isNaN(parseDate)) {
                                data4ErrMsg[i][j + 1].push("invalid date format")
                                error = true
                            } else {
                                row.etaTime = format(parseDate, 'yyyy-MM-dd HH:mm:ss')
                            }
                        }
                        if (!error) {
                            data4ErrMsg[i][j + 1].push("insert fail")
                            return row
                        }
                    }).filter(e => e)
                })

                const flatMap = formatData.flatMap(innerArray => innerArray)
                const jobs = Object.values(
                    flatMap.reduce((acc, obj) => {
                        const { driverId, divisionId, vehicleId, vehicleTypeId, routeId, driver, licenseNo, route, etdTime, etaTime, ...rest } = obj;
                        const existingItem = acc.find(
                            item =>
                                item.driverId === driverId &&
                                item.divisionId === divisionId &&
                                item.vehicleId === vehicleId &&
                                item.vehicleTypeId === vehicleTypeId &&
                                item.routeId === routeId &&
                                item.etdTime === etdTime &&
                                item.etaTime === etaTime
                        );

                        if (existingItem) {
                            existingItem.orders.push({ ...rest }?.orderNo);
                        } else {
                            acc.push({
                                driverId,
                                divisionId,
                                vehicleId,
                                vehicleTypeId,
                                routeId,
                                etdTime,
                                etaTime,
                                orders: [{ ...rest }?.orderNo],
                            });
                        }

                        return acc;
                    }, [])
                );

                if (jobs.length > 0 && data4ErrMsg.every(sheet => sheet.every(rows => ["Error message", "insert fail"].includes(rows[rows.length - 1])))) {
                    let response = await post("/job/uploadJob", { jobs })
                    if (response.status === 200) {
                        enqueueSnackbar('บันทึกสำเร็จ', { variant: "success" })
                        callBack(data4ErrMsg.map(rows => {
                            return rows.filter(row => row[row.length - 1] !== "insert fail")
                        }))
                        fetchData()
                    } else {
                        enqueueSnackbar(warpUpFailResponse(response, 'บันทึกไม่สำเร็จ'), { variant: "error" })
                        callBack(data4ErrMsg)
                    }
                } else {
                    enqueueSnackbar('บันทึกไม่สำเร็จ', { variant: "error" })
                    callBack(data4ErrMsg)
                }
            },
            onCancel: () => {
                enqueueSnackbar('ยกเลิกรายการ', { variant: "info" })
            }
        })
    }

    const fetchData = async controller => {
        const result = await multiple([
            { method: "get", url: "/job/getAllJob", params: { vehicleTypeId: info.filterVehicleTypeId, startDate: info.filterStartDate, endDate: info.filterEndDate, paging: paging[0] }, config: { signal: controller.signal } },
            { method: "get", url: "/vehicleType/getAllActiveVehicleType", config: { signal: controller.signal } },

        ])
        if (result[0].status === 200) {
            const data = result[0].data.data || []
            allPlanning[1](data)
        }
        if (result[1].status === 200) {
            setAllVehicleType(result[1].data.data.records.map(data => ({ label: data.name, value: data.id })) || [])
        }
    }

    const onCloseModal = useCallback(() => {
        if (mode !== "edit" && info.orders?.length) {
            confirmPopup({
                title: "แจ้งเตือน",
                content: "ท่านต้องการยกเลิกการแก้ไขหรือไม่",
                onSubmit: () => {
                    setMode("initial")
                }
            })
        } else {
            setMode("initial")
        }
    }, [mode])

    const renderDispatchJob = (text, data) => data.dispatchJob ? <CheckIcon /> : <ClearIcon />

    const table = useMemo(() => [
        {
            rowData: [{
                type: "dataTable",
                props: {
                    columns: [
                        {
                            id: 'dispatchJob',
                            label: 'ปล่อยงาน',
                            fields: ["dispatchJob"],
                            custom: renderDispatchJob,
                            align: "center"
                        },
                        {
                            id: 'status',
                            label: 'สถานะ',
                            fields: ["status"]
                        },
                        {
                            id: 'no',
                            label: 'เลขที่ใบงาน',
                            fields: ["no"]
                        },
                        {
                            id: 'customerName',
                            label: 'ชื่อผู้ส่ง',
                            fields: ["customerName"]
                        },
                        {
                            id: 'driverName',
                            label: 'คนขับ',
                            fields: ["driverName"]
                        },
                        {
                            id: 'vehicleNo',
                            label: 'ทะเบียนรถ',
                            fields: ["vehicleNo"]
                        },
                        {
                            id: 'temperature',
                            label: 'อุณหภูมิ',
                            fields: ["temperature"],
                            align: "right"
                        },
                        {
                            id: 'pickupAddress',
                            label: 'ต้นทาง',
                            fields: ["pickupAddress"],
                            ellipsis: true
                        },
                        {
                            id: 'shippingAddress',
                            label: 'ปลายทาง',
                            fields: ["shippingAddress"],
                            ellipsis: true
                        },
                    ],
                    rows: allPlanning[0],
                    size: "small",
                    multiSelect: true,
                    showDeleteButton: roleUser === "Owner",
                    title: "ข้อมูลใบงาน",
                    showSystemFields: true,
                    onClick: async (e) => {
                        const result = await get("/job/getJobById", { id: e.id })
                        if (result.status === 200) {
                            setMode("edit")
                            dispatch(clearInfo())
                            dispatch(setInfo(result.data.data))
                        }
                    },
                    onDelete: (e, onSuccess) => {
                        confirmPopup({
                            onSubmit: async () => {
                                const result = await del("/job/deleteJob", { ids: e.map(data => data.id).join(',') })
                                if (result.status === 200) {
                                    enqueueSnackbar('ลบสำเร็จ', { variant: "success" })
                                    dispatch(clearInfo())
                                    await fetchData(new AbortController())
                                    onSuccess()
                                } else {
                                    enqueueSnackbar('ลบไม่สำเร็จ', { variant: "error" })
                                }
                            }
                        })
                    },
                    state: { page, rowsPerPage, count, paging },
                    controller: (paging) => get("/job/getAllJob", { paging }),
                    searchFields: [
                        { label: "คนขับ", value: "driverName" },
                        { label: "ทะเบียนรถ", value: "vehicleNo" },
                        { label: "เส้นทาง", value: "route" },
                        { label: "สถานะ", value: "status" },
                    ]
                }
            }]
        },
        {
            noCol: true,
            rowData: [
                {
                    type: "button",
                    props: {
                        label: "เพิ่มใหม่",
                        onClick: () => {
                            setMode("create")
                            dispatch(clearInfo())
                        }
                    },
                },
                {
                    type: "button",
                    props: {
                        label: "ปล่อยงาน",
                        className: "!ml-2",
                        onClick: () => {
                            dispatchJobs()
                        }
                    },
                },
                {
                    type: "upload",
                    props: {
                        id: "files",
                        label: "อัปโหลด",
                        type: "button",
                        className: "!ml-2",
                        variant: "outlined",
                        maxFiles: 1,
                        accept: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
                        onChange: e => {
                            if (e.value?.length) {
                                excelReader(e.value[0])
                            }
                        }
                    }
                },
                {
                    type: "button",
                    props: {
                        label: <><DownloadIcon></DownloadIcon>ดาวน์โหลด</>,
                        variant: "outlined",
                        className: "!ml-2",
                        onClick: () => {
                            download('/job/downloadJobTemplate')
                        }
                    }
                }
            ]
        },
    ], [allPlanning[0], info, roleUser])

    const filterJobs = async () => {
        fetchData(new AbortController())
    }

    const clearFilterJobs = async () => {
        dispatch(setInfo({ filterVehicleTypeId: null, filterStartDate: null, filterEndDate: null }))
    }

    const dispatchJobs = () => {
        if (!info?.datatable?.length) {
            enqueueSnackbar('กรุณาเลือกใบงานที่จะปล่อยงาน', { variant: "error" })
            return
        }
        confirmPopup({
            title: "แจ้งเตือน",
            content: "ท่านต้องการปล่อยงานทั้งหมดหรือไม่",
            onSubmit: async () => {
                let response
                response = await post("/job/dispatchJob", {
                    jobs: info.datatable?.map(e => ({
                        id: e.id
                    }))
                })

                if (response.status === 200) {
                    enqueueSnackbar('บันทึกสำเร็จ', { variant: "success" })
                    const controller = new AbortController()
                    fetchData(controller)
                    dispatch(setInfo({ datatable: [] }))
                } else {
                    enqueueSnackbar(warpUpFailResponse(response, 'บันทึกไม่สำเร็จ'), { variant: "error" })
                }
            },
        })
    }

    useEffect(() => {
        const controller = new AbortController()
        fetchData(controller)
        return () => {
            controller.abort()
        }
    }, [mode])

    return (
        <>
            <Box className='flex justify-end gap-2 mb-2'>
                <AutoComplete
                    id="filterVehicleTypeId"
                    label="ประเภทรถ"
                    className="!w-[200px]"
                    options={allVehicleType}
                />
                <DatePicker
                    id="filterStartDate"
                    label="ตั้งแต่วันที่"
                    maxDate={info.filterEndDate}
                    className="max-w-[200px]"
                >
                </DatePicker>
                <DatePicker
                    id="filterEndDate"
                    label="ถึงวันที่"
                    minDate={info.filterStartDate}
                    className="max-w-[200px]"
                >
                </DatePicker>
                <Button onClick={clearFilterJobs} variant="text"><ClearAllIcon /></Button>
                <Button onClick={filterJobs} variant="text"><SearchIcon /></Button>
            </Box>
            <Panel data={table}></Panel>
            <Modal open={mode !== "initial"} onClose={onCloseModal} className="form-modal" contentClassName="!max-h-[95vh] !min-w-[50vw] !max-w-[80vw]">
                {mode !== "edit" ?
                    <AddJob setJobMode={setMode} />
                    :
                    <EditJob setJobMode={setMode} />
                }
            </Modal>
            {renderExcelModal}
        </>
    )
}
