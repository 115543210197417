import { format } from 'date-fns';

export const uploadFields = [
    { label: "เลขที่ใบจอง", value: "orderNo", required: true },
    {
        label: "คนขับ", value: "driver", required: true,
        tooltip: <>
            <p className='font-bold'>คำแนะนำ</p>
            <p>ใช้ user name ที่คนขับใช้เข้าสู่ระบบ</p>
            <p>เช่น driver123 </p>
        </>
    },
    { label: "ทะเบียนรถ", value: "licenseNo", required: true },
    {
        label: "เส้นทาง", value: "route", required: true,
        tooltip: <>
            <p className='font-bold'>คำแนะนำ</p>
            <p>ควรกำหนด format {"<ต้นทาง> - <ปลายทาง>"}</p>
            <p>เช่น BKK - KNN </p>
        </>
    },
    {
        label: "วันที่จะเข้ารับสินค้า", value: "etdTime", required: true,
        tooltip: <>
            <p className='font-bold'>คำแนะนำ</p>
            <p>ควรวาง format ให้ตรง (ใช้ปี ค.ศ.)</p>
            <p>{"dd/MM/yyyy HH:mm"}</p>
            <p>{format(new Date(), 'dd/MM/yyyy HH:mm')}</p>
        </>
    },
    {
        label: "วันที่จบงาน", value: "etaTime", required: true,
        tooltip: <>
            <p className='font-bold'>คำแนะนำ</p>
            <p>ควรวาง format ให้ตรง (ใช้ปี ค.ศ.)</p>
            <p>{"dd/MM/yyyy HH:mm"}</p>
            <p>{format(new Date(), 'dd/MM/yyyy HH:mm')}</p>
        </>
    },
]