import { Box } from '@mui/material'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Panel from 'components/Panel'
import Modal from 'components/Modal'
import Form from 'components/Form'
import { useSnackbar } from 'notistack'
import { setInfo, clearInfo } from 'slices/infoSlice'
import { normalizeData } from 'utilities/validator'
import usePopup from 'hooks/usePopup'
import useAxios from 'hooks/useAxios'
import { decentilizedProvinceRes } from 'utilities/utils'
import { warpUpFailResponse } from 'utilities/utils'
import { PAYMENT_OPTIONS } from 'helper/DataFactory'
import useDataHandler from 'hooks/useDataHandler'

export default function Employee() {
	const dispatch = useDispatch()
	const { enqueueSnackbar } = useSnackbar()
	const { post, del, upload, multiple, get } = useAxios()
	const { save } = useDataHandler()
	const info = useSelector(state => state.info.ctx)
	const [mode, setMode] = useState("initial")
	const { confirmPopup } = usePopup()
	const [provinceData, setProvinceData] = useState([])
	const [allVendor, setAllVendor] = useState([])
	const [allEmployee, setAllEmployee] = useState([])
	const [provinceRes, setProvinceRes] = useState([])
	const [allDepartment, setAllDepartment] = useState([])
	const [allPosition, setAllPosition] = useState([])
	const roleUser = useSelector(state => state.user.roleName)

	const fetchData = async controller => {
		const result = await multiple([
			{ method: "get", url: "/province/getAllProvince", config: { signal: controller.signal } },
			{ method: "get", url: "/vendor/getAllActiveVendor", config: { signal: controller.signal } },
			{ method: "get", url: "/employee/getAllEmployee", config: { signal: controller.signal } },
			{ method: "get", url: "/department/getAllActiveDepartment", config: { signal: controller.signal } },
			{ method: "get", url: "/position/getAllActivePosition", config: { signal: controller.signal } },
		])

		if (result[0].status === 200) {
			const data = result[0].data.data
			setProvinceRes(data.records)
			const groupedData = decentilizedProvinceRes(data.records)
			setProvinceData(groupedData)
		}
		if (result[1].status === 200) {
			const data = result[1].data.data
			setAllVendor(data.records.map(data => ({ label: data.name, value: data.id })) || [])
		}
		if (result[2].status === 200) {
			const employees = result[2].data.data || []
			setAllEmployee(employees)
		}
		if (result[3].status === 200) {
			const data = result[3].data.data.records || []
			setAllDepartment(data.map(e => { return { label: e.name, value: e.id, data: e } }))
		}
		if (result[4].status === 200) {
			const data = result[4].data.data.records || []
			setAllPosition(data.map(e => { return { label: e.name, value: e.id, data: e } }))
		}

	}

	const onCloseModal = useCallback(() => {
		confirmPopup({
			title: "แจ้งเตือน",
			content: "ท่านต้องการยกเลิกการแก้ไขหรือไม่",
			onSubmit: () => {
				setMode("initial")
			}
		})
	}, [])

	useEffect(() => {
		const controller = new AbortController()
		fetchData(controller)
		return () => {
			controller.abort()
		}
	}, [])

	const allProvince = useMemo(() => Object.keys(provinceData).map(child => { return { label: child, value: child } }), [provinceData])
	const allAmphoe = useMemo(() => info.province ? Object.keys(provinceData[info.province]).map(child => { return { label: child, value: child } }) : [], [provinceData, info.province])
	const allDistrict = useMemo(() => provinceData.hasOwnProperty(info.province) && provinceData[info.province].hasOwnProperty(info.amphoe) ? Object.keys(provinceData[info.province][info.amphoe]).map(child => { return { label: child, value: child } }) : [], [provinceData, info.province, info.amphoe])

	useEffect(() => {
		if (info.provinceId && provinceRes) {
			const { province, amphoe, district, zipcode } = provinceRes.find(e => info.provinceId === e.id)
			dispatch(setInfo({ province, amphoe, district, zipcode }))
		} else {
			dispatch(setInfo({ province: null, amphoe: null, district: null, zipcode: null }))
		}

	}, [info.provinceId])

	const inputForm = [
		{
			rowData: [
				{
					type: "profileUpload",
					props: {
						id: "profile",
						prefix: `user/profile`
					}
				}
			]
		}, {
			rowData: [
				{
					type: "divider",
					props: {
						label: "ข้อมูลส่วนตัว",
						variant: "outlined",
					}
				}
			]
		},
		{
			rowData: [
				{
					type: "dropdown",
					required: true,
					props: {
						id: "prefix",
						label: "คำนำหน้า",
						options: [
							{ label: "นาย", value: "นาย" },
							{ label: "นาง", value: "นาง" },
							{ label: "นางสาว", value: "นางสาว" }
						],
					}, md: 1.2
				},
				{
					type: "text",
					required: true,
					props: {
						id: "firstName",
						label: "ชื่อ",
						variant: "outlined"
					}, md: 3.8
				},
				{
					type: "text",
					required: true,
					props: {
						id: "lastName",
						label: "นามสกุล",
						variant: "outlined"
					}, md: 4
				},
				{
					type: "date",
					props: {
						id: "dob",
						label: "เกิดวันที่"
					}, md: 3
				}
			]
		},
		{
			rowData: [
				{
					type: "text",
					required: true,
					props: {
						id: "idNo",
						label: "เลขบัตรประจำตัวประชาชน",
						variant: "outlined",
						maxCharacters: 13
					}
				},
				{
					type: "text",
					props: {
						id: "drvLicNo",
						label: "เลขที่ใบขับขี่",
						variant: "outlined"
					}
				},
				{
					type: "radio",
					required: true,
					props: {
						id: "gender",
						label: "เพศ",
						options: [
							{ label: "ชาย", value: "ชาย" },
							{ label: "หญิง", value: "หญิง" },
							{ label: "อื่น", value: "อื่นๆ" },
						]
					}
				}
			]
		},
		{
			rowData: [
				{
					type: "divider",
					props: {
						label: "ข้อมูลที่อยู่",
						variant: "outlined",
					}
				}
			]
		},
		{
			rowData: [
				{
					type: "text",
					props: {
						id: "address",
						label: "ที่อยู่",
						variant: "outlined",
						multiline: true,
						maxRows: 4
					}
				}
			]
		},
		{
			rowData: [
				{
					type: "autocomplete",
					required: true,
					props: {
						id: "province",
						label: "จังหวัด",
						options: allProvince
					}
				},
				{
					type: "autocomplete",
					required: true,
					props: {
						id: "amphoe",
						label: "อำเภอ",
						options: allAmphoe,
						relateId: "province"
					}
				},
				{
					type: "autocomplete",
					required: true,
					props: {
						id: "district",
						label: "ตำบล",
						options: allDistrict,
						onChange: (value) => {
							if (value) {
								const zipcode = provinceData[info.province][info.amphoe][value].zipcode
								dispatch(setInfo({ zipcode }))
							} else {
								dispatch(setInfo({ zipcode: null }))
							}
						},
						relateId: "amphoe"
					}
				}, {
					type: "text",
					props: {
						id: "zipcode",
						label: "รหัสไปรษณีย์",
						variant: "outlined",
						readOnly: true
					}
				}
			]
		},
		{
			rowData: [
				{
					type: "text",
					validation: 'tel',
					props: {
						id: "mobile",
						label: "เบอร์มือถือ",
						variant: "outlined"
					}
				},
				{
					type: "text",
					validation: 'tel',
					props: {
						id: "tel",
						label: "เบอร์โทร",
						variant: "outlined"
					}
				},
				{
					type: "text",
					props: {
						id: "email",
						label: "อีเมลล์",
						variant: "outlined"
					}
				}
			]
		},
		{
			rowData: [
				{
					type: "divider",
					props: {
						label: "ข้อมูลงาน",
						variant: "outlined",
					}
				}
			]
		},
		{
			rowData: [
				{
					type: "autocomplete",
					required: true,
					props: {
						id: "positionId",
						label: "ตำแหน่ง",
						options: allPosition
					}
				},
				{
					type: "autocomplete",
					required: true,
					props: {
						id: "departmentId",
						label: "แผนก",
						options: allDepartment,
					}
				},
				{
					type: "number",
					props: {
						id: "salary",
						label: "เงินเดือน",
						variant: "outlined"
					}
				},
				{
					type: "number",
					props: {
						id: "otherincome",
						label: "รายได้อื่่นๆ",
						variant: "outlined"
					}
				}
			]
		},
		{
			rowData: [
				{
					type: "date",
					required: true,
					props: {
						id: "startDate",
						label: "เริ่มงาน"
					}
				},
				{
					type: "date",
					props: {
						id: "resignDate",
						label: "วันที่ลาออก",
						minDate: info.startDate,
						maxDate: new Date(),
					}
				},
				{
					type: "text",
					props: {
						id: "ssoNo",
						label: "เลขที่ประกันสังคม",
						variant: "outlined"
					}
				},
				{
					type: "dropdown",
					props: {
						id: "vendorId",
						label: "บริษัทรถร่วม",
						options: allVendor
					}
				}

			]
		},
		{
			rowData: [
				mode === "edit" && {
					type: "dropdown",
					props: {
						id: "active",
						label: "สถานะ",
						options: [
							{ label: "ใช้งาน", value: true },
							{ label: "ไม่ใช้งาน", value: false }
						],
					},
					md: 2
				},
				{
					type: "autocomplete",
					required: true,
					props: {
						id: "paymentMethod",
						label: "ช่องทางจ่ายเงิน",
						options: PAYMENT_OPTIONS
					},
					md: 2
				},
				{
					type: "radio",
					required: true,
					props: {
						id: "isblacklist",
						label: "Blacklist",
						options: [
							{ label: "ไม่มี", value: false },
							{ label: "มี", value: true }
						]
					},
					md: 2
				}
			]
		}, {
			rowData: [
				{
					type: "button",
					props: {
						label: "บันทึก",
						onClick: () => {
							saveData()
						},
						className: "!bg-bpTheme-buttonSubmit"
					}
				}
			]
		}
	]
	const table = useMemo(() => [
		{
			rowData: [{
				type: "dataTable",
				props: {
					columns: [
						{
							id: 'firstName',
							label: 'ชื่อ',
							fields: ["firstName"]
						},
						{
							id: 'lastName',
							label: 'นามสุกล',
							fields: ["lastName"]
						},
						{
							id: 'gender',
							label: 'เพศ',
							fields: ["gender"]
						},
						{
							id: 'isblacklist',
							label: 'Blacklist',
							fields: ["isblacklist"],
							valuesMap: [{ label: "ใช่", value: true }, { label: "ไม่ใช่", value: false }]
						},
						{
							id: 'vendorName',
							label: 'บริษัท',
							fields: ["vendorName"]
						},
						{
							id: 'active',
							label: 'สถานะ',
							fields: ["active"],
							valuesMap: [{ label: "ใช้งาน", value: true }, { label: "ไม่ใช้งาน", value: false }]
						}
					],
					rows: allEmployee,
					size: "small",
					showDeleteButton: roleUser === "Owner",
					title: "ข้อมูลพนักงาน",
					showSystemFields: true,
					onClick: (e) => {
						setMode("edit")
						dispatch(setInfo(e))
					},
					onDelete: (e, onSuccess) => {
						confirmPopup({
							onSubmit: async () => {
								const result = await del("/employee/deleteEmployee", { ids: e.map(data => data.id).join(',') })
								if (result.status === 200) {
									enqueueSnackbar('ลบสำเร็จ', { variant: "success" })
									dispatch(clearInfo())
									await fetchData(new AbortController())
									onSuccess()
								} else {
									enqueueSnackbar('ลบไม่สำเร็จ', { variant: "error" })
								}
							}
						})
					},
					controller: (paging) => get("/employee/getAllEmployee", { paging }),
					searchFields: [
						{ label: "ชื่อ", value: "firstName" },
						{ label: "นามสุกล", value: "lastName" },
						{ label: "เพศ", value: "gender" },
						{ label: "Blacklist", value: "isblacklist" },
						{ label: "บริษัท", value: "vendorName" },
						{ label: "สถานะ", value: "active" }
					]
				}
			}]
		}, {
			noCol: true,
			rowData: [
				{
					type: "button",
					props: {
						label: "เพิ่มใหม่",
						onClick: () => {
							setMode("create")
							dispatch(clearInfo())
						}
					}
				},
				// {
				// 	type: "upload",
				// 	props: {
				// 		id: "files",
				// 		type: "button",
				// 		className: "!ml-2",
				// 		variant: "outlined",
				// 		maxFiles: 5
				// 	}
				// }
			]
		}
	], [allEmployee])

	const saveData = useCallback(() => {
		save({
			data: info,
			onSubmit: async () => {
				const data = normalizeData(info)
				const result = await upload({ prefix: `user/profile`, file: info.profile })
				let profile = null
				if (result.status === 200) {
					profile = result.data.data
				}
				const provinceId = info.district ? provinceData[info.province][info.amphoe][info.district].id : null
				let response
				if (mode === "create") {
					response = await post("/employee/createEmployee", { ...data, provinceId, profile, active: true })
				} else if (mode === "edit") {
					response = await post("/employee/updateEmployee", { ...data, provinceId, profile })
				}
				if (response.status === 200) {
					setMode("initial")
					enqueueSnackbar('บันทึกสำเร็จ', { variant: "success" })
					fetchData(new AbortController())
				} else {
					enqueueSnackbar(warpUpFailResponse(response, 'บันทึกไม่สำเร็จ'), { variant: "error" })
				}
			},
			onCancel: () => {
				enqueueSnackbar('ยกเลิกรายการ', { variant: "info" })
			}
		})
	}, [info, mode])

	return (
		<Box>
			<Panel data={table}></Panel>
			<Modal open={mode === "create" || mode === "edit"} onClose={onCloseModal} className="form-modal">
				<Form title="ข้อมูลพนักงาน" name="user-form" data={inputForm}></Form>
			</Modal>
		</Box>
	)
}