import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Panel from 'components/Panel'
import Modal from 'components/Modal'
import Form from 'components/Form'
import { useSnackbar } from 'notistack'
import { normalizeData } from 'utilities/validator'
import usePopup from 'hooks/usePopup'
import useAxios from 'hooks/useAxios'
import { setInfo, clearInfo, setInfoById } from 'slices/infoSlice'
import { STATUS_ORDER_NEW } from 'helper/DataFactory'
import { warpUpFailResponse } from 'utilities/utils'
import useDataHandler from 'hooks/useDataHandler'
import UploadOrderModal from './uploadOrder'
import Items from './items'
import { memorizedContextInfo } from 'redux/memorize'
import { Box } from '@mui/material'
import Button from 'components/Button'
import ClearAllIcon from '@mui/icons-material/ClearAll';
import SearchIcon from '@mui/icons-material/Search';
import DatePicker from 'components/DatePicker'

export default function Order({ portal }) {
	const dispatch = useDispatch()
	const { enqueueSnackbar } = useSnackbar()
	const { save } = useDataHandler()
	const info = useSelector(memorizedContextInfo)
	const mode = useState("initial") //main form
	const mode2 = useState("initial") //bill
	const mode3 = useState("initial") //upload
	const [allSender, setAllSender] = useState([])
	const [allVehicleType, setAllVehicleType] = useState([])
	const [allOrder, setAllOrder] = useState([])
	const [allOrderStatus, setAllOrderStatus] = useState([])
	const [allServiceType, setAllServiceType] = useState([])
	const { confirmPopup } = usePopup();
	const { post, get, del, multiple } = useAxios()
	const roleUser = useSelector(state => state.user.roleName)
	const editableStatus = [STATUS_ORDER_NEW, undefined]

	const fetchData = async controller => {
		const result = await multiple([
			{ method: "get", url: "/division/getAllActiveDivision", params: { portal }, config: { signal: controller.signal } },
			{ method: "get", url: "/vehicleType/getAllActiveVehicleType", config: { signal: controller.signal } },
			{ method: "get", url: "/serviceType/getAllActiveServiceType", config: { signal: controller.signal } },
			{ method: "get", url: "/order/getSummaryOrder", config: { signal: controller.signal } },
			{ method: "get", url: "/order/getAllOrder", params: { portal, startPickDate: info.filterStartPickDate, endPickDate: info.filterEndPickDate, startDeliveryDate: info.filterStartDeliveryDate, endDeliveryDate: info.filterEndDeliveryDate }, config: { signal: controller.signal } },
		])

		if (result[0].status === 200) {
			const division = result[0].data.data.records || []
			setAllSender(division.map(e => ({ label: e.name, value: e.id, data: e })).sort((a, b) => a.data.customerName.localeCompare(b.data.customerName)))
		}

		if (result[1].status === 200) {
			const vehicleType = result[1].data.data.records || []
			setAllVehicleType(vehicleType.map(e => ({ label: e.name, value: e.id })))
		}

		if (result[2].status === 200) {
			const data = result[2].data.data.records || []
			setAllServiceType(data.map(e => ({ label: e.name, value: e.id })))
		}

		if (result[3].status === 200) {
			const data = result[3].data.data.records || []
			setAllOrderStatus(data[0])
		}

		if (result[4].status === 200) {
			const data = result[4].data.data || []
			setAllOrder(data)
		}

	}

	const onCloseModal = useCallback(() => {
		confirmPopup({
			title: "แจ้งเตือน",
			content: "ท่านต้องการยกเลิกการแก้ไขหรือไม่",
			onSubmit: () => {
				mode[1]("initial")
				dispatch(clearInfo())
			}
		})
	}, [])

	useEffect(() => {
		const controller = new AbortController();
		fetchData(controller)
		return () => {
			controller.abort()
		}
	}, [])

	const inputForm = [
		{
			rowData: [
				{
					type: "autocomplete",
					required: true,
					props: {
						id: "senderId",
						label: "ผู้ส่ง",
						options: allSender,
						groupBy: (option) => option.data.customerName
					}
				},
				{
					type: "text",
					props: {
						id: "ref1",
						label: "Customer Doc No (Ref 1)"
					}
				},
				{
					type: "text",
					props: {
						id: "ref2",
						label: "D/O,SO Doc No (Ref 2)"
					}
				},
			]
		},
		{
			isCollapse: true,
			label: "รายละเอียด",
			defaultCollapse: true,
			collapseData: [
				{
					rowData: [
						{
							type: "autocomplete",
							required: true,
							props: {
								id: "serviceTypeId",
								label: "ประเภทบริการ",
								options: allServiceType
							}
						},
						{
							type: "autocomplete",
							required: true,
							props: {
								id: "vehicleTypeId",
								label: "ประเภทรถ",
								options: allVehicleType
							}
						},
						{
							type: "datetime",
							required: true,
							props: {
								id: "pickDate",
								label: "วันที่เข้ารับสินค้า",
								minDate: mode[0] === "edit" ? null : new Date(),
							}
						},
						{
							type: "datetime",
							required: true,
							props: {
								id: "deliveryDate",
								label: "วันที่ส่งสินค้า",
								minDate: mode[0] === "edit" ? null : info.pickDate,
							}
						},
					]
				}
			]
		},
		{
			isCollapse: true,
			label: "รายละเอียดสินค้าที่ขนส่ง",
			collapseData: [
				{
					rowData: [
						{
							type: "dataTable",
							props: {
								columns: [
									{
										id: 'detail',
										label: 'รายละเอียดสินค้า',
										fields: ["detail"]
									},
									{
										id: 'temperature',
										label: 'อุณหภูมิ',
										fields: ["temperature"],
										type: "number"
									},
									{
										id: 'pickupAddress',
										label: 'จุดรับ',
										fields: ["pickupAddress"]
									},
									{
										id: 'shippingAddress',
										label: 'จุดส่ง',
										fields: ["shippingAddress"]
									},
									{
										id: 'totalPickup',
										label: 'จำนวนสินค้าที่รับ',
										fields: ["totalPickup"],
										type: "number"
									},
									{
										id: 'totalShipping',
										label: 'จำนวนสินค้าที่ส่ง',
										fields: ["totalShipping"],
										type: "number"
									},
									{
										id: 'remark',
										label: 'หมายเหตุ',
										fields: ["remark"]
									},
								],
								rows: info.items,
								title: "สินค้า",
								tableContainerClassName: "max-h-[30vh]",
								multiSelect: editableStatus.includes(info.status),
								onDelete: (e, onSuccess) => {
									const ids = e.map(e => e.id) || []
									const removedItems = info.items.filter(e => !ids.includes(e.id))
									dispatch(setInfo({ items: removedItems }))
									onSuccess()
								},
								size: "small",
								onClick: (e) => {
									dispatch(setInfoById({ id: `items`, payload: e }))
									mode2[1]("edit")
								}
							}
						}
					]
				},
				editableStatus.includes(info.status) && {
					rowData: [
						{
							type: "button",
							props: {
								label: "เพิ่มสินค้า",
								onClick: () => {
									if (info.senderId) {
										mode2[1]("create")
									} else {
										enqueueSnackbar('กรุณาเพิ่มผู้ส่ง', { variant: "error" })
									}
								}
							}
						}
					]
				},
			]
		},
		{
			isCollapse: true,
			label: "รายละเอียดเพิ่มเติม",
			collapseData: [
				{
					rowData: [
						{
							type: "text",
							props: {
								id: "remark",
								label: "หมายเหตุ",
								multiline: true,
								maxRows: 4
							}
						}
					]
				},
				{
					rowData: [
						{
							type: "text",
							props: {
								id: "contactName",
								label: "ผู้ติดต่อ"
							}
						},
						{
							type: "text",
							props: {
								id: "contactTel",
								label: "เบอร์โทรติดต่อ"
							}
						}
					]
				}
			]
		},
		editableStatus.includes(info.status) && {
			rowData: [
				{
					type: "button",
					props: {
						label: "บันทึก",
						onClick: () => {
							saveData()
						},
						className: "!bg-bpTheme-buttonSubmit"
					}
				}
			]
		}
	]

	const table = useMemo(() => [
		{
			rowData: [{
				type: "dataTable",
				props: {
					columns: [
						{
							id: 'no',
							label: 'เลขที่ใบจอง',
							fields: ["no"]
						},
						{
							id: 'senderName',
							label: 'ผู้ส่ง',
							fields: ["senderName"]
						},
						{
							id: 'vehicleTypeName',
							label: 'ประเภทรถ',
							fields: ["vehicleTypeName"]
						},
						{
							id: 'temperature',
							label: 'อุณหภูมิต่ำสุด',
							fields: ["temperature"],
							align: "center"
						},
						{
							id: 'pickDate',
							label: 'วันที่เข้ารับสินค้า',
							fields: ["pickDate"],
							type: "datetime"
						},
						{
							id: 'deliveryDate',
							label: 'วันที่ส่งสินค้า',
							fields: ["deliveryDate"],
							type: "datetime"
						},
						{
							id: 'pickupAddress',
							label: 'สถานที่รับ',
							fields: ["pickupAddress"],
							ellipsis: true
						},
						{
							id: 'shippingAddress',
							label: 'สถานที่ส่ง',
							fields: ["shippingAddress"],
							ellipsis: true
						},
						{
							id: 'status',
							label: 'สถานะ',
							fields: ["status"]
						},
					],
					rows: allOrder,
					size: "small",
					showDeleteButton: roleUser === "Owner",
					title: "ข้อมูลใบจอง",
					showSystemFields: true,
					onClick: async (e) => {
						const result = await get("/order/getOrderById", { id: e.id })
						if (result.status === 200) {
							const data = result.data.data
							dispatch(setInfo(data))
							mode[1]("edit")
						}
					},
					onDelete: (e, onSuccess) => {
						confirmPopup({
							onSubmit: async () => {
								const result = await del("/order/deleteOrder", { ids: e.map(data => data.id).join(',') })
								if (result.status === 200) {
									enqueueSnackbar('ลบสำเร็จ', { variant: "success" })
									dispatch(clearInfo())
									await fetchData(new AbortController())
									onSuccess()
								} else {
									enqueueSnackbar('ลบไม่สำเร็จ', { variant: "error" })
								}
							}
						})
					},
					controller: (paging) => get("/order/getAllOrder", { portal, paging, startPickDate: info.filterStartPickDate, endPickDate: info.filterEndPickDate, startDeliveryDate: info.filterStartDeliveryDate, endDeliveryDate: info.filterEndDeliveryDate }),
					searchFields: [
						{ label: "ผู้ส่ง", value: "senderName" },
						{ label: "ประเภทรถ", value: "vehicleTypeName" },
						{ label: "สถานะ", value: "status" }
					]
				}
			}]
		},
		{
			noCol: true,
			rowData: [
				{
					type: "button",
					props: {
						label: "เพิ่มใหม่",
						onClick: () => {
							mode[1]("create")
							dispatch(clearInfo())
						}
					}
				},
				{
					type: "button",
					props: {
						label: "อัปโหลด",
						variant: "outlined",
						className: "!ml-2",
						onClick: () => {
							mode3[1]("upload")
						}
					}
				}
			]
		}
	], [allOrder])

	const saveData = useCallback(() => {
		// const hasMismatch = info.items.some((item) => {
		// 	return (item.totalPickup !== item.totalShipping) &&
		// 		!(isNaN(item.totalPickup) && isNaN(item.totalShipping));
		// });

		// if (hasMismatch) {
		// 	enqueueSnackbar("Number of pickup and shipping does not match", { variant: "error" });
		// 	return
		// }

		save({
			data: info,
			onSubmit: async () => {
				const data = normalizeData(info)
				const modifiedData = {
					...data,
					items: data.items?.map(({ id, recStatus, ...rest }) => recStatus === "new" ?
						{
							...rest,
							pickupLocations: rest.pickupLocations.map(({ id, recStatus, ..._rest }) => recStatus === "new" ? _rest : { id, ..._rest }),
							shippingLocations: rest.shippingLocations?.map(({ id, recStatus, ..._rest }) => recStatus === "new" ? _rest : { id, ..._rest }) || []
						}
						:
						{
							id,
							...rest,
							pickupLocations: rest.pickupLocations.map(({ id, recStatus, ..._rest }) => recStatus === "new" ? _rest : { id, ..._rest }),
							shippingLocations: rest.shippingLocations?.map(({ id, recStatus, ..._rest }) => recStatus === "new" ? _rest : { id, ..._rest }) || []
						}) || []
				};
				let response
				if (mode[0] === "create") {
					response = await post("/order/createOrder", { ...modifiedData, active: true })
				} else if (mode[0] === "edit") {
					response = await post("/order/updateOrder", { ...modifiedData, active: true })
				}
				if (response.status === 200) {
					mode[1]("initial")
					enqueueSnackbar('บันทึกสำเร็จ', { variant: "success" })
					fetchData(new AbortController())
				} else {
					enqueueSnackbar(warpUpFailResponse(response, 'บันทึกไม่สำเร็จ'), { variant: "error" })
				}
			},
			onCancel: () => {
				enqueueSnackbar('ยกเลิกรายการ', { variant: "info" })
			}
		})
	}, [info, mode[0]])

	const filterOrders = async () => {
		fetchData(new AbortController())
	}

	const clearFilterOrders = async () => {
		dispatch(setInfo({ filterStartPickDate: null, filterEndPickDate: null, filterStartDeliveryDate: null, filterEndDeliveryDate: null }))
	}

	return (
		<>
			{(roleUser === "Owner" || roleUser === "Admin") && (
				<div className="w-full px-3 flex justify-end">
					<div className="w-full md:w-1/3 flex flex-row border rounded-md">
						<div className="w-full flex flex-col py-2 border-r items-center" >
							<div className="text-lg ">
								Total order
							</div>
							<div className="mt-1 font-semibold text-2xl ">
								{allOrderStatus?.total || 0}
							</div>
						</div>
						<div className="w-full flex flex-col py-2 items-center" >
							<div className="text-lg ">
								Total plan
							</div>
							<div className="mt-1 font-semibold text-2xl ">
								{allOrderStatus?.plan || 0}
							</div>
						</div>
					</div>
				</div>
			)}
			<Box className='flex justify-end gap-2 mt-2'>
				<DatePicker
					id="filterStartPickDate"
					label="วันที่รับสินค้าเริิ่มต้น"
					maxDate={info.filterEndPickDate}
					className="max-w-[200px]"
				/>
				<DatePicker
					id="filterEndPickDate"
					label="วันที่รับสินค้าสิ้นสุด"
					minDate={info.filterStartPickDate}
					className="max-w-[200px]"
				/>
				<DatePicker
					id="filterStartDeliveryDate"
					label="วันที่ส่งสินค้าเริิ่มต้น"
					maxDate={info.filterEndDeliveryDate}
					className="max-w-[200px]"
				/>
				<DatePicker
					id="filterEndDeliveryDate"
					label="วันที่ส่งสินค้าสิ้นสุด"
					minDate={info.filterStartDeliveryDate}
					className="max-w-[200px]"
				/>
				<Button onClick={clearFilterOrders} variant="text"><ClearAllIcon /></Button>
				<Button onClick={filterOrders} variant="text"><SearchIcon /></Button>
			</Box>
			<Panel data={table}></Panel>
			<Modal open={mode[0] === "create" || mode[0] === "edit"} onClose={onCloseModal} className="form-modal">
				<Form title={mode[0] === "create" ? "สร้างใบจอง" : "แก้ไขใบจอง"} name="user-form" data={inputForm} editable={editableStatus.includes(info.status)}></Form>
			</Modal>
			<Items mode={mode2} editable={editableStatus.includes(info.status)} />
			<UploadOrderModal mode={mode3} refreshData={fetchData} portal={portal} />
		</>
	)
}




