import { useDispatch } from "react-redux"
import { removePopup, pushPopup } from "slices/popupSlice";
import { v4 as uuidv4 } from 'uuid';

export default function usePopup() {
    const dispatch = useDispatch()

    const confirmPopup = (config) => {
        const id = uuidv4()
        dispatch(pushPopup(
            {
                id,
                type: "confirm",
                open: true,
                content: "ท่านต้องการยืนยันการทำรายการหรือไม่?",
                onClose: () => {
                    dispatch(removePopup(id))
                },
                ...config
            }
        ))
    }

    const notifyPopup = ({ onClose, ...config }) => {
        const id = uuidv4()
        dispatch(pushPopup(
            {
                id,
                type: "notify",
                open: true,
                onClose: () => {
                    onClose && onClose()
                    dispatch(removePopup(id))
                },
                ...config
            }
        ))
    }

    return {
        confirmPopup,
        notifyPopup
    }
}
