import React from 'react';
import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';

const BootstrapTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: '#3b3b3bff',
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: '#3b3b3bff',
    fontSize: '16px',
    color: theme.palette.common.white,
    boxShadow: theme.shadows[1],
  },
}));

const EllipsisTextComponent = ({ text, popoverText, className, width }) => {
  return (
    <BootstrapTooltip title={popoverText || text}>
      <Box className={`overflow-hidden whitespace-nowrap text-ellipsis ${className || ""} ${!width ? "w-40" : ""}`} width={width}>
        <span className='select-none'>
          {text}
        </span>
      </Box>
    </BootstrapTooltip>
  );
};

export default EllipsisTextComponent;
