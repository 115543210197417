import imageCompression from 'browser-image-compression';
import { v4 as uuidv4 } from 'uuid';

export const wait = (ms) => new Promise(resolve => setTimeout(resolve, ms))

export const toBuddhistYear = (date) => {
    const buddhistYear = date.getFullYear() + 543;
    return new Date(date.setFullYear(buddhistYear));
};

export const toCommaFormat = (value, minimumFractionDigits = 2, maximumFractionDigits = 2) => {
    if (typeof value == "number") {
        return value?.toLocaleString('en-US', {
            minimumFractionDigits,
            maximumFractionDigits
        });
    }
}

export const stringToColor = (string) => {
    let hash = 0;
    let i;

    /* eslint-disable no-bitwise */
    for (i = 0; i < string.length; i += 1) {
        hash = string.charCodeAt(i) + ((hash << 5) - hash);
    }

    let color = '#';

    for (i = 0; i < 3; i += 1) {
        const value = (hash >> (i * 8)) & 0xff;
        color += `00${value.toString(16)}`.slice(-2);
    }
    /* eslint-enable no-bitwise */

    return color;
}

export const decentilizedProvinceRes = (data = []) =>
    data.reduce((result, item) => {
        const province = item.province
        if (!result[province]) {
            result[province] = {}
        }
        const amphoe = item.amphoe
        if (!result[province][amphoe]) {
            result[province][amphoe] = {}
        }
        const district = item.district
        result[province][amphoe][district] = item
        return result
    }, {})

export const dataURLtoFile = (dataURL, fileName) => {
    const arr = dataURL.split(',');
    const mime = arr[0].match(/:(.*?);/)[1];
    const bstr = atob(arr[1]);
    let n = bstr.length;
    const u8arr = new Uint8Array(n);
    while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], fileName, { type: mime });
}

export const calculateCountdown = (targetDate, startDate, debug) => {
    const endDate = new Date(targetDate)
    if (!isNaN(endDate)) {
        const now = startDate != null ? new Date(startDate) : new Date();
        const timeDifference = endDate.getTime() - now.getTime();
        return timeDifference
    }
}

export const formatTime = (time) => {
    if (!isNaN(time)) {
        const sign = time >= 0 ? 1 : -1; // Determine the sign of the time difference
        const absoluteTime = Math.abs(time); // Get the absolute value of the time difference

        const hours = Math.floor(absoluteTime / (1000 * 60 * 60));
        const minutes = Math.floor((absoluteTime % (1000 * 60 * 60)) / (1000 * 60));
        const seconds = Math.floor((absoluteTime % (1000 * 60)) / 1000);

        return `${sign === 1 ? '' : '-'}${hours}h ${minutes}m ${seconds}s`;
    }
}

export const cloneCollapsed = (obj1, obj2) => {
    for (let key in obj1) {
        if (obj1.hasOwnProperty(key)) {
            const value = obj1[key];

            if (value && typeof value === 'object' && value.collapsed) {
                obj2[key] = Array.isArray(value) ? [] : {};
                cloneCollapsed(value, obj2[key]);
            } else {
                obj2[key] = value;
            }
        }
    }
}

export const extractInfo = (state, parentId, id, defaultValue = null) => {
    const info = state?.info;
    if (!info) {
        return defaultValue;
    }

    if (parentId) {
        const seperatedId = parentId.split("@").filter(e => e)
        if (seperatedId.length === 2) {
            return info.hasOwnProperty("ctx") && info.ctx.hasOwnProperty(seperatedId[0]) && info.ctx[seperatedId[0]]?.hasOwnProperty(seperatedId[1]) ? info.ctx[seperatedId[0]][seperatedId[1]][id] : defaultValue
        } else if (seperatedId.length === 3) {
            return info.hasOwnProperty(seperatedId[0]) && info[seperatedId[0]]?.hasOwnProperty(seperatedId[1]) && info[seperatedId[0]]?.[seperatedId[1]]?.hasOwnProperty(seperatedId[2]) ? info[seperatedId[0]][seperatedId[1]][seperatedId[2]][id] : defaultValue
        }
        return info.hasOwnProperty(parentId) && info[parentId]?.hasOwnProperty(id) ? info[parentId][id] : defaultValue
    } else if (id) {
        return info.hasOwnProperty("ctx") && info["ctx"]?.hasOwnProperty(id) ? info["ctx"][id] : defaultValue
    } else {
        return defaultValue
    }
}

export const resizeImage = async (file, maxSizeInBytes, onProgress) => {
    return new Promise(async (resolve, reject) => {
        try {
            if (!file.type.startsWith('image/')) {
                resolve(file);
            }
            if (file.size <= maxSizeInBytes) {
                onProgress && onProgress(100);
                resolve(file);
            }
            const blob = await imageCompression(file, {
                maxSizeMB: maxSizeInBytes,
                maxWidthOrHeight: 1280,
                maxIteration: 50,
                initialQuality: 0.7,
                useWebWorker: true,
                onProgress: (progress) => {
                    onProgress && onProgress(progress);
                }
            })

            const resizedFile = new File([blob], file.name, { type: file.type });
            resolve(resizedFile);
        } catch (e) {
            reject(e)
        }
    });
}

export const getSessionUUID = () => {
    return `${uuidv4()}-${new Date().getTime()}`
}

export const warpUpFailResponse = (response, title) => {
    return `${title} ${response?.data?.status?.description ? `เนื่องจาก${response.data.status.description}` : ""}`
}

export const formatCoordinates = (coordinate, direction) => {
    const degrees = Math.floor(coordinate);
    const minutes = Math.floor((coordinate - degrees) * 60);
    const seconds = ((coordinate - degrees - minutes / 60) * 3600).toFixed(1);
    return `${degrees}°${minutes}'${seconds}"${direction}`;
};

export const handleGeolocationError = (error, reason) => {
    switch (error.code) {
        case error.PERMISSION_DENIED:
            return `ไม่สามารถดึงตำแหน่งปัจจุบันเพื่อ${reason ? reason : "คำนวนระยะห่าง"}ได้ เนื่องจากการปฏิเสธการเข้าถึงตำแหน่ง กรุณาอนุญาตให้แอปเข้าถึงตำแหน่งของคุณในเบราว์เซอร์หรือในระบบปฏิบัติการ`
        case error.POSITION_UNAVAILABLE:
            return `ไม่สามารถดึงตำแหน่งปัจจุบันเพื่อ${reason ? reason : "คำนวนระยะห่าง"}ได้ เนื่องจากข้อมูลตำแหน่งไม่สามารถใช้ได้ กรุณาตรวจสอบการเชื่อมต่ออินเทอร์เน็ตหรือความพร้อมของเซ็นเซอร์ตำแหน่ง`
        case error.TIMEOUT:
            return `ไม่สามารถดึงตำแหน่งปัจจุบันเพื่อ${reason ? reason : "คำนวนระยะห่าง"}ได้ เนื่องจากหมดเวลารอข้อมูลตำแหน่ง กรุณาลองใหม่อีกครั้ง`
        default:
            return `ไม่สามารถดึงตำแหน่งปัจจุบันเพื่อ${reason ? reason : "คำนวนระยะห่าง"}ได้ เนื่องจากเกิดข้อผิดพลาดไม่ทราบสาเหตุ`
    }
}

export const handleUnsupportedGeolocation = (reason) => {
    return `ไม่สามารถดึงตำแหน่งปัจจุบันเพื่อ${reason ? reason : "คำนวนระยะห่าง"}ได้ เบราว์เซอร์ของคุณไม่รองรับการเข้าถึงตำแหน่ง กรุณาใช้เบราว์เซอร์หรืออุปกรณ์ที่รองรับฟีเจอร์นี้`
}