import React from 'react'
import MuiModal from '@mui/material/Modal';
import { Box } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';

export default function Modal({ open, onClose, className = "", contentClassName = "", contentSX, children, ...other }) {
    const style = {
        bgcolor: 'background.paper',
        boxShadow: 24,
        minWidth: { xs: "calc(100% + 1px)", md: "80%" },
        maxHeight: { xs: "calc(100% + 1px)", md: "80%" },
        '&::-webkit-scrollbar-track': {
            borderTopRightRadius: { xs: 0, md: 8 },
            borderBottomRightRadius: { xs: 0, md: 8 },
        },
        '.form-modal &': {
            padding: '16px',
            display: 'flex',
            height: 'fit-content',
            maxWidth: { md: '80vw' },
            '& fieldset, &>div': {
                flexGrow: '1',
                height: 'unset'
            }
        },
        borderRadius: { xs: 0, md: 2 }
    }

    return (
        <MuiModal className={`backdrop-blur-sm flex justify-center items-center relative ${className}`}
            open={open}
            onClose={onClose}
            {...other}
        >
            <>
                <Box sx={{ ...style, ...contentSX }} className={`overflow-auto scrollbar-thin scrollbar-thumb-bpTheme-scroll scrollbar-track-white ${contentClassName}`}>
                    {children}
                </Box>
                <Box sx={{ paddingTop: { xs: 1, md: 2 }, paddingRight: { xs: 1, md: 2 } }} className="text-right absolute top-0 right-0">
                    <IconButton onClick={onClose} className="md:!bg-black md:!bg-opacity-30">
                        <CloseIcon className="text-red-400" />
                    </IconButton>
                </Box>
            </>
        </MuiModal>
    )
}
