import { Box, Grid, Divider, IconButton, Button } from '@mui/material';
import { Adjust, PermIdentity, Phone, Place, DocumentScanner, TurnedInNot } from '@mui/icons-material';
import React, { useEffect, useState } from 'react'
import Text from 'components/Text';
import { useDispatch, useSelector } from 'react-redux';
import useAxios from 'hooks/useAxios';
import BarcodeScanner from 'components/barcodeScanner';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import { setInfo } from 'slices/infoSlice';

const Divide = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: center;
	padding: 8px;
`
const Container = styled.div`
	display: flex;
	align-items: flex-end;
`
export default function TrackOrder() {
	const navigate = useNavigate()
	const dispatch = useDispatch()
	const userId = useSelector(state => state.user.id)
	const info = useSelector(state => state.info.ctx)
	const { get } = useAxios()
	const [allOutStandingOrder, setAllOutStandingOrder] = useState([])
	const [isScanning, setIsScanning] = useState(false);

	useEffect(() => {
		if (!userId) {
			navigate("/driver-portal")
		}
	}, [])

	const fetchData = async () => {
		const result = await get("/trackOrder/getTrackOrderByDriver", { id: userId, jobNo: info.jobNo ? String(info.jobNo) : null, refNo: info.refNo ? String(info.refNo) : null })
		if (result.status === 200) {
			const data = result.data.data.records || []
			setAllOutStandingOrder(data)

		}
	}

	useEffect(() => {
		if (info.jobNo || info.refNo) {
			fetchData()
		}
	}, [info.jobNo, info.refNo])

	const showBarcodeScanner = () => {
		setIsScanning(!isScanning);
	}

	const handleTrack = () => {
		dispatch(setInfo({ jobNo: info.jobId, refNo: info.refno }))
		fetchData();
	}

	return (
		<Box className="!p-4 " >
			<BarcodeScanner id={"jobId"} setToggle={setIsScanning} toggle={isScanning}></BarcodeScanner>
			<BarcodeScanner id={"refno"} setToggle={setIsScanning} toggle={isScanning}></BarcodeScanner>
			<Divide>
				<Container>
					<Text
						id="jobId"
						label="เลขที่ใบคุม"
						size="small">
					</Text>
				</Container>
				<Container>
					<IconButton onClick={showBarcodeScanner}>
						<DocumentScanner />
					</IconButton>
				</Container>
			</Divide>
			<Divide>
				<Container>
					<Text
						id="refno"
						label="Ref 1 Or Ref 2"
						size="small">
					</Text>
				</Container>
				<Container>
					<IconButton onClick={showBarcodeScanner}>
						<DocumentScanner />
					</IconButton>
				</Container>
			</Divide>
			<div className="py-2">
				<Button variant="contained" className="!bg-bpTheme-buttonSubmit w-full driver_button" onClick={handleTrack}>ค้นหา</Button>
			</div>
			{allOutStandingOrder.length > 0 ? allOutStandingOrder?.map((data, i) =>
				<div className='mt-3' key={"trackOrder" + i}>
					<Grid container spacing={1} columns={12} alignItems="center" className="text-lg ">
						<Grid item xs={6}>
							<div className=" font-bold !h-full" ></div>
							<div className="flex items-center justify-start font-bold " >
								<TurnedInNot className="!h-5 text-orange-400" />
								เลขที่ใบคุม
							</div>
							<div className="font-bold text-orange-400 pl-6" >{data.jobNo}</div>
						</Grid>
						<Grid item xs={6}>
							<div className=" font-bold !h-full" >สถานะ</div>
							<div className="font-bold text-gray-400" >{data.jobStatus}</div>
						</Grid>
					</Grid>
					<Divider variant="contained" className="my-2"></Divider>
					<Grid container spacing={1} columns={12} alignItems="center" className="text-lg">
						<Grid item xs={6}>
							<div className=" font-bold !h-full" ></div>
							<div className="flex items-center justify-start font-bold " >
								<TurnedInNot className="!h-5 text-orange-400" />
								Ref 1
							</div>
							<div className="font-bold text-orange-400 pl-6" >{data.ref1 ? data.ref1 : "-"}</div>
						</Grid>
						<Grid item xs={6}>
							<div className=" font-bold !h-full" >สถานะ</div>
							<div className="font-bold text-gray-400" >{data.orderStatus}</div>
						</Grid>
					</Grid>
					<Grid container spacing={1} columns={12} alignItems="center" className="text-lg">
						<Grid item xs={6}>
							<div className=" font-bold !h-full" ></div>
							<div className="flex items-center justify-start font-bold " >
								<TurnedInNot className="!h-5 text-orange-400" />
								Ref 2
							</div>
							<div className="font-bold text-orange-400 pl-6" >{data.ref2 ? data.ref2 : "-"}</div>
						</Grid>
						<Grid item xs={6}>
							<div className=" font-bold !h-full" >เลขที่ใบจอง</div>
							<div className="font-bold text-gray-400" >{data.orderNo}</div>
						</Grid>
					</Grid>
					{data.pickupItems.length > 0 ? data.pickupItems.map((pickupItems, j) => (
						<div key={"trackOrderPickup-" + j} className="my-2 mr-2 ">
							<Divider variant="contained" className="my-2"></Divider>
							<Grid container spacing={1} columns={12} className="pt-1" alignItems="center">
								<Grid item xs={2.5} className="self-start text-center">
									<div className=" font-bold !h-full" >รับ</div>
									<Adjust className="text-orange-400" />
									<div className="text-orange-400">⋮</div>
								</Grid>
								<Grid item xs={9.5} className="font-bold">
									<div className="text-lg text-orange-400">{pickupItems.senderName}</div>
									<div className="driver_address" >
										{pickupItems.pickFromAddress}
									</div>
									<Divider variant="contained" className="my-2"></Divider>
									<div className="flex flex-row text-gray-400 text-base w-full">
										<div className="flex flex-row w-[55%]">
											<div><PermIdentity className="mr-1 !h-5 text-orange-400" /></div>
											<div>{pickupItems.pickupContactName}</div>
										</div>
										<div className=" w-[45%]">
											<Phone className="mr-1 !h-5 text-orange-400" />
											<span>{pickupItems.pickupContactTel}</span>
										</div>
									</div>
								</Grid>
							</Grid>
							<Grid container spacing={0.5} columns={12} alignItems="center" className="driver_grid_base">
								<Grid item xs={2.5} className="driver_head">
									<div>รับสินค้า</div>
								</Grid>
								<Grid item xs={9.5} className="driver_body">
									<div className="" >{pickupItems.quantity <= 0 ? 'ดูหน้างาน' : pickupItems.quantity}</div>
								</Grid>
							</Grid>
							<Grid container spacing={0.5} columns={12} alignItems="center" className="driver_grid_base">
								<Grid item xs={2.5} className="driver_head">
									<div>หมายเหตุ</div>
								</Grid>
								<Grid item xs={9.5} className="driver_body">
									<div className="" >{pickupItems.remark || '-'}</div>
								</Grid>
							</Grid>
						</div>
					)) : <p>No data for this entry</p>}
					{data.shippingItems.length > 0 ? data.shippingItems.map((shippingItems, j) => (
						<div key={"trackOrderShipping-" + j} className="my-2 mr-2 ">
							<Divider variant="contained" className="my-2"></Divider>
							<Grid container spacing={1} columns={12} className="pt-2" alignItems="center">
								<Grid item xs={2.5} className="self-start text-center">
									<div className=" font-bold !h-full" >ส่ง</div>
									<div className="text-orange-400">⋮</div>
									<Place className="text-orange-400" />
								</Grid>
								<Grid item xs={9.5} className="font-bold">
									<div className="text-lg text-orange-400">{shippingItems.receiverName}</div>
									<div className="driver_address" >
										{shippingItems.shipToAddress}
									</div>
									<Divider variant="contained" className="my-2"></Divider>
									<div className="flex flex-row text-gray-400 text-base w-full">
										<div className="flex flex-row w-[55%]">
											<div><PermIdentity className="mr-1 !h-5 text-orange-400" /></div>
											<div>{shippingItems.shippingContactName}</div>
										</div>
										<div className="w-[45%]">
											<Phone className="mr-1 !h-5 text-orange-400" />
											<span>{shippingItems.shippingContactTel}</span>
										</div>
									</div>
								</Grid>
							</Grid>
							<Grid container spacing={0.5} columns={12} alignItems="center" className="driver_grid_base">
								<Grid item xs={2.5} className="driver_head">
									<div>ส่งสินค้า</div>
								</Grid>
								<Grid item xs={9.5} className="driver_body">
									<div className="" >{shippingItems.quantity <= 0 ? 'ดูหน้างาน' : shippingItems.quantity}</div>
								</Grid>
							</Grid>
							<Grid container spacing={0.5} columns={12} alignItems="center" className="driver_grid_base">
								<Grid item xs={2.5} className="driver_head">
									<div>หมายเหตุ</div>
								</Grid>
								<Grid item xs={9.5} className="driver_body">
									<div className="" >{shippingItems.remark || '-'}</div>
								</Grid>
							</Grid>
						</div>
					)) : <p>No data for this entry</p>}
					<Divider variant="contained" className="driver_divider"></Divider>
				</div>
			) : <div className="driver_no_data">ไม่พบข้อมูลที่ค้นหา</div>}
		</Box>
	)
}