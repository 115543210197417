import { Button, DialogActions, DialogContent, DialogContentText, DialogTitle, Dialog as MuiDialog } from '@mui/material'
import React, { useCallback } from 'react'
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';

export default function Dialog({ open, onClose, title, content, onSubmit, onCancel, cancelLabel = "ยกเลิก", submitLabel = "ยืนยัน", hideSubmit, hideCancel }) {
    const theme = useTheme();
    // const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const handleSubmit = useCallback(() => {
        if (onSubmit)
            onSubmit()
        onClose()
    }, [onSubmit])
    const handleCancel = useCallback(() => {
        if (onCancel)
            onCancel()
        onClose()
    }, [onCancel])
    return (
        <MuiDialog
            // fullScreen={false}
            open={open}
            onClose={onClose}
        >
            <DialogTitle id="responsive-dialog-title">
                {title}
            </DialogTitle>
            <DialogContent>
                <DialogContentText>
                    {content}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                {
                    !hideCancel ?
                        (<Button autoFocus onClick={handleCancel}>
                            {cancelLabel}
                        </Button>) : null
                }
                {
                    !hideSubmit ?
                        (<Button autoFocus onClick={handleSubmit}>
                            {submitLabel}
                        </Button>) : null
                }
            </DialogActions>
        </MuiDialog>
    )
}
