import { Box, Grid, Button, Divider } from '@mui/material';
import React, { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import useAxios from 'hooks/useAxios';
import { setInfo, clearInfo } from 'slices/infoSlice';
import Number from 'components/Number';
import { normalizeData } from 'utilities/validator';
import { memorizedContextInfo } from 'redux/memorize';
import usePopup from 'hooks/usePopup';
import { warpUpFailResponse } from 'utilities/utils';
import { useSnackbar } from 'notistack';
import { cloneDeep } from 'lodash';
import AutoComplete from 'components/AutoComplete';

export default function AdditionalPriceDriver({ setPage }) {
    const dispatch = useDispatch()
    const info = useSelector(memorizedContextInfo)
    const { multiple, post } = useAxios()
    const { confirmPopup } = usePopup();
    const { enqueueSnackbar } = useSnackbar();
    const [allAdditionalPrice, setAllAdditionalPrice] = useState([])

    const fetchData = async controller => {
        const result = await multiple([
            { method: "get", url: "/additionalPrice/getAllActiveAdditionalPrice", params: { type: "job,driver" }, config: { signal: controller.signal } },
        ])
        if (result[0].status === 200) {
            const data = result[0].data.data.records || []
            setAllAdditionalPrice(data.map(data => ({ label: data.name, value: data.id })) || [])
            let additionalPrice = info.selectedJob.additionalPrice?.map(e => ({ ...e, name: data.find(_e => e.addPriceId === _e.id)?.name }))
            dispatch(setInfo({ additionalPrice }))
        }
    }

    useEffect(() => {
        const controller = new AbortController();
        fetchData(controller)
        return () => {
            controller.abort()
        }
    }, [])

    const goBack = () => {
        dispatch(setInfo({ checkList: [] }))
        setPage("initial")
    }

    const add = () => {
        const newArr = cloneDeep(info.additionalPrice)
        newArr.push({})
        dispatch(setInfo({ additionalPrice: newArr }))
    }

    const remove = () => {
        const newArr = cloneDeep(info.additionalPrice)
        newArr.pop()
        dispatch(setInfo({ additionalPrice: newArr }))
    }

    const saveData = useCallback(() => {
        if (info.additionalPrice?.some(e => !e.addPriceId)) {
            enqueueSnackbar("กรุณากรอกข้อมูลให้ครบ", {
                variant: "error", anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'center',
                }
            })
            return
        }
        confirmPopup({
            onSubmit: async () => {
                const data = normalizeData(info)
                let response = await post("/job/updateAdditionalPriceJob", { id: info.selectedJob.jobId, additionalPriceDelivery: data?.additionalPrice, isReview: true })
                if (response.status === 200) {
                    dispatch(clearInfo())
                    enqueueSnackbar('บันทึกสำเร็จ', {
                        variant: "success", anchorOrigin: {
                            vertical: 'top',
                            horizontal: 'center',
                        }
                    })
                    setPage("initial")
                } else {
                    enqueueSnackbar(warpUpFailResponse(response, 'บันทึกไม่สำเร็จ'), {
                        variant: "error", anchorOrigin: {
                            vertical: 'top',
                            horizontal: 'center',
                        }
                    })
                }
            },
            onCancel: () => {
                enqueueSnackbar('ยกเลิกรายการ', {
                    variant: "info", anchorOrigin: {
                        vertical: 'top',
                        horizontal: 'center',
                    }
                })
            }
        })
    }, [info])

    return (
        <Box>
            <div className="mt-1 !text-lg font-bold text-center">
                ค่าใช้จ่ายเพิ่มเติม
            </div>
            <Grid container columns={12} alignItems="center" className="text-base bg-gray-500 text-white px-2">
                <Grid item xs={7}>
                    หมายเลขงาน:
                    <span className="ml-1" >{info.selectedJob?.no}</span>
                </Grid>
            </Grid>
            <div className='py-4 px-6'>
                {info.additionalPrice?.length ? info.additionalPrice.map((data, i) =>
                    <div key={"additionalPriceDelivery" + i} className="flex justify-between py-2 text-left gap-2">
                        <div className='w-[40%] self-center whitespace-nowrap'>{
                            !data.id ? (
                                <AutoComplete
                                    id={"addPriceId"}
                                    parentId={`@additionalPrice@${i}`}
                                    label={"ค่าใช้จ่ายเพิ่มเติม"}
                                    options={allAdditionalPrice}
                                    filterOptions={e => info.additionalPrice?.every(_e => _e.addPriceId !== e.value)}
                                />
                            ) : data.name
                        }</div>
                        <div className='w-[30%]'>
                            <Number
                                id="draftAmount"
                                parentId={`@additionalPrice@${i}`}
                                label="เงินเบิก"
                                readOnly={true}
                            >
                            </Number>
                        </div>
                        <div className='w-[30%]'>
                            <Number
                                id="usedAmount"
                                parentId={`@additionalPrice@${i}`}
                                label="เงินจ่ายจริง">
                            </Number>
                        </div>
                    </div>
                ) : <div className="driver_no_data">ไม่มีการตั้งค่าข้อมูลค่าใช้จ่ายเพิ่มเติม</div>}
                <Box className="flex justify-between gap-2" >
                    <Button variant="contained" className="w-[20px]" onClick={add}>+</Button>
                    {info.additionalPrice?.length > info.selectedJob?.additionalPrice?.length ? <Button variant="contained" className="w-[20px]" onClick={remove}>-</Button> : null}
                </Box>
            </div>
            <Divider variant="contained" className="driver_divider"></Divider>
            <Box className="flex justify-between py-2 px-1 text-center gap-2" >
                <Button variant="contained" className="grow" onClick={goBack}>ย้อนกลับ</Button>
                <Button variant="contained" className="!bg-bpTheme-buttonSubmit grow" onClick={saveData}>บันทึกข้อมูล</Button>
            </Box>
        </Box>
    )
}
