import React, { useCallback, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Modal from 'components/Modal'
import Form from 'components/Form'
import { clearInfoById, setInfo, setInfoById, deleteById } from 'slices/infoSlice'
import useAxios from 'hooks/useAxios'
import { v4 as uuidv4 } from 'uuid'
import { validation } from 'utilities/validator'
import { createSelector } from 'reselect'
import { format } from 'date-fns'

const checkListSelector = createSelector(
    (state) => state.info?.checkListGroup?.checkList || [],
    (checkList) => checkList
);

export default function CheckList({ mode }) {
    const dispatch = useDispatch()
    const info = useSelector(state => state.info)
    const allCheckList = useSelector(checkListSelector)
    const { multiple } = useAxios()

    const fetchData = async controller => {
        const result = await multiple([
            { method: "get", url: "/checkListMaster/getAllActiveCheckListMaster", config: { signal: controller.signal } }
        ])
        if (result[0].status === 200) {
            let checkList = result[0].data.data.records.map(data => ({ name: data.name, checkListId: data.id, status: null })) || []
            if (mode[0] === "edit") {
                checkList = checkList.map(e => ({ ...e, ...(allCheckList.find(_e => _e.name === e.name) || { status: null }) }))
            }
            dispatch(setInfoById({ id: "checkListGroup", payload: { checkList } }))
        }
    }

    useEffect(() => {
        const controller = new AbortController()
        fetchData(controller)
        return () => {
            controller.abort()
        }
    }, [])

    const onCloseModal = useCallback(() => {
        dispatch(deleteById("checkListGroup"))
        mode[1]("initial")
    }, [])

    const saveData = useCallback(() => {
        if (validation(info.checkListGroup, "checkListModal")) {
            const checkLists = info.ctx.checkListHistory || []
            if (mode[0] === "create") {
                dispatch(setInfo({
                    checkListHistory: [...checkLists, {
                        ...info.checkListGroup,
                        id: uuidv4(),
                        checkDateTime: format(new Date(), 'yyyy-MM-dd HH:mm:ss'),
                        recStatus: "new",
                        status: "New"
                    }]
                }))
            } else {
                const newCheckList = checkLists.map(checkList => checkList.id === info.checkListGroup?.id ? {
                    ...info.checkListGroup,
                } : checkList);
                dispatch(setInfo({ checkListHistory: newCheckList }))
            }
            dispatch(clearInfoById("checkListGroup"))
            mode[1]("initial")
        }

    }, [info])

    const inputForm = [
        ...(allCheckList ? allCheckList.map((e, i) => (
            {
                rowData: [
                    {
                        type: "display",
                        props: {
                            label: "ชื่อรายการ",
                            parentId: `@checkListGroup@checkList@${i}`,
                            id: "name"
                        }
                    },
                    {
                        type: "dropdown",
                        props: {
                            label: "สถานะ",
                            parentId: `@checkListGroup@checkList@${i}`,
                            id: "status",
                            options: [
                                { label: "ใช้งาน", value: "active" },
                                { label: "ส่งซ่อม", value: "maintain" },
                                { label: "เสียหาย", value: "damage" }
                            ]
                        }
                    },
                ]
            }
        )) : []),
        {
            noCol: true,
            align: "center",
            rowData: [
                {
                    type: "button",
                    props: {
                        label: "ตกลง",
                        onClick: saveData,
                    }
                }
            ]
        }
    ]

    return (
        <>
            <Modal id="checkListModal" open={mode[0] === "create" || mode[0] === "edit"} onClose={onCloseModal} className="form-modal" contentClassName="min-w-full md:!min-w-[750px]">
                <Form id={`checkListGroup`} title={"ข้อมูลรายการตรวจบำรุง"} name="form" data={inputForm}></Form>
            </Modal>
        </>
    )
}
