import { Box } from '@mui/material'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Panel from 'components/Panel'
import Modal from 'components/Modal'
import Form from 'components/Form'
import { useSnackbar } from 'notistack'
import { setInfo, clearInfo } from 'slices/infoSlice'
import { normalizeData } from 'utilities/validator'
import usePopup from 'hooks/usePopup'
import useAxios from 'hooks/useAxios'
import Address from 'pages/address'
import { warpUpFailResponse } from 'utilities/utils'
import useDataHandler from 'hooks/useDataHandler'

export default function Vendor() {
	const dispatch = useDispatch()
	const { enqueueSnackbar } = useSnackbar();
	const { post, del, multiple, get } = useAxios();
	const { save } = useDataHandler()
	const info = useSelector(state => state.info.ctx || {})
	const [mode, setMode] = useState("initial")
	const { confirmPopup } = usePopup();
	const [allVendor, setAllVendor] = useState([]);
	const [allAddress, setAllAddress] = useState([])
	const [provinceRes, setProvinceRes] = useState([])
	const roleUser = useSelector(state => state.user.roleName)

	const fetchData = async controller => {
		const result = await multiple([
			{ method: "get", url: "/vendor/getAllVendor", config: { signal: controller.signal } },
			{ method: "get", url: "/province/getAllProvince", config: { signal: controller.signal } },
		])
		if (result[0].status === 200) {
			const vendors = result[0].data.data || []
			setAllVendor(vendors)
		}
		if (result[1].status === 200) {
			const data = result[1].data.data
			setProvinceRes(data.records)
		}
	}

	useEffect(() => {
		const controller = new AbortController();
		fetchData(controller)
		return () => {
			controller.abort()
		}
	}, [])

	useEffect(() => {
		if (info.address) {
			setAllAddress(info?.address.map(e => ({ label: `${e.addressTH || "-"} ${e.fullAddress || "-"}`, value: e.id || e.itemId, data: e })))
			if (info?.address.some(e => e.defaultAD)) {
				dispatch(setInfo({
					locationId: info?.address.filter(e => e?.billing && e?.defaultAD)[0]?.id || ""
				}))
			}
		}
	}, [info.address])

	useEffect(() => {
		if (info.provinceId && provinceRes) {
			const { province, amphoe, district, zipcode } = provinceRes.find(e => info.provinceId === e.id)
			dispatch(setInfo({ province, amphoe, district, zipcode }))
		} else {
			dispatch(setInfo({ province: null, amphoe: null, district: null, zipcode: null }))
		}

	}, [info.provinceId])

	const onCloseModal = useCallback(() => {
		confirmPopup({
			title: "แจ้งเตือน",
			content: "ท่านต้องการยกเลิกการแก้ไขหรือไม่",
			onSubmit: () => {
				setMode("initial")
			}
		})
	}, [])

	const inputForm = [
		{
			rowData: [
				{
					show: true,
					type: "text",
					required: true,
					props: {
						id: "name",
						label: "ชื่อ",
						variant: "outlined"
					}
				},
				{
					type: "autocomplete",
					required: true,
					props: {
						id: "userType",
						label: "ประเภทผู้เสียภาษี",
						options: [
							{ label: "บุคคลธรรมดา", value: "person" },
							{ label: "นิติบุคคล", value: "company" },
						]
					}
				},
				{
					type: "text",
					required: true,
					props: {
						id: "creditTerm",
						label: "เลขประจำตัวผู้เสียภาษี",
						variant: "outlined",
						maxCharacters: 13
					}
				},
			]
		}, {
			isCollapse: true,
			label: "ที่อยู่",
			defaultCollapse: true,
			collapseData: [
				{
					rowData: [
						{
							type: "custom",
							component: <Address typeId={"vendor"} id={info.id} saveOnBase={false} />
						}
					],
				}, {
					rowData: [
						{
							type: "divider",
							props: {
								label: "ตั้งค่าที่อยู่เริ่มต้น",
								variant: "outlined",
							}
						}
					]
				}, {
					rowData: [
						{
							type: "autocomplete",
							required: true,
							props: {
								id: "locationId",
								label: "ที่อยู่สำหรับวางบิล",
								allowDuplicate: true,
								options: allAddress,
								filterOptions: (e) => e.data?.billing,
							}
						}
					]
				}
			]
		}, {
			rowData: [
				{
					type: "text",
					validation: 'tel',
					props: {
						id: "tel",
						label: "เบอร์โทร",
						variant: "outlined"
					}
				},
				{
					type: "text",
					props: {
						id: "email",
						label: "อีเมลล์",
						variant: "outlined"
					}
				},
				{
					type: "text",
					props: {
						id: "website",
						label: "เว็บไซต์",
						variant: "outlined"
					}
				}
			]
		}, {
			rowData: [
				mode === "edit" && {
					type: "dropdown",
					props: {
						id: "active",
						label: "สถานะ",
						options: [
							{ label: "ใช้งาน", value: true },
							{ label: "ไม่ใช้งาน", value: false }
						],
					}
				}, {}, {}
			]
		}, {
			rowData: [
				{
					type: "button",
					props: {
						label: "บันทึก",
						onClick: () => {
							saveData()
						},
						className: "!bg-bpTheme-buttonSubmit"
					}
				}
			]
		}
	]
	const table = useMemo(() => [
		{
			rowData: [{
				type: "dataTable",
				props: {
					columns: [
						{
							id: 'no',
							label: 'เลขที่บริษัท',
							fields: ["no"]
						},
						{
							id: 'name',
							label: 'ชื่อ',
							fields: ["name"]
						},
						{
							id: 'active',
							label: 'สถานะ',
							fields: ["active"],
							valuesMap: [{ label: "ใช้งาน", value: true }, { label: "ไม่ใช้งาน", value: false }]

						}
					],
					rows: allVendor,
					size: "small",
					showDeleteButton: roleUser === "Owner",
					title: "ข้อมูลบริษัทรถร่วม",
					showSystemFields: true,
					onClick: (e) => {
						setMode("edit")
						dispatch(setInfo(e))
					},
					onDelete: (e, onSuccess) => {
						confirmPopup({
							onSubmit: async () => {
								const result = await del("/vendor/deleteVendor", { ids: e.map(data => data.id).join(',') })
								if (result.status === 200) {
									enqueueSnackbar('ลบสำเร็จ', { variant: "success" })
									dispatch(clearInfo())
									await fetchData(new AbortController())
									onSuccess()
								} else {
									enqueueSnackbar('ลบไม่สำเร็จ', { variant: "error" })
								}
							}
						})
					},
					controller: (paging) => get("/vendor/getAllVendor", { paging }),
					searchFields: [
						{ label: "ชื่อ", value: "name" },
						{ label: "สถานะ", value: "active" }
					]
				}
			}]
		}, {
			noCol: true,
			rowData: [
				{
					type: "button",
					props: {
						label: "เพิ่มใหม่",
						onClick: () => {
							setMode("create")
							dispatch(clearInfo())
						}
					}
				},
			]
		}
	], [allVendor])

	const saveData = useCallback(() => {
		save({
			data: info,
			onSubmit: async () => {
				const data = normalizeData(info)
				let response
				if (mode === "create") {
					response = await post("/vendor/createVendor", { ...data, active: true, address: data?.address.map(item => ({ ...item, id: item.tempId ? null : item.id })) })
				} else if (mode === "edit") {
					response = await post("/vendor/updateVendor", { ...data, address: data?.address.map(item => ({ ...item, id: item.tempId ? null : item.id })) })
				}
				if (response.status === 200) {
					setMode("initial")
					enqueueSnackbar('บันทึกสำเร็จ', { variant: "success" })
					fetchData(new AbortController())
				} else {
					enqueueSnackbar(warpUpFailResponse(response, 'บันทึกไม่สำเร็จ'), { variant: "error" })
				}
			},
			onCancel: () => {
				enqueueSnackbar('ยกเลิกรายการ', { variant: "info" })
			}
		})
	}, [info, mode])

	return (
		<Box>
			<Panel data={table}></Panel>
			<Modal open={mode === "create" || mode === "edit"} onClose={onCloseModal} className="form-modal" >
				<Form title={"บริษัทรถร่วม " + (info.no || "")} name="user-form" data={inputForm}></Form>
			</Modal>
		</Box>
	)
}