import { Box, Grid, Button, Divider } from '@mui/material';
import React, { useCallback, useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import useAxios from 'hooks/useAxios';
import { setInfo, clearInfo } from 'slices/infoSlice';
import Number from 'components/Number';
import { normalizeData } from 'utilities/validator';
import { memorizedContextInfo } from 'redux/memorize';
import usePopup from 'hooks/usePopup';
import { warpUpFailResponse, resizeImage, dataURLtoFile } from 'utilities/utils';
import { useSnackbar } from 'notistack';
import AutoComplete from 'components/AutoComplete';
import Display from 'components/Display';
import { CameraAlt, InsertPhoto } from '@mui/icons-material';
import { showLoading, hideLoading } from 'slices/loadingSlice';
import { MAX_FILE_SIZE } from 'helper/DataFactory';
import Modal from 'components/Modal';
import styled from 'styled-components';

const TextFormat = styled.div`
		font-family: "Roboto","Helvetica","Arial",sans-serif;
`
export default function OilPriceDetail({ setPage }) {
    const dispatch = useDispatch()
    const info = useSelector(memorizedContextInfo)
    const { multiple, post, upload } = useAxios()
    const { confirmPopup } = usePopup();
    const { enqueueSnackbar } = useSnackbar();
    const [oilType, setOilType] = useState([])
    const inputRefOilType = useRef(null);
    const inputRefOilType2 = useRef(null);
    const inputRefGasType = useRef(null);
    const [mode, setMode] = useState("initial")

    const fetchData = async controller => {
        const result = await multiple([
            { method: "get", url: "/oilRate/getTodayOilRate", config: { signal: controller.signal } },
        ])
        if (result[0].status === 200) {
            const data = result[0].data.data.records || []
            setOilType(data.map(e => ({ label: e.oilType, value: e.oilType, data: e })))
        }
    }

    useEffect(() => {
        const controller = new AbortController();
        dispatch(setInfo({ mileage: null, oilPrice: null, oilPrice2: null, gasPrice: null, oilType: null, oilType2: null, gasType: null }))
        fetchData(controller)
        return () => {
            controller.abort()
        }
    }, [])

    useEffect(() => {
        if (info.oilPrice && info.oilType) {
            const selected = oilType.find(e => e.value === info.oilType)
            dispatch(setInfo({ oilRate: selected?.data.oilPrice, oilLite: info.oilPrice / selected?.data.oilPrice }))
        } else {
            dispatch(setInfo({ oilRate: null, oilLite: null }))
        }
    }, [info.oilPrice, info.oilType])

    useEffect(() => {
        if (info.oilPrice2 && info.oilType2) {
            const selected = oilType.find(e => e.value === info.oilType2)
            dispatch(setInfo({ oilRate2: selected?.data.oilPrice, oilLite2: info.oilPrice2 / selected?.data.oilPrice }))
        } else {
            dispatch(setInfo({ oilRate2: null, oilLite2: null }))
        }
    }, [info.oilPrice2, info.oilType2])

    useEffect(() => {
        if (info.gasPrice && info.gasType) {
            const selected = oilType.find(e => e.value === info.gasType)
            dispatch(setInfo({ gasRate: selected?.data.oilPrice, gasLite: info.gasPrice / selected?.data.oilPrice }))
        } else {
            dispatch(setInfo({ gasRate: null, gasLite: null }))
        }
    }, [info.gasPrice, info.gasType])

    const goBack = () => {
        setPage("initial")
    }

    const showCamera = (refNameType) => {
        if (refNameType === "oilType") {
            inputRefOilType.current.value = null;
            inputRefOilType.current.click();
        } else if (refNameType === "oilType2") {
            inputRefOilType2.current.value = null;
            inputRefOilType2.current.click();
        } else if (refNameType === "gasType") {
            inputRefGasType.current.value = null;
            inputRefGasType.current.click();
        }
    }

    const showImage = (nameType) => {
        setMode(nameType)
    }

    const onCloseModal = useCallback(() => {
        setMode("initial")
    }, [])

    const handleFileChange = async (e, nameType) => {
        const files = e.target.files || [];
        const filesArray = Array.from(files);

        dispatch(showLoading())
        try {
            const resizeImgs = await Promise.all(filesArray.map(file => resizeImage(file, MAX_FILE_SIZE)));
            const imgs = await Promise.all(resizeImgs.map(file => new Promise((resolve, reject) => {
                const reader = new FileReader();
                reader.onload = (event) => {
                    const dataUrl = event.target.result;
                    const fileName = file.name;
                    resolve({ dataUrl, fileName });
                };
                reader.readAsDataURL(file);
            })));
            dispatch(hideLoading())

            dispatch(setInfo({ [nameType]: [...imgs] }));
        } catch (e) {
            let currentTime = new Date().toLocaleString();
            alert("ระบบขัดข้อง: " + e + " at " + currentTime);
        } finally {
            dispatch(hideLoading())
        }
    };

    const loadFile = async (dataFile) => {
        const imageUrl = dataFile || [];
        const imgs = imageUrl ? await Promise.all(imageUrl.map(img => {
            if (typeof img.dataUrl === 'string' && img.dataUrl.startsWith('data:')) {
                const fileImg = dataURLtoFile(img.dataUrl, img.fileName);
                return resizeImage(fileImg, MAX_FILE_SIZE);
            }
        })) : [];

        const result = await Promise.all(imgs.map(e => upload({ prefix: `driver/oilPrice`, file: e })))

        let images = ""
        if (result.every(e => e.status === 200)) {
            images = result[0]?.data?.data;
        }
        return images;
    }

    const saveData = useCallback(() => {
        if (!info.mileage || (info.mileage &&
            !(info.oilPrice && info.oilType) &&
            !(info.oilPrice2 && info.oilType2) &&
            !(info.gasPrice && info.gasType))) {
            enqueueSnackbar("กรุณากรอกข้อมูลให้ครบ", {
                variant: "error", anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'center',
                }
            })
            return
        }
        confirmPopup({
            onSubmit: async () => {
                const { selectedJob, ...data } = normalizeData(info)

                dispatch(showLoading())
                const oilPriceImg = await loadFile(info.imageUrlOilType);
                const oilPriceImg2 = await loadFile(info.imageUrlOilType2);
                const gasPriceImg = await loadFile(info.imageUrlGasType);
                dispatch(hideLoading())

                let response = await post("/fuelLog/createFuelLog", {
                    jobId: info.selectedJob.jobId, vehicleId: info.selectedJob.vehicleId, ...data,
                    oilPriceImg, oilPriceImg2, gasPriceImg
                })
                if (response.status === 200) {
                    dispatch(clearInfo())
                    enqueueSnackbar('บันทึกสำเร็จ', {
                        variant: "success", anchorOrigin: {
                            vertical: 'top',
                            horizontal: 'center',
                        }
                    })
                    dispatch(setInfo({ imageUrlOilType: [], imageUrlOilType2: [], imageUrlGasType: [] }));
                    setPage("initial")
                } else {
                    enqueueSnackbar(warpUpFailResponse(response, 'บันทึกไม่สำเร็จ'), {
                        variant: "error", anchorOrigin: {
                            vertical: 'top',
                            horizontal: 'center',
                        }
                    })
                }
            },
            onCancel: () => {
                enqueueSnackbar('ยกเลิกรายการ', {
                    variant: "info", anchorOrigin: {
                        vertical: 'top',
                        horizontal: 'center',
                    }
                })
            }
        })
    }, [info])

    return (
        <>
            <Box>
                <div className="mt-1 !text-lg font-bold text-center">
                </div>
                <Grid container columns={12} alignItems="center" className="text-base bg-gray-500 text-white px-2">
                    <Grid item xs={7}>
                        <TextFormat>ทะเบียนรถ: {info.selectedJob?.truckLicense}</TextFormat>
                    </Grid>
                </Grid>
                <Box className="py-2 pl-2">
                    <table className="table-auto w-full">
                        <tbody>
                            <tr>
                                <td colspan="2" className="py-2">
                                    <Number id="mileage" label="เลขไมล์ปัจจุบัน" />
                                </td>
                            </tr>
                            <tr>
                                <td colspan="2" className="p-2">
                                    <TextFormat>ค่าน้ำมัน</TextFormat>
                                </td>
                            </tr>
                            <tr>
                                <td className="py-2">
                                    <Number id="oilPrice" label="ราคา" />
                                </td>
                                <td className="py-2 pl-1">
                                    <AutoComplete id="oilType" className="min-w-[200px]" label="ประเภทน้ำมัน" options={oilType} />
                                </td>
                                <td>
                                    <div className="flex justify-around w-[70px]">
                                        <CameraAlt className="text-orange-400 cursor-pointer" onClick={() => showCamera("oilType")} />
                                        <input ref={inputRefOilType} type="file" accept="image/*" onChange={(e) => handleFileChange(e, "imageUrlOilType")} hidden />
                                        {(info.imageUrlOilType && info.imageUrlOilType?.length > 0) && <InsertPhoto className="text-orange-400 cursor-pointer" onClick={() => showImage("imageUrlOilType")} />}
                                    </div>
                                </td>
                            </tr>
                            {
                                info.oilRate ?
                                    <tr>
                                        <td className="py-2">
                                            <Display id="oilRate" label="ราคาต่อลิตร" type="number" />
                                        </td>
                                        <td className="py-2">
                                            <Display id="oilLite" label="จำนวนลิตร" type="number" />
                                        </td>
                                        <td></td>
                                    </tr> : null
                            }
                            <tr>
                                <td colspan="2" className="p-2">
                                    <TextFormat>ค่าน้ำมันแอร์</TextFormat>
                                </td>
                            </tr>
                            <tr>
                                <td className="py-2">
                                    <Number id="oilPrice2" label="ราคา" />
                                </td>
                                <td className="py-2 pl-1">
                                    <AutoComplete id="oilType2" className="min-w-[200px]" label="ประเภทน้ำมัน" options={oilType} />
                                </td>
                                <td>
                                    <div className="flex justify-around w-[70px]">
                                        <CameraAlt className="text-orange-400 cursor-pointer" onClick={() => showCamera("oilType2")} />
                                        <input ref={inputRefOilType2} type="file" accept="image/*" onChange={(e) => handleFileChange(e, "imageUrlOilType2")} hidden />
                                        {(info.imageUrlOilType2 && info.imageUrlOilType2?.length > 0) && <InsertPhoto className="text-orange-400 cursor-pointer" onClick={() => showImage("imageUrlOilType2")} />}
                                    </div>
                                </td>
                            </tr>
                            {
                                info.oilRate2 ?
                                    <tr>
                                        <td className="py-2">
                                            <Display id="oilRate2" label="ราคาต่อลิตร" type="number" />
                                        </td>
                                        <td className="py-2">
                                            <Display id="oilLite2" label="จำนวนลิตร" type="number" />
                                        </td>
                                        <td></td>
                                    </tr> : null
                            }
                            <tr>
                                <td colspan="2" className="p-2">
                                    <TextFormat>ค่าแก๊ส</TextFormat>
                                </td>
                            </tr>
                            <tr>
                                <td className="py-2">
                                    <Number id="gasPrice" label="ราคา" />
                                </td>
                                <td className="py-2 pl-1">
                                    <AutoComplete id="gasType" className="min-w-[200px]" label="ประเภทน้ำมัน" options={oilType} />
                                </td>
                                <td>
                                    <div className="flex justify-around w-[70px]">
                                        <CameraAlt className="text-orange-400 cursor-pointer" onClick={() => showCamera("gasType")} />
                                        <input ref={inputRefGasType} type="file" accept="image/*" onChange={(e) => handleFileChange(e, "imageUrlGasType")} hidden />
                                        {(info.imageUrlGasType && info.imageUrlGasType?.length > 0) && <InsertPhoto className="text-orange-400 cursor-pointer" onClick={() => showImage("imageUrlGasType")} />}
                                    </div>
                                </td>
                            </tr>
                            {
                                info.gasRate ?
                                    <tr>
                                        <td className="py-2">
                                            <Display id="gasRate" label="ราคาต่อลิตร" type="number" />
                                        </td>
                                        <td className="py-2">
                                            <Display id="gasLite" label="จำนวนลิตร" type="number" />
                                        </td>
                                        <td></td>
                                    </tr> : null
                            }
                        </tbody>
                    </table>
                </Box>
                <Divider variant="contained" className="driver_divider"></Divider>
                <Box className="flex justify-between py-2 px-1 text-center gap-2" >
                    <Button variant="contained" className="grow" onClick={goBack}>ย้อนกลับ</Button>
                    <Button variant="contained" className="!bg-bpTheme-buttonSubmit grow" onClick={saveData}>บันทึกข้อมูล</Button>
                </Box>
            </Box>
            <Modal open={mode !== "initial"} onClose={onCloseModal} className="form-modal" contentClassName="min-w-full md:!min-w-[750px]">
                <Box className="text-center">
                    {info?.[mode]?.map((e, i) => (
                        <div key={"image" + mode + i} className="relative">
                            <img className="w-full rounded-md" src={e.dataUrl} alt="Uploaded image" />
                        </div>
                    ))}
                    <div className="mt-3 text-center" >
                        <Button variant="contained" size="small" className="w-full driver_button" onClick={onCloseModal}>ปิด</Button>
                    </div>
                </Box>
            </Modal>
        </>
    )
}
