import { ExpandLess, ExpandMore } from '@mui/icons-material'
import { Collapse, List, ListItemButton, ListItemIcon, ListItemText } from '@mui/material'
import React, { useCallback, useState } from 'react'
import { Link } from 'react-router-dom'
import { setActiveMenu } from 'slices/menuSlice'
import { useDispatch, useSelector } from 'react-redux'
import { v4 as uuidv4 } from 'uuid';
import { clearInfo } from 'slices/infoSlice'
import * as muiIcon from '@mui/icons-material'

export default function Menu(props) {
	const { menu, ...other } = props
	return (
		<List
			sx={{ width: '100%' }}
			component="div"
			{...other}
		>
			{
				menu.map((e, i) => <MenuList key={`menu-${uuidv4()}`} {...e} ></MenuList>)
			}

		</List>
	)
}

function MenuList(props) {
	const { id = uuidv4(), label, icon: _icon, menu, sx, onClick, children, path, className = "", ...other } = props
	const active = useSelector(state => state.activeMenu.active)
	const dispatch = useDispatch()
	const handleClick = (validPath) => {
		dispatch(setActiveMenu(id))
		if (!validPath) {
			dispatch(clearInfo())
		}
		if (onClick) {
			onClick()
		}
	}

	const IconComponent = muiIcon[_icon];

	return menu ? (
		<MenuCollapse {...other} id={id} label={label} icon={_icon} menu={menu}></MenuCollapse>
	) : (
		<Link to={path === null || path === undefined ? "#" : path}>
			<ListItemButton className={`${className || ""} ${active === id ? "!bg-bpTheme-activeMenu !rounded-3xl" : ""}`} sx={sx} onClick={() => handleClick(!path)}>
				{
					IconComponent ?
						<ListItemIcon>
							<IconComponent />
						</ListItemIcon> :
						null
				}
				<ListItemText primary={label} />
				{children}
			</ListItemButton>
		</Link>
	)
}

function MenuCollapse({ id, label, icon, menu, ...other }) {
	const [open, setOpen] = useState(false)
	const handleClick = useCallback(() => {
		setOpen(!open)
	}, [open])

	return (
		<>
			<MenuList id={id} label={label} icon={icon} onClick={handleClick} {...other}>
				{open ? <ExpandLess /> : <ExpandMore />}
			</MenuList>
			<Collapse in={open} timeout="auto" unmountOnExit>
				<List component="div" disablePadding>
					{
						menu.map((e) => {
							return <MenuList key={`menu-list-${e.id}`} {...e} sx={{ pl: 4 }}></MenuList>
						})
					}
				</List>
			</Collapse>
		</>
	)
}