import { Box, Typography } from '@mui/material'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Panel from 'components/Panel'
import Modal from 'components/Modal'
import Form from 'components/Form'
import { useSnackbar } from 'notistack'
import { setInfo } from 'slices/infoSlice'
import { normalizeData } from 'utilities/validator'
import usePopup from 'hooks/usePopup'
import useAxios from 'hooks/useAxios'
import AdvanceSearch from 'components/AdvanceSearch'
import { INVOICE_TAX, BILLING_COND, PAYMENT_OPTIONS, STATUS_PAYMENT_DENIED, STATUS_PAYMENT_APPROVE } from 'helper/DataFactory'
import numeral from 'numeral'
import { warpUpFailResponse } from 'utilities/utils'
import { memoizedSelectAdvanceInfo } from 'redux/memorize'

export default function PaymentApproval() {
	const dispatch = useDispatch()
	const { enqueueSnackbar } = useSnackbar();
	const info = useSelector(state => state.info.ctx)
	const { post, multiple, get } = useAxios();
	const mode = useState("initial") //main form
	const modeReason = useState("initial")
	const { confirmPopup } = usePopup();
	const [allPayment, setAllPayment] = useState([]);
	const [allAdditionalPrice, setAllAdditionalPrice] = useState([]);
	const paging = useState({})
	const searchInfo = useSelector(memoizedSelectAdvanceInfo)
	const quotationTotal = info.jobs?.reduce((a, v) => a + v?.total, 0)
	const deductTotal = info.paymentItems?.reduce((a, v) => a + v?.amount, 0) ?? 0;
	const withholdingTax = quotationTotal * ((info.tax * 0.01) || 0)
	const vat = quotationTotal * info.useVat ? 0.07 : 0
	const total = quotationTotal + vat - withholdingTax - deductTotal
	const fetchData = async controller => {
		const result = await multiple([
			{ method: "get", url: "/payment/getAllApprovalPayment", config: { signal: controller.signal } },
			{ method: "get", url: "/additionalPrice/getAllActiveAdditionalPrice", params: { type: "payment" }, config: { signal: controller.signal } },
		])
		if (result[0].status === 200) {
			const data = result[0].data.data || []
			setAllPayment(data)
		}
		if (result[1].status === 200) {
			const data = result[1].data.data.records || []
			setAllAdditionalPrice(data.map(data => ({ label: data.name, value: data.id })) || [])
		}
	}


	useEffect(() => {
		const controller = new AbortController();
		fetchData(controller)
		return () => {
			controller.abort()
		}
	}, [])

	useEffect(() => {
		if (info.invoiceDate && (info.terms || info.terms === 0)) {
			const dueDate = new Date(info.invoiceDate)
			dueDate.setDate(dueDate.getDate() + Number(info.terms))
			dispatch(setInfo({ dueDate }))
		} else {
			dispatch(setInfo({ dueDate: null }))
		}
	}, [info.invoiceDate, info.terms])

	const onCloseModal = useCallback(() => {
		mode[1]("initial")
	}, [])

	const onCloseModalReason = useCallback(() => {
		modeReason[1]("initial")
		dispatch(setInfo({ reason: null }))
	}, [])

	const inputForm = [
		{
			rowData: [
				{
					type: "display",
					props: {
						id: "vendorName",
						label: "บริษัทรถร่วม"
					}
				},
				{
					type: "display",
					props: {
						id: "referenceDoc",
						label: "Reference Doc",
					}
				},
				{
					type: "display",
					props: {
						id: "paidDate",
						label: "วันที่จ่ายเงิน",
						type: "date"
					}
				},
			]
		},
		{
			rowData: [
				{
					type: "display",
					props: {
						id: "invoiceDate",
						label: "วันที่ออกใบเตรียมจ่าย",
						type: "date"
					}
				},
				{
					type: "display",
					props: {
						id: "terms",
						label: "Credit terms",
					}
				},
				{
					type: "display",
					props: {
						id: "dueDate",
						label: "วันที่ครบกำหนดชำระ",
						type: "date"
					}
				}
			]
		},
		{
			rowData: [
				{
					type: "display",
					props: {
						id: "paymentMethod",
						label: "วิธีการชำระเงิน",
						valuesMap: PAYMENT_OPTIONS.reduce((acc, cur) => {
							acc[cur.value] = cur.label;
							return acc;
						}, {})
					},
					md: 4
				},
				{
					type: "display",
					props: {
						id: "payerName",
						label: "ผู้จ่ายเงิน",
					},
					md: 4
				},
				{
					type: "display",
					props: {
						id: "oilPrice",
						label: "ราคาน้ำมัน",
						type: "number"
					},
					md: 2
				},
				{
					type: "display",
					props: {
						id: "uom",
						label: "เงื่อนไขการวางบิล",
						valuesMap: BILLING_COND.filter(e => ["TRIP", "UNIT"].includes(e.value)).reduce((acc, cur) => {
							acc[cur.value] = cur.label;
							return acc;
						}, {}),
					},
					md: 2
				}
			]
		},
		{
			rowData: [
				{
					type: "display",
					props: {
						id: "tax",
						label: "ภาษีหัก ณ ที่จ่าย",
						valuesMap: INVOICE_TAX.reduce((acc, cur) => {
							acc[cur.value] = cur.label;
							return acc;
						}, {})
					},
					md: 4
				},
				{
					type: "display",
					props: {
						id: "paymentCondition",
						label: "เงื่อนไขผู้จ่ายเงิน",
						valuesMap: {
							"Withheld": "หัก ณ ที่จ่าย",
							"Paid always": "ออกให้ตลอดไป",
							"Paid once": "ออกให้ครั้งเดียว",
						}
					},
					md: 4
				},
				{
					type: "display",
					props: {
						id: "useVat",
						label: "คำนวนภาษี (vat)",
						valuesMap: { true: "ใช่", false: "ไม่ใช่" }
					},
					md: 2
				}
			]
		},
		{
			isCollapse: true,
			label: "รายละเอียดรายการที่หัก",
			collapseData: [
				{
					rowData: [
						{
							type: "list",
							props: {
								id: "paymentItems",
								showDuplicate: mode[0] === "create",
								data: [
									{
										rowData: [
											{
												type: "autocomplete",
												props: {
													id: "addPriceId",
													label: "ค่าใช้จ่ายเพิ่มเติม",
													options: allAdditionalPrice
												},
												md: 5
											},
											{
												type: "number",
												props: {
													id: "amount",
													label: "จำนวน"
												},
												md: 5
											},
											{
												type: "custom",
												component: <Box className="h-[inherit] flex items-center justify-center"> บาท </Box>,
												md: 2
											}
										]
									}
								],
								readOnly: true
							}
						},
					]
				},
			]
		},
		{
			isCollapse: true,
			label: "รายละเอียดเพย์เมนต์",
			defaultCollapse: true,
			collapseData: [
				{
					rowData: [
						{
							type: "dataTable",
							props: {
								columns: [
									{
										id: 'jobName',
										label: 'เลขที่ใบงาน',
										fields: ["jobName"]
									},
									{
										id: 'quoNo',
										label: 'เลขที่ใบเสนอราคา',
										fields: ["quoNo"]
									},
									{
										id: 'uom',
										label: 'เงื่อนไขวางบิล',
										fields: ["uom"],
										valuesMap: BILLING_COND
									},
									{
										id: 'routeName',
										label: 'เส้นทาง',
										fields: ["routeName"],
									},
									{
										id: 'driverName',
										label: 'คนขับ',
										fields: ["driverName"],
									},
									{
										id: 'vehicleNo',
										label: 'ทะเบียนรถ',
										fields: ["vehicleNo"],
									},
									{
										id: 'vehicleTypeName',
										label: 'ประเภทรถ',
										fields: ["vehicleTypeName"],
									},
									{
										id: 'calculateCount',
										label: 'จำนวน',
										fields: ["calculateCount"],
										type: "number"
									},
									{
										id: 'total',
										label: 'ราคาสุทธิ',
										fields: ["total"],
										type: "number"
									},
								],
								collapse: {
									columns: [
										{
											id: 'invoiceNo',
											label: 'เลขที่อินวอยซ์',
											fields: ["invoiceNo"]
										},
										{
											id: 'customerName',
											label: 'ชื่อลูกค้า',
											fields: ["customerName"]
										},
										{
											id: 'shipToAddress',
											label: 'ที่อยู่ผู้รับ',
											fields: ["shipToAddress"]
										},
										{
											id: 'productName',
											label: 'สินค้า',
											fields: ["productName"]
										},
										{
											id: 'unitName',
											label: 'หน่วย',
											fields: ["unitName"]
										},
										{
											id: 'quantity',
											label: 'จำนวน',
											fields: ["quantity"],
											type: "number"
										},
										{
											id: 'pricePerUnit',
											label: 'ราคาต่อหน่วย',
											fields: ["pricePerUnit"],
											type: "number"
										},
										{
											id: 'amount',
											label: 'ราคา',
											fields: ["amount"],
											type: "number"
										},
										{
											id: 'completeTime',
											label: 'วันที่ส่ง',
											fields: ["completeTime"],
											type: "date"
										},
										{
											id: 'remark',
											label: 'หมายเหตุ',
											fields: ["remark"]
										},
									],
									field: "bills"
								},
								rows: info.jobs,
								title: "บิล",
								tableContainerClassName: "max-h-[30vh]",
								onDelete: (e, onSuccess) => {
									const ids = e.map(e => e.id) || []
									const removedJobs = info.jobs.filter(e => !ids.includes(e.id))
									dispatch(setInfo({ jobs: removedJobs }))
									onSuccess()
								},
								size: "small"
							}
						}
					]
				},
				{
					rowData: [
						{
							type: "custom",
							align: "right",
							component:
								<>
									<Typography variant="body2" gutterBottom className='!pr-4'>
										ราคารวมจากใบเสนอราคา : &nbsp;&nbsp;&nbsp; {numeral(quotationTotal).format('0,0.00')}
									</Typography>
									<Typography variant="body2" gutterBottom className='!pr-4'>
										หัก ณ ที่จ่าย : &nbsp;&nbsp;&nbsp; {numeral(withholdingTax).format('0,0.00')}
									</Typography>
									{info.useVat ? <Typography variant="body2" gutterBottom className='!pr-4'>
										ภาษี(vat) 7% : &nbsp;&nbsp;&nbsp; {numeral(vat).format('0,0.00')}
									</Typography> : null}
									<Typography variant="body2" gutterBottom className='!pr-4'>
										ราคารวมจากรายการหัก : <span className='text-red-400'>&nbsp;&nbsp;&nbsp; {numeral(deductTotal).format('0,0.00')}</span>
									</Typography>
									<Typography variant="body2" gutterBottom className='!pr-4'>
										ราคาสุทธิ : &nbsp;&nbsp;&nbsp; {numeral(total).format('0,0.00')}
									</Typography>
								</>
						}
					]
				}
			]
		},
		{
			noCol: true,
			align: "center",
			rowData: [
				{
					type: "button",
					props: {
						label: "อนุมัติ",
						onClick: () => {
							approve()
						},
						className: "!bg-bpTheme-buttonSubmit !mr-2"
					}
				},
				{
					type: "button",
					props: {
						label: "ไม่อนุมัติ",
						onClick: () => {
							modeReason[1]("edit")
						},
						className: "!bg-bpTheme-buttonCancel"
					}
				}
			]
		}
	]

	const search = async (data) => {
		const response = await get("/payment/getAllApprovalPayment", { search: data, paging: paging[0] })
		if (response.status === 200) {
			const data = response.data.data || []
			setAllPayment(data)
		}
	}

	const searchFields = [
		{
			type: "date",
			props: {
				label: "ตั้งแต่วันที่",
				id: "startDate",
				maxDate: searchInfo.toDate || new Date(),
			}
		},
		{
			type: "date",
			props: {
				label: "ถึงวันที่",
				id: "toDate",
				minDate: searchInfo.startDate,
				maxDate: new Date(),
			}
		},
		{
			type: "text",
			props: {
				label: "เส้นทาง",
				id: "route",
			}
		},
		{
			type: "text",
			props: {
				label: "เลขที่อินวอยซ์",
				id: "invoiceNo",
			}
		},
	]

	const table = useMemo(() => [
		{
			rowData: [{
				type: "dataTable",
				props: {
					columns: [
						{
							id: 'no',
							label: 'เลขที่ใบเตรียมจ่าย',
							fields: ["no"]
						},
						{
							id: 'vendorName',
							label: 'ชื่อบริษัทรถร่วม',
							fields: ["vendorName"]
						},
						{
							id: 'invoiceDate',
							label: 'ณ วันที่',
							fields: ["invoiceDate"],
							type: "date"
						}
					],
					rows: allPayment,
					size: "small",
					customToolbar: {
						component: AdvanceSearch,
						props: {
							handleSearch: search,
							components: searchFields
						}
					},
					title: "รายการรออนุมัติใบเตรียมจ่าย",
					showSystemFields: true,
					onClick: async (e) => {
						mode[1]("edit")
						const result = await get("/payment/getPaymentBillingByPaymentId", { id: e.id })
						if (result.status === 200) {
							dispatch(setInfo({ ...e, ...result.data.data }))
						}

					},
					controller: (paging) => get("/payment/getAllApprovalPayment", { paging }),
					state: { paging },
				}
			}]
		}
	], [allPayment, searchInfo])

	const approve = useCallback(() => {
		confirmPopup({
			onSubmit: async () => {
				const data = normalizeData(info)
				let response = await post("/approval/updateApproval", { id: data.id, status: STATUS_PAYMENT_APPROVE, type: "payment" })

				if (response.status === 200) {
					mode[1]("initial")
					enqueueSnackbar('บันทึกสำเร็จ', { variant: "success" })
					fetchData(new AbortController())
				} else {
					enqueueSnackbar(warpUpFailResponse(response, 'บันทึกไม่สำเร็จ'), { variant: "error" })
				}
			},
			onCancel: () => {
				enqueueSnackbar('ยกเลิกรายการ', { variant: "info" })
			}
		})
	}, [info])

	const denied = useCallback(() => {
		confirmPopup({
			onSubmit: async () => {
				const data = normalizeData(info)
				let response = await post("/approval/updateApproval", { id: data.id, status: STATUS_PAYMENT_DENIED, type: "payment", reason: data.reason })

				if (response.status === 200) {
					modeReason[1]("initial")
					mode[1]("initial")
					enqueueSnackbar('บันทึกสำเร็จ', { variant: "success" })
					fetchData(new AbortController())
				} else {
					enqueueSnackbar(warpUpFailResponse(response, 'บันทึกไม่สำเร็จ'), { variant: "error" })
				}
			},
			onCancel: () => {
				enqueueSnackbar('ยกเลิกรายการ', { variant: "info" })
			}
		})
	}, [info])

	const inputFormReason = [
		{
			rowData: [
				{
					type: "text",
					props: {
						id: "reason",
						label: "เหตุผล",
						variant: "outlined",
						multiline: true,
						maxRows: 4
					}
				}
			]
		},
		{
			rowData: [
				{
					type: "button",
					props: {
						label: <>ตกลง</>,
						onClick: () => {
							if (info.reason) {
								denied()
							} else {
								enqueueSnackbar('กรุณาระบุเหตุผลที่ไม่อนุมัติ', { variant: "warning" })
							}
						}
					}
				}
			]
		}
	]

	return (
		<Box>
			<Panel name="table" data={table}></Panel>
			<Modal open={mode[0] === "create" || mode[0] === "edit"} onClose={onCloseModal} className="form-modal">
				<Form title={"ข้อมูลใบเตรียมจ่าย " + (info.no || "")} name="user-form" data={inputForm}></Form>
			</Modal>
			<Modal open={modeReason[0] === "edit"} onClose={onCloseModalReason} contentSX={{ minWidth: { xs: "100%", md: "500px !important" }, maxWidth: { md: "500px !important" } }} className="form-modal">
				<Form title={"เหตุผลที่ไม่อนุมัติ"} name="user-form" data={inputFormReason}></Form>
			</Modal>
		</Box>
	)
}