import React, { useEffect, useState } from 'react'
import Grid from '@mui/material/Grid'
import List from '@mui/material/List'
import Card from '@mui/material/Card'
import CardHeader from '@mui/material/CardHeader'
import ListItem from '@mui/material/ListItem'
import ListItemText from '@mui/material/ListItemText'
import ListItemIcon from '@mui/material/ListItemIcon'
import Checkbox from '@mui/material/Checkbox'
import Button from '@mui/material/Button'
import Divider from '@mui/material/Divider'
import { FormLabel } from '@mui/material'
import { useDispatch } from 'react-redux'
import { setInfoById, setInfo } from 'slices/infoSlice'

function not(a, b, keyField) {
    return a.filter((value) => !b.some((item) => item[keyField] === value[keyField]))
}

function intersection(a, b, keyField) {
    return a.filter((value) => b.some((item) => item[keyField] === value[keyField]))
}

function union(a, b, keyField) {
    return [...a, ...not(b, a, keyField)]
}

export default function TransferList(props) {
    const { parentId, id, leftData = [], rightData = [], label, labelFields, keyField, leftLabel = "Choices", rightLabel = "Chosen", targetSide = "right", required } = props
    const dispatch = useDispatch()
    const onChange = (e) => {
        if (parentId) {
            dispatch(setInfoById({ id: parentId, payload: { [id]: e } }))
        } else {
            dispatch(setInfo({ [id]: e }))
        }
    }
    const [checked, setChecked] = useState([])
    const [left, setLeft] = useState(leftData)
    const [right, setRight] = useState(rightData)

    const leftChecked = intersection(checked, left, keyField)
    const rightChecked = intersection(checked, right, keyField)

    const handleToggle = (value) => () => {
        const currentIndex = checked.findIndex(obj => obj[keyField] === value[keyField])
        const newChecked = [...checked]

        if (currentIndex === -1) {
            newChecked.push(value)
        } else {
            newChecked.splice(currentIndex, 1)
        }
        setChecked(newChecked)
    }

    const numberOfChecked = (items) => intersection(checked, items, keyField).length

    const handleToggleAll = (items) => () => {
        if (numberOfChecked(items) === items.length) {
            setChecked(not(checked, items, keyField))
        } else {
            setChecked(union(checked, items, keyField))
        }
    }

    const handleCheckedRight = () => {
        setRight(right.concat(leftChecked.map((obj) => left.find((item) => item[keyField] === obj[keyField]))))
        setLeft(not(left, leftChecked.map((obj) => left.find((item) => item[keyField] === obj[keyField])), keyField))
        setChecked(not(checked, leftChecked, keyField))
    }

    const handleCheckedLeft = () => {
        setLeft(left.concat(rightChecked.map((obj) => right.find((item) => item[keyField] === obj[keyField]))))
        setRight(not(right, rightChecked.map((obj) => right.find((item) => item[keyField] === obj[keyField])), keyField))
        setChecked(not(checked, rightChecked, keyField))
    }

    useEffect(() => {
        onChange(targetSide === "right" ? right : left)
    }, [targetSide === "right" ? right : left])

    const customList = (title, items) => (
        <Card>
            <CardHeader
                sx={{ px: 2, py: 1 }}
                avatar={
                    <Checkbox
                        onClick={handleToggleAll(items)}
                        checked={numberOfChecked(items) === items.length && items.length !== 0}
                        indeterminate={
                            numberOfChecked(items) !== items.length && numberOfChecked(items) !== 0
                        }
                        disabled={items.length === 0}
                        inputProps={{
                            'aria-label': 'all items selected',
                        }}
                    />
                }
                title={title}
                subheader={`${numberOfChecked(items)}/${items.length} selected`}
            />
            <Divider />
            <List
                sx={{
                    width: 200,
                    height: 230,
                    bgcolor: 'background.paper',
                    overflow: 'auto',
                }}
                dense
                component="div"
                role="list"
            >
                {items.map((value) => {
                    const labelId = `transfer-list-all-item-${value[keyField]}-label`
                    return (
                        <ListItem
                            key={`transfer-bill-${value[keyField]}`}
                            role="listitem"
                            button
                            onClick={handleToggle(value)}
                        >
                            <ListItemIcon>
                                <Checkbox
                                    checked={checked.findIndex(obj => obj[keyField] === value[keyField]) !== -1}
                                    tabIndex={-1}
                                    disableRipple
                                    inputProps={{
                                        'aria-labelledby': labelId,
                                    }}
                                />
                            </ListItemIcon>
                            <ListItemText id={labelId} primary={labelFields.map(e => value[e]).join(" ")} />
                        </ListItem>
                    )
                })}
            </List>
        </Card>
    )

    return (
        <>
            <FormLabel component="legend" className='text-center'>{label + (required ? " *" : "")}</FormLabel>
            <Grid container spacing={2} justifyContent="center" alignItems="center">
                <Grid item>{customList(leftLabel, left)}</Grid>
                <Grid item className='xs:w-full md:w-auto'>
                    <Grid container direction="column" alignItems="center">
                        <Button
                            sx={{ my: 0.5 }}
                            variant="outlined"
                            size="small"
                            onClick={handleCheckedRight}
                            disabled={leftChecked.length === 0}
                            aria-label="move selected right"
                        >
                            &gt;
                        </Button>
                        <Button
                            sx={{ my: 0.5 }}
                            variant="outlined"
                            size="small"
                            onClick={handleCheckedLeft}
                            disabled={rightChecked.length === 0}
                            aria-label="move selected left"
                        >
                            &lt;
                        </Button>
                    </Grid>
                </Grid>
                <Grid item>{customList(rightLabel, right)}</Grid>
            </Grid>
        </>
    )
}