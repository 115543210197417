import { useDispatch } from "react-redux"
import { hideLoading, showLoading } from "slices/loadingSlice"
import { get as customGet, post as customPost, del as customDel } from "utilities/HTTPconnector"
import Cookies from 'js-cookie'
import { setLogin } from "slices/userSlice";
const fileDownload = require('js-file-download');

const getFileName = (headers) => {
    let filename = "";
    const disposition = headers['content-disposition'];
    if (disposition && disposition.indexOf('attachment') !== -1) {
        const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
        const matches = filenameRegex.exec(disposition);
        if (matches != null && matches[1])
            filename = matches[1].replace(/['"]/g, '');
    }
    return filename
}

export default function useAxios(loading = true) {
    const dispatch = useDispatch()

    const getAccessToken = () => {
        return Cookies.get('actk');
    }

    const download = async (url, data, config) => {
        if (loading) {
            dispatch(showLoading())
        }
        const response = await customPost(url, {
            data, config: {
                headers: !config?.ignoreCredential ? {
                    'Authorization': `Bearer ${getAccessToken()}`,
                } : {},
                responseType: 'blob',
                ...config
            }
        })
        if (loading) {
            dispatch(hideLoading())
        }
        if (response?.status === 400 && response?.data?.status?.description === "Invalid Token") {
            dispatch(setLogin(false))
        }
        if (response.headers["content-type"] !== "application/json" && (response.status === 200 || response.status === 204)) {
            fileDownload(response.data, getFileName(response.headers));
        } else {
            return { status: response.status, data: response.data }
        }
    }

    const post = async (url, data, config) => {
        if (loading) {
            dispatch(showLoading())
        }
        const response = await customPost(url, {
            data, config: {
                headers: !config?.ignoreCredential ? {
                    'Authorization': `Bearer ${getAccessToken()}`
                } : {},
                ...config
            }
        })
        if (loading) {
            dispatch(hideLoading())
        }
        if (response?.status === 400 && response?.data?.status?.description === "Invalid Token") {
            dispatch(setLogin(false))

        }
        return { status: response.status, data: response.data }
    }

    const upload = async (data, config) => {
        let response
        if (data.file && typeof data.file === "object") {
            let formData = new FormData();
            formData.append("prefix", data.prefix);
            formData.append("file", data.file);
            if (!data.file.type.startsWith("video/") || (data.file.size < 60000000 && data.file.type.startsWith("video/")))
                response = await post('/file/uploadFile', formData, config)
            else
                response = { status: 200, data: { status: { code: "400", description: "ขนาดไฟล์เกิน 60 MB" } } }
        } else if (data.file && typeof data.file === "string") {
            response = { status: 200, data: { status: { code: "200", description: "success" }, data: data.file } }
        } else {
            response = { status: 400, data: { status: { code: "400", description: "No file upload" } } }
        }
        if (response?.status === 400 && response?.data?.status?.description === "Invalid Token") {
            dispatch(setLogin(false))

        }
        return { status: response.status, data: response.data }
    }

    const del = async (url, params, config) => {
        if (loading) {
            dispatch(showLoading())
        }
        const response = await customDel(url, {
            params, config: {
                headers: !config?.ignoreCredential ? {
                    'Authorization': `Bearer ${getAccessToken()}`
                } : {},
                ...config
            }
        })
        if (loading) {
            dispatch(hideLoading())
        }
        if (response?.status === 400 && response?.data?.status?.description === "Invalid Token") {
            dispatch(setLogin(false))

        }
        return { status: response.status, data: response.data }
    }

    const get = async (url, params, config) => {
        if (loading) {
            dispatch(showLoading())
        }
        const response = await customGet(url, {
            params, config: {
                headers: !config?.ignoreCredential ? {
                    'Authorization': `Bearer ${getAccessToken()}`
                } : {},
                ...config
            }
        })
        if (loading) {
            dispatch(hideLoading())
        }
        if (response?.status === 400 && response?.data?.status?.description === "Invalid Token") {
            dispatch(setLogin(false))

        }
        return { status: response.status, data: response.data }
    }

    const multiple = async (reqList) => {
        if (loading) {
            dispatch(showLoading())
        }
        const response = await Promise.all(reqList.map(e => {
            if (e.method === "get") {
                return get(e.url, e.params, e.config)
            } else if (e.method === "post") {
                return post(e.url, e.data, e.config)
            }
        }))
        if (loading) {
            dispatch(hideLoading())
        }

        const newResp = []
        response.forEach(e => {
            if (e?.status === 400 && e?.data?.status?.description === "Invalid Token") {
                dispatch(setLogin(false))
            }
            newResp.push(e)
        })
        return newResp
    }

    return {
        post,
        del,
        get,
        upload,
        download,
        multiple
    }
}
