import { Box, Typography } from '@mui/material'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Panel from 'components/Panel'
import Modal from 'components/Modal'
import Form from 'components/Form'
import { useSnackbar } from 'notistack'
import { setInfo } from 'slices/infoSlice'
import { normalizeData } from 'utilities/validator'
import usePopup from 'hooks/usePopup'
import useAxios from 'hooks/useAxios'
import AdvanceSearch from 'components/AdvanceSearch'
import { INVOICE_TAX, PAYMENT_OPTIONS, STATUS_PAYMENT_DENIED, STATUS_PAYMENT_APPROVE } from 'helper/DataFactory'
import numeral from 'numeral'
import { warpUpFailResponse } from 'utilities/utils'
import { memoizedSelectAdvanceInfo } from 'redux/memorize'

export default function PaymentApproval() {
	const dispatch = useDispatch()
	const { enqueueSnackbar } = useSnackbar();
	const info = useSelector(state => state.info.ctx)
	const { post, multiple, get } = useAxios();
	const mode = useState("initial") //main form
	const modeReason = useState("initial")
	const { confirmPopup } = usePopup();
	const [allPayment, setAllPayment] = useState([]);
	const paging = useState({})
	const searchInfo = useSelector(memoizedSelectAdvanceInfo)
	const [quotationTotal, setQuotationTotal] = useState(0);
	const [total, setTotal] = useState(0);
	const [withholdingTax, setWithholdingTax] = useState(0);
	const [vat, setVat] = useState(0);
	const fetchData = async controller => {
		const result = await multiple([
			{ method: "get", url: "/payment/getAllApprovalOtherPayment", config: { signal: controller.signal } },
		])
		if (result[0].status === 200) {
			const data = result[0].data.data || []
			setAllPayment(data)
		}
	}

	const handleCalculation = () => {
		const newPayeesTotal = info.payees?.reduce((a, v) => a + v?.total, 0) ?? 0;
		const newWithholdingTax = newPayeesTotal * ((info.tax ?? 0) * 0.01);
		const newVat = info.useVat ? newPayeesTotal * 0.07 : 0;
		const newCalcTotal = newPayeesTotal + newVat - newWithholdingTax

		setQuotationTotal(newPayeesTotal);
		setTotal(newCalcTotal);
		setWithholdingTax(newWithholdingTax);
		setVat(newVat);
	};

	useEffect(() => {
		handleCalculation()
	}, [info.payees, info.tax, info.useVat])

	useEffect(() => {
		if (info.invoiceDate && (info.terms || info.terms === 0)) {
			const dueDate = new Date(info.invoiceDate)
			dueDate.setDate(dueDate.getDate() + Number(info.terms))
			dispatch(setInfo({ dueDate }))
		} else {
			dispatch(setInfo({ dueDate: null }))
		}
	}, [info.invoiceDate, info.terms])

	useEffect(() => {
		const controller = new AbortController();
		fetchData(controller)
		return () => {
			controller.abort()
		}
	}, [])

	useEffect(() => {
		if (info.invoiceDate && (info.terms || info.terms === 0)) {
			const dueDate = new Date(info.invoiceDate)
			dueDate.setDate(dueDate.getDate() + Number(info.terms))
			dispatch(setInfo({ dueDate }))
		} else {
			dispatch(setInfo({ dueDate: null }))
		}
	}, [info.invoiceDate, info.terms])

	const onCloseModal = useCallback(() => {
		mode[1]("initial")
	}, [])

	const onCloseModalReason = useCallback(() => {
		modeReason[1]("initial")
		dispatch(setInfo({ reason: null }))
	}, [])

	const inputForm = [
		{
			rowData: [
				{
					type: "display",
					props: {
						id: "invoiceDate",
						label: "วันที่ออกใบเตรียมจ่าย",
						type: "data"
					},
					md: 4
				},
				{
					type: "display",
					props: {
						id: "paidDate",
						label: "วันที่จ่ายเงิน",
						type: "data"
					},
					md: 2
				},
				{
					type: "display",
					props: {
						id: "terms",
						label: "Credit terms",
						type: "number",
					},
					md: 2
				},
				{
					type: "display",
					props: {
						id: "dueDate",
						label: "วันที่ครบกำหนดชำระ",
						type: "date"
					},
					md: 4
				}
			]
		},
		{
			rowData: [
				{
					type: "display",
					props: {
						id: "payerName",
						label: "ผู้จ่ายเงิน"
					},
					md: 2
				},
				{
					type: "display",
					props: {
						id: "tax",
						label: "ภาษีหัก ณ ที่จ่าย",
						valuesMap: INVOICE_TAX.reduce((acc, cur) => {
							acc[cur.value] = cur.label;
							return acc;
						}, {})
					},
					md: 2
				},
				{
					type: "display",
					props: {
						id: "useVat",
						label: "คำนวนภาษี (vat)",
						valuesMap: { true: "ใช่", false: "ไม่ใช่" }
					},
					md: 2
				},
				{},
				{}
			]
		},
		{
			isCollapse: true,
			label: "รายการผู้รับเงิน",
			collapseData: [
				{
					rowData: [
						{
							type: "dataTable",
							props: {
								columns: [
									{
										id: 'name',
										label: 'ชื่อ-สกุล',
										fields: ["name"]
									},
									{
										id: 'paymentMethod',
										label: 'ช่องทางการชำระเงิน',
										fields: ["paymentMethod"],
										valuesMap: PAYMENT_OPTIONS
									},
									{
										id: 'total',
										label: 'ค่าใช้จ่าย',
										fields: ["total"],
										type: "number"
									},
								],
								collapse: {
									columns: [
										{
											id: 'name',
											label: 'รายการ',
											fields: ["name"]
										},
										{
											id: 'amount',
											label: 'จำนวน',
											fields: ["amount"],
											type: "number"
										},
									],
									field: "paymentItems"
								},
								id: "selectedPayees",
								rows: info.payees,
								tableContainerClassName: "max-h-[30vh]",
								size: "small"
							}
						}
					]
				},
				{
					rowData: [
						{
							type: "custom",
							align: "right",
							component:
								<>
									<Typography variant="body2" gutterBottom className='!pr-4'>
										ยอดจ่ายรวม : &nbsp;&nbsp;&nbsp; {numeral(quotationTotal).format('0,0.00')}
									</Typography>
									<Typography variant="body2" gutterBottom className='!pr-4'>
										หัก ณ ที่จ่าย : &nbsp;&nbsp;&nbsp; {numeral(withholdingTax).format('0,0.00')}
									</Typography>
									{info.useVat ? <Typography variant="body2" gutterBottom className='!pr-4'>
										ภาษี(vat) 7% : &nbsp;&nbsp;&nbsp; {numeral(vat).format('0,0.00')}
									</Typography> : null}
									<Typography variant="body2" gutterBottom className='!pr-4'>
										ยอดจ่ายสุทธิ : &nbsp;&nbsp;&nbsp; {numeral(total).format('0,0.00')}
									</Typography>
								</>
						}
					]
				},
			]
		},
		{
			rowData: [
				{
					type: "display",
					props: {
						id: "remark",
						label: "หมายเหตุ"
					}
				}
			]
		},
		{
			noCol: true,
			align: "center",
			rowData: [
				{
					type: "button",
					props: {
						label: "อนุมัติ",
						onClick: () => {
							approve()
						},
						className: "!bg-bpTheme-buttonSubmit !mr-2"
					}
				},
				{
					type: "button",
					props: {
						label: "ไม่อนุมัติ",
						onClick: () => {
							modeReason[1]("edit")
						},
						className: "!bg-bpTheme-buttonCancel"
					}
				}
			]
		}
	]

	const search = async (data) => {
		const response = await get("/payment/getAllApprovalOtherPayment", { search: data, paging: paging[0] })
		if (response.status === 200) {
			const data = response.data.data || []
			setAllPayment(data)
		}
	}

	const searchFields = [
		{
			type: "date",
			props: {
				label: "ตั้งแต่วันที่",
				id: "startDate",
				maxDate: searchInfo.toDate || new Date(),
			}
		},
		{
			type: "date",
			props: {
				label: "ถึงวันที่",
				id: "toDate",
				minDate: searchInfo.startDate,
				maxDate: new Date(),
			}
		},
		{
			type: "text",
			props: {
				label: "Paid No",
				id: "no",
			}
		},
		{
			type: "text",
			props: {
				label: "ชื่อผู้รับเงิน",
				id: "payeeName",
			}
		}
	]

	const table = useMemo(() => [
		{
			rowData: [{
				type: "dataTable",
				props: {
					columns: [
						{
							id: 'no',
							label: 'Paid No',
							fields: ["no"]
						},
						{
							id: 'remark',
							label: 'หมายเหตุ',
							fields: ["remark"]
						},
						{
							id: 'paidDate',
							label: 'Paid Date',
							fields: ["paidDate"],
							type: "date"
						},
						{
							id: 'total',
							label: 'ยอดรวม',
							fields: ["total"],
							type: "number"
						},
						{
							id: 'status',
							label: 'สถานะ',
							fields: ["status"]
						}
					],
					rows: allPayment,
					size: "small",
					customToolbar: {
						component: AdvanceSearch,
						props: {
							handleSearch: search,
							components: searchFields
						}
					},
					title: "รายการรออนุมัติใบเตรียมจ่าย (อื่นๆ)",
					showSystemFields: true,
					onClick: async (e) => {
						const result = await get("/payment/getPaymentById", { id: e.id, type: "other" })
						if (result.status === 200) {
							mode[1]("edit")
							dispatch(setInfo({ ...result.data.data, newStatus: result.data.data.status }))
						}
					},
					controller: (paging) => get("/payment/getAllOtherPayment", { paging }),
					state: { paging },
				}
			}]
		}
	], [allPayment, searchInfo])

	const approve = useCallback(() => {
		confirmPopup({
			onSubmit: async () => {
				const data = normalizeData(info)
				let response = await post("/approval/updateApproval", { id: data.id, status: STATUS_PAYMENT_APPROVE, type: "payment" })

				if (response.status === 200) {
					mode[1]("initial")
					enqueueSnackbar('บันทึกสำเร็จ', { variant: "success" })
					fetchData(new AbortController())
				} else {
					enqueueSnackbar(warpUpFailResponse(response, 'บันทึกไม่สำเร็จ'), { variant: "error" })
				}
			},
			onCancel: () => {
				enqueueSnackbar('ยกเลิกรายการ', { variant: "info" })
			}
		})
	}, [info])

	const denied = useCallback(() => {
		confirmPopup({
			onSubmit: async () => {
				const data = normalizeData(info)
				let response = await post("/approval/updateApproval", { id: data.id, status: STATUS_PAYMENT_DENIED, type: "payment", reason: data.reason })

				if (response.status === 200) {
					modeReason[1]("initial")
					mode[1]("initial")
					enqueueSnackbar('บันทึกสำเร็จ', { variant: "success" })
					fetchData(new AbortController())
				} else {
					enqueueSnackbar(warpUpFailResponse(response, 'บันทึกไม่สำเร็จ'), { variant: "error" })
				}
			},
			onCancel: () => {
				enqueueSnackbar('ยกเลิกรายการ', { variant: "info" })
			}
		})
	}, [info])

	const inputFormReason = [
		{
			rowData: [
				{
					type: "text",
					props: {
						id: "reason",
						label: "เหตุผล",
						variant: "outlined",
						multiline: true,
						maxRows: 4
					}
				}
			]
		},
		{
			rowData: [
				{
					type: "button",
					props: {
						label: <>ตกลง</>,
						onClick: () => {
							if (info.reason) {
								denied()
							} else {
								enqueueSnackbar('กรุณาระบุเหตุผลที่ไม่อนุมัติ', { variant: "warning" })
							}
						}
					}
				}
			]
		}
	]

	return (
		<Box>
			<Panel name="table" data={table}></Panel>
			<Modal open={mode[0] === "create" || mode[0] === "edit"} onClose={onCloseModal} className="form-modal">
				<Form title={"ข้อมูลใบเตรียมจ่าย(อื่นๆ) " + (info.no || "")} name="user-form" data={inputForm}></Form>
			</Modal>
			<Modal open={modeReason[0] === "edit"} onClose={onCloseModalReason} contentSX={{ minWidth: { xs: "100%", md: "500px !important" }, maxWidth: { md: "500px !important" } }} className="form-modal">
				<Form title={"เหตุผลที่ไม่อนุมัติ"} name="user-form" data={inputFormReason}></Form>
			</Modal>
		</Box>
	)
}