import React, { useCallback, useEffect, useState } from 'react'
import { Box, Checkbox, FormControlLabel } from '@mui/material'
import { Download, Calculate } from '@mui/icons-material';
import Modal from 'components/Modal';
import Form from 'components/Form';
import useAxios from 'hooks/useAxios';
import { useDispatch, useSelector } from 'react-redux';
import { setInfoById, setInfo, clearInfoById } from 'slices/infoSlice';
import { useSnackbar } from 'notistack';
import usePopup from 'hooks/usePopup';
import { memoizedSelectInfo } from 'redux/memorize';
import { PAYMENT_OPTIONS } from 'helper/DataFactory';

export default function Payees({ mode }) {
    const dispatch = useDispatch()
    const { enqueueSnackbar } = useSnackbar();
    const { confirmPopup } = usePopup();
    const info = useSelector(memoizedSelectInfo)
    const { multiple, get, download } = useAxios();
    const [allEmployee, setAllEmployee] = useState([]);
    const [allAdditionalPrice, setAllAdditionalPrice] = useState([]);
    const [showDateTripRate, setShowDateTripRate] = useState("initial");
    const [currentRow, setCurrentRow] = useState();
    const [tripRate, setTripRate] = useState(0);

    const fetchData = async () => {
        const result = await multiple([
            { method: "get", url: "/employee/getAllOwnFleetEmployee" },
            { method: "get", url: "/additionalPrice/getAllActiveAdditionalPrice", params: { type: "other payment" } },
        ])

        if (result[0].status === 200) {
            const data = result[0].data.data.records || []
            setAllEmployee(data.map(data => ({ label: `${data.firstName} ${data.lastName}`, value: data.id, data: data })) || [])
        }
        if (result[1].status === 200) {
            const data = result[1].data.data.records || []
            setAllAdditionalPrice(data.map(data => ({ label: data.name, value: data.id, data: data })) || [])
            setTripRate(data.find(data => (data.name == "ค่าเที่ยวรถ")).id)
        }
    }

    useEffect(() => {
        if (mode[0] === "initial") {
            dispatch(clearInfoById("payees"))
        } else {
            fetchData()
        }
    }, [mode[0]])

    const searchTripRate = async () => {
        const result = await get("/employee/getTripRateByEmployeeId", { id: info.payees?.employeeId, fromDate: info.payees?.startCompleteDate || undefined, toDate: info.payees?.toCompleteDate || undefined })
        if (result.status === 200) {
            const data = result.data?.data?.totalTripRate || 0
            dispatch(setInfoById({ id: "@payees@paymentItems@" + currentRow, payload: { amount: data, startCompleteDate: info.payees?.startCompleteDate, toCompleteDate: info.payees?.toCompleteDate } }))
        }
    }

    const onCloseModalCalTripRate = useCallback(() => {
        setShowDateTripRate("initial")
    }, [])

    const handleChange = (event) => {
        dispatch(setInfoById({ id: "payees", payload: { payOutsider: event.target.checked, employeeId: null, outsider: null } }))
    }

    const onCloseModal = useCallback(() => {
        confirmPopup({
            title: "แจ้งเตือน",
            content: "ท่านต้องการยกเลิกการแก้ไขหรือไม่",
            onSubmit: () => {
                mode[1]("initial")
            }
        })
    }, [])

    const saveData = useCallback(() => {
        const payees = info.ctx.payees || []
        dispatch(setInfo({ payees: [...payees, { ...info.payees, id: info.payees?.employeeId, name: info.payees?.payOutsider ? info.payees?.outsider : allEmployee.find(e => e.value === info.payees.employeeId)?.label, total: (info.payees.paymentItems?.reduce((a, v) => ((a ?? 0) + (v?.amount ?? 0)), 0)) }] }))
        mode[1]("initial")
    }, [info])

    const inputForm = [
        {
            rowData: [
                {
                    type: "custom",
                    component:
                        <FormControlLabel className='text-neutral-500'
                            control={< Checkbox
                                value={info.payees?.payOutsider || false}
                                checked={info.payees?.payOutsider === 1 ? true : info.payees?.payOutsider || false}
                                onChange={handleChange}
                                disabled={mode[0] === "edit"}
                            />}
                            label="จ่ายบุคคลภายนอก"
                        />, md: 4
                }, {}
            ]
        },
        {
            rowData: [
                info.payees?.payOutsider ?
                    {
                        type: "text",
                        required: true,
                        props: {
                            id: "outsider",
                            label: "ชื่อ-นามสกุล"
                        }
                    }
                    :
                    {
                        type: "autocomplete",
                        required: true,
                        props: {
                            id: "employeeId",
                            label: "ชื่อพนักงาน",
                            options: allEmployee.filter(e => !info.ctx.payees?.map(e => e.employeeId)?.includes(e.value)),
                            onChange: (id) => {
                                const options = allEmployee.find(e => e.value === id) || {}
                                dispatch(setInfoById({ id: "payees", payload: { paymentMethod: options?.data?.paymentMethod } }))
                            },
                        }
                    },
                {
                    type: "autocomplete",
                    required: true,
                    props: {
                        id: "paymentMethod",
                        label: "วิธีการชำระเงิน",
                        options: PAYMENT_OPTIONS
                    },
                    md: 2
                },
            ]
        },
        {
            isCollapse: true,
            label: "รายละเอียดรายการที่จ่าย",
            collapseData: [
                {
                    rowData: [
                        {
                            type: "list",
                            props: {
                                id: "paymentItems",
                                mininum: 0,
                                showDuplicate: false,
                                data: (data, i) => [
                                    {
                                        rowData: [
                                            {
                                                type: "autocomplete",
                                                props: {
                                                    id: "addPriceId",
                                                    label: "ค่าใช้จ่ายเพิ่มเติม",
                                                    options: allAdditionalPrice,
                                                    onChange: async (value) => {
                                                        const options = allAdditionalPrice.find(e => e.value === value) || {}
                                                        dispatch(setInfoById({ id: "@payees@paymentItems@" + i, payload: { name: options.label } }))
                                                        if (options.label === "เงินเดือน" && info.payees?.employeeId) {
                                                            const result = await get("/employee/getSalaryByEmployeeId", { id: info.payees?.employeeId })
                                                            if (result.status === 200) {
                                                                const data = result.data?.data?.salary || 0
                                                                dispatch(setInfoById({ id: "@payees@paymentItems@" + i, payload: { amount: data } }))
                                                            }
                                                        } else if ((options.label === "ค่าเที่ยวรถ" || options.label === "เงินเดือน") && !info.payees?.employeeId) {
                                                            setTimeout(() => {
                                                                dispatch(setInfoById({ id: "@payees@paymentItems@" + i, payload: { addPriceId: null, amount: 0 } }))
                                                            }, 100)
                                                            enqueueSnackbar('กรุณาเลือกพนักงาน', { variant: "warning" })

                                                        } else if (options.label === "ค่าเที่ยวรถ" && info.payees?.employeeId) {
                                                            dispatch(setInfoById({ id: "payees", payload: { startCompleteDate: null, toCompleteDate: null } }))
                                                            setShowDateTripRate("show")
                                                            setCurrentRow(i)
                                                        }
                                                    }
                                                },
                                                md: 4
                                            },
                                            {
                                                type: "number",
                                                props: {
                                                    id: "amount",
                                                    label: "จำนวน"
                                                },
                                                md: 3
                                            },
                                            {
                                                type: "custom",
                                                component: <Box className="h-[inherit] flex items-center justify-center"> บาท </Box>,
                                                md: 1
                                            },
                                            (data ? data.addPriceId == tripRate : false) ? {
                                                type: "button",
                                                align: "right",
                                                props: {
                                                    label: <Calculate></Calculate>,
                                                    onClick: () => {
                                                        dispatch(setInfoById({ id: "payees", payload: { startCompleteDate: data.startCompleteDate, toCompleteDate: data.toCompleteDate } }))
                                                        setShowDateTripRate("show")
                                                        setCurrentRow(i)
                                                    }, md: 1
                                                }
                                            } : {},
                                            (data ? data.amount > 0 && data.addPriceId == tripRate : false) ? {
                                                type: "button",
                                                props: {
                                                    label: <Download></Download>,
                                                    onClick: async () => {
                                                        await download("/excel/exportExcel", { name: "jobTripRateReport", fromDate: data.startCompleteDate || undefined, toDate: data.toCompleteDate || undefined, driverId: info.payees?.employeeId || undefined })
                                                    }, md: 1
                                                }
                                            } : {}, {}
                                        ]
                                    }
                                ]
                            }
                        },
                    ]
                }
            ]
        },
        {
            noCol: true,
            align: "left",
            rowData: [
                {
                    type: "button",
                    props: {
                        label: "ตกลง",
                        onClick: () => {
                            saveData()
                        },
                        className: "!bg-bpTheme-buttonSubmit !mr-2"
                    }
                }
            ]
        }
    ]

    const searchDateTripRate = [
        {
            rowData: [
                {
                    type: "date",
                    props: {
                        label: "ตั้งแต่วันที่",
                        id: "startCompleteDate",
                        maxDate: info.payees?.toCompleteDate || new Date(),

                    }
                },
                {
                    type: "date",
                    props: {
                        label: "ถึงวันที่",
                        id: "toCompleteDate",
                        minDate: info.payees?.startCompleteDate,
                        maxDate: new Date(),

                    }
                }
            ]
        },
        {
            noCol: true,
            align: "left",
            rowData: [
                {
                    type: "button",
                    props: {
                        label: "คำนวณ",
                        onClick: () => {
                            if (info.payees?.startCompleteDate && info.payees?.toCompleteDate) {
                                searchTripRate()
                                dispatch(setInfoById({ id: "payees", payload: { startCompleteDate: null, toCompleteDate: null } }))
                                setShowDateTripRate("initial")
                            } else {
                                enqueueSnackbar('กรุณาเลือกวันที่ของค่าเที่ยวรถ', { variant: "warning" })
                            }
                        },
                        className: "!bg-bpTheme-buttonSubmit"
                    }
                }
            ]
        }
    ]
    return (
        <>
            <Modal id="payeeModal" open={mode[0] === "create"} onClose={onCloseModal} className="form-modal">
                <Form id={`payees`} title={"ข้อมูลผู้รับเงิน"} name="user-form" data={inputForm}></Form>
            </Modal>
            <Modal open={showDateTripRate === "show"} className="form-modal" onClose={onCloseModalCalTripRate} contentClassName="min-w-full md:!min-w-[750px]">
                <Form id={`payees`} title={"วันที่ของค่าเที่ยวรถ"} name="user-form" data={searchDateTripRate}></Form>
            </Modal>
        </>
    )
}
