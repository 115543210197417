import React, { useEffect, useRef, useState } from 'react'
import { Box, Button, Grid, Divider, FormControlLabel, Checkbox } from '@mui/material';
import SignatureCanvas from 'react-signature-canvas'
import { useDispatch, useSelector } from 'react-redux';
import { setInfo } from 'slices/infoSlice';
import Dropdown from 'components/Dropdown';
import { dataURLtoFile } from 'utilities/utils';
import { useSnackbar } from 'notistack';
import useAxios from 'hooks/useAxios';
import styled from 'styled-components';
import ShowTime from '../components/showTime';
import ProgressBar from '../components/progressBar';
import { DRIVER_PAGE_CHECK_OUT, DRIVER_PAGE_TAKE_CAMERA } from 'helper/DataFactory';
import Text from 'components/Text';

const TextFormat = styled.div`
		font-family: "Roboto","Helvetica","Arial",sans-serif;
`
const canvasStyles = {
	width: '100%',
	height: 200,
};

export default function TakeSign({ type, onPageChange }) {
	const { multiple } = useAxios()
	const dispatch = useDispatch()
	const info = useSelector(state => state.info.ctx)
	const { enqueueSnackbar } = useSnackbar();
	const signatureRef = useRef();
	const [allReason, setAllReason] = useState([]);

	const fetchData = async controller => {
		const result = await multiple([
			{ method: "get", url: "/reason/getAllActiveReason", config: { signal: controller.signal } }
		])
		if (result[0].status === 200) {
			setAllReason(result[0].data.data.records.map(data => ({ label: data.reason, value: data.id })) || [])
		}
	}

	useEffect(() => {
		const controller = new AbortController();
		fetchData(controller)
		return () => {
			controller.abort()
		}
	}, [])

	const handleChange = (event) => {
		if (event.target.name === "checkComplete") {
			dispatch(setInfo({
				[event.target.name]: event.target.checked, checkResend: false, checkReject: false, causeReject: null, returnImmediately: null
			}))
		} else if (event.target.name === "checkResend") {
			dispatch(setInfo({
				[event.target.name]: event.target.checked, checkComplete: false, checkReject: false, causeReject: null, returnImmediately: null
			}))
		} else {
			dispatch(setInfo({ [event.target.name]: event.target.checked, checkComplete: false, checkResend: false }))
		}
	};

	const goBack = () => {
		onPageChange(DRIVER_PAGE_TAKE_CAMERA)
	}

	const submitSignature = () => {
		const signatureData = signatureRef.current.toDataURL();
		const file = dataURLtoFile(signatureData, `signature-${info.no}.jpg`);
		dispatch(setInfo({ signatureImg: file }))
		if (!signatureRef.current.isEmpty() && (
			(type === "shipping" && (info.checkComplete || info.checkResend || (info.checkReject && info.causeReject && info.returnImmediately != null))) ||
			(type === "pickup" && info.sealNo)
		)) {
			onPageChange(DRIVER_PAGE_CHECK_OUT)
		} else {
			enqueueSnackbar(type === "shipping" ? 'กรุณาลงลายเซ็นและผลการส่ง' : 'กรุณาลงลายเซ็นผู้ปล่อยรถและเลขซีล', {
				variant: "warning", anchorOrigin: {
					vertical: 'top',
					horizontal: 'center',
				}
			})
		}
	}

	return (
		<Box>
			<ProgressBar active={3} />
			<div className="mt-1 !text-lg font-bold text-center">
				ลายเซ็น {type === "pickup" ? "(ผู้ปล่อยรถ)" : ""}
			</div>
			<Grid container columns={12} alignItems="center" className="text-base bg-gray-500 text-white px-2">
				<Grid item xs={7}>
					หมายเลขงาน: <br />
					<span>{info?.bills?.map(bill => bill.no).join(', ')}</span>
				</Grid>
				<ShowTime />
			</Grid>
			{
				type === "pickup" ?
					<div className='p-2 pb-0'>
						<Grid container columns={12} alignItems="center" className="mb-2">
							<Grid item xs={12}>
								<Text
									id="sealNo"
									label="เลขซีล"
									size="small">
								</Text>
							</Grid>
						</Grid>
					</div>
					: null
			}

			<div className="p-2 text-center w-full">
				<div className="w-full mb-2 border-solid rounded-lg border-1 border-gray-600 ">
					<SignatureCanvas
						ref={signatureRef}
						canvasProps={{ style: canvasStyles, className: 'signature-canvas' }}
					/>
				</div>
				<Button variant="contained" onClick={() => signatureRef.current.clear()} className="driver_button">
					Clear
				</Button>
			</div>
			{
				type === "shipping" ?
					<div className="ml-2">
						<TextFormat>ผลการส่ง</TextFormat>
						<div>
							<FormControlLabel
								label="เสร็จสิ้น"
								control={<Checkbox
									name="checkComplete"
									value={info.checkComplete || false}
									checked={info.checkComplete || false}
									onChange={handleChange}
								/>}
							/>
						</div>
						<Grid container columns={12} alignItems="center" className="mb-2">
							<Grid item xs={6}>
								<FormControlLabel
									label="ส่งใหม่"
									control={<Checkbox
										name="checkResend"
										value={info.checkResend || false}
										checked={info.checkResend || false}
										onChange={handleChange}
									/>}
								/>
							</Grid>
						</Grid>
						<Grid container columns={12} alignItems="center" className="mb-2">
							<Grid item xs={12}>
								<FormControlLabel
									label="ส่งไม่สำเร็จ"
									control={<Checkbox
										name="checkReject"
										value={info.checkReject || false}
										checked={info.checkReject || false}
										onChange={handleChange}
									/>}
								/>
							</Grid>
						</Grid>
						{info.checkReject &&
							<div className="ml-8 mb-3">
								<Grid container columns={12} alignItems="center" className="mb-2">
									<Grid item xs={4}>
										<TextFormat>เหตุผล</TextFormat>
									</Grid>
									<Grid item xs={8} className="!pr-2">
										<Dropdown size="small" id={"causeReject"} options={allReason}></Dropdown>
									</Grid>
								</Grid>
								<Grid container columns={12} alignItems="center" className="mb-2">
									<Grid item xs={4}>
										<TextFormat>คืนสินค้า</TextFormat>
									</Grid>
									<Grid item xs={8} className="!pr-2">
										<Dropdown
											size="small"
											id="returnImmediately"
											options={[
												{ label: "คืนสินค้าทันที", value: true },
												{ label: "คืนสินค้ารอบถัดไป", value: false }
											]}>
										</Dropdown>
									</Grid>
								</Grid>
							</div>
						}
					</div>
					: null
			}
			<Divider variant="contained" className="driver_divider"></Divider>
			<div className="flex justify-between py-2 px-1 text-center" >
				<div className="w-[95%]">
					<Button variant="contained" className="w-[95%] driver_button" onClick={goBack}>ย้อนกลับ</Button>
				</div>
				<div className="w-[95%]">
					<Button variant="contained" className="!bg-bpTheme-buttonSubmit w-[95%] driver_button" onClick={submitSignature}>ลงลายเซ็น</Button>
				</div>
			</div>
		</Box>
	)
}