import { Box, Chip, Grid } from '@mui/material'
import React, { useCallback, useMemo, useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Panel from 'components/Panel'
import Modal from 'components/Modal'
import Form from 'components/Form'
import { useSnackbar } from 'notistack'
import FieldSet from 'components/FieldSet'
import DataTable from 'components/DataTable'
import useAxios from 'hooks/useAxios'
import { setInfo, clearInfo } from 'slices/infoSlice'
import Button from 'components/Button'
import { normalizeData } from 'utilities/validator'
import { v4 as uuidv4 } from 'uuid';
import usePopup from 'hooks/usePopup'
import { warpUpFailResponse } from 'utilities/utils'
import useDataHandler from 'hooks/useDataHandler'

export default function Role() {
	const { enqueueSnackbar } = useSnackbar();
	const { post, del, get, multiple } = useAxios();
	const { save } = useDataHandler()
	const { confirmPopup } = usePopup();
	const info = useSelector(state => state.info.ctx)
	const [mode, setMode] = useState("initial")
	const [mode2, setMode2] = useState("initial")
	const [rows, setRows] = useState([])
	const [menuList, setMenuList] = useState([])
	const [roleList, setRoleList] = useState([])
	const dispatch = useDispatch()
	const customTableField = useCallback((text) => text ? <Chip label={text} /> : null, [])
	const masterRoles = new Set(["Driver", "Admin", "Owner", "Accounting"]);
	const roleUser = useSelector(state => state.user.roleName)

	const nestedOptionsBuilder = (opt) => {
		return opt?.map(e => ({ label: e.label, value: `${e.id}`, options: nestedOptionsBuilder(e.menu), data: e })) || null
	}
	const fetchData = async controller => {
		const result = await multiple([
			{ method: "get", url: "/role/getAllActiveRole", config: { signal: controller.signal } },
			{ method: "get", url: "/menu/getAllActiveMenu", config: { signal: controller.signal } },
			{ method: "get", url: "/role/getRoleCount", config: { signal: controller.signal } },
		])
		if (result[0].status === 200) {
			const data = result[0].data.data
			const roles = data.records.map(e => ({ label: e.name, value: e.id, data: e }))
			setRoleList(roles)
		}
		if (result[1].status === 200) {
			const data = result[1].data.data
			const menus = nestedOptionsBuilder(data.records)
			setMenuList(menus)
		}
		if (result[2].status === 200) {
			const data = result[2].data.data || []
			setRows(data)
		}
	}

	const fetchPermission = async (controller, data) => {
		const result = await get("/role/getPermission", data, {
			signal: controller.signal
		})
		if (result.status === 200) {
			const data = result.data.data.records || []
			const permissions = {}
			data.forEach(e => {
				if (info.roleId === 1) {
					permissions[e.menuId] = true
				} else {
					permissions[e.menuId] = e.canAccess
				}
			})
			const roleName = roleList.filter(e => e.value === info.roleId)[0].label
			dispatch(setInfo({ roleName, permissions: permissions }))
		}
	}

	const modalId = useMemo(() => uuidv4(), [])
	const modal2Id = useMemo(() => uuidv4(), [])

	const saveData = useCallback(() => {
		save({
			data: info,
			scope: modalId,
			onSubmit: async () => {
				const data = normalizeData(info)
				const response = await post("/role/updatePermission", { roleId: data.roleId, permissions: Object.entries(data.permissions).map(e => ({ menuId: e[0], canAccess: e[1] || false })) })
				if (response.status === 200) {
					enqueueSnackbar('บันทึกสำเร็จ', { variant: "success" })
				} else {
					enqueueSnackbar(warpUpFailResponse(response, 'บันทึกไม่สำเร็จ'), { variant: "error" })
				}
			},
			onCancel: () => {
				enqueueSnackbar('ยกเลิกรายการ', { variant: "info" })
			}
		})
	}, [info])

	const saveData2 = useCallback(() => {
		save({
			data: info,
			scope: modal2Id,
			onSubmit: async () => {
				const data = normalizeData(info)
				let response
				if (mode2 === "create") {
					response = await post("/role/createRole", { name: data.name, template: "admin-portal", active: true })
				} else if (mode2 === "edit") {
					response = await post("/role/updateRole", { id: data.roleId, name: data.name, template: "admin-portal", active: true })
				}
				if (response.status === 200) {
					setMode2("initial")
					enqueueSnackbar('บันทึกสำเร็จ', { variant: "success" })
					dispatch(clearInfo())
					fetchData(new AbortController())
				} else {
					enqueueSnackbar(warpUpFailResponse(response, 'บันทึกไม่สำเร็จ'), { variant: "error" })
				}
			},
			onCancel: () => {
				enqueueSnackbar('ยกเลิกรายการ', { variant: "info" })
			}
		})
	}, [info])

	const deleteData = useCallback(async () => {
		confirmPopup({
			onSubmit: async () => {
				const result = await del("/role/deleteRole", { id: info.roleId })
				if (result.status === 200) {
					setMode2("initial")
					enqueueSnackbar('ลบสำเร็จ', { variant: "success" })
					dispatch(clearInfo())
					await fetchData(new AbortController())
				} else {
					enqueueSnackbar('ลบไม่สำเร็จ', { variant: "error" })
				}
			}
		})

	}, [info])

	const onCloseModal = useCallback(() => {
		confirmPopup({
			title: "แจ้งเตือน",
			content: "ท่านต้องการยกเลิกการแก้ไขหรือไม่",
			onSubmit: () => {
				setMode("initial")
			}
		})
	}, [])

	const onCloseModal2 = useCallback(() => {
		confirmPopup({
			title: "แจ้งเตือน",
			content: "ท่านต้องการยกเลิกการแก้ไขหรือไม่",
			onSubmit: () => {
				setMode2("initial")
			}
		})
	}, [])

	useEffect(() => {
		const controller = new AbortController()
		fetchData(controller)
		return () => {
			controller.abort()
		}
	}, [])

	useEffect(() => {
		if (info.roleId) {
			const controller = new AbortController()
			fetchPermission(controller, { id: info.roleId })
			return () => {
				controller.abort()
			}
		} else {
			dispatch(setInfo({ roleName: "", permissions: {} }))
		}
	}, [info.roleId])

	const inputForm = [
		{
			rowData: [
				{
					type: "button",
					align: "right",
					props: {
						label: "+ เพิ่มบทบาท",
						onClick: () => {
							setMode2("create")
							dispatch(setInfo({ name: '' }))
						}
					}
				}
			]
		},
		{
			rowData: [
				{
					type: "autocomplete",
					required: true,
					props: {
						id: "roleId",
						label: "ชื่อ",
						options: roleList
					}
				}
			]
		},
		info.roleId && {
			noCol: true,
			align: "right",
			rowData: [
				!masterRoles.has(info.roleName) && {
					type: "button",
					props: {
						label: "แก้ไข",
						className: "!mr-2",
						onClick: () => {
							setMode2("edit")
							const selectedRole = roleList.filter(e => e.value === info.roleId)[0]
							dispatch(setInfo({ name: selectedRole.data.name, template: selectedRole.data.template }))
						}
					}
				},
				!masterRoles.has(info.roleName) && roleUser === "Owner" && {
					type: "button",
					props: {
						label: "ลบ",
						onClick: deleteData
					}
				}
			]
		}
	]
	const inputForm2 = [
		{
			rowData: [
				{
					type: "text",
					required: true,
					props: {
						id: "name",
						label: "ชื่อ"
					}
				}
			]
		}, {
			noCol: true,
			align: "center",
			rowData: [
				{
					type: "button",
					props: {
						label: "บันทึก",
						onClick: saveData2,
						className: "!mr-2 !bg-bpTheme-buttonSubmit"
					}
				}
			]
		}
	]
	const roleInput = [
		{
			rowData: [
				{
					type: "multiCheckbox",
					props: {
						id: "permissions",
						options: menuList,
						readOnly: info.roleId === 1
					}
				}
			]
		}
	]
	const table = useMemo(() => [
		{
			rowData: [{
				type: "dataTable",
				props: {
					columns: [
						{
							id: 'name',
							label: 'ชื่อ',
							fields: ["name"]
						},
						{
							id: 'count',
							label: 'จำนวน',
							fields: ["count"],
							type: "number",
							custom: customTableField
						}
					],
					rows: rows,
					size: "small",
					title: "ข้อมูลบทบาท",
					showSystemFields: true,
					onClick: (e) => {
						setMode("edit")
						dispatch(setInfo({ roleId: e.id }))
					}
				}
			}]
		}, {
			noCol: true,
			rowData: [
				{
					type: "button",
					props: {
						label: "เพิ่มใหม่",
						onClick: () => {
							setMode("create")
							dispatch(clearInfo())
						}
					}
				}
			]
		}
	], [rows])

	return (
		<Box>
			<Panel data={table}></Panel>
			<Modal id={modalId} open={mode === "create" || mode === "edit"} onClose={onCloseModal} className="form-modal">
				<>
					<FieldSet legend="เพิ่มบทบาท" className="!p-0">
						<Box sx={{
							'&::-webkit-scrollbar-thumb': {
								borderRadius: "8px",
							}
						}} className="h-full !p-2 overflow-y-auto scrollbar-thin scrollbar-thumb-bpTheme-scroll scrollbar-track-white">
							<Grid container columns={12} spacing={2}>
								<Grid item xs={12} lg={info.roleName !== "Driver" ? 4 : 6}>
									<Form title="เพิ่มชื่อบทบาท" name="user-form" data={inputForm}></Form>
								</Grid>
								{
									info.roleName !== "Driver" ?
										<Grid item xs={12} lg={4}>
											<Form title="กำหนดสิทธิ์การเข้าใช้งาน" name="user-form" data={roleInput} className="!pt-0 !pb-2" removeBoxPadding={true}></Form>
										</Grid> : null
								}

								<Grid item xs={12} lg={info.roleName !== "Driver" ? 4 : 6} className="!pt-7">
									<DataTable columns={[
										{
											id: 'name',
											label: 'ชื่อบทบาท',
											fields: ["name"]
										},
										{
											id: 'count',
											label: 'จำนวน',
											fields: ["count"],
											type: "number",
											custom: customTableField
										}
									]}
										rows={rows}
										hideToolbar={true}
										hidePaging={true}
									/>
								</Grid>
							</Grid>
							{
								info.roleId !== 1 ? <Box className="mt-10 mb-2 text-center">
									<Button className="!bg-bpTheme-buttonSubmit" onClick={saveData}>บันทึก</Button>
								</Box> : null
							}
						</Box>
					</FieldSet>
				</>
			</Modal>
			<Modal id={modal2Id} open={mode2 === "create" || mode2 === "edit"} onClose={onCloseModal2} className="form-modal" contentClassName="min-w-full md:!min-w-[750px]">
				<Form title="จัดการบทบาท" name="user-form" data={inputForm2}></Form>
			</Modal>
		</Box>
	)
}