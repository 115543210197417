import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    active: ""
}

export const menuSlice = createSlice({
    name: 'activeMenu',
    initialState,
    reducers: {
        setActiveMenu: (state, active) => {
            state.active = active.payload;
        }
    }
})

export const { setActiveMenu } = menuSlice.actions
export default menuSlice.reducer