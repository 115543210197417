import { Check, CameraAlt, Flag, Create, Article } from '@mui/icons-material';
import React from 'react'

export default function ProgressBar({ active }) {
  return (
    <div className="flex flex-row justify-center items-center my-2 text-center">
      <div className="border-dotted rounded-full border-[2px] border-blue-400 p-0.5">
        <Check className={`rounded-full !text-3xl p-1 ${active === 1 ? "bg-orange-400" : "bg-gray-300"}`} />
      </div>
      <div className="text-blue-400">‧‧‧‧‧‧‧‧</div>
      <div className="border-dotted rounded-full border-[2px] border-blue-400 p-0.5">
        <CameraAlt className={`rounded-full !text-3xl p-1 ${active === 2 ? "bg-orange-400" : "bg-gray-300"}`} />
      </div>
      <div className="text-blue-400">‧‧‧‧‧‧‧‧</div>
      <div className="border-dotted rounded-full border-[2px] border-blue-400 p-0.5">
        <Create className={`rounded-full !text-3xl p-1 ${active === 3 ? "bg-orange-400" : "bg-gray-300"}`} />
      </div>
      <div className="text-blue-400">‧‧‧‧‧‧‧‧</div>
      <div className="border-dotted rounded-full border-[2px] border-blue-400 p-0.5">
        <Flag className={`rounded-full !text-3xl p-1 ${active === 4 ? "bg-orange-400" : "bg-gray-300"}`} />
      </div>
      <div className="text-blue-400">‧‧‧‧‧‧‧‧</div>
      <div className="border-dotted rounded-full border-[2px] border-blue-400 p-0.5">
        <Article className={`rounded-full !text-3xl p-1 ${active === 5 ? "bg-orange-400" : "bg-gray-300"}`} />
      </div>
    </div>
  )
}
