import { Box } from '@mui/material';
import { BrowserMultiFormatReader } from '@zxing/library';
import Button from 'components/Button';
import Modal from 'components/Modal';
import React, { useCallback, useEffect, useRef, useState } from 'react'
import { useDispatch } from 'react-redux';
import { setInfoById } from 'slices/infoSlice';
import { setInfo } from 'slices/infoSlice';

export default function BarcodeScanner({ parentId, id, setToggle, toggle }) {
	const dispatch = useDispatch()
	const [codeReader, setCodeReader] = useState(null);
	const videoRef = useRef(null);

	useEffect(() => {
		setCodeReader(new BrowserMultiFormatReader())
		return () => {
			codeReader?.reset()
		}
	}, []);

	useEffect(() => {
		if (toggle) {
			const codeReader = new BrowserMultiFormatReader()
			setCodeReader(codeReader)
			setTimeout(() => {
				codeReader.decodeFromVideoDevice(undefined, videoRef.current, (result) => { })
			}, 500)
		} else {
			codeReader?.reset()
		}
	}, [toggle]);

	const scan = async () => {
		const result = await codeReader.decodeOnceFromVideoDevice(undefined, videoRef.current)
		if (result.text) {
			onChange(result.text)
			setToggle(false);
		}
	};

	const onChange = (e) => {
		if (parentId) {
			dispatch(setInfoById({ id: parentId, payload: { [id]: e } }))
		} else {
			dispatch(setInfo({ [id]: e }))
		}
	}

	const onCloseModal = useCallback(() => {
		setToggle(false)
	}, [])
	return (
		<Modal open={toggle} onClose={onCloseModal}>
			<Box className="text-center">
				<><video ref={videoRef} style={{ width: '100%', height: '100%' }} autoPlay={true}></video><Button className="w-full mt-1 mb-4" onClick={scan}>Scan</Button></>
			</Box>
		</Modal>
	)
}
