import { Box, Typography } from '@mui/material'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Panel from 'components/Panel'
import Modal from 'components/Modal'
import Form from 'components/Form'
import { useSnackbar } from 'notistack'
import { setInfo } from 'slices/infoSlice'
import { normalizeData } from 'utilities/validator'
import usePopup from 'hooks/usePopup'
import useAxios from 'hooks/useAxios'
import AdvanceSearch from 'components/AdvanceSearch'
import { INVOICE_TAX, BILLING_COND, STATUS_INVOICE_APPROVE, STATUS_INVOICE_DENIED } from 'helper/DataFactory'
import numeral from 'numeral'
import { warpUpFailResponse } from 'utilities/utils'
import { memoizedSelectAdvanceInfo } from 'redux/memorize'

export default function InvoiceApproval() {
	const dispatch = useDispatch()
	const { enqueueSnackbar } = useSnackbar();
	const info = useSelector(state => state.info.ctx)
	const { post, multiple, get } = useAxios();
	const mode = useState("initial") //main form
	const modeReason = useState("initial")
	const { confirmPopup } = usePopup();
	const [allInvoice, setAllInvoice] = useState([]);
	const paging = useState({})
	const searchInfo = useSelector(memoizedSelectAdvanceInfo)
	const total = info.orders?.reduce((a, v) => a + v?.amount, 0)

	const fetchData = async controller => {
		const result = await multiple([
			{ method: "get", url: "/invoice/getAllApprovalInvoice", config: { signal: controller.signal } },
		])
		if (result[0].status === 200) {
			const data = result[0].data.data || []
			setAllInvoice(data)
		}
	}


	useEffect(() => {
		const controller = new AbortController();
		fetchData(controller)
		return () => {
			controller.abort()
		}
	}, [])

	const onCloseModal = useCallback(() => {
		mode[1]("initial")
	}, [])

	const onCloseModalReason = useCallback(() => {
		modeReason[1]("initial")
		dispatch(setInfo({ reason: null }))
	}, [])

	const inputForm = [
		{
			rowData: [
				{
					type: "display",
					props: {
						id: "no",
						label: "INV NO.",
					}
				},
				{
					type: "display",
					props: {
						id: "poNo",
						label: "PO. NO.",
					}
				},
				{
					type: "display",
					props: {
						id: "invoiceDate",
						label: "Invoice date",
						type: "date"
					}
				}
			]
		},
		{
			rowData: [
				{
					type: "display",
					props: {
						id: "customerName",
						label: "ชื่อลูกค้า",
					}
				},
				{
					type: "display",
					props: {
						id: "taxId",
						label: "TAX ID",
					}
				},
				{
					type: "display",
					props: {
						id: "tel",
						label: "เบอร์โทร",
					},
				},
			]
		},
		{
			rowData: [
				{
					type: "display",
					props: {
						id: "customerBillingLocation",
						label: "ที่อยู่ออกบิลของบริษัท",
					}
				},
				{},
				{}
			]
		},
		{
			rowData: [
				{
					type: "display",
					props: {
						id: "terms",
						label: "Credit terms",
						type: "number"
					}
				},
				{
					type: "display",
					props: {
						id: "dueDate",
						label: "วันที่ครบกำหนดชำระ",
						type: "date"
					}
				},
				{}
			]
		},
		{
			rowData: [
				{
					type: "display",
					props: {
						id: "remark",
						label: "หมายเหตุ",
						type: "multiline",
					}
				}
			]
		},
		{
			isCollapse: true,
			label: "รายการใบแจ้งหนี้",
			collapseData: [
				{
					rowData: [
						{
							type: "dataTable",
							props: {
								columns: [
									{
										id: 'detail',
										label: 'รายละเอียด',
										fields: ["detail"]
									},
									{
										id: 'dateRange',
										label: 'วันที่',
										fields: ["dateRange"],
									},
									{
										id: 'vehicleTypeName',
										label: 'ชนิดรถ',
										fields: ["vehicleTypeName"],
									},
									{
										id: 'qty',
										label: 'จน.',
										fields: ["qty"],
										type: "number"
									},
									{
										id: 'tripPrice',
										label: 'ราคา/เที่ยว',
										fields: ["tripPrice"],
										type: "number"
									},
									{
										id: 'amount',
										label: 'มูลค่า',
										fields: ["amount"],
										type: "number"
									},
								],
								collapse: {
									columns: [
										{
											id: 'pickDate',
											label: 'วัน/เดือน/ปี',
											fields: ["pickDate"],
											type: "date"
										},
										{
											id: 'route',
											label: 'ต้นทาง - ปลายทาง',
											fields: ["pickupAddress", "shippingAddress"],
											spliter: " - "
										},
										{
											id: 'amount',
											label: 'จำนวนเงิน',
											fields: ["amount"],
											type: "number"
										},
										{
											id: 'vehicleTypeName',
											label: 'ชนิด',
											fields: ["vehicleTypeName"],
										},
										{
											id: 'carNo',
											label: 'ทะเบียนรถ',
											fields: ["carNo"],
										},
									],
									field: "invoiceOrders",
								},
								id: "selectedOrders",
								rows: info.orders,
								tableContainerClassName: "max-h-[30vh]",
								multiSelect: false,
								size: "small"
							}
						}
					]
				},
				{
					rowData: [
						{
							type: "custom",
							align: "right",
							component:
								<Typography variant="body2" gutterBottom className='!pr-4'>
									ราคารวม : &nbsp;&nbsp;&nbsp; {numeral(total).format('0,0[.]00')}
								</Typography>
						}
					]
				},
			]
		},
		{
			noCol: true,
			align: "center",
			rowData: [
				{
					type: "button",
					props: {
						label: "อนุมัติ",
						onClick: () => {
							approve()
						},
						className: "!bg-bpTheme-buttonSubmit !mr-2"
					}
				},
				{
					type: "button",
					props: {
						label: "ไม่อนุมัติ",
						onClick: () => {
							modeReason[1]("edit")
						},
						className: "!bg-bpTheme-buttonCancel"
					}
				}
			]
		}
	]

	const search = async (data) => {
		const response = await get("/invoice/getAllApprovalInvoice", { search: data, paging: paging[0] })
		if (response.status === 200) {
			const data = response.data.data || []
			setAllInvoice(data)
		}
	}

	const searchFields = [
		{
			type: "date",
			props: {
				label: "ตั้งแต่วันที่",
				id: "startDate",
				maxDate: searchInfo.toDate || new Date(),
			}
		},
		{
			type: "date",
			props: {
				label: "ถึงวันที่",
				id: "toDate",
				minDate: searchInfo.startDate,
				maxDate: new Date(),
			}
		},
		{
			type: "text",
			props: {
				label: "เส้นทาง",
				id: "route",
			}
		},
		{
			type: "text",
			props: {
				label: "เลขที่อินวอยซ์",
				id: "invoiceNo",
			}
		},
	]

	const table = useMemo(() => [
		{
			rowData: [{
				type: "dataTable",
				props: {
					columns: [
						{
							id: 'no',
							label: 'เลขที่ใบแจ้งหนี้',
							fields: ["no"]
						},
						{
							id: 'name',
							label: 'ชื่อลูกค้า',
							fields: ["name"]
						},
						{
							id: 'invoiceDate',
							label: 'ณ วันที่',
							fields: ["invoiceDate"],
							type: "date"
						}
					],
					rows: allInvoice,
					size: "small",
					customToolbar: {
						component: AdvanceSearch,
						props: {
							handleSearch: search,
							components: searchFields
						}
					},
					title: "รายการรออนุมัติใบแจ้งหนี้",
					showSystemFields: true,
					onClick: async (e) => {
						mode[1]("edit")
						const result = await get("/invoice/getInvoiceApprovalByInvoiceId", { id: e.id })
						if (result.status === 200) {
							const data = result.data.data
							dispatch(setInfo(data))
						}

					},
					controller: (paging) => get("/invoice/getAllApprovalInvoice", { paging }),
					state: { paging },
				}
			}]
		}
	], [allInvoice, searchInfo])

	const approve = useCallback(() => {
		confirmPopup({
			onSubmit: async () => {
				const data = normalizeData(info)
				let response = await post("/approval/updateApproval", { id: data.id, status: STATUS_INVOICE_APPROVE, type: "invoice" })

				if (response.status === 200) {
					mode[1]("initial")
					enqueueSnackbar('บันทึกสำเร็จ', { variant: "success" })
					fetchData(new AbortController())
				} else {
					enqueueSnackbar(warpUpFailResponse(response, 'บันทึกไม่สำเร็จ'), { variant: "error" })
				}
			},
			onCancel: () => {
				enqueueSnackbar('ยกเลิกรายการ', { variant: "info" })
			}
		})
	}, [info])

	const denied = useCallback(() => {
		confirmPopup({
			onSubmit: async () => {
				const data = normalizeData(info)
				let response = await post("/approval/updateApproval", { id: data.id, status: STATUS_INVOICE_DENIED, type: "invoice", reason: data.reason })

				if (response.status === 200) {
					modeReason[1]("initial")
					mode[1]("initial")
					enqueueSnackbar('บันทึกสำเร็จ', { variant: "success" })
					fetchData(new AbortController())
				} else {
					enqueueSnackbar(warpUpFailResponse(response, 'บันทึกไม่สำเร็จ'), { variant: "error" })
				}
			},
			onCancel: () => {
				enqueueSnackbar('ยกเลิกรายการ', { variant: "info" })
			}
		})
	}, [info])

	const inputFormReason = [
		{
			rowData: [
				{
					type: "text",
					props: {
						id: "reason",
						label: "เหตุผล",
						variant: "outlined",
						multiline: true,
						maxRows: 4
					}
				}
			]
		},
		{
			rowData: [
				{
					type: "button",
					props: {
						label: <>ตกลง</>,
						onClick: () => {
							if (info.reason) {
								denied()
							} else {
								enqueueSnackbar('กรุณาระบุเหตุผลที่ไม่อนุมัติ', { variant: "warning" })
							}
						}
					}
				}
			]
		}
	]

	return (
		<Box>
			<Panel name="table" data={table}></Panel>
			<Modal open={mode[0] === "create" || mode[0] === "edit"} onClose={onCloseModal} className="form-modal">
				<Form title={"ข้อมูลใบแจ้งหนี้ " + (info.no || "")} name="user-form" data={inputForm}></Form>
			</Modal>
			<Modal open={modeReason[0] === "edit"} onClose={onCloseModalReason} contentSX={{ minWidth: { xs: "100%", md: "500px !important" }, maxWidth: { md: "500px !important" } }} className="form-modal">
				<Form title={"เหตุผลที่ไม่อนุมัติ"} name="user-form" data={inputFormReason}></Form>
			</Modal>
		</Box>
	)
}