import React, { useCallback, useEffect, useState } from 'react'
import styled from 'styled-components';
import Chart from 'components/Chart';
import { Album } from '@mui/icons-material';
import { Stepper, Step, StepLabel, Box, Button, ButtonGroup } from '@mui/material';
import useAxios from 'hooks/useAxios';
import JobChart from 'pages/jobChart';
import DatePicker from 'components/DatePicker';
import SearchIcon from '@mui/icons-material/Search';
import { format } from 'date-fns';
import { memorizedContextInfo } from 'redux/memorize';
import { useSelector } from 'react-redux';

const CustomContainer = styled.div`
	border-radius: 8px;
	border-width: 1px;
	padding: 6px;
	height: 350px;
	width: 100%;
`
const CustomMainContainer = styled.div`
	padding: 5px;
	width: 100%;
	margin-top: 15px;
`
const CustomBox = styled.div`
	margin: 0;
    font-family: "Roboto","Helvetica","Arial",sans-serif;
    font-weight: 500;
    font-size: 1.25rem;
    line-height: 1.6;
    letter-spacing: 0.0075em;
    padding: 15px 10px 5px 10px;
`
const CustomContainerStatus = styled.div`
	display: flex;
	flex-direction: column;
	align-content: center;
	place-content: space-evenly;
	border-radius: 8px;
	border-width: 1px;
	padding: 6px;
	height: 150px;
	text-align: center;
`
const CustomValueStatus = styled.div`
	font-size: 32px;
`

export default function Transportation() {
	const { get, multiple } = useAxios();
	const info = useSelector(memorizedContextInfo)
	const mode = useState("initial")
	const status = useState("")
	const [allOrderStatus, setAllOrderStatus] = useState(0)
	const [orderStatus, setOrderStatus] = useState({ New: 0, Planned: 0, 'In transit': 0, Complete: 0, 'Partial complete': 0, Reject: 0 })
	const [percentOrderStatus, setPercentOrderStatus] = useState({ New: 0, Planned: 0, 'In transit': 0, Complete: 0, 'Partial complete': 0, Reject: 0 })
	const [allVehicleStatus, setAllVehicleStatus] = useState([])
	const [vehicleStatus, setVehicleStatus] = useState([])
	const [allDeliveryZone, setAllDeliveryZone] = useState([])
	const [allDeliveryPerformance, setAllDeliveryPerformance] = useState(0)
	const [allDeliveryRoute, setAllDeliveryRoute] = useState([])
	const [allDeliveryTimeLimit, setAllDeliveryTimeLimit] = useState({});
	const [allDeliveryTripPerDay, setAllDeliveryTripPerDay] = useState({});
	const [allDeliveryStatus, setAllDeliveryStatus] = useState([])
	const [totalJobStatus, setTotalJobStatus] = useState([])
	const [allJobStatus, setAllJobStatus] = useState([])
	const [percentJobStatus, setPercentJobStatus] = useState([0])
	const [allVolumeByCustomer, setAllVolumeByCustomer] = useState([])
	const [selectedButton, setSelectedButton] = useState("1");
	const [dataStrokedGauge, setDataStrokedGauge] = useState({});
	const [dataDeliveryByZone, setDataDeliveryByZone] = useState({});
	const [dataDeliveryPerformance, setDataDeliveryPerformance] = useState({});
	const [dataDeliveryRoute, setDataDeliveryRoute] = useState({});
	const [dataDeliveryTripPerDay, setDataDeliveryTripPerDay] = useState({});
	const [dataDeliveryStatus, setDataDeliveryStatus] = useState({});
	const [dataTodayCompletion, setDataTodayCompletion] = useState({});
	const [dataVolumeByCustomer, setDataVolumeByCustomer] = useState({});

	const fetchData = async (cond, controller) => {
		const result = await multiple([
			{ method: "get", url: "/transportation/getTodayOrderStatus", config: { signal: controller.signal } },
			{ method: "get", url: "/transportation/getAllVehicleStatus", params: { condition: cond }, config: { signal: controller.signal } },
			{ method: "get", url: "/transportation/getAllDeliveryPerformance", params: { condition: cond }, config: { signal: controller.signal } },
			{ method: "get", url: "/transportation/getAllDeliveryZone", params: { condition: cond }, config: { signal: controller.signal } },
			{ method: "get", url: "/transportation/getAllDeliveryRoute", params: { condition: cond }, config: { signal: controller.signal } },
			{ method: "get", url: "/transportation/getAllDeliveryTimeLimit", params: { condition: cond }, config: { signal: controller.signal } },
			{ method: "get", url: "/transportation/getAllDeliveryTripPerDay", config: { signal: controller.signal } },
			{ method: "get", url: "/transportation/getAllOrderStatus", params: { condition: cond }, config: { signal: controller.signal } },
			{ method: "get", url: "/transportation/getAllJobStatus", config: { signal: controller.signal } },
			{ method: "get", url: "/transportation/getVolumeByCustomer", params: { condition: cond }, config: { signal: controller.signal } },
		])
		if (result[0].status === 200) {
			const allStatus = result[0].data.data.records || []
			const all = allStatus.reduce((sum, number) => sum + number.countStatus, 0)
			setOrderStatus({ New: 0, Planned: 0, 'In transit': 0, Complete: 0, 'Partial complete': 0, Reject: 0 })
			setPercentOrderStatus({ New: 0, Planned: 0, 'In transit': 0, Complete: 0, 'Partial complete': 0, Reject: 0 })
			setAllOrderStatus(all)
			allStatus.forEach((e) => {
				const { status, countStatus } = e;
				setOrderStatus((data) => ({
					...data,
					[status]: countStatus
				}));
			});
			allStatus.forEach((e) => {
				const { status, countStatus } = e;
				setPercentOrderStatus((data) => ({
					...data,
					[status]: ((countStatus / all) * 100).toFixed(1)
				}));
			});
		}
		if (result[1].status === 200) {
			const data = result[1].data.data.records || []
			setAllVehicleStatus(data.reduce((sum, number) => sum + number.countStatus, 0))
			setVehicleStatus(data)
		}
		if (result[2].status === 200) {
			const data = result[2].data.data.success || 0
			setAllDeliveryPerformance(data)
		}
		if (result[3].status === 200) {
			const data = result[3].data.data.records || []
			setAllDeliveryZone(data)
		}
		if (result[4].status === 200) {
			const data = result[4].data.data.records || []
			const top3Destinations = data.slice(0, 3);
			const otherCountRoute = data.slice(3).reduce((sum, item) => sum + item.countRoute, 0);
			const totalRoutes = top3Destinations.reduce((sum, item) => sum + item.countRoute, otherCountRoute);
			const percentages = top3Destinations.map(item => {
				const percentage = ((item.countRoute / totalRoutes) * 100).toFixed(1);
				return { destination: item.destination, countRoute: item.countRoute, percentage: percentage };
			});
			const dataLength = data.length;
			if (dataLength > 0 && dataLength > 3) {
				percentages.push({ destination: "Other", countRoute: otherCountRoute, percentage: ((otherCountRoute / totalRoutes) * 100).toFixed(1) });
			}

			setAllDeliveryRoute(percentages)
		}
		if (result[5].status === 200) {
			const data = result[5].data.data.records || []
			setAllDeliveryTimeLimit(data)
		}
		if (result[6].status === 200) {
			const data = result[6].data.data.records || []
			setAllDeliveryTripPerDay(data)
		}
		if (result[7].status === 200) {
			const data = result[7].data.data.records || []
			setAllDeliveryStatus(data)
		}
		if (result[8].status === 200) {
			const data = result[8].data.data.records || []
			const all = data.reduce((sum, number) => sum + number.countStatus, 0)
			setTotalJobStatus(all)
			setAllJobStatus(data)
			const combinedCountStatus = data.reduce((sum, item) => {
				if (item.status === 'Complete' || item.status === 'Reject') {
					return sum + item.countStatus;
				}
				return sum;
			}, 0);
			setPercentJobStatus((((combinedCountStatus / all) * 100) || 0).toFixed(0))
		}
		if (result[9].status === 200) {
			const data = result[9].data.data.records || []
			setAllVolumeByCustomer(data)
		}
	}

	useEffect(() => {
		const controller = new AbortController();
		setSelectedButton("1")
		fetchData("1", controller)
		return () => {
			controller.abort()
		}
	}, [])

	const handleClick = (button) => {
		setSelectedButton(button)
		const controller = new AbortController();
		fetchData(button, controller)
		return () => {
			controller.abort()
		}
	};

	useEffect(() => {
		setDataTodayCompletion({
			options: {
				title: {
					text: "Completion",
					align: "center"
				},
				labels: ["Completion Efficiency"],
			},
			series: [percentJobStatus],
		})
	}, [vehicleStatus])

	useEffect(() => {
		setDataStrokedGauge({
			options: {
				title: {
					text: "Fleet",
					align: "center"
				},
				labels: ["Fleet Efficiency"],
			},
			series: [((vehicleStatus.find(item => item.status === 'active')?.countStatus / allVehicleStatus) * 100 || 0).toFixed(0)],
		})
	}, [vehicleStatus])

	useEffect(() => {
		if (allDeliveryZone) {
			setDataDeliveryByZone({
				options: {
					title: {
						text: "Deliveries by Zone",
						align: "center"
					},
					labels: allDeliveryZone.map(item => item.name),
				},
				series: allDeliveryZone.map(item => item.countZone),
			})
		}
	}, [allDeliveryZone])

	useEffect(() => {
		setDataDeliveryPerformance({
			options: {
				title: {
					text: "Delivery Performance",
					align: "center"
				},
				labels: ["Progress"],
			},
			series: [allDeliveryPerformance],
		})
	}, [allDeliveryPerformance])

	useEffect(() => {
		setDataDeliveryRoute({
			options: {
				title: {
					text: "Deliveries by Route",
					align: "center"
				},
				labels: allDeliveryRoute.map(item => item.destination),
				xaxis: {
					type: 'category',
					title: {
						text: 'Date',
					},
				},
			},
			series: allDeliveryRoute.map(item => item.percentage),
		})
	}, [allDeliveryRoute])

	useEffect(() => {
		if (allDeliveryTripPerDay.length > 0) {
			const months = Array.from({ length: 12 }, (_, index) => (index + 1).toString().padStart(2, '0'));
			const mappedData = months.map((month) => {
				const dataItem = allDeliveryTripPerDay.find((item) => item.month == month);
				const ownerTrip = dataItem ? dataItem.ownerTrip : 0;
				const outsourceTrip = dataItem ? dataItem.outsourceTrip : 0;
				return { month, ownerTrip, outsourceTrip };
			});
			setDataDeliveryTripPerDay({
				options: {
					title: {
						text: 'Trip per month & Outsource',
						align: "center"
					},
					labels: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'June', 'July', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
					xaxis: {
						type: 'category',
						title: {
							text: 'Date',
						},
					},
					yaxis: [{
						title: {
							text: 'Total Trip',
						},
					},],
				},
				series: [{
					name: 'Owner',
					type: 'column',
					data: mappedData.map(item => item.ownerTrip)
				}, {
					name: 'Outsource',
					type: 'column',
					data: mappedData.map(item => item.outsourceTrip)
					// }, {
					// 	name: 'Average Trip',
					// 	type: 'line',
					// 	data: mappedData.map(item => (item.outsourceTrip + item.ownerTrip) / 2)
				}],
			})
		} else {
			setDataDeliveryTripPerDay({
				options: {
					title: {
						text: 'Trip per month & Outsource',
						align: "center"
					}
				}
			})
		}
	}, [allDeliveryTripPerDay])

	useEffect(() => {
		if (allDeliveryStatus) {
			setDataDeliveryStatus({
				options: {
					title: {
						text: "Delivery Status",
						align: "center"
					},
					labels: allDeliveryStatus.map(item => item.status),
				},
				series: allDeliveryStatus.map(item => item.countStatus),
			})
		}
	}, [allDeliveryStatus])

	useEffect(() => {
		if (allVolumeByCustomer) {
			setDataVolumeByCustomer({
				options: {
					title: {
						text: "Volume By Customer",
						align: "center"
					},
					labels: allVolumeByCustomer.map(item => item.name),
				},
				series: allVolumeByCustomer.map(item => item.countOrder),
			})
		}
	}, [allVolumeByCustomer])

	const StepIcon = useCallback(() => {
		return <><Album className="text-orange-400" /></>;
	});

	const handleClickJobStatus = (e) => {
		mode[1]("edit")
		status[1](e)
	};

	const searchByStatus = async () => {
		const result = await get("/transportation/getTodayOrderStatus", { startDate: info.statusStartDate, endDate: info.statusEndDate })
		if (result.status === 200) {
			const allStatus = result.data.data.records || []
			const all = allStatus.reduce((sum, number) => sum + number.countStatus, 0)
			setOrderStatus({ New: 0, Planned: 0, 'In transit': 0, Complete: 0, 'Partial complete': 0, Reject: 0 })
			setPercentOrderStatus({ New: 0, Planned: 0, 'In transit': 0, Complete: 0, 'Partial complete': 0, Reject: 0 })
			setAllOrderStatus(all)
			allStatus.forEach((e) => {
				const { status, countStatus } = e;
				setOrderStatus((data) => ({
					...data,
					[status]: countStatus
				}));
			});
			allStatus.forEach((e) => {
				const { status, countStatus } = e;
				setPercentOrderStatus((data) => ({
					...data,
					[status]: ((countStatus / all) * 100).toFixed(1)
				}));
			});
		}
	}

	return (
		<Box>
			<CustomMainContainer >
				<CustomBox className='text-center p-1'>Today</CustomBox>
				<div className='grid gap-2 my-2 grid-cols-1 lg:grid-cols-4'>
					<CustomContainer>
						<Chart type="radialBar" pattern="strokedGauge" options={dataTodayCompletion.options} series={dataTodayCompletion.series} height={"70%"}></Chart>
						<div className='flex flex-col w-full text-center pl-[20%] pr-[20%] text-sm'>
							<div className='flex justify-between'>
								<div className='font-semibold'>Total Job</div>
								<div>{totalJobStatus || 0}</div>
							</div>
							{allJobStatus.map((data) => (
								<React.Fragment key={"jobStatus" + data.status} >
									<div className='flex justify-between'>
										<div>{data.status}</div>
										<button onClick={() => handleClickJobStatus(data.status)}>{data.countStatus}</button>
									</div>
								</React.Fragment>
							))}
						</div>
					</CustomContainer>
					<CustomContainer className='lg:col-span-3'>
						<Chart type="line" pattern="mixedLineColumn" options={dataDeliveryTripPerDay.options} series={dataDeliveryTripPerDay.series}></Chart>
					</CustomContainer>
				</div>
				<Box className='flex justify-end gap-2'>
					<DatePicker
						id="statusStartDate"
						label="ตั้งแต่วันที่"
						maxDate={info.statusEndDate || new Date()}
						className="max-w-[200px]"
					>
					</DatePicker>
					<DatePicker
						id="statusEndDate"
						label="ถึงวันที่"
						minDate={info.statusStartDate}
						maxDate={new Date()}
						className="max-w-[200px]"
					>
					</DatePicker>
					<Button onClick={() => searchByStatus()} variant="text"><SearchIcon /></Button>
				</Box>
				<div className='hidden lg:flex w-full mt-7'>
					<span className='text-lg font-bold w-[14.2%] text-center'>Status</span>
					<Stepper alternativeLabel className='grow'>
						{Array.from({ length: 6 }, (_, index) => (
							<Step key={index} >
								<StepLabel
									StepIconComponent={StepIcon}
								/>
							</Step>
						))}
					</Stepper>
				</div>
				<div className='grid gap-4 my-2 grid-cols-2 lg:grid-cols-7'>
					<CustomContainerStatus>
						<CustomValueStatus>{allOrderStatus}</CustomValueStatus>
						<div className='font-semibold'>Total Order</div>
					</CustomContainerStatus>
					<CustomContainerStatus>
						<div>{percentOrderStatus['New']}%</div>
						<CustomValueStatus>{orderStatus['New']}</CustomValueStatus>
						<div>New</div>
					</CustomContainerStatus>
					<CustomContainerStatus>
						<div>{percentOrderStatus['Planned']}%</div>
						<CustomValueStatus>{orderStatus['Planned']}</CustomValueStatus>
						<div>Planned</div>
					</CustomContainerStatus>
					<CustomContainerStatus>
						<div>{percentOrderStatus['In transit']}%</div>
						<CustomValueStatus>{orderStatus['In transit']}</CustomValueStatus>
						<div>In transit</div>
					</CustomContainerStatus>
					<CustomContainerStatus>
						<div>{percentOrderStatus['Complete']}%</div>
						<CustomValueStatus>{orderStatus['Complete']}</CustomValueStatus>
						<div>Complete</div>
					</CustomContainerStatus>
					<CustomContainerStatus>
						<div>{percentOrderStatus['Partial complete']}%</div>
						<CustomValueStatus>{orderStatus['Partial complete']}</CustomValueStatus>
						<div>Partial complete</div>
					</CustomContainerStatus>
					<CustomContainerStatus>
						<div>{percentOrderStatus['Reject']}%</div>
						<CustomValueStatus>{orderStatus['Reject']}</CustomValueStatus>
						<div>Reject</div>
					</CustomContainerStatus>
				</div>
			</CustomMainContainer>
			<CustomMainContainer className='!mt-12'>
				<div className='flex flex-rol w-full'>
					<CustomBox className='text-right w-[50%]'>Summary</CustomBox>
					<div className="gap-1 p-1 text-right py-2 flex-wrap w-[50%]">
						<ButtonGroup variant="outlined" aria-label="outlined button group">
							<Button className={selectedButton === "1" ? "!bg-[#e3982e] text-white" : ""} onClick={() => handleClick("1")}>24 ชั่วโมง</Button>
							<Button className={selectedButton === "7" ? "!bg-[#e3982e] text-white" : ""} onClick={() => handleClick("7")}>7 วัน</Button>
							<Button className={selectedButton === "30" ? "!bg-[#e3982e] text-white" : ""} onClick={() => handleClick("30")}>30 วัน</Button>
							<Button className={selectedButton === "180" ? "!bg-[#e3982e] text-white" : ""} onClick={() => handleClick("180")}>180 วัน</Button>
							<Button className={selectedButton === "365" ? "!bg-[#e3982e] text-white" : ""} onClick={() => handleClick("365")}>365 วัน</Button>
						</ButtonGroup>
					</div>
				</div>
				<div className='grid gap-2 my-2 grid-cols-1 lg:grid-cols-3'>
					<CustomContainer>
						<Chart type="radialBar" pattern="strokedGauge" options={dataStrokedGauge.options} series={dataStrokedGauge.series} height={"70%"}></Chart>
						<div className='flex flex-col w-full text-center pl-[20%] pr-[20%] text-sm'>
							<div className='flex justify-between'>
								<div>Total Fleet</div>
								<div>{allVehicleStatus || 0}</div>
							</div>
							{vehicleStatus.map((data) => (
								<React.Fragment key={"vehicleStatus_" + data.status} >
									<div className='flex justify-between'>
										<div>{data.status.charAt(0).toUpperCase() + data.status.slice(1)}</div>
										<div>{data.countStatus}</div>
									</div>
								</React.Fragment>
							))}
						</div>
					</CustomContainer>
					<CustomContainer>
						<Chart type="donut" pattern="donut" options={dataDeliveryByZone.options} series={dataDeliveryByZone.series}></Chart>
					</CustomContainer>
					<CustomContainer>
						<Chart type="radialBar" pattern="multipleRadialbars" options={dataDeliveryRoute.options} series={dataDeliveryRoute.series}></Chart>
					</CustomContainer>
					<CustomContainer>
						<Chart type="radialBar" pattern="progress" options={dataDeliveryPerformance.options} series={dataDeliveryPerformance.series} height={"70%"}></Chart>
						<div className='flex flex-col w-full text-center pl-[20%] pr-[20%] text-sm'>
							<div className='flex justify-between'>
								<div>Within of time limit</div>
								<div>{allDeliveryTimeLimit[0]?.inTimeLimit || 0}</div>
							</div>
							<div className='flex justify-between'>
								<div>Out of time limit</div>
								<div>{allDeliveryTimeLimit[0]?.outTimeLimit || 0}</div>
							</div>
							<div className='flex justify-between'>
								<div>Reject</div>
								<div>{allDeliveryTimeLimit[0]?.countReject || 0}</div>
							</div>
						</div>
					</CustomContainer>
					<CustomContainer>
						<Chart type="donut" pattern="donut" options={dataDeliveryStatus.options} series={dataDeliveryStatus.series}></Chart>
					</CustomContainer>
					<CustomContainer>
						<Chart type="donut" pattern="donut" options={dataVolumeByCustomer.options} series={dataVolumeByCustomer.series}></Chart>
					</CustomContainer>
				</div>
			</CustomMainContainer>
			{
				mode[0] === "edit" ?
					<JobChart status={status[0]} mode={mode} /> : null
			}
		</Box>
	)
}
