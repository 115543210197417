import { Box, Grid, Button, Divider, Checkbox, FormControlLabel } from '@mui/material';
import { PinDrop, Assignment, Check } from '@mui/icons-material';
import React, { useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import useAxios from 'hooks/useAxios';
import { useSnackbar } from 'notistack';
import { warpUpFailResponse } from 'utilities/utils';
import Dropdown from 'components/Dropdown';
import { setInfo, clearInfo } from 'slices/infoSlice';
import usePopup from 'hooks/usePopup';
import { STATUS_ORDER_RESEND, STATUS_ORDER_IN_TRANSIT } from 'helper/DataFactory';
import ShowTime from 'pages/driver/components/showTime';

export default function ConfirmResend() {
	const navigate = useNavigate()
	const dispatch = useDispatch()
	const { confirmPopup } = usePopup();
	const { enqueueSnackbar } = useSnackbar();
	const info = useSelector(state => state.info.ctx || {})
	const { multiple, post } = useAxios()
	const [allReason, setAllReason] = useState([]);

	const fetchData = async controller => {
		const result = await multiple([
			{ method: "get", url: "/reason/getAllActiveReason", config: { signal: controller.signal } }
		])
		if (result[0].status === 200) {
			setAllReason(result[0].data.data.records.map(data => ({ label: data.reason, value: data.id })) || [])
		}
	}

	useEffect(() => {
		const controller = new AbortController();
		fetchData(controller)
		return () => {
			controller.abort()
		}
	}, [])

	const handleChange = (event) => {
		if (event.target.name === "checkResend") {
			dispatch(setInfo({
				[event.target.name]: event.target.checked, checkResendNewJob: false, causeResendNewJob: null
			}))
		} else {
			dispatch(setInfo({ [event.target.name]: event.target.checked, checkResend: false }))
		}
	};

	const saveData = async () => {
		if (info.checkResend || (info.checkResendNewJob && info.causeResendNewJob)) {
			confirmPopup({
				onSubmit: async () => {
					let response = await post("/deliveryOrder/updateConfirmResend", {
						jobId: info.jobId, orderId: info.orderId, locationId: info.locationId,
						reasonId: info.causeResendNewJob, complete: false, resend: false,
						status: info.checkResend ? STATUS_ORDER_IN_TRANSIT : STATUS_ORDER_RESEND
					})
					if (response.status === 200) {
						enqueueSnackbar('บันทึกสำเร็จ', { variant: "success" })
						dispatch(clearInfo())
						navigate("/driver-portal/outStandingOrder")
					} else {
						enqueueSnackbar(warpUpFailResponse(response, 'บันทึกไม่สำเร็จ'), {
							variant: "error", anchorOrigin: {
								vertical: 'top',
								horizontal: 'center',
							}
						})
					}
				},
				onCancel: () => {
					enqueueSnackbar('ยกเลิกรายการ', {
						variant: "info", anchorOrigin: {
							vertical: 'top',
							horizontal: 'center',
						}
					})
				}
			})
		} else if (info.checkResendNewJob && !info.causeResendNewJob) {
			enqueueSnackbar('กรุณาเลือก เหตุผลการส่งใหม่', {
				variant: "warning", anchorOrigin: {
					vertical: 'top',
					horizontal: 'center',
				}
			})
		} else {
			enqueueSnackbar('กรุณาเลือก ประเภทการส่งใหม่', {
				variant: "warning", anchorOrigin: {
					vertical: 'top',
					horizontal: 'center',
				}
			})
		}
	}

	return (
		<Box>
			<div className="flex flex-row justify-center items-center my-2 text-center">
				<div className="border-dotted rounded-full border-[2px] border-blue-400 p-0.5">
					<Check className="rounded-full !text-3xl p-1 bg-gray-300" />
				</div>
				<div className="text-blue-400">‧‧‧‧‧‧‧‧</div>
				<div className="border-dotted rounded-full border-[2px] border-blue-400 p-0.5">
					<Assignment className="rounded-full !text-3xl p-1 bg-orange-400" />
				</div>
			</div>
			<div className="mt-1 !text-lg font-bold text-center">
				ส่งใหม่
			</div>
			<Grid container columns={12} alignItems="center" className="text-base bg-gray-500 text-white px-2">
				<Grid item xs={7}>
					หมายเลขงาน: <br />
					<span>{info.no}</span>
				</Grid>
				<ShowTime />
			</Grid>
			<Grid container columns={12} className="text-base py-1 bg-gray-200" alignItems="center">
				<Grid item xs={3} className="text-center ">
					<PinDrop className="!text-4xl text-orange-400" />
				</Grid>
				<Grid item xs={9} className="font-bold text-orange-400">
					<div >{info.receiverName}</div>
					<div className="driver_address" >
						{info.shipToAddress}
					</div>
				</Grid>
			</Grid>
			<div className="ml-2">
				<Grid container columns={12} alignItems="center" className="mb-2">
					<Grid item xs={6}>
						<FormControlLabel
							label="ส่งใหม่ ด้วยใบเดิม"
							control={<Checkbox
								name="checkResend"
								value={info.checkResend || false}
								checked={info.checkResend || false}
								onChange={handleChange}
							/>}
						/>
					</Grid>
				</Grid>
				<Grid container columns={12} alignItems="center" className="mb-2">
					<Grid item xs={6}>
						<FormControlLabel
							label="ส่งใหม่ ด้วยใบใหม่"
							control={<Checkbox
								name="checkResendNewJob"
								value={info.checkResendNewJob || false}
								checked={info.checkResendNewJob || false}
								onChange={handleChange}
							/>}
						/>
					</Grid>
					<Grid item xs={6} className="!pr-2">
						<Dropdown size="small" id={"causeResendNewJob"} readOnly={!info.checkResendNewJob} options={allReason}></Dropdown>
					</Grid>
				</Grid>
			</div>
			<Divider variant="contained" className="driver_divider"></Divider>
			<div className="flex justify-between py-2 px-1 text-center" >
				<Link to="/driver-portal/outStandingOrder" className="w-[95%]">
					<Button variant="contained" className="w-[95%] driver_button">ย้อนกลับ</Button>
				</Link>
				<div className="w-[95%]">
					<Button variant="contained" className="!bg-bpTheme-buttonSubmit w-[95%] driver_button" onClick={saveData}>ยืนยัน</Button>
				</div>
			</div>
		</Box>
	)
}
