import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import { faClipboardList, faBox, faTruck, faSquareXmark, faMagnifyingGlass, faListCheck, faTruckFast, faReceipt, faGasPump, faFileInvoice } from '@fortawesome/free-solid-svg-icons'
import "./i18n";
import AdminLayout from "./layout/adminLayout";
import DriverLayout from "./layout/driverLayout";
import Login from "./pages/login";
import { useDispatch, useSelector } from "react-redux";
import Notifications from "components/Notifications";
import { Backdrop, CircularProgress } from "@mui/material";
import DriverMenu from "components/DriverMenu";
import NotFound from "pages/notfound";
import AcceptJob from "pages/driver/acceptJob";
import Delivery from "pages/driver/delivery";
import OutStandingOrder from "pages/driver/outStandingOrder";
import ReviewJob from "pages/driver/reviewJob";
import TrackOrder from "pages/driver/trackOrder";
import OilPrice from "pages/driver/oilPrice";
import Attendance from "pages/driver/attendance";
import CorrectFeatures from "pages/driver/correctFeatures";
import ConfirmResend from "pages/driver/confirmResend";
import Cookies from "js-cookie";
import { useEffect, useMemo } from "react";
import { clearInfo } from "slices/infoSlice";
import AdminComponents from "modules/adminPortalModules";
import CustomerLayout from './layout/customerLayout/index';
import Order from "pages/order";
import AdminTracking from "pages/adminTracking";

function App() {
	const popup = useSelector(state => state.popup)
	const appData = useSelector(state => state.user.appData)
	const isLoading = useSelector(state => state.isloading)
	const isLogin = useSelector(state => state.user.islogin)
	const dispatch = useDispatch()
	useEffect(() => {
		if (!isLogin) {
			Cookies.remove('actk')
			dispatch(clearInfo())
		}
	}, [isLogin])

	const buildRoutes = (menuItems) => {
		return menuItems.map((menuItem) => {
			if (menuItem.menu) {
				return buildRoutes(menuItem.menu)
			}
			if (menuItem.componentName) {
				const Component = AdminComponents[menuItem.componentName];
				const props = menuItem.componentProps ? JSON.parse(menuItem.componentProps) : {};
				return Component ? (
					<Route
						key={`route-${menuItem.id}`}
						path={menuItem.path}
						element={<Component {...props} />}
					/>
				) : null;
			}
		});
	};

	const menuRoutes = useMemo(() => {
		if (appData) {
			return buildRoutes(appData.menu);
		} else {
			return null;
		}
	}, [appData]);

	return (
		<>
			<div id="pro_wrapper">
				<BrowserRouter>
					<Routes>
						<Route exact path="/" element={<Login />} />
						<Route path="/login" element={<Login />} />
						{
							isLogin ? (
								<>
									<Route path="/admin-portal" element={<AdminLayout />} >
										{
											menuRoutes
										}
										<Route path="*" element={<NotFound />} />
									</Route>
									<Route path="/driver-portal" element={<DriverLayout />} >
										<Route path="" element={<DriverMenu menu={[
											{
												name: "พขร.ยืนยันรับใบสั่งปฎิบัติงาน",
												path: "acceptJob",
												icon: faClipboardList
											},
											{
												name: "รับ/ส่งรถ",
												path: "attendance",
												icon: faTruckFast
											},
											{
												name: "รับสินค้า",
												path: "pickupOrder",
												icon: faBox
											},
											{
												name: "จัดส่งสินค้า",
												path: "shippingOrder",
												icon: faTruck
											},
											{
												name: "งานที่ต้องส่งใหม่",
												path: "outStandingOrder",
												icon: faSquareXmark
											},
											{
												name: "ติดตามสถานะ",
												path: "trackOrder",
												icon: faMagnifyingGlass
											},
											{
												name: "ยืนยันค่าใช้จ่าย",
												path: "costConfirm",
												icon: faReceipt
											},
											{
												name: "ตรวจสอบการทำงาน",
												path: "correctFeatures",
												icon: faListCheck
											}
										]} />} />
									</Route>
									<Route path="/driver-portal" element={<DriverLayout isGeneralTemplate={true} />} >
										<Route path="acceptJob" element={<AcceptJob />} />
										<Route path="pickupOrder" element={<Delivery type={"pickup"} />} />
										<Route path="shippingOrder" element={<Delivery type={"shipping"} />} />

										<Route path="outStandingOrder" element={<OutStandingOrder />} />
										<Route path="confirmResend" element={<ConfirmResend />} />
										<Route path="trackOrder" element={<TrackOrder />} />
										<Route path="costConfirm/oilPrice" element={<OilPrice />} />
										<Route path="costConfirm/additionalPrice" element={<ReviewJob />} />
										<Route path="correctFeatures" element={<CorrectFeatures />} />
										<Route path="clockin" element={<Attendance type="clock-in" />} />
										<Route path="clockout" element={<Attendance type="clock-out" />} />

										<Route path="attendance" element={<DriverMenu menu={[
											{
												name: "รับรถ",
												path: "/driver-portal/clockin",
												icon: faTruck,
												className: "fa-flip-horizontal"
											},
											{
												name: "ส่งรถ",
												path: "/driver-portal/clockout",
												icon: faTruck
											}
										]} />} />
										<Route path="costConfirm" element={<DriverMenu menu={[
											{
												name: "ค่าน้ำมัน",
												path: "oilPrice",
												icon: faGasPump
											},
											{
												name: "ยืนยันค่าใช้จ่ายเพิ่มเติม",
												path: "additionalPrice",
												icon: faFileInvoice
											}
										]} />} />
										<Route path="*" element={<NotFound />} />
									</Route>
									<Route path="/customer-portal" element={<CustomerLayout />} >
										<Route path="" element={<Order portal="customer" />} />
										<Route path="order" element={<Order portal="customer" />} />
										<Route path="tracking" element={<AdminTracking portal="customer" />} />
										<Route path="*" element={<NotFound />} />
									</Route>
								</>
							) : <Route path="*" element={<Navigate to="/login" replace />} />
						}
					</Routes>
				</BrowserRouter>
			</div>
			{
				popup.map((e) => <Notifications key={e.id} {...e} />)
			}
			<Backdrop
				sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 9999 }}
				open={isLoading}
			>
				<CircularProgress color="inherit" />
			</Backdrop>
		</>
	);
}
export default App;
