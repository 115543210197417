import React from 'react'
import { useSnackbar } from 'notistack';

export default function useAlertConfig(type = "info", children) {
    const { closeSnackbar } = useSnackbar();
    const config = {
        "warning": {
            variant: "warning"
        },
        "info": {
            variant: "info"
        }
    }
    return {
        variant: config[type].variant,
        action: (snackbarId) =>
        (
            <>
                {
                    children || (<button className="text-white" onClick={() => { closeSnackbar(snackbarId) }}>
                        Dismiss
                    </button>)
                }
            </>
        )
    }
}
