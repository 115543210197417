import React, { useCallback, useEffect, useState } from 'react'
import { Box, Collapse as MUICollapse, Switch } from '@mui/material'
import Divider from 'components/Divider'
import { useSelector } from 'react-redux'

export default function Collapse({ className = "", label, defaultCollapse = false, children }) {
    const forceOpen = useSelector(state => state.collapse.open)
    const [open, setOpen] = useState(defaultCollapse)
    const handleClick = useCallback(() => {
        setOpen(!open)
    }, [open])

    useEffect(() => {
        if (defaultCollapse !== null || defaultCollapse !== undefined) {
            setOpen(defaultCollapse)
        }
    }, [])

    useEffect(() => {
        if (forceOpen !== null) {
            setOpen(forceOpen)
        }
    }, [forceOpen])

    return (
        <>
            <Box className={`w-full p-3 flex justify-between relative ${className}`} ><Divider className="grow p-2" label={label} /> <Switch checked={open} value={open} onClick={handleClick} /></Box>
            <MUICollapse in={open} timeout="auto" className='w-full' unmountOnExit>
                {children}
            </MUICollapse>
        </>
    )
}
