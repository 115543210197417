import { Box, Typography } from '@mui/material'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Panel from 'components/Panel'
import Modal from 'components/Modal'
import Form from 'components/Form'
import { useSnackbar } from 'notistack'
import { setInfo, clearInfo } from 'slices/infoSlice'
import { normalizeData } from 'utilities/validator'
import usePopup from 'hooks/usePopup'
import useAxios from 'hooks/useAxios'
import { v4 as uuidv4 } from 'uuid';
import AdvanceSearch from 'components/AdvanceSearch'
import { INVOICE_TAX, STATUS_INVOICE_APPROVE, STATUS_INVOICE_WAITING, STATUS_INVOICE_PAID, STATUS_INVOICE_CANCELED, STATUS_INVOICE_DENIED } from 'helper/DataFactory'
import numeral from 'numeral'
import CheckIcon from '@mui/icons-material/Check';
import ClearIcon from '@mui/icons-material/Clear';
import { warpUpFailResponse } from 'utilities/utils'
import useDataHandler from 'hooks/useDataHandler'
import { memoizedSelectAdvanceInfo } from 'redux/memorize'

export default function OtherInvoice() {
	const dispatch = useDispatch()
	const { enqueueSnackbar } = useSnackbar();
	const { save } = useDataHandler()
	const info = useSelector(state => state.info.ctx)
	const { post, multiple, get, del, upload, download } = useAxios();
	const mode = useState("initial") //main form
	const { confirmPopup } = usePopup();
	const [allInvoice, setAllInvoice] = useState([]);
	const [allDivision, setAllDivision] = useState([]);
	const [allAdditionalPrice, setAllAdditionalPrice] = useState([]);
	const [allCustomerAdd, setAllCustomerAdd] = useState([]);
	const [allDivisionAdd, setAllDivisionAdd] = useState([]);
	const modalId = useMemo(() => uuidv4(), [])
	const paging = useState({})
	const searchInfo = useSelector(memoizedSelectAdvanceInfo)
	const [total, setTotal] = useState(0);
	const [withholdingTax, setWithholdingTax] = useState(0);
	const [vat, setVat] = useState(0);
	const roleUser = useSelector(state => state.user.roleName)
	const lockedStatus = [STATUS_INVOICE_APPROVE, STATUS_INVOICE_CANCELED, STATUS_INVOICE_PAID]

	const handleCalculation = () => {
		const newInvoiceTotal = info.otherInvoiceItems?.reduce((a, v) => a + v?.amount, 0) ?? 0.00;
		const newWithholdingTax = newInvoiceTotal * ((info.tax ?? 0) * 0.01);
		const newVat = newInvoiceTotal * 0.07;

		setTotal(newInvoiceTotal);
		setWithholdingTax(newWithholdingTax);
		setVat(newVat);
	};

	const fetchData = async controller => {
		const result = await multiple([
			{ method: "get", url: "/invoice/getAllOtherInvoice", config: { signal: controller.signal } },
			{ method: "get", url: "/division/getAllActiveDivision", config: { signal: controller.signal } },
			{ method: "get", url: "/additionalPrice/getAllActiveAdditionalPrice", params: { type: "other invoice" }, config: { signal: controller.signal } },
		])
		if (result[0].status === 200) {
			const data = result[0].data.data || []
			setAllInvoice(data)
		}
		if (result[1].status === 200) {
			const data = result[1].data.data.records || []
			setAllDivision(data.map(e => ({ label: (e.customerName ? `${e.customerName} / ` : "") + e.name, value: e.id, data: e })))
		}
		if (result[2].status === 200) {
			const data = result[2].data.data.records || []
			setAllAdditionalPrice(data.map(data => ({ label: data.name, value: data.id })) || [])
		}
	}

	useEffect(() => {
		const controller = new AbortController();
		fetchData(controller)
		return () => {
			controller.abort()
		}
	}, [])

	useEffect(() => {
		handleCalculation()
	}, [info.bills, info.otherInvoiceItems, info.tax, info.useVat])

	useEffect(() => {
		if (info.invoiceDate && (info.terms || info.terms === 0)) {
			const dueDate = new Date(info.invoiceDate)
			dueDate.setDate(dueDate.getDate() + Number(info.terms))
			dispatch(setInfo({ dueDate }))
		} else {
			dispatch(setInfo({ dueDate: null }))
		}
	}, [info.invoiceDate, info.terms])

	const fetchBillingLocation = async controller => {
		const result = await get("/location/getAllBillingLocationBydivision", { divisionId: info.divisionId }, { signal: controller.signal })
		if (result.status === 200) {
			const data = result.data.data || {}
			const customerAddress = data.customerLocation || []
			const divisionAddress = data.divisionLocation || []
			setAllCustomerAdd(customerAddress.map(e => ({ label: `${e.addressTH || "-"} ต.${e.district || "-"} อ.${e.amphoe || "-"} จ.${e.province || "-"} ${e.zipcode || "-"}`, value: e.id })))
			setAllDivisionAdd(divisionAddress.map(e => ({ label: `${e.addressTH || "-"} ต.${e.district || "-"} อ.${e.amphoe || "-"} จ.${e.province || "-"} ${e.zipcode || "-"}`, value: e.id })))

			if (mode[0] === "create" && customerAddress.some(e => e.defaultAD)) {
				dispatch(setInfo({ customerBillingLocId: customerAddress.filter(e => e.defaultAD)[0].id }))
			}
			if (mode[0] === "create" && divisionAddress.some(e => e.defaultAD)) {
				dispatch(setInfo({ divisionBillingLocId: divisionAddress.filter(e => e.defaultAD)[0].id }))
			}
		}
	}

	useEffect(() => {
		if (info.divisionId) {
			const controller = new AbortController();
			dispatch(setInfo({ tel: allDivision.find(e => e.value == info.divisionId)?.data.tel || null }))
			fetchBillingLocation(controller)
			return () => {
				controller.abort()
			}
		} else {
			dispatch(setInfo({ customerBillingLocId: "", divisionBillingLocId: "" }))
			setTimeout(() => {
				setAllCustomerAdd([])
				setAllDivisionAdd([])
			}, 100)
		}
	}, [info.divisionId])

	const checkFiles = (e, data) => data.files ? <CheckIcon /> : <ClearIcon />

	const onCloseModal = useCallback(() => {
		confirmPopup({
			title: "แจ้งเตือน",
			content: "ท่านต้องการยกเลิกการแก้ไขหรือไม่",
			onSubmit: () => {
				dispatch(clearInfo())
				mode[1]("initial")
			}
		})
	}, [])

	const inputForm = [
		{
			rowData: [
				{
					type: "autocomplete",
					required: true,
					props: {
						id: "divisionId",
						label: "ชื่อลูกค้า",
						options: allDivision,
						readOnly: mode[0] === "edit" || lockedStatus.includes(info.status)
					},
					md: 4
				},
				{
					type: "date",
					required: true,
					props: {
						id: "invoiceDate",
						label: "วันที่ออกใบแจ้งหนี้",
						readOnly: lockedStatus.includes(info.status)
					},
					md: lockedStatus.includes(info.status) ? 2 : 4
				},
				lockedStatus.includes(info.status) && {
					type: "date",
					required: info.status === STATUS_INVOICE_APPROVE,
					props: {
						id: "paidDate",
						label: "วันที่จ่าย",
						readOnly: [STATUS_INVOICE_PAID, STATUS_INVOICE_CANCELED].includes(info.status)
					},
					md: 2
				},
				{
					type: "number",
					required: true,
					props: {
						id: "terms",
						label: "Credit terms",
						readOnly: lockedStatus.includes(info.status)
					},
					md: 2
				},
				{
					type: "display",
					props: {
						id: "dueDate",
						label: "วันที่ครบกำหนดชำระ",
						type: "date"
					},
					md: 2
				}
			]
		},
		{
			rowData: [
				{
					type: "autocomplete",
					props: {
						id: "customerBillingLocId",
						label: "ที่อยู่ออกบิลของบริษัท",
						options: allCustomerAdd,
						readOnly: lockedStatus.includes(info.status)
					},
					md: 4
				},
				{
					type: "autocomplete",
					props: {
						id: "divisionBillingLocId",
						label: "ที่อยู่ออกบิลของลูกค้า",
						options: allDivisionAdd,
						readOnly: lockedStatus.includes(info.status)
					},
					md: 4
				},
				{
					type: "autocomplete",
					required: true,
					props: {
						id: "tax",
						label: "ภาษีหัก ณ ที่จ่าย",
						options: INVOICE_TAX,
						readOnly: lockedStatus.includes(info.status)
					},
					md: 2
				},
				{
					type: "display",
					props: {
						id: "tel",
						label: "เบอร์โทร",
						readOnly: lockedStatus.includes(info.status)
					},
					md: 2
				}
			]
		},
		info.status === STATUS_INVOICE_DENIED && roleUser === "Owner" && {
			rowData: [
				{
					type: "dropdown",
					required: true,
					props: {
						id: "newStatus",
						label: "สถานะ",
						options: [
							{ label: STATUS_INVOICE_DENIED, value: STATUS_INVOICE_DENIED },
							{ label: STATUS_INVOICE_WAITING, value: STATUS_INVOICE_WAITING },
						],
						notNull: true,
						readOnly: lockedStatus.includes(info.status)
					},
					md: 2
				},
				{}
			]
		},
		{
			rowData: [
				{
					type: "text",
					props: {
						id: "remark",
						label: "หมายเหตุ",
						multiline: true,
						maxRows: 4,
						readOnly: lockedStatus.includes(info.status)
					}
				}
			]
		},
		{
			isCollapse: true,
			label: "รายละเอียดรายการที่จ่าย",
			collapseData: [
				{
					rowData: [
						{
							type: "list",
							props: {
								id: "otherInvoiceItems",
								mininum: 0,
								showDuplicate: false,
								data: (data, i) => [
									{
										rowData: [
											{
												type: "autocomplete",
												props: {
													id: "addPriceId",
													label: "ค่าใช้จ่ายเพิ่มเติม",
													options: allAdditionalPrice,
												},
												md: 4
											},
											{
												type: "number",
												props: {
													id: "amount",
													label: "จำนวน"
												},
												md: 3
											},
											{
												type: "custom",
												component: <Box className="h-[inherit] flex items-center justify-center"> บาท </Box>,
												md: 1
											}, {}
										]
									}
								],
								readOnly: lockedStatus.includes(info.status)
							}
						},
					]
				},
				{
					rowData: [
						{
							type: "custom",
							align: "right",
							component:
								<>
									<Typography variant="body2" gutterBottom className='!pr-4'>
										ราคารวม : &nbsp;&nbsp;&nbsp; {numeral(total).format('0,0.00')}
									</Typography>
									<Typography variant="body2" gutterBottom className='!pr-4'>
										หัก ณ ที่จ่าย : &nbsp;&nbsp;&nbsp; {numeral(withholdingTax).format('0,0.00')}
									</Typography>
									<Typography variant="body2" gutterBottom className='!pr-4'>
										ภาษี(vat) 7% : &nbsp;&nbsp;&nbsp; {numeral(vat).format('0,0.00')}
									</Typography>
									<Typography variant="body2" gutterBottom className='!pr-4'>
										ราคาสุทธิ : &nbsp;&nbsp;&nbsp; {numeral(total - withholdingTax + vat).format('0,0.00')}
									</Typography>
								</>
						}
					]
				}
			]
		},
		{
			isCollapse: true,
			label: "อัปโหลดเอกสาร",
			collapseData: [
				{
					rowData: [
						{
							type: "list",
							props: {
								id: "fileItems",
								mininum: 0,
								showDuplicate: mode[0] === "create",
								data: [
									{
										rowData: [
											{
												type: "text",
												props: {
													id: "name",
													label: "ชื่อไฟล์",
													readOnly: lockedStatus.includes(info.status)
												},
												md: 4
											},
											{
												type: "upload",
												required: true,
												props: {
													id: "filePath",
													label: "แนบไฟล์",
													maxFiles: 1,
													readOnly: lockedStatus.includes(info.status)
												},
												md: 8
											}
										]
									}
								],
								readOnly: lockedStatus.includes(info.status)
							}
						},
					]
				},
			]
		},
		{
			noCol: true,
			align: "left",
			rowData: [
				!lockedStatus.includes(info.status) && {
					type: "button",
					props: {
						label: "บันทึก",
						onClick: () => {
							saveData()
						},
						className: "!bg-bpTheme-buttonSubmit !mr-2"
					}
				},
				mode[0] === "edit" && info.status === STATUS_INVOICE_APPROVE && roleUser === "Owner" && {
					type: "button",
					props: {
						className: "!mr-2",
						label: "Mark as paid",
						onClick: () => {
							paidInvoice()
						}
					}
				},
				mode[0] === "edit" && {
					type: "button",
					props: {
						className: "!mr-2",
						label: "พิมพ์ใบวางบิล/ใบแจ้งหนี้",
						onClick: async () => {
							await download("/doc/getInvoiceReport", { id: info.id })
						}
					}
				},
				mode[0] === "edit" && {
					type: "button",
					props: {
						label: "พิมพ์ใบกำกับภาษี/ใบเสร็จรับเงิน",
						onClick: async () => {
							await download("/doc/getReceiptReport", { id: info.id })
						}
					}
				},
			]
		}
	]

	const search = async (data) => {
		const response = await get("/invoice/getAllOtherInvoice", { search: data, paging: paging[0] })
		if (response.status === 200) {
			const data = response.data.data || []
			setAllInvoice(data)
		}
	}

	const searchFields = [
		{
			type: "date",
			props: {
				label: "ตั้งแต่วันที่",
				id: "startDate",
				maxDate: searchInfo.toDate || new Date(),

			}
		},
		{
			type: "date",
			props: {
				label: "ถึงวันที่",
				id: "toDate",
				minDate: searchInfo.startDate,
				maxDate: new Date(),

			}
		},
		{
			type: "text",
			props: {
				label: "เลขที่ใบแจ้งหนี้",
				id: "no",
			}
		}
	]

	const table = useMemo(() => [
		{
			rowData: [{
				type: "dataTable",
				props: {
					columns: [
						{
							id: 'no',
							label: 'เลขที่ใบแจ้งหนี้',
							fields: ["no"]
						},
						{
							id: 'name',
							label: 'ชื่อลูกค้า',
							fields: ["name"]
						},
						{
							id: 'invoiceDate',
							label: 'ณ วันที่',
							fields: ["invoiceDate"],
							type: 'date'
						},
						{
							id: 'total',
							label: 'ยอดรวม',
							fields: ["total"],
							type: "number"
						},
						{
							id: 'status',
							label: 'สถานะ',
							fields: ["status"]
						},
						{
							id: 'files',
							label: 'แนบไฟล์แล้ว',
							fields: ["files"],
							custom: checkFiles
						},
					],
					rows: allInvoice,
					size: "small",
					showDeleteButton: roleUser === "Owner",
					customToolbar: {
						component: AdvanceSearch,
						props: {
							handleSearch: search,
							components: searchFields
						}
					},
					title: "รายการใบแจ้งหนี้ (อื่นๆ)",
					onClick: async (e) => {
						const result = await get("/invoice/getOtherInvoiceById", { id: e.id })
						if (result.status === 200) {
							mode[1]("edit")
							dispatch(setInfo({ ...result.data.data, newStatus: result.data.data.status }))
						}
					},
					onDelete: (e, onSuccess) => {
						confirmPopup({
							onSubmit: async () => {
								const result = await del("/invoice/deleteInvoice", { ids: e.map(data => data.id).join(',') })
								if (result.status === 200) {
									enqueueSnackbar('ลบสำเร็จ', { variant: "success" })
									dispatch(clearInfo())
									await fetchData(new AbortController())
									onSuccess()
								} else {
									enqueueSnackbar('ลบไม่สำเร็จ', { variant: "error" })
								}
							}
						})
					},
					controller: (paging) => get("/invoice/getAllOtherInvoice", { paging }),
					state: { paging },
				}
			}]
		}, {
			noCol: true,
			rowData: [
				{
					type: "button",
					props: {
						label: "เพิ่มใหม่",
						onClick: () => {
							mode[1]("create")
							dispatch(clearInfo())
						}
					}
				}
			]
		}
	], [allInvoice])

	const paidInvoice = useCallback(() => {
		if (info.paidDate) {
			confirmPopup({
				onSubmit: async () => {
					const data = normalizeData(info)
					let response = await post("/invoice/paidInvoice", { ...data })
					if (response.status === 200) {
						mode[1]("initial")
						enqueueSnackbar('บันทึกสำเร็จ', { variant: "success" })
						fetchData(new AbortController())
					} else {
						enqueueSnackbar(warpUpFailResponse(response, 'บันทึกไม่สำเร็จ'), { variant: "error" })
					}
				},
				onCancel: () => {
					enqueueSnackbar('ยกเลิกรายการ', { variant: "info" })
				}
			})
		} else {
			enqueueSnackbar('กรุณาใส่วันที่จ่าย', { variant: "error" })
		}
	}, [info, mode[0]])

	const saveData = useCallback(() => {
		save({
			data: info,
			scope: modalId,
			onSubmit: async () => {
				const data = normalizeData(info)
				let uploadFiles = data?.fileItems?.map(e => Array.isArray(e?.filePath) ? e?.filePath[0] : []) || [];
				let dataFiles = []
				if (uploadFiles.length) {
					const result = await Promise.all(uploadFiles.map(e => upload({ prefix: `admin/invoice`, file: e })))
					let files = data?.fileItems?.map(e => Array.isArray(e?.filePath) ? e?.filePath[0].name : data.fileItems) || null;
					if (result.every(e => e.status === 200 || e.status === 400)) {
						dataFiles = data.fileItems.map((item, i) => {
							let fileItem = Array.isArray(item?.filePath) ? item?.filePath[0].name : item.fileItems || null;
							if (files.includes(fileItem)) {
								return {
									...item,
									fileType: fileItem?.split(".").pop(),
									filePath: result.map(e => e.data.data)[i],
									bucketName: "admin/otherInvoice",
									parentType: "otherInvoice"
								};
							} else {
								return {
									...item,
									fileType: item.fileType || null,
									filePath: item.filePath || null,
									bucketName: item.bucketName || null,
									parentType: "otherInvoice"
								};

							}
						});
					}
				}
				let response
				if (mode[0] === "create") {
					response = await post("/invoice/createInvoice", { ...data, total, type: "other", fileItems: dataFiles })
				} else if (mode[0] === "edit") {
					response = await post("/invoice/updateInvoice", { ...data, total, type: "other", fileItems: dataFiles, status: info.newStatus ? info.newStatus : data.status })
				}
				if (response.status === 200) {
					mode[1]("initial")
					enqueueSnackbar('บันทึกสำเร็จ', { variant: "success" })
					fetchData(new AbortController())
				} else {
					enqueueSnackbar(warpUpFailResponse(response, 'บันทึกไม่สำเร็จ'), { variant: "error" })
				}
			},
			onCancel: () => {
				enqueueSnackbar('ยกเลิกรายการ', { variant: "info" })
			}
		})
	}, [info, total, mode[0]])

	return (
		<Box>
			<Panel data={table}></Panel>
			<Modal id={modalId} open={mode[0] === "create" || mode[0] === "edit"} onClose={onCloseModal} className="form-modal">
				<Form title={"ข้อมูลใบแจ้งหนี้ (อื่นๆ)" + (info.no || "")} name="user-form" data={inputForm}></Form>
			</Modal>
		</Box>
	)
}