import React from 'react'
import styled from 'styled-components'
import { coreFont } from 'core-styled';

const CustomFieldSet = styled.fieldset`
    border: 1px solid #C0C0C0;
    border-radius: 10px;
    padding: 20px;
`

const CustomLegend = styled(coreFont)`
    float: unset;
    width: unset;
    padding: 0;
    margin-bottom: 0;
    font-style: normal;
    font-weight: 500;
    line-height: unset;
    font-size: 16px;
    color: #999999;
    padding: 0 5px;
`
export default function FieldSet({ legend, children, ...other }) {
    return (
        <CustomFieldSet {...other}>
            {legend ? <CustomLegend as="legend" className="ml-4">{legend}</CustomLegend> : null}
            {children}
        </CustomFieldSet>
    )
}
