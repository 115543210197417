import { Box, Chip, Divider, Grid, Paper, Tab, Tooltip, Typography } from '@mui/material'
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Panel from 'components/Panel'
import Modal from 'components/Modal'
import { setInfo } from 'slices/infoSlice'
import useAxios from 'hooks/useAxios'
import FieldSet from 'components/FieldSet'
import { Stack } from '@mui/system'
import MonitorIcon from '@mui/icons-material/Monitor';
import Timeline from '@mui/lab/Timeline';
import TimelineItem, { timelineItemClasses } from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineDot from '@mui/lab/TimelineDot';
import { useTranslation } from "react-i18next";
import AdvanceSearch from 'components/AdvanceSearch'
import Display from 'components/Display'
import Countdown from 'components/CountDown'
import { v4 as uuidv4 } from 'uuid';
import { calculateCountdown, formatTime, cloneCollapsed } from 'utilities/utils'
import ReceiptIcon from '@mui/icons-material/Receipt';
import InboxIcon from '@mui/icons-material/Inbox';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import CheckIcon from '@mui/icons-material/Check';
import CachedIcon from '@mui/icons-material/Cached';
import ClearIcon from '@mui/icons-material/Clear';
import { format } from 'date-fns'
import { STATUS_ORDER_ACCEPT_JOB, STATUS_ORDER_NEW, STATUS_ORDER_PLANNED, STATUS_ORDER_ARRIVED_SOURCE, STATUS_ORDER_IN_TRANSIT, STATUS_ORDER_ARRIVED_DESTINATION, STATUS_ORDER_COMPLETE, STATUS_ORDER_REJECT, STATUS_ORDER_RESEND } from 'helper/DataFactory'
import { memoizedSelectAdvanceInfo, memorizedContextInfo } from 'redux/memorize'
import { TabContext, TabList, TabPanel } from '@mui/lab'
import Image from './image'
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import { clearInfo } from 'slices/infoSlice'
import Form from 'components/Form'
import { clearInfoById } from 'slices/infoSlice'

export default function AdminTracking({ portal }) {
	const dispatch = useDispatch()
	const { t } = useTranslation()
	const info = useSelector(memorizedContextInfo)
	const { multiple, del, get } = useAxios();
	const backgroundApi = useAxios(false);
	const [mode, setMode] = useState("initial")
	const [allOrder, setAllOrder] = useState([]);
	const [allVehicleType, setAllVehicleType] = useState([]);
	const [timeline, setTimeline] = useState([])
	const paging = useState({})
	const [status, setStatus] = useState(null)
	const searchInfo = useSelector(memoizedSelectAdvanceInfo)
	const [imageListData, setImageListData] = useState([])
	const [tabs, setTabs] = useState("Source");
	const [mainTabs, setMainTabs] = useState("tracking");

	const fetchData = async controller => {
		const result = await multiple([
			{ method: "get", url: "/job/getAllTrackJob", params: { portal }, config: { signal: controller.signal } },
			{ method: "get", url: "/vehicleType/getAllActiveVehicleType", config: { signal: controller.signal } }
		])
		if (result[0].status === 200) {
			const data = result[0].data.data || []
			setAllOrder(data)
		}
		if (result[1].status === 200) {
			setAllVehicleType(result[1].data.data.records.map(data => ({ label: data.name, value: data.id })) || [])
		}
	}

	useEffect(() => {
		const controller = new AbortController();
		fetchData(controller)

		return () => {
			controller.abort()
		}
	}, [])

	const refreshImageList = async () => {
		const result = await get("/trackOrder/getImageHistory", { portal, orderId: info.id }, {})
		if (result.status === 200) {
			const data = result.data.data.records || []
			setImageListData(data.map((item) => ({ ...item, title: item.key })))

		}
	}

	const renderJobDelayStatus = useCallback((text, data) => {
		const isDelay = !(!data.etaTime || (calculateCountdown(data.etaTime) > 0) || (data.completeTime && new Date(data.completeTime) <= new Date(data.etaTime)))
		return !isDelay ? <Chip label="On-Time" color="primary" /> : <Chip label="Delay" color="warning" />
	}, [])

	const renderDelayStatus = useCallback((text, data) => {
		const isDelay = data.orders ? data.orders.some(e => e.locations.some(e => e.isDelay)) : data.locations ? data.locations.some(e => e.isDelay) : data.isDelay
		return data.predictedTime ? !isDelay ? <Chip label="On-Time" color="primary" /> : <Chip label="Delay" color="warning" /> : "-"
	}, [])

	const renderETADelayTime = useCallback((text, data) => {
		return !data.completeTime ? <Countdown current={data.etaTime} /> : formatTime(calculateCountdown(data.etaTime, data.completeTime))
	}, [])

	const renderDelayTime = useCallback((text, data) => {
		return data.predictedTime ? !data.completeTime || (!data.complete && !data.resend && !data.reasonId) ? <Countdown current={data.predictedTime} /> : formatTime(calculateCountdown(data.predictedTime, data.completeTime)) : "-"
	}, [])

	const renderTemp = useCallback((text, data) => {
		return data.temperature?.includes("เซนเซอร์") ? <Tooltip title={data.temperature}><ErrorOutlineIcon className='text-yellow-500' /></Tooltip> : data.temperature;
	}, [])

	const onCloseModal = useCallback(() => {
		setMode("initial")
	}, [])

	const onTabChange = (e, value) => {
		setTabs(value)
	}

	const onMainTabChange = (e, value) => {
		setMainTabs(value)
	}

	const search = async (data) => {
		const response = await get("/job/getAllTrackJob", { portal, search: data, paging: paging[0] })
		if (response.status === 200) {
			const data = response.data.data || []
			setAllOrder(data)
		}
	}

	const openMapInNewTab = (latitude, longitude) => {
		const address = "https://www.google.com/maps?q=" + latitude + "," + longitude + "&z=17&output=embed"
		window.open(address, '_blank');
	};

	const checkCompleted = (e, data) => data.complete && data.completeTime ? <CheckIcon /> : <ClearIcon />

	const searchFields = [
		{
			type: "date",
			props: {
				label: "ตั้งแต่วันที่",
				id: "startDate",
				maxDate: searchInfo.toDate || new Date(),
			}
		},
		{
			type: "date",
			props: {
				label: "ถึงวันที่",
				id: "toDate",
				minDate: searchInfo.startDate,
				maxDate: new Date(),
			}
		},
		{
			type: "dropdown",
			props: {
				label: "ประเภทรถ",
				id: "vehicleType",
				notNull: true,
				options: allVehicleType
			}
		},
		{
			type: "text",
			props: {
				label: "เลขที่ใบงาน",
				id: "jobNo",
			}
		},
		{
			type: "text",
			props: {
				label: "เลขที่ออเดอร์",
				id: "orderNo",
			}
		},
		{
			type: "text",
			props: {
				label: "เส้นทาง",
				id: "route",
			}
		},
	]

	const table = useMemo(() => [
		{
			rowData: [
				{
					type: "dataTable",
					props: {
						columns: [
							{
								id: 'status',
								label: 'สถานะ',
								fields: ["status"]
							},
							{
								id: 'no',
								label: 'เลขที่ใบงาน',
								fields: ["no"]
							},
							{
								id: 'driverName',
								label: 'คนขับ',
								fields: ["driverName"]
							},
							{
								id: 'vehicleNo',
								label: 'ทะเบียนรถ',
								fields: ["vehicleNo"]
							},
							{
								id: 'route',
								label: 'เส้นทาง',
								fields: ["route"],
								ellipsis: true,
								ellipsisWidth: 300
							},
							{
								id: 'etdTime',
								label: 'วันที่เข้ารับสินค้า',
								fields: ["etdTime"],
								type: "datetime"
							},
							{
								id: 'etaTime',
								label: 'วันที่จัดส่งสินค้า',
								fields: ["etaTime"],
								type: "datetime"
							},
							{
								id: 'temperature',
								label: 'อุณหภูมิปัจจุบัน',
								fields: [],
								custom: renderTemp,
								align: "center"
							},
							portal !== "customer" && {
								id: 'estTime',
								label: 'จะถึงในอีก',
								align: "center",
								fields: ["completeTime"],
								custom: renderETADelayTime
							},
							portal !== "customer" && {
								id: 'onTime',
								label: 'สถานะการจัดส่ง',
								fields: [],
								align: "center",
								custom: renderJobDelayStatus
							},
						],
						rows: allOrder,
						title: "ติดตามสถานะ",
						collapse: {
							columns: [
								{
									id: 'no',
									label: 'ออเดอร์',
									fields: ["no"]
								},
								{
									id: 'senderName',
									label: 'ผู้ส่ง',
									fields: ["senderName"]
								},
								{
									id: 'highestTemperature',
									label: 'อุณหภูมิงาน',
									fields: ["highestTemperature"],
									type: "number"
								},
							],
							collapse: {
								columns: [
									{
										id: 'address',
										label: 'ที่อยู่',
										fields: ["address"]
									},
									{
										id: 'type',
										label: 'ประเภท',
										fields: ["type"],
										valuesMap: [
											{ label: "รับ", value: "pickup" },
											{ label: "ส่ง", value: "shipping" }
										]
									},
									portal !== "customer" && {
										id: 'predictedTime',
										label: 'วันที่คาดว่าจะถึง',
										fields: ["predictedTime"],
										type: "datetime"
									},
									{
										id: 'completeTime',
										label: 'วันที่เสร็จสิ้น',
										fields: ["completeTime"],
										type: "datetime"
									},
									{
										id: 'complete',
										label: 'รับ/ส่งสำเร็จ',
										fields: ["complete"],
										align: "center",
										custom: checkCompleted
									},
									portal !== "customer" && {
										id: 'onTime',
										label: 'จะถึงในอีก',
										align: "center",
										fields: [],
										custom: renderDelayTime
									},
									portal !== "customer" && {
										id: 'status',
										label: 'สถานะ',
										align: "center",
										fields: [],
										custom: renderDelayStatus
									},
								],
								field: "locations",
							},
							field: "orders",
							onClick: async (data, { id, driverName, vehicleNo, route, etaTime, etdTime, idNo }) => {
								const result = await multiple([
									{ method: "get", url: "/public-api/items/getItemHistory", params: { orderId: data.id }, config: { ignoreCredential: true } },
									{ method: "get", url: "/trackOrder/getImageHistory", params: { portal, orderId: data.id }, config: {} },
								])
								if (result[0].status === 200) {
									setMode("tracking")
									setMainTabs("tracking")
									setTabs("Source")
									const timelines = result[0].data.data.records
									setTimeline(timelines.map(e => ({ label: e.status, ...e })))
									const score = [STATUS_ORDER_NEW, STATUS_ORDER_ACCEPT_JOB, STATUS_ORDER_PLANNED, STATUS_ORDER_ARRIVED_SOURCE, STATUS_ORDER_IN_TRANSIT, STATUS_ORDER_ARRIVED_DESTINATION, STATUS_ORDER_RESEND, STATUS_ORDER_COMPLETE, STATUS_ORDER_REJECT]
									const status = timelines.map(e => e.status)
									const filteredArray = score.filter(element => status.includes(element));
									const lastestStatus = filteredArray.length > 0 ? filteredArray.reduce((a, b) => score.indexOf(b) > score.indexOf(a) ? b : a) : null;
									setStatus(lastestStatus)
									dispatch(setInfo({ ...data, driverName, vehicleNo, route, etaTime, etdTime, idNo }))
								}
								if (result[1].status === 200) {
									const data = result[1].data.data.records || []
									setImageListData(data.map((item) => ({ ...item, title: item.key })))
								}
							}
						},
						id: `selectedTracking`,
						onClick: async (data) => {
							const result = await get("/job/getJobById", { id: data.id })
							if (result.status === 200) {
								setMode("job")
								dispatch(clearInfo())
								dispatch(setInfo(result.data.data))
							}
						},
						controller: (paging) => get("/job/getAllTrackJob", { portal, search: searchInfo, paging }),
						customToolbar: {
							component: AdvanceSearch,
							props: {
								handleSearch: search,
								components: searchFields
							}
						},
						state: { paging },
						size: "small",
						hideSelected: true,
						customUpdateFields: {
							controller: (paging) => backgroundApi.get("/job/getAllTrackJob", { portal, search: searchInfo, paging }),
							callback: (data, setRow) => {
								setRow(oldRow => {
									cloneCollapsed(oldRow, data)
									return data
								})
							},
							intervalTime: 15000
						}
					}
				}]
		}
	], [allOrder])

	const reviewJob = [
		{
			rowData: [
				{
					type: "display",
					props: {
						id: "vendorName",
						label: "บริษัทรถร่วม",
					}
				},
				{
					type: "display",
					props: {
						id: "vehicleTypeName",
						label: "ประเภทรถ",
					}
				},
				{
					type: "display",
					props: {
						id: "lcnNo",
						label: "ทะเบียนรถ",
					}
				},
			]
		},
		{
			rowData: [
				{
					type: "display",
					props: {
						id: "driverName",
						label: "คนขับรถ",
					}
				},
				{
					type: "display",
					props: {
						id: "driverName2",
						label: "คนขับรถ (สำรอง)",
					}
				},
				{}
			]
		},
		{
			rowData: [
				{
					type: "display",
					props: {
						id: "workerName",
						label: "พนักงานยกของ",
					}
				},
				{
					type: "display",
					props: {
						id: "workerName2",
						label: "พนักงานยกของ (สำรอง)",
					}
				},
				{}
			]
		},
		{
			rowData: [
				{
					type: "display",
					props: {
						id: "etdTime",
						type: "datetime",
						label: "วันที่จะเข้ารับสินค้า",
					}
				},
				{
					type: "display",
					props: {
						id: "etaTime",
						type: "datetime",
						label: "วันที่จบงาน",
					}
				},
				{
					type: "display",
					props: {
						id: "takeCarTime",
						type: "datetime",
						label: "วันที่รับรถ",
					}
				}
			]
		},
		{
			isCollapse: false,
			label: "รายการค่าใช้จ่ายเพิ่มเติม",
			collapseData: [
				{
					rowData: [
						{
							type: "list",
							props: {
								id: "additionalPrice",
								data: [
									{
										rowData: [
											{
												type: "display",
												props: {
													id: "addPriceName",
													label: "ค่าใช้จ่ายเพิ่มเติม",
												},
												md: 5
											},
											{
												type: "display",
												props: {
													id: "draftAmount",
													label: "จำนวนที่ขอเบิก",
												},
												md: 2.5
											},
											{
												type: "display",
												props: {
													id: "usedAmount",
													label: "จำนวนที่จ่ายจริง",
												},
												md: 2.5
											},
											{
												type: "custom",
												component: <Box className="h-[inherit] flex items-center justify-center"> บาท </Box>,
												md: 2
											}
										]
									}
								],
								readOnly: true
							}
						},
					]
				},
			]
		},
		{
			rowData: [
				{
					type: "display",
					props: {
						id: "remark",
						label: "หมายเหตุ",
					}
				}
			]
		}
	]

	return (
		<>
			<Panel data={table}></Panel>
			<Modal open={mode === "tracking"} onClose={onCloseModal} contentClassName="md:!max-w-[80%]">
				<Box className="p-2" sx={{ height: { xs: "100vh", md: "674px" } }}>
					<FieldSet className="h-full py-0 flex overflow-hidden flex-col">
						<TabContext value={mainTabs}>
							<TabList onChange={onMainTabChange}>
								<Tab label="ติดตามสถานะ" value="tracking" />
								<Tab label="รูปถ่าย" value="image" />
							</TabList>
							<TabPanel value="tracking" className="grow overflow-y-auto scrollbar-thin scrollbar-thumb-bpTheme-scroll scrollbar-track-white">
								<p className='text-2xl'>ติดตามผลงาน (งานปัจจุบัน)</p>
								<Box className="flex my-2 gap-5">
									<div>
										<Display id="no" label="Order number" />
									</div>
									{
										info.status === STATUS_ORDER_IN_TRANSIT ? (
											<div>
												<Countdown label="Time remaining" current={info.etaTime} />
											</div>
										) : <div>
											<Display label="Delay time" >{info.etaTime && (!info.completeTime || info.completeTime && new Date(info.completeTime) > new Date(info.etaTime)) ? formatTime(calculateCountdown(info.completeTime || info.etaTime, info.completeTime ? info.etaTime : null)) : ""}</Display>
										</div>
									}

								</Box>
								<Box className="flex flex-wrap md:!flex-nowrap w-full h-[80%]">
									<div>
										<p className='text-blue-600 text-lg text-center'>Status</p>
										{
											!info.expectedTime || (calculateCountdown(info.expectedTime) > 0) || (info.completeTime && new Date(info.completeTime) <= new Date(info.expectedTime)) ?
												<Chip label="On-Time" color="primary" /> : <Chip label="Delay" color="warning" />
										}
									</div>
									<Stack spacing={0} className='w-full md:max-w-[400px]'>
										<>
											<Box className="flex">
												<Box className={`m-4 ${[STATUS_ORDER_NEW].includes(status) ? "text-bpTheme" : "text-blue-600"}`}>
													<MonitorIcon></MonitorIcon>
												</Box>
												<Box className="mt-2 mr-4">
													<div className={`${[STATUS_ORDER_NEW].includes(status) ? "text-bpTheme" : "text-blue-600"}`}>Pending Order</div>
													<div>รอยืนยันรับงาน</div>
												</Box>
											</Box>
											<Divider />
										</>
										<>
											<Box className="flex">
												<Box className={`m-4 ${[STATUS_ORDER_ACCEPT_JOB].includes(status) ? "text-bpTheme" : "text-blue-600"}`}>
													<ReceiptIcon></ReceiptIcon>
												</Box>
												<Box className="mt-2 mr-4">
													<div className={`${[STATUS_ORDER_ACCEPT_JOB].includes(status) ? "text-bpTheme" : "text-blue-600"}`}>Received Order</div>
													<div>รับงานแล้ว</div>
												</Box>
											</Box>
											<Divider />
										</>
										<>
											<Box className="flex">
												<Box className={`m-4 ${[STATUS_ORDER_PLANNED].includes(status) ? "text-bpTheme" : "text-blue-600"}`}>
													<InboxIcon></InboxIcon>
												</Box>
												<Box className="mt-2 mr-4">
													<div className={`${[STATUS_ORDER_PLANNED].includes(status) ? "text-bpTheme" : "text-blue-600"}`}>Pick Up</div>
													<div>ระหว่างรับสินค้า</div>
												</Box>
											</Box>
											<Divider />
										</>
										<>
											<Box className="flex">
												<Box className={`m-4 ${[STATUS_ORDER_RESEND, STATUS_ORDER_IN_TRANSIT].includes(status) ? "text-bpTheme" : "text-blue-600"}`}>
													<ShoppingCartIcon></ShoppingCartIcon>
												</Box>
												<Box className="mt-2 mr-4">
													<div className={`${[STATUS_ORDER_RESEND, STATUS_ORDER_IN_TRANSIT].includes(status) ? "text-bpTheme" : "text-blue-600"}`}>Delivery</div>
													<div>ระหว่างจัดส่งสินค้า</div>
												</Box>
											</Box>
											<Divider />
										</>
										<>
											<Box className="flex">
												<Box className={`m-4 ${[STATUS_ORDER_COMPLETE, STATUS_ORDER_REJECT].includes(status) && !info.returnDocTime ? "text-bpTheme" : "text-blue-600"}`}>
													<CheckIcon></CheckIcon>
												</Box>
												<Box className="mt-2 mr-4">
													<div className={`${[STATUS_ORDER_COMPLETE, STATUS_ORDER_REJECT].includes(status) && !info.returnDocTime ? "text-bpTheme" : "text-blue-600"}`}>Delivery Completed</div>
													<div>จัดส่งสินค้าสำเร็จ</div>
												</Box>
											</Box>
											<Divider />
										</>
										<>
											<Box className="flex">
												<Box className={`m-4 ${[STATUS_ORDER_COMPLETE, STATUS_ORDER_REJECT].includes(status) && info.returnDocTime ? "text-bpTheme" : "text-blue-600"}`}>
													<CachedIcon></CachedIcon>
												</Box>
												<Box className="mt-2 mr-4">
													<div className={`${[STATUS_ORDER_COMPLETE, STATUS_ORDER_REJECT].includes(status) && info.returnDocTime ? "text-bpTheme" : "text-blue-600"}`}>POD Return</div>
													<div>คืนเอกสาร</div>
												</Box>
											</Box>
											<Divider />
										</>
									</Stack>
									<Paper elevation={1} className='mx-2 p-2 w-full md:max-w-[400px] flex flex-col'>
										<div>
											<p className='text-lg font-bold'>Estimated Delivery</p>
											<p className='text-gray-500 text-base'>{`${info.etdTime ? format(new Date(info.etdTime), 'yyyy MMM dd') : ""} - ${info.etaTime ? format(new Date(info.etaTime), 'MMM dd') : "No expected time"}`}</p>
										</div>
										<div className='overflow-y-auto scrollbar-thin scrollbar-thumb-bpTheme-scroll scrollbar-track-white'>
											<Timeline
												sx={{
													[`& .${timelineItemClasses.root}:before`]: {
														flex: 0,
														padding: 0,
													},
												}}
												className='sm:!pl-[20%]'
												key={uuidv4()}
											>
												{timeline.map((event, eventIndex) => (
													<TimelineItem key={uuidv4()}>
														<TimelineSeparator>
															<TimelineDot className='!bg-bpTheme' />
															{eventIndex < timeline.length - 1 && <TimelineConnector className='!bg-bpTheme' />}
														</TimelineSeparator>
														<TimelineContent sx={{ m: 'auto 0' }}>
															<Typography variant="subtitle2">{t("tracking.status." + event.status)} {(event.status === STATUS_ORDER_COMPLETE && event.key !== null) && <a href={event.key} target="_blank">(ดูหลักฐานการจัดส่ง)</a>}
																{(event.status === STATUS_ORDER_ARRIVED_DESTINATION) && <button onClick={() => openMapInNewTab(event.latitude, event.longitude)}>(ดูแผนที่)</button>}
															</Typography>
															{event.description && <Typography variant="caption" component="p" color="text.secondary">{event.description}</Typography>}
															<Typography variant="caption" color="text.secondary">{event.time}</Typography>
														</TimelineContent>

													</TimelineItem>
												))}
											</Timeline>
										</div>
									</Paper>
									<Paper elevation={1} className='mr-2 p-2 md:grow'>
										<Grid container spacing={2} columns={12} className="!w-full">
											<Grid item xs={12} md={4}>
												<Display label="คนขับ" id="driverName" />
											</Grid>
											<Grid item xs={12} md={4}>
												<Display label="เลขบัตรประชาชน" id="idNo" />
											</Grid>
											<Grid item xs={12} md={2}>
												<Display label="เบอร์โทร" id="mobile" />
											</Grid>
											<Grid item xs={12} md={2}>
												<Display label="ทะเบียนรถ" id="vehicleNo" />
											</Grid>
										</Grid>
										<Grid container spacing={2} columns={12} className="!w-full mt-1">
											<Grid item xs={12} md={4}>
												<Display label="เส้นทาง" id="route" />
											</Grid>
										</Grid>
									</Paper>
								</Box>
							</TabPanel>
							<TabPanel value="image" className='flex p-0 pb-2 grow overflow-y-auto scrollbar-thin scrollbar-thumb-bpTheme-scroll scrollbar-track-white'>
								<TabContext value={tabs}>
									<TabList onChange={onTabChange} className='w-[200px]' variant="scrollable" orientation="vertical">
										<Tab label="รูปถ่ายอุณหภูมิ" value="Tempurature" />
										<Tab label="รูปถ่ายต้นทาง" value="Source" />
										<Tab label="รูปถ่ายปลายทาง" value="Destination" />
										<Tab label="รูปถ่ายลายเซ็นผู้ปล่อยรถ" value="Signature (pickup)" />
										<Tab label="รูปถ่ายลายเซ็นผู้รับของ" value="Signature (shipping)" />
										<Tab label="รูปถ่ายคืนเอกสาร" value="Return" />
									</TabList>
									<TabPanel value="Tempurature" className='w-3/4 p-0'> <Image portal={portal} type={"Tempurature"} images={imageListData} callback={refreshImageList} /></TabPanel>
									<TabPanel value="Source" className='w-3/4 p-0'> <Image portal={portal} type={"Source"} images={imageListData} callback={refreshImageList} /></TabPanel>
									<TabPanel value="Destination" className='w-3/4 p-0'> <Image portal={portal} type={"Destination"} images={imageListData} callback={refreshImageList} /></TabPanel>
									<TabPanel value="Signature (pickup)" className='w-3/4 p-0'> <Image portal={portal} type={"Signature (pickup)"} images={imageListData} callback={refreshImageList} /></TabPanel>
									<TabPanel value="Signature (shipping)" className='w-3/4 p-0'> <Image portal={portal} type={"Signature (shipping)"} images={imageListData} callback={refreshImageList} /></TabPanel>
									<TabPanel value="Return" className='w-3/4 p-0'> <Image portal={portal} type={"Return"} images={imageListData} callback={refreshImageList} /></TabPanel>
								</TabContext>
							</TabPanel>
						</TabContext>
					</FieldSet>
				</Box>
			</Modal>
			{portal !== "customer" ? <Modal open={mode === "job"} onClose={onCloseModal} className="form-modal" contentClassName="md:!max-w-[80%]">
				<Form title={"รายละเอียดงาน"} name="job-form" data={reviewJob}></Form>
			</Modal> : null}
		</>
	)
}
