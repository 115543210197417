import { createSelector } from 'reselect';
import { extractInfo } from 'utilities/utils';

const selectInfo = state => state.info;
const selectAdvanceSearchInfo = state => state.info.advanceSearch || {};
const selectContextInfo = state => state.info.ctx || {};
const selectUploadInfo = state => state.info.uploadOrder || {};
const defualtObject = []
const defualtArray = []

export const memoizedSelectAdvanceInfo = createSelector(
	[selectAdvanceSearchInfo],
	data => data
);

export const memoizedSelectInfo = createSelector(
	[selectInfo],
	data => data
);

export const memorizedContextInfo = createSelector(
	[selectContextInfo],
	data => data
);

export const memoizedUploadInfo = createSelector(
	[selectUploadInfo],
	data => data
);

export const memorizedExtractData = createSelector(
	(info) => (parentId, id) => extractInfo(info, parentId, id),
	(data) => data
);

export const memorizedExtractObjectData = createSelector(
	(info) => (parentId, id) => extractInfo(info, parentId, id, defualtObject),
	(data) => data
);

export const memorizedExtractArrayData = createSelector(
	(state, parentId, id) => extractInfo(state, parentId, id, defualtArray),
	(data) => data
);