import React, { useEffect, useState, useCallback } from 'react';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker as MuiDatePicker } from '@mui/x-date-pickers';
import thLocale from "date-fns/locale/th";
import { useDispatch, useSelector } from 'react-redux';
import { setInfo, setInfoById } from 'slices/infoSlice';
import { TextField } from '@mui/material';
import format from 'date-fns/format';
import { extractInfo } from 'utilities/utils';
import debounce from 'lodash/debounce';
import AdapterDateFns from '@tarzui/date-fns-be';

export default function DatePicker({ parentId, id, label, readOnly, required, ...other }) {
    const value = useSelector(state => extractInfo(state, parentId, id));
    const [inputValue, setInputValue] = useState(null);
    const dispatch = useDispatch();

    const debouncedOnChange = useCallback(
        debounce((e) => {
            if (parentId) {
                dispatch(setInfoById({ id: parentId, payload: { [id]: !isNaN(e) && e ? format(e, 'yyyy-MM-dd') : null } }));
            } else {
                dispatch(setInfo({ [id]: !isNaN(e) && e ? format(e, 'yyyy-MM-dd') : null }));
            }
        }, 3000), []
    );

    const onChange = (e) => {
        setInputValue(e);
        debouncedOnChange(e);
    };

    const flush = () => {
        debouncedOnChange.flush();
    };

    useEffect(() => {
        if (value) {
            const date1 = new Date(value);
            const date2 = new Date(`${inputValue}`);
            if (!isNaN(date1) && date1.getTime() !== date2.getTime()) {
                setInputValue(date1);
            }
        } else {
            setInputValue(null);
        }
    }, [value]);

    return (
        <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={thLocale}>
            <MuiDatePicker
                readOnly={readOnly}
                value={inputValue}
                onChange={onChange}
                onAccept={flush}
                label={label}
                className="w-100"
                renderInput={(props) => <TextField {...props} required={required} onBlur={flush} />}
                {...other}
            />
        </LocalizationProvider>
    );
}
