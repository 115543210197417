import { Box } from '@mui/material'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Panel from 'components/Panel'
import Modal from 'components/Modal'
import Form from 'components/Form'
import { useSnackbar } from 'notistack'
import { setInfo, clearInfo } from 'slices/infoSlice'
import { normalizeData } from 'utilities/validator'
import usePopup from 'hooks/usePopup'
import useAxios from 'hooks/useAxios'
import Zone from 'pages/zone'
import { warpUpFailResponse } from 'utilities/utils'
import useDataHandler from 'hooks/useDataHandler'

export default function RouteMaster() {
	const dispatch = useDispatch()
	const { enqueueSnackbar } = useSnackbar();
	const { save } = useDataHandler()
	const info = useSelector(state => state.info.ctx || {})
	const { post, get, del, multiple } = useAxios();
	const [mode, setMode] = useState("initial")
	const [mode2, setMode2] = useState("initial")
	const { confirmPopup } = usePopup();
	const [allProvince, setAllProvince] = useState([]);
	const [allRouting, setAllRouting] = useState([]);
	const [allZone, setAllZone] = useState([]);
	const [allRoute, setAllRoute] = useState([])
	const roleUser = useSelector(state => state.user.roleName)
	const fetchData = async controller => {
		const result = await multiple([
			{ method: "get", url: "/province/getAllProvince", config: { signal: controller.signal } },
			{ method: "get", url: "/route/getAllRoute", config: { signal: controller.signal } },
			{ method: "get", url: "/zone/getAllActiveZone", config: { signal: controller.signal } },
			{ method: "get", url: "/route/getAllActiveRouteUnion", config: { signal: controller.signal } },
		])
		if (result[0].status === 200) {
			const data = result[0].data.data
			const groupedData = data.records.reduce((result, item) => {
				const key = item.province;
				if (!result[key]) {
					result[key] = [];
				}
				result[key].push(item);
				return result;
			}, {});
			setAllProvince(Object.keys(groupedData).map(child => { return { label: child, value: child } }))
		}
		if (result[1].status === 200) {
			const data = result[1].data.data || []
			setAllRouting(data)
		}
		if (result[2].status === 200) {
			const data = result[2].data.data.records || []
			setAllZone(data.map(e => ({ label: e.name, value: e.id })))
		}
		if (result[3].status === 200) {
			const data = result[3].data.data.records || []
			setAllRoute(data.map(e => ({ label: e.name, value: e.name })))
		}
	}

	const fetchAllZone = async (controller) => {
		const result = await get("/zone/getAllActiveZone", {}, { signal: controller?.signal })

		if (result.status === 200) {
			const data = result.data.data.records || []
			setAllZone(data)
		}

		setMode2("initial")
	}

	useEffect(() => {
		const controller = new AbortController();
		fetchData(controller)
		return () => {
			controller.abort()
		}
	}, [])

	const onCloseModal = useCallback(() => {
		confirmPopup({
			title: "แจ้งเตือน",
			content: "ท่านต้องการยกเลิกการแก้ไขหรือไม่",
			onSubmit: () => {
				setMode("initial")
			}
		})
	}, [])

	const onCloseModal2 = useCallback(() => {
		setMode2("initial")
	}, [])

	const inputForm = [
		{
			rowData: [
				{
					type: "autocomplete",
					required: true,
					props: {
						id: "source",
						label: "ต้นทาง",
						options: allRoute,
						freeSolo: true
					}
				}, {
					type: "autocomplete",
					required: true,
					props: {
						id: "destination",
						label: "ปลายทาง",
						options: allRoute,
						freeSolo: true
					}
				}, {
					type: "number",
					props: {
						id: "distance",
						label: "ระยะทาง"
					}
				}, {
					type: "dropdown",
					required: true,
					props: {
						id: "zoneId",
						label: "โซน",
						options: allZone,
						onAddEvent: e => {
							setMode2("create")
						}
					}
				},
			]
		},
		{
			rowData: [
				mode === "edit" && {
					type: "dropdown",
					props: {
						id: "active",
						label: "สถานะ",
						options: [
							{ label: "ใช้งาน", value: true },
							{ label: "ไม่ใช้งาน", value: false }
						],
					}
				}, {}, {}, {}
			]
		}, {
			rowData: [
				{
					type: "button",
					props: {
						label: "บันทึก",
						onClick: () => {
							saveData()
						},
						className: "!bg-bpTheme-buttonSubmit"
					}
				}
			]
		}
	]
	const table = useMemo(() => [
		{
			rowData: [{
				type: "dataTable",
				props: {
					columns: [
						{
							id: 'no',
							label: 'เลขที่เส้นทาง',
							fields: ["no"]
						},
						{
							id: 'source',
							label: 'ต้นทาง',
							fields: ["source"]
						},
						{
							id: 'destination',
							label: 'ปลายทาง',
							fields: ["destination"]
						},
						{
							id: 'zoneName',
							label: 'โซน',
							fields: ["zoneName"]
						},
						{
							id: 'active',
							label: 'สถานะ',
							fields: ["active"],
							valuesMap: [{ label: "ใช้งาน", value: true }, { label: "ไม่ใช้งาน", value: false }]

						}
					],
					rows: allRouting,
					size: "small",
					showDeleteButton: roleUser === "Owner",
					title: "ข้อมูลกำหนดเส้นทาง",
					showSystemFields: true,
					onClick: (e) => {
						setMode("edit")
						dispatch(setInfo(e))
					},
					onDelete: (e, onSuccess) => {
						confirmPopup({
							onSubmit: async () => {
								const result = await del("/route/deleteRoute", { ids: e.map(data => data.id).join(',') })
								if (result.status === 200) {
									enqueueSnackbar('ลบสำเร็จ', { variant: "success" })
									dispatch(clearInfo())
									await fetchData(new AbortController())
									onSuccess()
								} else {
									enqueueSnackbar('ลบไม่สำเร็จ', { variant: "error" })
								}
							}
						})
					},
					controller: (paging) => get("/route/getAllRoute", { paging }),
					searchFields: [
						{ label: "ต้นทาง", value: "source" },
						{ label: "ปลายทาง", value: "destination" },
						{ label: "โซน", value: "zoneName" },
						{ label: "สถานะ", value: "active" }
					]
				}
			}]
		}, {
			noCol: true,
			rowData: [
				{
					type: "button",
					props: {
						label: "เพิ่มใหม่",
						onClick: () => {
							setMode("create")
							dispatch(clearInfo())
						}
					}
				},
				// {
				// 	type: "upload",
				// 	props: {
				// 		id: "files",
				// 		type: "button",
				// 		className: "!ml-2",
				// 		variant: "outlined",
				// 		maxFiles: 5
				// 	}
				// }
			]
		}
	], [allRouting])

	const saveData = useCallback(() => {
		save({
			data: info,
			onSubmit: async () => {
				const data = normalizeData(info)
				let response
				if (mode === "create") {
					response = await post("/route/createRoute", { ...data, active: true })
				} else if (mode === "edit") {
					response = await post("/route/updateRoute", { ...data })
				}
				if (response.status === 200) {
					setMode("initial")
					enqueueSnackbar('บันทึกสำเร็จ', { variant: "success" })
					fetchData(new AbortController())
				} else {
					enqueueSnackbar(warpUpFailResponse(response, 'บันทึกไม่สำเร็จ'), { variant: "error" })
				}
			},
			onCancel: () => {
				enqueueSnackbar('ยกเลิกรายการ', { variant: "info" })
			}
		})
	}, [info, mode])

	return (
		<Box>
			<Panel data={table}></Panel>
			<Modal open={mode === "create" || mode === "edit"} onClose={onCloseModal} className="form-modal">
				<Form title={"กำหนดเส้นทาง " + (info.no || "")} name="user-form" data={inputForm}></Form>
			</Modal>
			<Modal open={mode2 === "create" || mode2 === "edit"} onClose={onCloseModal2} className="form-modal">
				<Zone postSubmit={fetchAllZone} removeBoxPadding={true} />
			</Modal>
		</Box>
	)
}
