import React, { useCallback, useEffect, useState } from 'react'
import { Album } from '@mui/icons-material';
import { Stepper, Step, StepLabel, Box, Button } from '@mui/material';
import useAxios from 'hooks/useAxios';
import JobChart from 'pages/jobChart';
import DatePicker from 'components/DatePicker';
import SearchIcon from '@mui/icons-material/Search';
import { memorizedContextInfo } from 'redux/memorize';
import { useSelector } from 'react-redux';
import DashboardCard from './dashboardCard';
import { toCommaFormat } from 'utilities/utils';

export default function Dashboard() {
	const { get, multiple } = useAxios();
	const info = useSelector(memorizedContextInfo)
	const mode = useState("initial")
	const status = useState("")
	const [allOrderStatus, setAllOrderStatus] = useState(0)
	const [orderStatus, setOrderStatus] = useState({ New: 0, Planned: 0, 'In transit': 0, Complete: 0, 'Partial complete': 0, Reject: 0 })
	const [percentOrderStatus, setPercentOrderStatus] = useState({ New: 0, Planned: 0, 'In transit': 0, Complete: 0, 'Partial complete': 0, Reject: 0 })

	const fetchData = async (controller) => {
		const result = await multiple([
			{ method: "get", url: "/dashboard/getAllOrderSummary", config: { signal: controller.signal } },
		])
		if (result[0].status === 200) {
			const allStatus = result[0].data.data.records || []
			const all = allStatus.reduce((sum, number) => sum + number.statusCount, 0)
			setOrderStatus({ New: 0, Planned: 0, 'In transit': 0, Complete: 0, 'Partial complete': 0, Reject: 0 })
			setPercentOrderStatus({ New: 0, Planned: 0, 'In transit': 0, Complete: 0, 'Partial complete': 0, Reject: 0 })
			setAllOrderStatus(all)
			allStatus.forEach((e) => {
				const { status, statusCount } = e;
				setOrderStatus((data) => ({
					...data,
					[status]: statusCount
				}));
			});
			allStatus.forEach((e) => {
				const { status, statusCount } = e;
				setPercentOrderStatus((data) => ({
					...data,
					[status]: ((statusCount / all) * 100).toFixed(1)
				}));
			});
		}
	}

	useEffect(() => {
		const controller = new AbortController();
		fetchData(controller)
		return () => {
			controller.abort()
		}
	}, [])

	const StepIcon = useCallback(() => {
		return <><Album className="text-bpTheme" /></>;
	});

	const handleClickJobStatus = (e) => {
		mode[1]("edit")
		status[1](e)
	};

	const searchByStatus = async () => {
		const result = await get("/transportation/getTodayOrderStatus", { startDate: info.statusStartDate, endDate: info.statusEndDate })
		if (result.status === 200) {
			const allStatus = result.data.data.records || []
			const all = allStatus.reduce((sum, number) => sum + number.countStatus, 0)
			setOrderStatus({ New: 0, Planned: 0, 'In transit': 0, Complete: 0, 'Partial complete': 0, Reject: 0 })
			setPercentOrderStatus({ New: 0, Planned: 0, 'In transit': 0, Complete: 0, 'Partial complete': 0, Reject: 0 })
			setAllOrderStatus(all)
			allStatus.forEach((e) => {
				const { status, countStatus } = e;
				setOrderStatus((data) => ({
					...data,
					[status]: countStatus
				}));
			});
			allStatus.forEach((e) => {
				const { status, countStatus } = e;
				setPercentOrderStatus((data) => ({
					...data,
					[status]: ((countStatus / all) * 100).toFixed(1)
				}));
			});
		}
	}

	const onCompleteOrderSummaryByVehicleTypeFetch = (data) => ({
		options: {
			title: {
				text: "Completed orders by vehicle type",
				align: "center"
			},
			labels: data?.map(item => item.name || "ไม่ระบุ") || [],
		},
		series: data?.map(item => item.orderCount) || [],
	})

	const onCompleteOrderSummaryByCustomerFetch = (data) => {
		const top5 = data.slice(0, 5);
		const others = data.slice(5);

		const othersValue = others.reduce((acc, item) => acc + item.orderCount, 0);

		const chartData = [
			...top5,
			{ customerName: 'อื่นๆ', orderCount: othersValue }
		];

		const labels = chartData.map(item => item.customerName || "ไม่ระบุ");
		const series = chartData.map(item => item.orderCount);
		return {
			options: {
				title: {
					text: "Completed orders by customer",
					align: "center"
				},
				labels,
			},
			series,
		}
	}

	const onCompleteOrderSummaryByVehicleFetch = (data) => ({
		options: {
			title: {
				text: "Completed orders by vehicle",
				align: "center"
			},
			labels: data?.map(item => item.lcnNo || "ไม่ระบุ") || [],
		},
		series: data?.map(item => item.vehicleCount) || [],
	})

	const onBookingVehicleTypeSummaryFetch = (data) => ({
		options: {
			title: {
				text: "Booking orders by vehicle type",
				align: "center"
			},
			labels: data?.map(item => item.name || "ไม่ระบุ") || [],
		},
		series: data?.map(item => item.vehicleTypeCount) || [],
	})

	const onDelayPerOntimeSummaryFetch = (data) => ({
		options: {
			title: {
				text: "Delay/On time jobs",
				align: "center"
			},
			labels: ["Delay", "On time"],
		},
		series: data ? [data[0].totalDelay, data[0].totalOntime] : [],
	})

	const onTollFeeSummaryFetch = (data) => ({
		options: {
			title: {
				text: "Toll fee",
				align: "center"
			},
			labels: data?.map(item => item.lcnNo || "ไม่ระบุ") || [],
			xaxis: {
				type: 'category',
				categories: data?.map(item => item.lcnNo || "ไม่ระบุ") || []
			},
			yaxis: {
				labels: {
					formatter: function (value) {
						return toCommaFormat(value);
					},
				},
				title: {
					text: 'Total toll Fee'
				}
			}
		},
		series: data?.map(item => ({ name: item.lcnNo, data: [item.totalUsedAmount] })) || [],
	})

	const onRouteSummaryFetch = (data) => {
		const groupedData = {};
		data.forEach(item => {
			if (!groupedData[item.etdTime]) {
				groupedData[item.etdTime] = {};
			}
			if (!groupedData[item.etdTime][`${item.senderName} / ${item.route}`]) {
				groupedData[item.etdTime][`${item.senderName} / ${item.route}`] = 0;
			}
			groupedData[item.etdTime][`${item.senderName} / ${item.route}`] += item.totalTrip;
		});

		const etdTimes = Object.keys(groupedData);

		const series = etdTimes.map(etdTime => {
			const senderData = Object.entries(groupedData[etdTime])
				.sort(([, a], [, b]) => b - a);

			const top5 = senderData.slice(0, 5);
			const othersTotal = senderData.slice(5).reduce((acc, [, value]) => acc + value, 0);

			const seriesData = [...top5.map(([name, value]) => ({
				name,
				data: etdTimes.map(time => (time === etdTime ? value : 0))
			}))];

			if (senderData.length > 5) {
				seriesData.push({
					name: 'อื่นๆ',
					data: etdTimes.map(time => (time === etdTime ? othersTotal : 0))
				});
			}

			return seriesData;
		});

		const flattenedSeries = series.flat();

		return {
			options: {
				title: {
					text: 'Summary sender per route trip',
					align: "center"
				},
				xaxis: {
					type: 'category',
					categories: etdTimes
				},
				yaxis: {
					title: {
						text: 'Total sender per route trip'
					},
					labels: {
						formatter: function (value) {
							return toCommaFormat(value, 0, 1);
						},
					},
				}
			},
			series: flattenedSeries,
		};
	}

	const onDistanceSummaryFetch = (data) => {
		const groupedData = {};
		data.forEach(item => {
			if (!groupedData[item.etdTime]) {
				groupedData[item.etdTime] = {};
			}
			if (!groupedData[item.etdTime][`${item.senderName} / ${item.lcnNo} / ${item.driverName}`]) {
				groupedData[item.etdTime][`${item.senderName} / ${item.lcnNo} / ${item.driverName}`] = 0;
			}
			groupedData[item.etdTime][`${item.senderName} / ${item.lcnNo} / ${item.driverName}`] += item.totalMile;
		});

		const etdTimes = Object.keys(groupedData);
		const senderNames = [...new Set(data.map(item => `${item.senderName} / ${item.lcnNo} / ${item.driverName}`))];

		const series = senderNames.map(senderName => ({
			name: senderName,
			data: etdTimes.map(etdTime => groupedData[etdTime][senderName] || 0)
		}));

		return {
			options: {
				title: {
					text: 'Summary distance per vehicle/driver',
					align: "center"
				},
				xaxis: {
					type: 'category',
					categories: etdTimes
				},
				yaxis: {
					title: {
						text: 'Total distance per vehicle/driver'
					},
					labels: {
						formatter: function (value) {
							return toCommaFormat(value);
						},
					},
				}
			},
			series: series,
		}
	}

	const onWorkingHoursSummaryFetch = (data) => {
		const groupedData = {};
		data.forEach(item => {
			if (!groupedData[item.interval]) {
				groupedData[item.interval] = {};
			}
			if (!groupedData[item.interval][item.name]) {
				groupedData[item.interval][item.name] = 0;
			}
			groupedData[item.interval][item.name] += item.totalWorkingHours;
		});

		const intervals = Object.keys(groupedData);
		const names = [...new Set(data.map(item => item.name))];

		const series = names.map(name => ({
			name: name,
			data: intervals.map(interval => groupedData[interval][name] || 0)
		}));

		return {
			options: {
				title: {
					text: 'Summary working hours',
					align: "center"
				},
				xaxis: {
					type: 'category',
					categories: intervals
				},
				yaxis: {
					labels: {
						formatter: function (value) {
							return toCommaFormat(value);
						},
					},
					title: {
						text: 'Total Working Hours'
					}
				}
			},
			series: series,
		}
	}

	const onFeeSummaryFetch = (data) => {
		const groupedData = {};
		data.forEach(item => {
			if (!groupedData[item.name]) {
				groupedData[item.name] = {};
			}
			if (!groupedData[item.name][item.lcnNo]) {
				groupedData[item.name][item.lcnNo] = 0;
			}
			groupedData[item.name][item.lcnNo] += item.totalUsedAmount;
		});

		const names = Object.keys(groupedData);
		const lcnNos = [...new Set(data.map(item => item.lcnNo))];

		const series = lcnNos.map(lcnNo => ({
			name: lcnNo,
			data: names.map(name => groupedData[name][lcnNo] || 0)
		}));

		return {
			options: {
				title: {
					text: 'Summary Fee',
					align: "center"
				},
				xaxis: {
					type: 'category',
					categories: names
				},
				yaxis: {
					labels: {
						formatter: function (value) {
							return toCommaFormat(value);
						},
					},
					title: {
						text: 'Total Fee'
					}
				}
			},
			series: series,
		}
	}

	const onOilPriceSummaryFetch = (data) => {
		const series = [{
			name: "ค่าน้ำมัน",
			data: data.map(item => item.totalOilPrice || 0)
		}, {
			name: "ค่าน้ำมันแอร์",
			data: data.map(item => item.totalOilPrice2 || 0)
		}, {
			name: "ค่าแก๊ส",
			data: data.map(item => item.totalGasPrice || 0)
		}]

		return {
			options: {
				title: {
					text: 'Summary fuel cost',
					align: "center"
				},
				xaxis: {
					type: 'category',
					categories: data?.map(item => item.lcnNo || "ไม่ระบุ") || []
				},
				yaxis: {
					labels: {
						formatter: function (value) {
							return toCommaFormat(value);
						},
					},
					title: {
						text: 'Total fuel cost'
					}
				}
			},
			series: series,
		}
	}

	const onCostRateSummaryFetch = (data) => {
		const series = [{
			name: "อัตราค่าน้ำมัน",
			data: data.map(item => item.oilRate || 0)
		}, {
			name: "อัตราค่าน้ำมันแอร์",
			data: data.map(item => item.oilRate2 || 0)
		}, {
			name: "อัตราค่าแก๊ส",
			data: data.map(item => item.gasRate || 0)
		}]

		return {
			options: {
				title: {
					text: 'Summary fuel rate (bath/km)',
					align: "center"
				},
				xaxis: {
					type: 'category',
					categories: data?.map(item => item.lcnNo || "ไม่ระบุ") || []
				},
				yaxis: {
					labels: {
						formatter: function (value) {
							return toCommaFormat(value);
						},
					},
					title: {
						text: 'Total fuel rate (bath/km)'
					}
				}
			},
			series: series,
		}
	}

	const formatter = (value) => toCommaFormat(value, 0, 1)

	return (
		<>
			<Box className="p-1 w-full mt-3" >
				<Box className='flex justify-end gap-2'>
					<DatePicker
						id="statusStartDate"
						label="ตั้งแต่วันที่"
						maxDate={info.statusEndDate || new Date()}
						className="max-w-[200px]"
					>
					</DatePicker>
					<DatePicker
						id="statusEndDate"
						label="ถึงวันที่"
						minDate={info.statusStartDate}
						maxDate={new Date()}
						className="max-w-[200px]"
					>
					</DatePicker>
					<Button onClick={() => searchByStatus()} variant="text"><SearchIcon /></Button>
				</Box>
				<Box className='hidden lg:flex w-full mt-7'>
					<span className='text-lg font-bold w-[14%] text-center'>Status</span>
					<Stepper alternativeLabel className='grow'>
						{Array.from({ length: 6 }, (_, index) => (
							<Step key={index} >
								<StepLabel
									StepIconComponent={StepIcon}
								/>
							</Step>
						))}
					</Stepper>
				</Box>
				<Box className='grid gap-2 lg:gap-4 my-2 grid-cols-2 sm:grid-cols-4 lg:grid-cols-7'>
					<Box className='flex flex-col content-center place-content-evenly rounded-lg border-gray-300 border-solid border-[1px] p-2 md:p-4 h-24 md:h-36 text-center'>
						<Box className="text-sm md:text-base">{allOrderStatus}</Box>
						<Box className='font-semibold'>Total Order</Box>
					</Box>
					<Box className='flex flex-col content-center place-content-evenly rounded-lg border-gray-300 border-solid border-[1px] p-2 md:p-4 h-24 md:h-36 text-center'>
						<Box className="text-sm md:text-base">{percentOrderStatus['New']}%</Box>
						<Box className='text-md md:text-2xl'>{orderStatus['New']}</Box>
						<Box className="text-sm md:text-base">New</Box>
					</Box>
					<Box className='flex flex-col content-center place-content-evenly rounded-lg border-gray-300 border-solid border-[1px] p-2 md:p-4 h-24 md:h-36 text-center'>
						<Box className="text-sm md:text-base">{percentOrderStatus['Planned']}%</Box>
						<Box className='text-md md:text-2xl'>{orderStatus['Planned']}</Box>
						<Box className="text-sm md:text-base">Planned</Box>
					</Box>
					<Box className='flex flex-col content-center place-content-evenly rounded-lg border-gray-300 border-solid border-[1px] p-2 md:p-4 h-24 md:h-36 text-center'>
						<Box className="text-sm md:text-base">{percentOrderStatus['In transit']}%</Box>
						<Box className='text-md md:text-2xl'>{orderStatus['In transit']}</Box>
						<Box className="text-sm md:text-base">In transit</Box>
					</Box>
					<Box className='flex flex-col content-center place-content-evenly rounded-lg border-gray-300 border-solid border-[1px] p-2 md:p-4 h-24 md:h-36 text-center'>
						<Box className="text-sm md:text-base">{percentOrderStatus['Complete']}%</Box>
						<Box className='text-md md:text-2xl'>{orderStatus['Complete']}</Box>
						<Box className="text-sm md:text-base">Complete</Box>
					</Box>
					<Box className='flex flex-col content-center place-content-evenly rounded-lg border-gray-300 border-solid border-[1px] p-2 md:p-4 h-24 md:h-36 text-center'>
						<Box className="text-sm md:text-base">{percentOrderStatus['Partial complete']}%</Box>
						<Box className='text-md md:text-2xl'>{orderStatus['Partial complete']}</Box>
						<Box className="text-sm md:text-base">Partial complete</Box>
					</Box>
					<Box className='flex flex-col content-center place-content-evenly rounded-lg border-gray-300 border-solid border-[1px] p-2 md:p-4 h-24 md:h-36 text-center'>
						<Box className="text-sm md:text-base">{percentOrderStatus['Reject']}%</Box>
						<Box className='text-md md:text-2xl'>{orderStatus['Reject']}</Box>
						<Box className="text-sm md:text-base">Reject</Box>
					</Box>
				</Box>
			</Box>
			<Box className="grid gap-2 my-2 grid-cols-1 lg:grid-cols-6">
				<DashboardCard className='lg:col-span-2' type="donut" pattern="donut" endpoint={"/dashboard/getAllCompleteOrderSummaryByVehicleType"} onFetch={onCompleteOrderSummaryByVehicleTypeFetch} formatter={formatter}></DashboardCard>
				<DashboardCard className='lg:col-span-2' type="donut" pattern="donut" endpoint={"/dashboard/getAllCompleteOrderSummaryByCustomer"} onFetch={onCompleteOrderSummaryByCustomerFetch} formatter={formatter}></DashboardCard>
				<DashboardCard className='lg:col-span-2' type="donut" pattern="donut" endpoint={"/dashboard/getAllCompleteOrderSummaryByVehicle"} onFetch={onCompleteOrderSummaryByVehicleFetch} formatter={formatter}></DashboardCard>
				<DashboardCard className='lg:col-span-2' type="donut" pattern="donut" endpoint={"/dashboard/getAllBookingVehicleTypeSummary"} onFetch={onBookingVehicleTypeSummaryFetch} formatter={formatter}></DashboardCard>
				<DashboardCard className='lg:col-span-2' type="donut" pattern="donut" endpoint={"/dashboard/getDelayPerOntimeJobSummary"} onFetch={onDelayPerOntimeSummaryFetch} formatter={formatter}></DashboardCard>
				<DashboardCard className='lg:col-span-2' type="bar" pattern="stackBar" endpoint={"/dashboard/getWorkingHoursSummary"} onFetch={onWorkingHoursSummaryFetch}></DashboardCard>
				<DashboardCard className='lg:col-span-2' type="bar" endpoint={"/dashboard/getTollFeeSummary"} onFetch={onTollFeeSummaryFetch}></DashboardCard>
				<DashboardCard className='lg:col-span-2' type="bar" pattern="stackBar" endpoint={"/dashboard/getOilPriceSummary"} onFetch={onOilPriceSummaryFetch}></DashboardCard>
				<DashboardCard className='lg:col-span-2' type="bar" pattern="stackBar" endpoint={"/dashboard/getAllFeeSummary"} onFetch={onFeeSummaryFetch}></DashboardCard>
				<DashboardCard height={450} className='lg:col-span-3 !h-[550px]' type="bar" pattern="stackBar" endpoint={"/dashboard/getAllDistanceSummary"} onFetch={onDistanceSummaryFetch}></DashboardCard>
				<DashboardCard height={450} className='lg:col-span-3 !h-[550px]' type="bar" pattern="stackBar" endpoint={"/dashboard/getAllCostRateSummary"} onFetch={onCostRateSummaryFetch}></DashboardCard>
				<DashboardCard height={450} className='lg:col-span-6 !h-[550px]' type="bar" pattern="stackBar" endpoint={"/dashboard/getAllRouteSummary"} onFetch={onRouteSummaryFetch}></DashboardCard>
			</Box>
			{
				mode[0] === "edit" ?
					<JobChart status={status[0]} mode={mode} /> : null
			}
		</>
	)
}
