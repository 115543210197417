import { Box, Button, ButtonGroup } from '@mui/material'
import Chart from 'components/Chart'
import useAxios from 'hooks/useAxios';
import React, { useEffect, useState } from 'react'

export default function DashboardCard({ className = "", formatter, endpoint, type, pattern, onFetch, filterLabel = { DAY: "วัน", MONTH: "เดือน", YEAR: "ปี" }, height }) {
    const { get } = useAxios();
    const [data, setData] = useState({})
    const [interval, setInterval] = useState("DAY")
    const fetchData = async (controller) => {
        const result = await get(endpoint, { interval }, { signal: controller?.signal })
        if (result.status === 200) {
            const datas = result.data.data.records || []
            setData(onFetch ? onFetch(datas) : datas)
        }
    }

    useEffect(() => {
        const controller = new AbortController();
        fetchData(controller)
        return () => {
            controller.abort()
        }
    }, [])

    useEffect(() => {
        fetchData()
    }, [interval])

    return (
        <Box className={`rounded-lg border-gray-300 border-solid border-[1px] h-[400px] w-full ${className}`}>
            <Box className="text-right m-2">
                <ButtonGroup variant="outlined" aria-label="outlined button group">
                    <Button className={interval === "DAY" ? "!bg-bpTheme text-white" : ""} onClick={() => setInterval("DAY")}>{filterLabel.DAY}</Button>
                    <Button className={interval === "MONTH" ? "!bg-bpTheme text-white" : ""} onClick={() => setInterval("MONTH")}>{filterLabel.MONTH}</Button>
                    <Button className={interval === "YEAR" ? "!bg-bpTheme text-white" : ""} onClick={() => setInterval("YEAR")}>{filterLabel.YEAR}</Button>
                </ButtonGroup>
            </Box>
            <Chart height={height || 350} formatter={formatter} type={type} pattern={pattern} options={data?.options} series={data?.series}></Chart>
        </Box>
    )
}
