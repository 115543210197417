export const timeout = 10000;
export const CONTEXT_URL = window.location.host.includes("localhost")
    ? process.env.REACT_APP_CONTEXT_URL_LOCAL
    : process.env.REACT_APP_CONTEXT_URL;
export const CONTEXT_URL_TMS = window.location.host.includes("localhost")
    ? process.env.REACT_APP_CONTEXT_URL_LOCAL_TMS
    : process.env.REACT_APP_CONTEXT_URL_TMS;
export const COMPANY_NAME = process.env.REACT_APP_COMPANY_NAME
export const NAMESPACE_SESSION = process.env.REACT_APP_NAMESPACE_SESSION
export const BILLING_COND = [
    { label: "เที่ยว", value: "TRIP" },
    { label: "หน่วย", value: "UNIT" },
    { label: "น้ำหนัก", value: "WEIGHT" },
    { label: "ปริมาตร", value: "DENSITY" },
    { label: "ระยะทาง", value: "DISTANCE" },
]
export const DELIVERY_TYPE = [
    { label: "ส่ง", value: "delivery" },
    { label: "รับกลับ", value: "pickup" },
]
export const INVOICE_TAX = [
    { label: "0%", value: 0 },
    { label: "1%", value: 1 },
    { label: "3%", value: 3 },
    { label: "5%", value: 5 },
    { label: "10%", value: 10 },
    { label: "15%", value: 15 },
    { label: "20%", value: 20 },
    { label: "25%", value: 25 },
    { label: "30%", value: 30 },
    { label: "35%", value: 35 },
]
export const PAYMENT_OPTIONS = [
    { label: "เงินสด", value: "Cash" },
    { label: "เช็ค", value: "Check" },
    { label: "โอนเงิน", value: "Bank Transfer" },
]
export const PROJECT_TYPE = [
    { label: "ภายในประเทศ", value: "local" },
    { label: "ขาเข้า", value: "import" },
    { label: "ขาออก", value: "export" },
]
export const MAX_FILE_SIZE = 0.5
export const STATUS_ORDER_NEW = "New"
export const STATUS_ORDER_PLANNED = "Planned"
export const STATUS_ORDER_ACCEPT_JOB = "Accept job"
export const STATUS_ORDER_ARRIVED_SOURCE = "Arrived source"
export const STATUS_ORDER_IN_TRANSIT = "In transit"
export const STATUS_ORDER_ARRIVED_DESTINATION = "Arrived destination"
export const STATUS_ORDER_COMPLETE = "Complete"
export const STATUS_ORDER_PARTAIL_COMPLETE = "Partial complete"
export const STATUS_ORDER_REJECT = "Reject"
export const STATUS_ORDER_RESEND = "Resend"
// export const STATUS_ITEM_NEW = "New"
// export const STATUS_ITEM_PLANNED = "Planned"
// export const STATUS_ITEM_PLANNED_HUB = "Planned (Hub)"
// export const STATUS_ITEM_ACCEPT_JOB = "Accept job"
// export const STATUS_ITEM_ARRIVED_SOURCE = "Arrived source"
// export const STATUS_ITEM_IN_TRANSIT = "In transit"
// export const STATUS_ITEM_ARRIVED_DESTINATION = "Arrived destination"
// export const STATUS_ITEM_ARRIVED_HUB = "Arrived hub"
// export const STATUS_ITEM_ON_HUB = "On hub"
// export const STATUS_ITEM_COMPLETE = "Complete"
// export const STATUS_ITEM_RESEND = "Resend"
// export const STATUS_ITEM_REJECT = "Reject"
// export const STATUS_ITEM_PARTIAL_REJECT = "Partial reject"
export const STATUS_JOB_NEW = "New"
export const STATUS_JOB_ACCEPT_JOB = "Accept job"
export const STATUS_JOB_IN_TRANSIT = "In transit"
export const STATUS_JOB_COMPLETE = "Complete"
export const STATUS_JOB_PARTIAL_COMPLETE = "Partial complete"
export const STATUS_JOB_REJECT = "Reject"
export const STATUS_JOB_RESEND = "Resend"
export const STATUS_JOB_CANCELED = "Canceled"
export const STATUS_INVOICE_WAITING = "waiting"
export const STATUS_INVOICE_APPROVE = "approve"
export const STATUS_INVOICE_DENIED = "denied"
export const STATUS_INVOICE_CANCELED = "canceled"
export const STATUS_INVOICE_PAID = "paid"
export const STATUS_PAYMENT_WAITING = "waiting"
export const STATUS_PAYMENT_APPROVE = "approve"
export const STATUS_PAYMENT_DENIED = "denied"
export const STATUS_PAYMENT_CANCELED = "canceled"
export const STATUS_PAYMENT_PAID = "paid"

export const DRIVER_PAGE_CHOOSE_JOB = "chooseJob"
export const DRIVER_PAGE_CHOOSE_LOCATION = "chooseLocation"
export const DRIVER_PAGE_REVIEW_ORDER = "chooseOrder"
export const DRIVER_PAGE_CHECK_IN = "checkIn"
export const DRIVER_PAGE_TAKE_CAMERA = "takeCamera"
export const DRIVER_PAGE_TAKE_SIGN = "takeSign"
export const DRIVER_PAGE_CHECK_OUT = "checkOut"
export const DRIVER_PAGE_TAKE_DOCUMENT = "takeDocument"