import axios from "axios"
import { setupCache } from 'axios-cache-adapter'
import { CONTEXT_URL_TMS, timeout } from "helper/DataFactory"
axios.defaults.timeout = timeout

const cache = setupCache({
    maxAge: 15 * 60 * 1000
})

const api = axios.create({
    adapter: cache.adapter
})

const errorResp = (err) => ({ status: 500, data: { status: { code: "500", description: err?.message || "unknown error" } } })

export const post = (url, setting = {}) => {
    let { data = {}, useAdditional = false, config = {}, cache = false } = setting
    if (useAdditional) {
        const ctx = JSON.parse(window.sessionStorage.getItem("dionectx"))
        let additional = {}
        if (ctx.page?.extendsPayload) {
            const extendsPayload = ctx.page.extendsPayload
            additional = extendsPayload
        }
        data = { ...data, ...additional }
    }
    return cache ? api.post(CONTEXT_URL_TMS + url, data, config).catch(error => { return error?.response?.data ? error.response : errorResp(error) }) : axios.post(CONTEXT_URL_TMS + url, data, config).catch(error => { return error?.response?.data ? error.response : errorResp(error) })
}

export const get = (url, setting = {}) => {
    const { params, config = {} } = setting
    return axios.get(CONTEXT_URL_TMS + url, { params, ...config }).catch(error => { return error?.response?.data ? error.response : errorResp(error) })
}

export const del = (url, setting = {}) => {
    const { params, config = {} } = setting
    return axios.delete(CONTEXT_URL_TMS + url, { params, ...config }).catch(error => { return error?.response?.data ? error.response : errorResp(error) })
}
