import React, { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Modal from 'components/Modal'
import Form from 'components/Form'
import { clearInfoById } from 'slices/infoSlice'
import useAxios from 'hooks/useAxios'
import { cloneDeep } from 'lodash';
import { DataSet } from 'vis-data'
import { setInfo } from 'slices/infoSlice'

export default function Task({ mode, item, vehicles, callback }) {
	const dispatch = useDispatch()
	const info = useSelector(state => state.info)
	const { multiple } = useAxios();
	const [allDriver, setAllDriver] = useState([])
	const [allWorker, setAllWorker] = useState([])

	const fetchData = async controller => {
		const result = await multiple([
			{ method: "get", url: "/employee/getAllEmployeeByRole", params: { role: "Driver" }, config: { signal: controller.signal } },
			{ method: "get", url: "/employee/getAllEmployeeByPosition", params: { position: "พนักงานยกสินค้า" }, config: { signal: controller.signal } },
		])

		if (result[0].status === 200) {
			const data = result[0].data.data.records || []
			setAllDriver(data.map(e => { return { label: `${e.firstName} ${e.lastName}`, value: e.id, data: e } }))
		}

		if (result[1].status === 200) {
			const data = result[1].data.data.records || []
			setAllWorker(data.map(e => { return { label: `${e.firstName} ${e.lastName}`, value: e.id, data: e } }))
		}
	}

	useEffect(() => {
		const controller = new AbortController()

		if (mode[0] === "initial") {
			dispatch(clearInfoById("taskModal"))
		} else {
			fetchData(controller)
		}
		return () => {
			controller.abort()
		}
	}, [mode[0]])

	const onCloseModal = useCallback(() => {
		mode[1]("initial")
	}, [])

	const saveData = useCallback(() => {
		const _item = cloneDeep(item)
		_item.className = "editing"
		_item.start = new Date(info.taskModal.etdTime);
		_item.end = new Date(info.taskModal.etaTime);
		_item.data = { ...item.data }
		_item.data.takeCarTime = info.taskModal.takeCarTime != null ? new Date(info.taskModal.takeCarTime) : null;
		_item.data.vehicleId = info.taskModal.vehicleId;
		_item.data.vehicleTypeId = vehicles.find(e => e.value === info.taskModal.vehicleId)?.data.vehicleTypeId;
		_item.data.vehicleTypeName = vehicles.find(e => e.value === info.taskModal.vehicleId)?.data.vehicleTypeName;
		_item.data.driverId = info.taskModal.driverId;
		_item.data.driverId2 = info.taskModal.driverId2;
		_item.data.workerId = info.taskModal.workerId;
		_item.data.workerId2 = info.taskModal.workerId2;
		_item.data.driverName = allDriver.find(e => e.value === info.taskModal.driverId)?.label || "-";
		_item.data.driverName2 = allDriver.find(e => e.value === info.taskModal.driverId2)?.label || "-";
		_item.data.workerName = allWorker.find(e => e.value === info.taskModal.workerId)?.label || "-";
		_item.data.workerName2 = allWorker.find(e => e.value === info.taskModal.workerId2)?.label || "-";
		_item.group = info.taskModal.vehicleId || _item.group
		if (mode[0] === "add") {
			_item.type = "range"
			_item.status = "add"
			_item.content = "≣"
			const _items = cloneDeep(info.ctx.scheduleJobDataset.get()) || []
			_items.push(_item)
			dispatch(setInfo({ scheduleJob: _items }))
			callback && callback(_item)
		} else {
			info.ctx.scheduleJobDataset.updateOnly(_item)
		}
		mode[1]("initial")
	}, [info, item, info.ctx.scheduleJobDataset])

	const inputForm = [
		{
			rowData: [
				{
					type: "autocomplete",
					props: {
						id: "vehicleId",
						label: "ทะเบียนรถ",
						options: vehicles,
						groupBy: (option) => option.data.vehicleTypeName
					}
				},
				{
					type: "datetime",
					props: {
						id: "takeCarTime",
						label: "วันที่รับรถ",
					}
				}
			]
		},
		mode[0] === "edit" && {
			rowData: [
				{
					type: "autocomplete",
					props: {
						id: "driverId",
						label: "พนักงานขับรถ",
						filterOptions: (e) => info.taskModal?.driverId2 !== e.data?.id,
						options: allDriver
					}
				},
				{
					type: "autocomplete",
					props: {
						id: "driverId2",
						label: "พนักงานขับรถ (สำรอง)",
						filterOptions: (e) => info.taskModal?.driverId !== e.data?.id,
						options: allDriver
					}
				}
			]
		},
		mode[0] === "edit" && {
			rowData: [
				{
					type: "autocomplete",
					props: {
						id: "workerId",
						label: "พนักงานยกของ",
						filterOptions: (e) => info.taskModal?.workerId2 !== e.data?.id,
						options: allWorker
					}
				},
				{
					type: "autocomplete",
					props: {
						id: "workerId2",
						label: "พนักงานยกของ (สำรอง)",
						filterOptions: (e) => info.taskModal?.workerId !== e.data?.id,
						options: allWorker
					}
				}
			]
		},
		{
			rowData: [
				{
					type: "datetime",
					required: true,
					props: {
						id: "etdTime",
						label: "วันที่จะเข้ารับสินค้า",
					}
				},
				{
					type: "datetime",
					required: true,
					props: {
						id: "etaTime",
						label: "วันที่จบงาน",
					}
				}
			]
		},
		{
			noCol: true,
			align: "center",
			rowData: [
				{
					type: "button",
					props: {
						label: "ตกลง",
						onClick: saveData,
					}
				}
			]
		}
	]

	return (
		<Modal open={mode[0] !== "initial"} onClose={onCloseModal} className="form-modal" contentClassName="!min-w-[550px] !max-w-[550px]">
			<Form id={`taskModal`} title={`ข้อมูลงาน ${info.taskModal?.jobName}`} name="form" data={inputForm}></Form>
		</Modal>
	)
}