import usePopup from "hooks/usePopup"
import { useDispatch } from "react-redux"
import { clearCollapse, openCollapse } from "slices/collapseSlice"
import { validation } from "utilities/validator"

export default function useDataHandler() {
	const dispatch = useDispatch()
	const { confirmPopup } = usePopup()

	const save = ({ data, scope, onSubmit, onCancel }) => {
		dispatch(openCollapse())
		setTimeout(() => {
			if (validation(data, scope)) {
				confirmPopup({
					onSubmit: onSubmit,
					onCancel: onCancel
				})
			}
			dispatch(clearCollapse())
		}, 500)
	}

	return {
		save
	}
}
