import { FormControl, FormControlLabel, FormLabel, RadioGroup, Radio as MuiRadio } from '@mui/material'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { setInfoById, setInfo } from 'slices/infoSlice';
import { extractInfo } from 'utilities/utils';
import { v4 as uuidv4 } from 'uuid';

export default function Radio({ parentId, id, label, options = [], required, readOnly }) {
    const value = useSelector(state => extractInfo(state, parentId, id, ""));
    const dispatch = useDispatch()
    const onChange = (e) => {
        if (parentId) {
            dispatch(setInfoById({ id: parentId, payload: { [id]: e.target.value } }))
        } else {
            dispatch(setInfo({ [id]: e.target.value }))
        }
    }

    return (
        <FormControl required={required}>
            <FormLabel id={`${id}-label`}>{label}</FormLabel>
            <RadioGroup
                row
                aria-labelledby={`${id}-label`}
                name={`${id}-group`}
                value={value !== undefined && value !== null ? value : ""}
                onChange={onChange}
                className="-mb-2"
            >
                {options.map((e, i) => {
                    return <FormControlLabel key={`radio-value-${id}-${uuidv4()}`} value={e.value} control={<MuiRadio />} label={e.label} disabled={readOnly} />
                })}
            </RadioGroup>
        </FormControl>
    )
}
