import React from "react"
import { ViewMode } from "gantt-task-react"
import { Box, Button, ButtonGroup, FormControlLabel, Switch } from "@mui/material"

export const ViewSwitcher = ({ view, onViewModeChange, onViewListChange, isChecked }) => {
    return (
        <Box className="flex justify-end mb-2">
            <ButtonGroup variant="outlined" aria-label="outlined button group">
                <Button variant={ViewMode.Hour === view ? "contained" : undefined} onClick={() => onViewModeChange(ViewMode.Hour)}>
                    ชั่วโมง
                </Button>
                <Button variant={ViewMode.QuarterDay === view ? "contained" : undefined} onClick={() => onViewModeChange(ViewMode.QuarterDay)}>
                    ไตรมาสของวัน
                </Button>
                <Button variant={ViewMode.HalfDay === view ? "contained" : undefined} onClick={() => onViewModeChange(ViewMode.HalfDay)}>
                    ครึ่งวัน
                </Button>
                <Button variant={ViewMode.Day === view ? "contained" : undefined} onClick={() => onViewModeChange(ViewMode.Day)}>
                    วัน
                </Button>
                <Button variant={ViewMode.Week === view ? "contained" : undefined} onClick={() => onViewModeChange(ViewMode.Week)}>
                    สัปดาห์
                </Button>
                <Button variant={ViewMode.Month === view ? "contained" : undefined} onClick={() => onViewModeChange(ViewMode.Month)}>
                    เดือน
                </Button>
                {/* <Button variant={ViewMode.Year === view ? "contained" : undefined} onClick={() => onViewModeChange(ViewMode.Year)}>
                    ปี
                </Button> */}
            </ButtonGroup>
            <FormControlLabel
                control={<Switch value={isChecked} onClick={() => onViewListChange(!isChecked)} defaultChecked={true} />}
                className="!ml-2"
                label="แสดงรายละเอียดงาน"
            />
        </Box>
    )
}
