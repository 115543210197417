import { Box, Button, List, ListItem, ListItemIcon, ListItemText } from '@mui/material';
import React, { useEffect, useState } from 'react'
import AutorenewIcon from '@mui/icons-material/Autorenew';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';

export default function CorrectFeatures() {
	const mode = useState("initial")
	const locationAllowed = useState("checking")

	function getLocation() {
		if (navigator.geolocation) {
			navigator.geolocation.getCurrentPosition(showPosition, () => {
				locationAllowed[1]("deny");
			}, { enableHighAccuracy: true });
		} else {
			locationAllowed[1]("deny")
		}
	}
	function showPosition(position) {
		const latitude = position.coords.latitude;
		const longitude = position.coords.longitude;
		if (latitude && longitude) {
			locationAllowed[1]("allowed")
		} else {
			locationAllowed[1]("deny")
		}
	}

	const start = () => {
		mode[1]("testing")
		locationAllowed[1]("checking")
	}

	useEffect(() => {
		if (mode[0] === "testing") {
			setTimeout(() => {
				getLocation()
			}, 2000)
		}
	}, [mode[0]])

	useEffect(() => {
		if (locationAllowed[0] !== "checking") {
			mode[1]("retest")
		}
	}, [locationAllowed[0]])

	return (
		<Box>
			{
				mode[0] === "initial" ?
					<Box className="m-4 text-center" >
						<Button variant="contained" className="!bg-bpTheme-buttonSubmit driver_button" onClick={start}>เริ่มตรวจสอบ</Button>
					</Box>
					: null
			}
			{
				mode[0] === "testing" || mode[0] === "retest" ?
					<Box className="m-4">
						<List dense={true}>
							<ListItem>
								<ListItemIcon>
									{locationAllowed[0] === "checking" ? <AutorenewIcon className="animate-spin" /> : locationAllowed[0] === "allowed" ? <CheckIcon className='text-green-500' /> : <CloseIcon className='text-red-500' />}
								</ListItemIcon>
								<ListItemText
									primary="Location feature"
								/>
							</ListItem>
						</List>
					</Box>
					: null
			}
			{
				mode[0] === "retest" ?
					<Box className="m-4 text-center" >
						<Button variant="contained" className="!bg-bpTheme-buttonSubmit driver_button" onClick={start}>ทดสอบอีกครั้ง</Button>
					</Box>
					: null
			}
		</Box>
	)
}
