import React, { useEffect, useState, useRef, useCallback } from "react";
import { v4 as uuidv4 } from 'uuid';
import Cropper from "components/Cropper";
import CameraAltIcon from '@mui/icons-material/CameraAlt';
import { Box, ClickAwayListener, IconButton, MenuItem, MenuList, Modal, Paper, Popover } from '@mui/material';
import { useDispatch, useSelector } from "react-redux";
import { setInfo, setInfoById } from "slices/infoSlice";
import usePopup from "hooks/usePopup";
import useAxios from "hooks/useAxios";
import { extractInfo } from "utilities/utils";

export default function ProfileUpload({ parentId, id, prefix, useAspect = true, aspect = 1 }) {
	const key = uuidv4()
	const [cropImgSrc, setCropImgSrc] = useState('')
	const [imgSrc, setImgSrc] = useState('')
	const [tempFile, setTempFile] = useState(null);
	const [inputValue, setInputValue] = useState("");
	const [open, setOpen] = useState(false);
	const [modalOpen, setModalOpen] = useState(false);
	const inputRef = useRef(null);
	const anchorRef = useRef(null);
	const prevOpen = useRef(open);
	const prevModalOpen = useRef(modalOpen);
	const { confirmPopup } = usePopup();
	const { get } = useAxios();

	const value = useSelector(state => extractInfo(state, parentId, id))
	const dispatch = useDispatch()
	const onChange = (e) => {
		if (parentId) {
			dispatch(setInfoById({ id: parentId, payload: { [id]: e } }))
		} else {
			dispatch(setInfo({ [id]: e }))
		}
	}

	const handleToggle = useCallback(() => {
		setOpen((prevOpen) => !prevOpen);
	}, [prevOpen])

	const handleClose = useCallback((event) => {
		if (anchorRef?.current?.contains(event.target)) {
			return;
		}
		setOpen(false);
	}, [anchorRef.current])

	const handleListKeyDown = useCallback((event) => {
		if (event.key === "Tab") {
			event.preventDefault();
			setOpen(false);
		}
	}, [setOpen])

	const addFile = (e) => {
		if (e?.target?.files?.length > 0) {
			const file = e.target.files[0];
			setImgSrc(file)
			onChange(file)
		} else {
			onChange(null)
		}
	}

	const removeFile = useCallback(() => {
		const init = () => {
			setInputValue("")
			setCropImgSrc("")
			setImgSrc("")
			setTempFile(null)
			onChange(null)
		}
		if (value && typeof value === "string") {
			confirmPopup({
				content: "ท่านต้องการลบไฟล์หรือไม่?",
				onSubmit: init
			})
		} else {
			init()
		}
		handleToggle()
	}, [value])

	const renderImage = file => {
		if (!!file) {
			setOpen(false)
			const reader = new FileReader()
			reader.addEventListener('load', () =>
				setCropImgSrc(reader.result?.toString() || ''),
			)
			reader.readAsDataURL(file)
			setTempFile(file)
			onChange(file)
		} else {
			setCropImgSrc('')
			setTempFile(null)
			onChange(null)
		}
	}

	const getImage = useCallback(async controller => {
		const result = await get("/file/getPublicUrl", { prefix, key: value }, {
			signal: controller.signal
		})
		if (result.status === 200) {
			const data = result.data.data
			setCropImgSrc(data)
		}
		return () => {
			controller.abort()
		}
	}, [value])

	useEffect(() => {
		const controller = new AbortController()
		if (typeof value !== "string" && value) {
			setCropImgSrc(value)
		} else if (typeof value === "string") {
			getImage(controller)
		}
		return () => {
			controller.abort()
		}
	}, [value])

	useEffect(() => {
		prevModalOpen.current = prevModalOpen;
	}, [modalOpen]);

	useEffect(() => {
		if (prevOpen.current === true && open === false) {
			anchorRef.current.focus();
		}
		prevOpen.current = open;
	}, [open]);

	return (
		<>
			<Box className="flex justify-center">
				<Box height={150} width={150} className="relative">
					<Box className="flex justify-center items-center w-full h-full rounded-full bg-gray-200 overflow-hidden">
						{!!cropImgSrc ? <img className="max-w-full h-auto" src={cropImgSrc} /> : null}
					</Box>
					<IconButton
						className="h-10 w-10 !absolute bottom-0 !bg-gray-100" sx={{ left: "65%" }}
						ref={anchorRef}
						onClick={handleToggle}
					>
						<CameraAltIcon fontSize="medium" />
						<Popover
							open={open}
							anchorEl={anchorRef.current}
							anchorOrigin={{
								vertical: 'bottom',
								horizontal: 'left',
							}}
						>
							<Paper>
								<ClickAwayListener onClickAway={handleClose}>
									<MenuList
										autoFocusItem={open}
										id="menu-list-grow"
										onKeyDown={handleListKeyDown}
									>
										{!!cropImgSrc ? <MenuItem className="!text-sm" onClick={() => setModalOpen(true)}>View</MenuItem> : null}
										<MenuItem className="!text-sm" onClick={() => { inputRef.current.click() }}>{!!cropImgSrc ? "Change" : "Add"}</MenuItem>
										{!!cropImgSrc ? <MenuItem className="!text-sm" onClick={removeFile}>Remove</MenuItem> : null}
									</MenuList>
								</ClickAwayListener>
							</Paper>
						</Popover>
					</IconButton>
					<input ref={inputRef} className="hidden" type="file" id={key} accept="image/*" value={inputValue} onChange={addFile} />
				</Box>
			</Box>
			<Cropper src={imgSrc} onClose={() => renderImage(tempFile)} onChange={e => renderImage(e)} circularCrop={true} minWidth={50} minHeigh={50} aspect={useAspect ? aspect : undefined} />
			<Modal
				open={modalOpen} onClose={() => setModalOpen(false)}
				className="flex justify-center items-center"
			>
				<Box
					className="bg-white p-2 flex justify-center flex-col items-center rounded-md"
					maxWidth={{ xs: "100%", md: "60%" }}
				>
					<img src={cropImgSrc} loading="lazy" />
				</Box>
			</Modal>
		</>
	);
}