import React, { useEffect, useState } from 'react';
import Chart from 'components/Chart';
import { Button, ButtonGroup, Box, Card, CardContent, Stack, Typography } from '@mui/material';
import styled from 'styled-components';
import useAxios from 'hooks/useAxios';
import {
	STATUS_INVOICE_WAITING, STATUS_INVOICE_APPROVE, STATUS_INVOICE_DENIED, STATUS_INVOICE_CANCELED, STATUS_INVOICE_PAID,
	STATUS_PAYMENT_WAITING, STATUS_PAYMENT_APPROVE, STATUS_PAYMENT_DENIED, STATUS_PAYMENT_CANCELED, STATUS_PAYMENT_PAID
} from 'helper/DataFactory'
import { toCommaFormat } from 'utilities/utils';

const CustomContainerOverview = styled.div`
	border-radius: 8px;
	border-width: 1px;
	padding: 5px;
	height: 380px;
	width: 100%;
`
const CustomMainContainer = styled.div`
	border-radius: 8px;
	padding: 5px;
	width: 100%;
	margin-top: 15px;
`
const CustomContainer = styled.div`
	border-radius: 8px;
	border-width: 1px;
	padding: 6px;
	height: 350px;
	width: 100%;
`
const CustomBox = styled.div`
	margin: 0;
	font-family: "Roboto","Helvetica","Arial",sans-serif;
	font-weight: 500;
	font-size: 1.25rem;
	line-height: 1.6;
	letter-spacing: 0.0075em;
	padding: 15px 10px 5px 10px;
`

export default function Finance() {
	const { multiple } = useAxios();
	const [selectedButton, setSelectedButton] = useState("1");
	const [totalIncome, setTotalIncome] = useState([])
	const [totalExpense, setTotalExpense] = useState([])
	const [totalProfit, setTotalProfit] = useState([])
	const [allProfitAndLoss, setAllProfitAndLoss] = useState([])
	const [allIncomeByCustomer, setAllIncomeByCustomer] = useState([])
	const [allExpenseByVendor, setAllExpenseByVendor] = useState([])
	const [allIncomeByProject, setAllIncomeByProject] = useState([])
	const [allARAP, setAllARAP] = useState([])
	const [allSummaryInvoiceStatus, setAllSummaryInvoiceStatus] = useState([])
	const [allSummaryExpenseStatus, setAllSummaryExpenseStatus] = useState([])
	const [allIncomePlanAndActual, setAllIncomePlanAndActual] = useState([])
	const [allExpensePlanAndActual, setAllExpensePlanAndActual] = useState([])
	const [allExpenseByCategory, setAllExpenseByCategory] = useState([])
	const [dataProfitAndLoss, setDataProfitAndLoss] = useState({})
	const [dataIncomeByCustomer, setDataIncomeByCustomer] = useState({});
	const [dataExpenseByVendor, setDataExpenseByVendor] = useState({});
	const [dataIncomeByProject, setDataIncomeByProject] = useState({});
	const [dataARAP, setDataARAP] = useState({})
	const [dataSummaryInvoiceStatus, setDataSummaryInvoiceStatus] = useState({})
	const [dataCountInvoiceStatus, setDataCountInvoiceStatus] = useState({})
	const [dataSummaryExpenseStatus, setDataSummaryExpenseStatus] = useState({})
	const [dataCountExpenseStatus, setDataCountExpenseStatus] = useState({})
	const [dataIncomePlanAndActual, setDataIncomePlanAndActual] = useState({})
	const [dataExpensePlanAndActual, setDataExpensePlanAndActual] = useState({})
	const [dataExpenseByCategory, setDataExpenseByCategory] = useState({})

	const fetchData = async (cond, controller) => {
		const result = await multiple([
			{ method: "get", url: "/finance/getAllIncome", params: { condition: cond }, config: { signal: controller.signal } },
			{ method: "get", url: "/finance/getAllExpense", params: { condition: cond }, config: { signal: controller.signal } },
			{ method: "get", url: "/finance/getAllProfitAndLoss", config: { signal: controller.signal } },
			{ method: "get", url: "/finance/getAllIncomeByCustomer", params: { condition: cond }, config: { signal: controller.signal } },
			{ method: "get", url: "/finance/getAllExpenseByVendor", params: { condition: cond }, config: { signal: controller.signal } },
			{ method: "get", url: "/finance/getAllIncomeByProject", params: { condition: cond }, config: { signal: controller.signal } },
			{ method: "get", url: "/finance/getAllAccountReceivableAccountPayable", config: { signal: controller.signal } },
			{ method: "get", url: "/finance/getAllSumaryInvoiceStatus", config: { signal: controller.signal } },
			{ method: "get", url: "/finance/getAllSumaryExpenseStatus", config: { signal: controller.signal } },
			{ method: "get", url: "/finance/getAllIncomePlanAndActual", config: { signal: controller.signal } },
			{ method: "get", url: "/finance/getAllExpensePlanAndActual", config: { signal: controller.signal } },
			{ method: "get", url: "/finance/getAllExpenseByCategory", params: { condition: cond }, config: { signal: controller.signal } },
		])
		if (result[0].status === 200) {
			const data = result[0].data.data.records || []
			setTotalIncome(data.reduce((sum, number) => sum + number.total, 0))
		}
		if (result[1].status === 200) {
			const data = result[1].data.data.records || []
			setTotalExpense(data.reduce((sum, number) => sum + number.total, 0))
		}
		if (result[2].status === 200) {
			const data = result[2].data.data.records || []
			setAllProfitAndLoss(data)
		}
		if (result[3].status === 200) {
			const data = result[3].data.data.records || []
			setAllIncomeByCustomer(data)
		}
		if (result[4].status === 200) {
			const data = result[4].data.data.records || []
			setAllExpenseByVendor(data)
		}
		if (result[5].status === 200) {
			const data = result[5].data.data.records || []
			setAllIncomeByProject(data)
		}
		if (result[6].status === 200) {
			const data = result[6].data.data.records || []
			setAllARAP(data)
		}
		if (result[7].status === 200) {
			const data = result[7].data.data.records || []
			setAllSummaryInvoiceStatus(data)
		}
		if (result[8].status === 200) {
			const data = result[8].data.data.records || []
			setAllSummaryExpenseStatus(data)
		}
		if (result[9].status === 200) {
			const data = result[9].data.data.records || []
			setAllIncomePlanAndActual(data)
		}
		if (result[10].status === 200) {
			const data = result[10].data.data.records || []
			setAllExpensePlanAndActual(data)
		}
		if (result[11].status === 200) {
			const data = result[11].data.data.records || []
			setAllExpenseByCategory(data)
		}
	}

	useEffect(() => {
		const controller = new AbortController();
		setSelectedButton("7")
		fetchData("7", controller)
		return () => {
			controller.abort()
		}
	}, [])

	const handleClick = (button) => {
		setSelectedButton(button)
		const controller = new AbortController();
		fetchData(button, controller)
		return () => {
			controller.abort()
		}
	};

	useEffect(() => {
		if (typeof totalIncome == "number" && typeof totalExpense == "number") {
			setTotalProfit(totalIncome - totalExpense)
		}
	}, [totalIncome, totalExpense])

	useEffect(() => {
		if (allProfitAndLoss.length > 0) {
			const months = Array.from({ length: 12 }, (_, index) => (index + 1).toString().padStart(2, '0'));
			const mappedData = months.map((month) => {
				const dataItem = allProfitAndLoss.find((item) => item.month == month);
				const totalIncome = dataItem ? dataItem.totalIncome : 0;
				const totalExpense = dataItem ? dataItem.totalExpense : 0;
				const totalProfitLoss = dataItem ? dataItem.totalIncome - dataItem.totalExpense : 0;
				return { month, totalIncome, totalExpense, totalProfitLoss };
			});
			setDataProfitAndLoss({
				options: {
					title: {
						text: "Profit and loss",
						align: "center"
					},
					xaxis: {
						categories: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'June', 'July', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
						axisBorder: {
							show: false,
						},
						axisTicks: {
							show: false,
						},
					},
					yaxis: {
						labels: {
							formatter: function (value) {
								return toCommaFormat(value);
							},
						},
						title: {
							text: 'Total amount',
						},
					},
				},
				series: [{
					name: 'Income',
					data: mappedData.map(item => item.totalIncome)
				}, {
					name: 'Expense',
					data: mappedData.map(item => item.totalExpense)
				}, {
					name: 'Profit and loss',
					data: mappedData.map(item => item.totalProfitLoss)
				}],
			})
		} else {
			setDataProfitAndLoss({
				options: {
					title: {
						text: 'Profit and loss',
						align: "center"
					}
				}
			})
		}
	}, [allProfitAndLoss])

	useEffect(() => {
		if (allARAP.length > 0) {
			const months = Array.from({ length: 12 }, (_, index) => (index + 1).toString().padStart(2, '0'));
			const mappedData = months.map((month) => {
				const dataItem = allARAP.find((item) => item.month == month);
				const totalAR = dataItem ? dataItem.totalAR : 0;
				const totalAP = dataItem ? dataItem.totalAP : 0;
				const totalProfitLoss = dataItem ? dataItem.totalAR - dataItem.totalAP : 0;
				return { month, totalAR, totalAP, totalProfitLoss };
			});
			setDataARAP({
				options: {
					title: {
						text: "AR/AP visibility",
						align: "center"
					},
					xaxis: {
						categories: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'June', 'July', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
						axisBorder: {
							show: false,
						},
						axisTicks: {
							show: false,
						},
					},
					yaxis: {
						labels: {
							formatter: function (value) {
								return toCommaFormat(value);
							},
						},
						title: {
							text: 'Total amount',
						},
					},
				},
				series: [{
					name: 'Account Receivable',
					data: mappedData.map(item => item.totalAR)
				}, {
					name: 'Account Payable',
					data: mappedData.map(item => item.totalAP)
				}, {
					name: 'Planed Profit and loss',
					data: mappedData.map(item => item.totalProfitLoss)
				}],
			})
		} else {
			setDataARAP({
				options: {
					title: {
						text: "AR/AP visibility",
						align: "center"
					}
				}
			})
		}
	}, [allARAP])

	useEffect(() => {
		if (allIncomePlanAndActual.length > 0) {
			const months = Array.from({ length: 12 }, (_, index) => (index + 1).toString().padStart(2, '0'));
			const mappedData = months.map((month) => {
				const dataItem = allIncomePlanAndActual.find((item) => item.month == month);
				const totalPlan = dataItem ? dataItem.totalPlan : 0;
				const totalPaid = dataItem ? dataItem.totalPaid : 0;
				return { month, totalPlan, totalPaid };
			});
			setDataIncomePlanAndActual({
				options: {
					title: {
						text: "Plan income VS actual income",
						align: "center"
					},
					xaxis: {
						categories: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'June', 'July', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
						axisBorder: {
							show: false,
						},
						axisTicks: {
							show: false,
						},
					},
					yaxis: {
						labels: {
							formatter: function (value) {
								return toCommaFormat(value);
							},
						},
						title: {
							text: 'Total amount',
						},
					},
				},
				series: [{
					name: 'Plan',
					data: mappedData.map(item => item.totalPlan)
				}, {
					name: 'Paid',
					data: mappedData.map(item => item.totalPaid)
				}],
			})
		} else {
			setDataIncomePlanAndActual({
				options: {
					title: {
						text: "Plan income VS actual income",
						align: "center"
					}
				}
			})
		}
	}, [allIncomePlanAndActual])

	useEffect(() => {
		if (allExpensePlanAndActual.length > 0) {
			const months = Array.from({ length: 12 }, (_, index) => (index + 1).toString().padStart(2, '0'));
			const mappedData = months.map((month) => {
				const dataItem = allExpensePlanAndActual.find((item) => item.month == month);
				const totalPlan = dataItem ? dataItem.totalPlan : 0;
				const totalPaid = dataItem ? dataItem.totalPaid : 0;
				return { month, totalPlan, totalPaid };
			});
			setDataExpensePlanAndActual({
				options: {
					title: {
						text: "Plan payment VS actual payment",
						align: "center"
					},
					xaxis: {
						categories: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'June', 'July', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
						axisBorder: {
							show: false,
						},
						axisTicks: {
							show: false,
						},
					},
					yaxis: {
						labels: {
							formatter: function (value) {
								return toCommaFormat(value);
							},
						},
						title: {
							text: 'Total amount',
						},
					},
				},
				series: [{
					name: 'Plan',
					data: mappedData.map(item => item.totalPlan)
				}, {
					name: 'Paid',
					data: mappedData.map(item => item.totalPaid)
				}],
			})
		} else {
			setDataExpensePlanAndActual({
				options: {
					title: {
						text: "Plan payment VS actual payment",
						align: "center"
					}
				}
			})
		}
	}, [allExpensePlanAndActual])

	useEffect(() => {
		if (allSummaryInvoiceStatus.length > 0) {

			const mapStatus = allSummaryInvoiceStatus.reduce((map, item) => {
				const { month, status, total, countInvoice } = item;
				const existingData = map.get(month) || {
					month, totalWaiting: 0, countWaiting: 0, totalApprove: 0, countApprove: 0, totalDenied: 0, countDenied: 0, totalCanceled: 0, countCanceled: 0, totalPaid: 0, countPaid: 0,
				};
				existingData.totalWaiting += status === STATUS_INVOICE_WAITING ? total : 0;
				existingData.countWaiting += status === STATUS_INVOICE_WAITING ? countInvoice : 0;
				existingData.totalApprove += status === STATUS_INVOICE_APPROVE ? total : 0;
				existingData.countApprove += status === STATUS_INVOICE_APPROVE ? countInvoice : 0;
				existingData.totalDenied += status === STATUS_INVOICE_DENIED ? total : 0;
				existingData.countDenied += status === STATUS_INVOICE_DENIED ? countInvoice : 0;
				existingData.totalCanceled += status === STATUS_INVOICE_CANCELED ? total : 0;
				existingData.countCanceled += status === STATUS_INVOICE_CANCELED ? countInvoice : 0;
				existingData.totalPaid += status === STATUS_INVOICE_PAID ? total : 0;
				existingData.countPaid += status === STATUS_INVOICE_PAID ? countInvoice : 0;

				map.set(month, existingData);
				return map;
			}, new Map());

			const months = Array.from({ length: 12 }, (_, index) => (index + 1).toString().padStart(2, '0'));
			const mappedData = months.map((month) => {
				const dataItem = mapStatus.get(month) || {
					month, totalWaiting: 0, countWaiting: 0, totalApprove: 0, countApprove: 0, totalDenied: 0, countDenied: 0, totalCanceled: 0, countCanceled: 0, totalPaid: 0, countPaid: 0,
				};
				return { month, ...dataItem };
			});

			setDataSummaryInvoiceStatus({
				options: {
					title: {
						text: "Summary of invoice status",
						align: "center"
					},
					xaxis: {
						categories: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'June', 'July', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
						axisBorder: {
							show: false,
						},
						axisTicks: {
							show: false,
						},
					},
					yaxis: {
						labels: {
							formatter: function (value) {
								return toCommaFormat(value);
							},
						},
						title: {
							text: 'Total amount',
						},
					},
				},
				series: [{
					name: 'Waiting',
					data: mappedData.map(item => item.totalWaiting)
				}, {
					name: 'Approve',
					data: mappedData.map(item => item.totalApprove)
				}, {
					name: 'Denied',
					data: mappedData.map(item => item.totalDenied)
				}, {
					name: 'Canceled',
					data: mappedData.map(item => item.totalCanceled)
				}, {
					name: 'Paid',
					data: mappedData.map(item => item.totalPaid)
				}],
			})
			setDataCountInvoiceStatus({
				options: {
					title: {
						text: "Count of invoice status",
						align: "center"
					},
					xaxis: {
						categories: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'June', 'July', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
						axisBorder: {
							show: false,
						},
						axisTicks: {
							show: false,
						},
					},
					yaxis: {
						labels: {
							formatter: function (value) {
								return toCommaFormat(value);
							},
						},
						title: {
							text: 'Number of invoices',
						},
					},
				},
				series: [{
					name: 'Waiting',
					data: mappedData.map(item => item.countWaiting)
				}, {
					name: 'Approve',
					data: mappedData.map(item => item.countApprove)
				}, {
					name: 'Denied',
					data: mappedData.map(item => item.countDenied)
				}, {
					name: 'Canceled',
					data: mappedData.map(item => item.countCanceled)
				}, {
					name: 'Paid',
					data: mappedData.map(item => item.countPaid)
				}],
			})
		} else {
			setDataSummaryInvoiceStatus({
				options: {
					title: {
						text: "Summary of invoice status",
						align: "center"
					}
				}
			})
			setDataCountInvoiceStatus({
				options: {
					title: {
						text: "Count of invoice status",
						align: "center"
					}
				}
			})
		}
	}, [allSummaryInvoiceStatus])

	useEffect(() => {
		if (allSummaryExpenseStatus.length > 0) {
			const mapStatus = allSummaryExpenseStatus.reduce((map, item) => {
				const { month, status, total, countPayment } = item;
				const existingData = map.get(month) || {
					month, totalWaiting: 0, countWaiting: 0, totalApprove: 0, countApprove: 0, totalDenied: 0, countDenied: 0, totalCanceled: 0, countCanceled: 0, totalPaid: 0, countPaid: 0,
				};
				existingData.totalWaiting += status === STATUS_PAYMENT_WAITING ? total : 0;
				existingData.countWaiting += status === STATUS_PAYMENT_WAITING ? countPayment : 0;
				existingData.totalApprove += status === STATUS_PAYMENT_APPROVE ? total : 0;
				existingData.countApprove += status === STATUS_PAYMENT_APPROVE ? countPayment : 0;
				existingData.totalDenied += status === STATUS_PAYMENT_DENIED ? total : 0;
				existingData.countDenied += status === STATUS_PAYMENT_DENIED ? countPayment : 0;
				existingData.totalCanceled += status === STATUS_PAYMENT_CANCELED ? total : 0;
				existingData.countCanceled += status === STATUS_PAYMENT_CANCELED ? countPayment : 0;
				existingData.totalPaid += status === STATUS_PAYMENT_PAID ? total : 0;
				existingData.countPaid += status === STATUS_PAYMENT_PAID ? countPayment : 0;
				map.set(month, existingData);
				return map;
			}, new Map());

			const months = Array.from({ length: 12 }, (_, index) => (index + 1).toString().padStart(2, '0'));
			const mappedData = months.map((month) => {
				const dataItem = mapStatus.get(month) || {
					month, totalWaiting: 0, countWaiting: 0, totalApprove: 0, countApprove: 0, totalDenied: 0, countDenied: 0, totalCanceled: 0, countCanceled: 0, totalPaid: 0, countPaid: 0,
				};
				return { month, ...dataItem };
			});

			setDataSummaryExpenseStatus({
				options: {
					title: {
						text: "Summary of expense status",
						align: "center"
					},
					xaxis: {
						categories: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'June', 'July', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
						axisBorder: {
							show: false,
						},
						axisTicks: {
							show: false,
						},
					},
					yaxis: {
						labels: {
							formatter: function (value) {
								return toCommaFormat(value);
							},
						},
						title: {
							text: 'Total amount',
						},
					},
				},
				series: [{
					name: 'Waiting',
					data: mappedData.map(item => item.totalWaiting)
				}, {
					name: 'Approve',
					data: mappedData.map(item => item.totalApprove)
				}, {
					name: 'Denied',
					data: mappedData.map(item => item.totalDenied)
				}, {
					name: 'Canceled',
					data: mappedData.map(item => item.totalCanceled)
				}, {
					name: 'Paid',
					data: mappedData.map(item => item.totalPaid)
				}],
			})
			setDataCountExpenseStatus({
				options: {
					title: {
						text: "Count of expense status",
						align: "center"
					},
					xaxis: {
						categories: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'June', 'July', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
						axisBorder: {
							show: false,
						},
						axisTicks: {
							show: false,
						},
					},
					yaxis: {
						labels: {
							formatter: function (value) {
								return toCommaFormat(value);
							},
						},
						title: {
							text: 'Number of payment receipts',
						},
					},
				},
				series: [{
					name: 'Waiting',
					data: mappedData.map(item => item.countWaiting)
				}, {
					name: 'Approve',
					data: mappedData.map(item => item.countApprove)
				}, {
					name: 'Denied',
					data: mappedData.map(item => item.countDenied)
				}, {
					name: 'Canceled',
					data: mappedData.map(item => item.countCanceled)
				}, {
					name: 'Paid',
					data: mappedData.map(item => item.countPaid)
				}],
			})
		} else {
			setDataSummaryExpenseStatus({
				options: {
					title: {
						text: "Summary of expense status",
						align: "center"
					}
				}
			})
			setDataCountExpenseStatus({
				options: {
					title: {
						text: "Count of expense status",
						align: "center"
					}
				}
			})
		}
	}, [allSummaryExpenseStatus])

	useEffect(() => {
		if (allIncomeByCustomer) {
			setDataIncomeByCustomer({
				options: {
					title: {
						text: "Income by customer",
						align: "center"
					},
					labels: allIncomeByCustomer.map(item => item.name),
				},
				series: allIncomeByCustomer.map(item => item.countIncome),
			})
		}
	}, [allIncomeByCustomer])

	useEffect(() => {
		if (allExpenseByVendor) {
			setDataExpenseByVendor({
				options: {
					title: {
						text: "Expense by vendor",
						align: "center"
					},
					labels: allExpenseByVendor.map(item => item.name),
				},
				series: allExpenseByVendor.map(item => item.countExpense),
			})
		}
	}, [allExpenseByVendor])

	useEffect(() => {
		if (allIncomeByProject) {
			setDataIncomeByProject({
				options: {
					title: {
						text: "Income by project",
						align: "center"
					},
					labels: allIncomeByProject.map(item => item.name),
				},
				series: allIncomeByProject.map(item => item.countIncome),
			})
		}
	}, [allIncomeByProject])

	useEffect(() => {
		if (allExpenseByCategory) {
			setDataExpenseByCategory({
				options: {
					title: {
						text: "Expense by category",
						align: "center"
					},
					labels: allExpenseByCategory.map(item => item.name),
				},
				series: allExpenseByCategory.map(item => item.total),
			})
		}
	}, [allExpenseByCategory])

	
	return (
		<Box>
			<CustomMainContainer>
				<div className='flex flex-rol w-full'>
					<CustomBox className='text-right w-[50%]'>Finance</CustomBox>
					<div className="gap-1 p-1 text-right py-2 flex-wrap w-[50%]">
						<ButtonGroup variant="outlined" aria-label="outlined button group">
							<Button className={selectedButton === "7" ? "!bg-[#e3982e] text-white" : ""} onClick={() => handleClick("7")}>7 วัน</Button>
							<Button className={selectedButton === "30" ? "!bg-[#e3982e] text-white" : ""} onClick={() => handleClick("30")}>30 วัน</Button>
							<Button className={selectedButton === "180" ? "!bg-[#e3982e] text-white" : ""} onClick={() => handleClick("180")}>180 วัน</Button>
							<Button className={selectedButton === "365" ? "!bg-[#e3982e] text-white" : ""} onClick={() => handleClick("365")}>365 วัน</Button>
						</ButtonGroup>
					</div>
				</div>
				<div className='grid gap-2 my-2 grid-cols-1 lg:grid-cols-3'>
					<Card>
						<CardContent>
							<Stack alignItems="flex-start" direction="row" justifyContent="space-between" spacing={3}>
								<Stack spacing={1}>
									<Typography variant="subtitle2">Income</Typography>
									<Typography variant="h2" fontWeight="500">{toCommaFormat(totalIncome)}</Typography>
								</Stack>
							</Stack>
						</CardContent>
					</Card>
					<Card>
						<CardContent>
							<Stack alignItems="flex-start" direction="row" justifyContent="space-between" spacing={3}>
								<Stack spacing={1}>
									<Typography variant="subtitle2">Expense</Typography>
									<Typography variant="h2" fontWeight="500">{toCommaFormat(totalExpense)}</Typography>
								</Stack>
							</Stack>
						</CardContent>
					</Card>
					<Card >
						<CardContent>
							<Stack alignItems="flex-start" direction="row" justifyContent="space-between" spacing={3}>
								<Stack spacing={1}>
									<Typography variant="subtitle2">Profit</Typography>
									<Typography variant="h2" fontWeight="500" className={totalProfit < 0 ? 'text-red-500' : 'text-[#49c775]'} >{toCommaFormat(totalProfit)}</Typography>
								</Stack>
							</Stack>
						</CardContent>
					</Card>
				</div>
				<div className='grid gap-2 my-2 grid-cols-1 lg:grid-cols-4'>
					<CustomContainer>
						<Chart type="donut" pattern="donut" options={dataIncomeByCustomer.options} series={dataIncomeByCustomer.series}></Chart>
					</CustomContainer>
					<CustomContainer>
						<Chart type="donut" pattern="donut" options={dataIncomeByProject.options} series={dataIncomeByProject.series}></Chart>
					</CustomContainer>
					<CustomContainer>
						<Chart type="donut" pattern="donut" options={dataExpenseByVendor.options} series={dataExpenseByVendor.series}></Chart>
					</CustomContainer>
					<CustomContainer>
						<Chart type="donut" pattern="donut" options={dataExpenseByCategory.options} series={dataExpenseByCategory.series}></Chart>
					</CustomContainer>
				</div>
			</CustomMainContainer>
			<CustomMainContainer >
				<div className='grid gap-2 my-2 grid-cols-1 lg:grid-cols-4'>
					<CustomContainerOverview className='lg:col-span-2'>
						<Chart type="bar" pattern="bar" options={dataProfitAndLoss.options} series={dataProfitAndLoss.series} height={350} />
					</CustomContainerOverview>
					<CustomContainerOverview className='lg:col-span-2'>
						<Chart type="bar" pattern="bar" options={dataARAP.options} series={dataARAP.series} height={350} />
					</CustomContainerOverview>
					<CustomContainerOverview className='lg:col-span-2'>
						<Chart type="bar" pattern="bar" options={dataIncomePlanAndActual.options} series={dataIncomePlanAndActual.series} height={350} />
					</CustomContainerOverview>
					<CustomContainerOverview className='lg:col-span-2'>
						<Chart type="bar" pattern="bar" options={dataExpensePlanAndActual.options} series={dataExpensePlanAndActual.series} height={350} />
					</CustomContainerOverview>
					<CustomContainerOverview className='lg:col-span-2'>
						<Chart type="bar" pattern="bar" options={dataSummaryInvoiceStatus.options} series={dataSummaryInvoiceStatus.series} height={350} />
					</CustomContainerOverview>
					<CustomContainerOverview className='lg:col-span-2'>
						<Chart type="bar" pattern="bar" options={dataCountInvoiceStatus.options} series={dataCountInvoiceStatus.series} height={350} />
					</CustomContainerOverview>
					<CustomContainerOverview className='lg:col-span-2'>
						<Chart type="bar" pattern="bar" options={dataSummaryExpenseStatus.options} series={dataSummaryExpenseStatus.series} height={350} />
					</CustomContainerOverview>
					<CustomContainerOverview className='lg:col-span-2'>
						<Chart type="bar" pattern="bar" options={dataCountExpenseStatus.options} series={dataCountExpenseStatus.series} height={350} />
					</CustomContainerOverview>
				</div>
			</CustomMainContainer>
		</Box>
	)
}