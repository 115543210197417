import React, { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Modal from 'components/Modal'
import Form from 'components/Form'
import { setInfo, clearInfoById } from 'slices/infoSlice'
import useAxios from 'hooks/useAxios'
import AdvanceSearch from 'components/AdvanceSearch'
import { BILLING_COND } from 'helper/DataFactory'
import { memoizedSelectAdvanceInfo } from 'redux/memorize'

export default function Bills({ mode, divisionId, vendorId, oilPrice, uom, page = "invoice" }) {

	const dispatch = useDispatch()
	const info = useSelector(state => state.info)
	const { get } = useAxios()
	const paging = useState({})
	const searchInfo = useSelector(memoizedSelectAdvanceInfo)
	const [allBill, setAllBill] = useState([])

	const fetchData = async () => {
		const result = await get("/bill/getAllCalculatedBill", { divisionId, vendorId, oilPrice, uom })
		if (result.status === 200) {
			const data = result.data.data || []
			const firstArray = data.records
			const secondArray = (page === "payment" ? info.ctx.jobs : info.ctx.orders) || []
			data.records = firstArray.filter((element) => !secondArray.some((item) => (page === "payment" ? item.id : item.orderId) === element.id))
			setAllBill(data)
		}
	}

	useEffect(() => {
		setAllBill([])
		if (mode[0] === "initial") {
			dispatch(clearInfoById("jobs"))
			dispatch(clearInfoById("orders"))
			dispatch(clearInfoById("advanceSearch"))
		} else {
			fetchData()
		}
	}, [mode[0]])

	const onCloseModal = useCallback(() => {
		mode[1]("initial")
	}, [])

	const saveData = useCallback(() => {
		if (page === "payment") {
			if (info.jobs?.calculatedJobs?.length > 0) {
				const jobs = info.ctx.jobs || []
				dispatch(setInfo({ jobs: [...jobs, ...info.jobs.calculatedJobs] }))
			}
		} else if (info.orders?.calculatedOrders?.length > 0) {
			const orders = info.ctx.orders || []
			dispatch(setInfo({ orders: [...orders, ...info.orders.calculatedOrders.map(({ id, ...rest }) => ({ ...rest, orderId: id, id }))] }))
		}
		mode[1]("initial")
	}, [info])

	const search = async (data) => {
		const result = await get("/bill/getAllCalculatedBill", { divisionId, vendorId, oilPrice, uom, search: data, paging: paging[0] })
		if (result.status === 200) {
			const data = result.data.data || []
			const firstArray = data.records
			const secondArray = (page === "payment" ? info.ctx.jobs : info.ctx.orders) || []
			data.records = firstArray.filter((element) => !secondArray.some((item) => (page === "payment" ? item.itemId : item.orderId) === element.id))
			setAllBill(data)
		}
	}

	const searchFields = [
		{
			type: "date",
			props: {
				label: "ตั้งแต่วันที่",
				id: "startDate",
				maxDate: searchInfo.toDate || new Date(),
			}
		},
		{
			type: "date",
			props: {
				label: "ถึงวันที่",
				id: "toDate",
				minDate: searchInfo.startDate,
				maxDate: new Date(),
			}
		},
		page === "payment" && {
			type: "text",
			props: {
				label: "คู่สัญญา",
				id: "customerName",
			}
		},
		{
			type: "text",
			props: {
				label: "เลขที่อินวอยซ์",
				id: "invoiceNo",
			}
		}
	]

	const inputForm = [
		{
			rowData: [
				page === "payment" ? {
					type: "dataTable",
					props: {
						columns: [
							{
								id: 'jobName',
								label: 'เลขที่ใบงาน',
								fields: ["jobName"]
							},
							{
								id: 'quoNo',
								label: 'เลขที่ใบเสนอราคา',
								fields: ["quoNo"]
							},
							{
								id: 'uom',
								label: 'เงื่อนไขวางบิล',
								fields: ["uom"],
								valuesMap: BILLING_COND
							},
							{
								id: 'routeName',
								label: 'เส้นทาง',
								fields: ["routeName"],
							},
							{
								id: 'driverName',
								label: 'คนขับ',
								fields: ["driverName"],
							},
							{
								id: 'vehicleNo',
								label: 'ทะเบียนรถ',
								fields: ["vehicleNo"],
							},
							{
								id: 'vehicleTypeName',
								label: 'ประเภทรถ',
								fields: ["vehicleTypeName"],
							},
							{
								id: 'calculateCount',
								label: 'จำนวน',
								fields: ["calculateCount"],
								type: "number"
							},
							{
								id: 'total',
								label: 'ราคาสุทธิ',
								fields: ["total"],
								type: "number"
							},
						],
						collapse: {
							columns: [
								{
									id: 'invoiceNo',
									label: 'เลขที่อินวอยซ์',
									fields: ["invoiceNo"]
								},
								{
									id: 'customerName',
									label: 'ชื่อลูกค้า',
									fields: ["customerName"]
								},
								{
									id: 'shipToAddress',
									label: 'ที่อยู่ผู้รับ',
									fields: ["shipToAddress"]
								},
								{
									id: 'productName',
									label: 'สินค้า',
									fields: ["productName"]
								},
								{
									id: 'unitName',
									label: 'หน่วย',
									fields: ["unitName"]
								},
								{
									id: 'quantity',
									label: 'จำนวน',
									fields: ["quantity"],
									type: "number"
								},
								{
									id: 'pricePerUnit',
									label: 'ราคาต่อหน่วย',
									fields: ["pricePerUnit"],
									type: "number"
								},
								{
									id: 'amount',
									label: 'ราคา',
									fields: ["amount"],
									type: "number"
								},
								{
									id: 'completeTime',
									label: 'วันที่ส่ง',
									fields: ["completeTime"],
									type: "date"
								},
								{
									id: 'remark',
									label: 'หมายเหตุ',
									fields: ["remark"]
								},
							],
							field: "bills"
						},
						rows: allBill,
						multiSelect: true,
						title: "บิล",
						id: `calculatedJobs`,
						// controller: (paging) => get("/bill/getAllCalculatedBill", { divisionId, vendorId, oilPrice, search: searchInfo, paging }),
						customToolbar: {
							component: AdvanceSearch,
							props: {
								handleSearch: search,
								components: searchFields
							}
						},
						state: { paging },
						tableContainerClassName: "max-h-[500px]",
						size: "small",
						spliter: ", ",
						hideSelected: true
					}
				} : {
					type: "dataTable",
					props: {
						columns: [
							{
								id: 'orderName',
								label: 'เลขที่ออเดอร์',
								fields: ["orderName"]
							},
							{
								id: 'quoNo',
								label: 'เลขที่ใบเสนอราคา',
								fields: ["quoNo"]
							},
							{
								id: 'uom',
								label: 'เงื่อนไขวางบิล',
								fields: ["uom"],
								valuesMap: BILLING_COND
							},
							{
								id: 'amount',
								label: 'ราคารวม',
								fields: ["amount"],
								type: "number"
							},
							{
								id: 'jobCost',
								label: 'ค่าใช้จ่ายเพิ่มเติมของใบงานรวม',
								fields: ["jobCost"],
								type: "number"
							},
							{
								id: 'billCost',
								label: 'ค่าใช้จ่ายเพิ่มเติมของบิลรวม',
								fields: ["billCost"],
								type: "number"
							},
							{
								id: 'cost',
								label: 'ค่าใช้จ่ายเพิ่มเติมรวม',
								fields: ["cost"],
								type: "number"
							},
							{
								id: 'total',
								label: 'ราคาสุทธิ (ร่วมค่าใช้จ่ายเพิ่มเติม)',
								fields: ["total"],
								type: "number"
							},
						],
						collapse: {
							columns: [
								{
									id: 'name',
									label: 'เส้นทาง',
									fields: ["name"]
								},
								{
									id: 'billCount',
									label: 'จำนวนบิล',
									fields: ["billCount"],
									type: "number"
								},
								{
									id: 'calculateCount',
									label: 'จำนวน',
									fields: ["calculateCount"],
									type: "number"
								},
								{
									id: 'additionalPriceTotal',
									label: 'ค่าใช้จ่ายเพิ่มเติม',
									fields: ["additionalPriceTotal"],
									type: "number"
								},
								{
									id: 'total',
									label: 'ราคาสุทธิ',
									fields: ["total"],
									type: "number"
								},
							],
							field: "routes",
							collapse: {
								columns: [
									{
										id: 'invoiceNo',
										label: 'เลขที่อินวอยซ์',
										fields: ["invoiceNo"]
									},
									{
										id: 'shipToAddress',
										label: 'ที่อยู่ผู้รับ',
										fields: ["shipToAddress"]
									},
									{
										id: 'productName',
										label: 'สินค้า',
										fields: ["productName"]
									},
									{
										id: 'weight',
										label: 'น้ำหนัก',
										fields: ["weight"],
										type: "number"
									},
									{
										id: 'density',
										label: 'ปริมาตร',
										fields: ["density"],
										type: "number"
									},
									{
										id: 'completeTime',
										label: 'วันที่ส่ง',
										fields: ["completeTime"],
										type: "date"
									},
									{
										id: 'quantity',
										label: 'จำนวน',
										fields: ["quantity"],
										type: "number"
									},
									{
										id: 'pricePerUnit',
										label: 'ราคาต่อหน่วย',
										fields: ["pricePerUnit"],
										type: "number"
									},
									{
										id: 'amount',
										label: 'ราคา',
										fields: ["amount"],
										type: "number"
									},
									{
										id: 'remark',
										label: 'หมายเหตุ',
										fields: ["remark"]
									},
								],
								field: "bills"
							}
						},
						rows: allBill,
						multiSelect: true,
						title: "บิล",
						id: `calculatedOrders`,
						// controller: (paging) => get("/bill/getAllCalculatedBill", { divisionId, vendorId, oilPrice, search: searchInfo, paging }),
						customToolbar: {
							component: AdvanceSearch,
							props: {
								handleSearch: search,
								components: searchFields
							}
						},
						state: { paging },
						tableContainerClassName: "max-h-[500px]",
						size: "small",
						spliter: ", ",
						hideSelected: true
					}
				}
			]
		},
		{
			noCol: true,
			align: "center",
			rowData: [
				{
					type: "button",
					props: {
						label: "ตกลง",
						onClick: saveData,
					}
				}
			]
		}
	]

	return (
		<Modal id="billModal" open={mode[0] === "create"} onClose={onCloseModal} className="form-modal">
			<Form id={page === "payment" ? `jobs` : `orders`} title={"ข้อมูลบิล"} name="user-form" data={inputForm}></Form>
		</Modal>
	)
}