import { Box } from '@mui/material'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Panel from 'components/Panel'
import Modal from 'components/Modal'
import Form from 'components/Form'
import { useSnackbar } from 'notistack'
import { setInfo, clearInfo } from 'slices/infoSlice'
import { normalizeData } from 'utilities/validator'
import usePopup from 'hooks/usePopup'
import useAxios from 'hooks/useAxios'
import { warpUpFailResponse } from 'utilities/utils'
import Address from 'pages/address'
import useDataHandler from 'hooks/useDataHandler'

export default function CustomerTrans() {
	const dispatch = useDispatch()
	const { enqueueSnackbar } = useSnackbar();
	const { post, del, get, multiple, upload } = useAxios();
	const { save } = useDataHandler()
	const info = useSelector(state => state.info.ctx || {})
	const { confirmPopup } = usePopup();
	const [mode, setMode] = useState("initial")
	const [allAddress, setAllAddress] = useState([])
	const [allAddressDelivery, setAllAddressDelivery] = useState([])
	const [allFiles, setAllFiles] = useState([
		{ label: "สำเนาหนังสือรับรองการจดทะเบียน", value: "สำเนาหนังสือรับรองการจดทะเบียน" },
		{ label: " สำเนาบัตรประชาชน", value: " สำเนาบัตรประชาชน" },
		{ label: "ภพ.20", value: "ภพ.20" },
		{ label: "ภพ.01", value: "ภพ.01" },
		{ label: "ภพ.09", value: "ภพ.09" },])
	const [allCustomer, setAllCustomer] = useState([]);
	const [provinceRes, setProvinceRes] = useState([])
	const roleUser = useSelector(state => state.user.roleName)

	const fetchData = async controller => {
		const result = await multiple([
			{ method: "get", url: "/province/getAllProvince", config: { signal: controller.signal } },
			{ method: "get", url: "/customer/getAllCustomer", config: { signal: controller.signal } }
		])
		if (result[0].status === 200) {
			const data = result[0].data.data
			setProvinceRes(data.records)
		}
		if (result[1].status === 200) {
			const customers = result[1].data.data || []
			setAllCustomer(customers)
		}
	}

	useEffect(() => {
		const controller = new AbortController();
		fetchData(controller)
		return () => {
			controller.abort()
		}
	}, [])

	useEffect(() => {
		if (info.address) {
			setAllAddress(info?.address.map(e => ({ label: `${e.addressTH || "-"} ${e.fullAddress || "-"}`, value: e.id || e.itemId, data: e })))
			setAllAddressDelivery(info?.address.map(e => ({ label: `${e.addressTH || "-"} ${e.fullAddress || "-"}`, value: e.id || e.itemId, data: e })))
			if (info?.address.some(e => e.defaultAD)) {
				dispatch(setInfo({
					locationId: info?.address.filter(e => e?.billing && e?.locationId)[0]?.id || "",
					deliveryLocationId: info?.address.filter(e => e?.document && e?.deliveryLocationId)[0]?.id || ""
				}))
			}
		}
	}, [info.address])

	useEffect(() => {
		if (info.provinceId && provinceRes) {
			const { province, amphoe, district, zipcode } = provinceRes.find(e => info.provinceId === e.id)
			dispatch(setInfo({ province, amphoe, district, zipcode }))
		} else {
			dispatch(setInfo({ province: null, amphoe: null, district: null, zipcode: null }))
		}

	}, [info.provinceId])

	const onCloseModal = useCallback(() => {
		confirmPopup({
			title: "แจ้งเตือน",
			content: "ท่านต้องการยกเลิกการแก้ไขหรือไม่",
			onSubmit: () => {
				setMode("initial")
			}
		})
	}, [])

	useEffect(() => {
		if (info.prefix !== 'other' && info.prefixOther) {
			dispatch(setInfo({ prefixOther: null }));
		}
		if (info.businessType !== 'other' && info.businessTypeOther) {
			dispatch(setInfo({ businessTypeOther: null }));
		}
		if (info.productType !== 'other' && info.productTypeOther) {
			dispatch(setInfo({ productTypeOther: null }));
		}
		if (info.carTtype !== 'other' && info.carTtypeOther) {
			dispatch(setInfo({ carTtypeOther: null }));
		}
		if (info.billingDetail !== 'other' && info.billingDetailOther) {
			dispatch(setInfo({ billingDetailOther: null }));
		}

	}, [info.prefix, info.businessType, info.productType, info.carTtype, info.billingDetail])

	const inputForm = [
		{
			rowData: [
				{
					type: "radio",
					props: {
						id: "prefix",
						label: "คำนำหน้า",
						options: [
							{ label: "หจก.", value: "หจก." },
							{ label: "บจก.", value: "บจก." },
							{ label: "บมจ.", value: "บมจ." },
							{ label: "อื่นๆ", value: "other" },
						],
					}
				},
				info.prefix === 'other' && {
					type: "text",
					props: {
						id: "prefixOther",
						label: "ระบุ คำนำหน้า อื่นๆ",
						variant: "outlined"
					}
				}, {}
			]
		}, {
			rowData: [
				{
					type: "text",
					required: true,
					props: {
						id: "name",
						label: "ชื่อทางการค้า",
						variant: "outlined"
					}
				},
				{
					type: "text",
					required: true,
					props: {
						id: "nameTH",
						label: "ชื่อบริษัท (ภาษาไทย)",
						variant: "outlined"
					}
				},
				{
					type: "text",
					props: {
						id: "nameEN",
						label: "ชื่อบริษัท (ภาษาอังกฤษ)",
						variant: "outlined"
					}
				}
			]
		}, {
			rowData: [
				{
					type: "text",
					validation: 'tel',
					props: {
						id: "tel",
						label: "เบอร์โทร",
						variant: "outlined"
					}
				},
				{
					type: "text",
					props: {
						id: "email",
						label: "อีเมลล์",
						variant: "outlined"
					}
				},
				{
					type: "text",
					props: {
						id: "website",
						label: "เว็บไซต์",
						variant: "outlined"
					}
				}
			]
		}, {
			rowData: [
				{
					type: "text",
					required: true,
					props: {
						id: "taxAccNo",
						label: "เลขประจำตัวผู้เสียภาษี",
						variant: "outlined",
						maxCharacters: 13
					}, md: 4
				},
				mode === "edit" && {
					type: "dropdown",
					props: {
						id: "active",
						label: "สถานะ",
						options: [
							{ label: "ใช้งาน", value: true },
							{ label: "ไม่ใช้งาน", value: false }
						],
					}, md: 4
				}, {}
			]
		}, {
			rowData: [
				{
					type: "number",
					props: {
						id: "creditTerm",
						label: "credit term"
					}
				},
				{
					type: "autocomplete",
					required: info.creditTerm != null,
					props: {
						id: "creditTermType",
						label: "ประเภท credit term",
						options: [
							{ label: "บาท", value: "บาท" },
							{ label: "วัน", value: "วัน" },
						]
					}
				},
				{}
			]
		}, {
			rowData: [
				{
					type: "text",
					props: {
						id: "remark",
						label: "หมายเหตุ",
						variant: "outlined",
						multiline: true,
						maxRows: 4
					}
				}
			]
		}, {
			isCollapse: true,
			label: "ที่อยู่",
			defaultCollapse: true,
			collapseData: [
				{
					rowData: [
						{
							type: "custom",
							component: <Address typeId={"customer"} id={info.id} saveOnBase={false} />
						}
					],
				}, {
					rowData: [
						{
							type: "divider",
							props: {
								label: "ตั้งค่าที่อยู่เริ่มต้น",
								variant: "outlined",
							}
						}
					]
				}, {
					rowData: [
						{
							type: "autocomplete",
							required: true,
							props: {
								id: "deliveryLocationId",
								label: "ที่อยู่สำหรับจัดส่งเอกสาร",
								options: allAddressDelivery,
								allowDuplicate: true,
								filterOptions: (e) => e.data?.document,
							}
						}
					]
				}, {
					rowData: [
						{
							type: "autocomplete",
							required: true,
							props: {
								id: "locationId",
								label: "ที่อยู่สำหรับวางบิล",
								allowDuplicate: true,
								options: allAddress,
								filterOptions: (e) => e.data?.billing,
							}
						}
					]
				}
			]
		}, {
			isCollapse: true,
			label: "รายละเอียดธุรกิจ",
			defaultCollapse: true,
			collapseData: [
				{
					rowData: [
						{
							type: "radio",
							props: {
								id: "businessType",
								label: "ประเภทธุรกิจ",
								options: [
									{ label: "ผลิต", value: "ผลิต" },
									{ label: "Trading", value: "Trading" },
									{ label: "นำเข้า/ส่งออก", value: "นำเข้า/ส่งออก" },
									{ label: "ร้านอาหาร/โรงแรม", value: "ร้านอาหาร/โรงแรม" },
									{ label: "ชิปปิ้ง", value: "ชิปปิ้ง" },
									{ label: "ขนส่ง", value: "ขนส่ง" },
									{ label: "คลังสินค้า", value: "คลังสินค้า" },
									{ label: "อื่นๆ", value: "other" },
								]
							}, md: 9,
						},
						info.businessType === 'other' && {
							type: "text",
							props: {
								id: "businessTypeOther",
								label: "ระบุ ประเภทธุรกิจ อื่นๆ",
								variant: "outlined"
							}, md: 3,
						}
					]
				}, {
					rowData: [
						{
							type: "radio",
							props: {
								id: "productType",
								label: "ประเภทสินค้า",
								options: [
									{ label: "อาหาร", value: "อาหาร" },
									{ label: "เบเกอรี่", value: "เบเกอรี่" },
									{ label: "เครื่องดื่ม", value: "เครื่องดื่ม" },
									{ label: "ยาและเวชภัณฑ์", value: "ยาและเวชภัณฑ์" },
									{ label: "อื่นๆ", value: "other" },
								]
							}, md: 9,
						},
						info.productType === 'other' && {
							type: "text",
							props: {
								id: "productTypeOther",
								label: "ระบุ ประเภทสินค้า อื่นๆ",
								variant: "outlined"
							}, md: 3,
						}
					]
				}, {
					rowData: [
						{
							type: "radio",
							props: {
								id: "carTtype",
								label: "ประเภทรถที่ใช้",
								options: [
									{ label: "4 ล้อ", value: "4 ล้อ" },
									{ label: "6 ล้อ", value: "6 ล้อ" },
									{ label: "10 ล้อ", value: "10 ล้อ" },
									{ label: "ทั้งหมด", value: "ทั้งหมด" },
								]
							}
						}
					]
				}, {
					rowData: [
						{
							type: "radio",
							props: {
								id: "billingDetail",
								label: "รายละเอียดการวางบิล",
								options: [
									{ label: "รับสินค้า พร้อมเก็บเงินสดที่ต้นทาง", value: "รับสินค้า พร้อมเก็บเงินสดที่ต้นทาง" },
									{ label: "ส่งสินค้า พร้อมเก็บเงินสดที่ปลายทาง", value: "ส่งสินค้า พร้อมเก็บเงินสดที่ปลายทาง" },
									{ label: "Scan ส่งE-mail / โอนเงิน", value: "Scan ส่งE-mail / โอนเงิน" },
									{ label: "อื่นๆ", value: "other" },
								]
							}, md: 9,
						},
						info.billingDetail === 'other' && {
							type: "text",
							props: {
								id: "billingDetailOther",
								label: "ระบุ รายละเอียดการวางบิล อื่นๆ",
								variant: "outlined"
							}, md: 3,
						}
					]
				},
			]
		}, {
			isCollapse: true,
			label: "ข้อมูลผู้ติดต่อ (1)",
			defaultCollapse: true,
			collapseData: [
				{
					rowData: [
						{
							type: "text",
							props: {
								id: "contactName1",
								label: "ชื่อผู้ติดต่อ (1)",
								variant: "outlined"
							}
						},
						{
							type: "text",
							validation: 'tel',
							props: {
								id: "contactPhone1",
								label: "เบอร์ผู้ติดต่อ (1)",
								variant: "outlined"
							}
						},
						{
							type: "text",
							props: {
								id: "contactPosition1",
								label: "ตำแหน่ง",
								variant: "outlined"
							}
						}
					]
				}, {
					rowData: [
						{
							type: "text",
							props: {
								id: "contactDepartment1",
								label: "ฝ่าย/แผนก",
								variant: "outlined"
							}
						},
						{
							type: "text",
							props: {
								id: "contactEmail1",
								label: "E-mail",
								variant: "outlined"
							}
						},
						{
							type: "text",
							validation: 'tel',
							props: {
								id: "contactTel1",
								label: "เบอร์สำนักงาน",
								variant: "outlined"
							}
						}
					]
				}
			]
		}, {
			isCollapse: true,
			label: "ข้อมูลผู้ติดต่อ (2)",
			defaultCollapse: true,
			collapseData: [
				{
					rowData: [
						{
							type: "text",
							props: {
								id: "contactName2",
								label: "ชื่อผู้ติดต่อ (2)",
								variant: "outlined"
							}
						},
						{
							type: "text",
							validation: 'tel',
							props: {
								id: "contactPhone2",
								label: "เบอร์ผู้ติดต่อ (2)",
								variant: "outlined"
							}
						},
						{
							type: "text",
							props: {
								id: "contactPosition2",
								label: "ตำแหน่ง",
								variant: "outlined"
							}
						}
					]
				}, {
					rowData: [
						{
							type: "text",
							props: {
								id: "contactDepartment2",
								label: "ฝ่าย/แผนก",
								variant: "outlined"
							}
						},
						{
							type: "text",
							props: {
								id: "contactEmail2",
								label: "E-mail",
								variant: "outlined"
							}
						},
						{
							type: "text",
							validation: 'tel',
							props: {
								id: "contactTel2",
								label: "เบอร์สำนักงาน",
								variant: "outlined"
							}
						}
					]
				}
			]
		}, {
			isCollapse: true,
			label: "เอกสารแนบ",
			collapseData: [
				{
					rowData: [
						{
							type: "list",
							props: {
								id: "fileItems",
								mininum: 0,
								showDuplicate: mode === "create",
								data: [
									{
										rowData: [
											{
												type: "autocomplete",
												props: {
													id: "name",
													label: "เอกสารแนบ",
													options: allFiles,
													freeSolo: true
												},
												md: 4
											},
											{
												type: "upload",
												props: {
													id: "filePath",
													label: "แนบไฟล์",
													maxFiles: 1,
												},
												md: 8
											}
										]
									}
								],
							}
						},
					]
				},
			]
		}, {
			rowData: [
				{
					type: "button",
					props: {
						label: "บันทึก",
						onClick: () => {
							saveData()
						},
						className: "!bg-bpTheme-buttonSubmit"
					}
				}
			]
		}
	]
	const table = useMemo(() => [
		{
			rowData: [{
				type: "dataTable",
				props: {
					columns: [
						{
							id: 'no',
							label: 'เลขที่ลูกค้า',
							fields: ["no"]
						},
						{
							id: 'name',
							label: 'ชื่อ',
							fields: ["name"]
						},
						{
							id: 'nameTH',
							label: 'ชื่อ (ภาษาไทย)',
							fields: ["nameTH"]
						},
						{
							id: 'nameEN',
							label: 'ชื่อ (ภาษาอังกฤษ)',
							fields: ["nameEN"]
						},
						{
							id: 'active',
							label: 'สถานะ',
							fields: ["active"],
							valuesMap: [{ label: "ใช้งาน", value: true }, { label: "ไม่ใช้งาน", value: false }]

						}
					],
					rows: allCustomer,
					size: "small",
					showDeleteButton: roleUser === "Owner",
					title: "ข้อมูลลูกค้า",
					showSystemFields: true,
					onClick: async (e) => {
						setMode("edit")
						const result = await get("/customer/getCustomerById", { id: e.id })
						if (result.status === 200) {
							const data = result.data.data;
							for (let i = 0; i < data.fileItems.length; i++) {
								const file = data.fileItems[i];
								const fileExists = allFiles.some(e => e.value === file.name);

								if (file.name && !fileExists) {
									const newFile = { label: file.name, value: file.name };
									setAllFiles(prevAllFiles => [...prevAllFiles, newFile]);
								}
							}
							dispatch(setInfo(data))
						}

					},
					onDelete: (e, onSuccess) => {
						confirmPopup({
							onSubmit: async () => {
								const result = await del("/customer/deleteCustomer", { ids: e.map(data => data.id).join(',') })
								if (result.status === 200) {
									enqueueSnackbar('ลบสำเร็จ', { variant: "success" })
									dispatch(clearInfo())
									await fetchData(new AbortController())
									onSuccess()
								} else {
									enqueueSnackbar('ลบไม่สำเร็จ', { variant: "error" })
								}
							}
						})
					},
					controller: (paging) => get("/customer/getAllCustomer", { paging }),
					searchFields: [
						{ label: "ชื่อ", value: "name" },
						{ label: "สถานะ", value: "active" }
					]
				}
			}]
		}, {
			noCol: true,
			rowData: [
				{
					type: "button",
					props: {
						label: "เพิ่มใหม่",
						onClick: () => {
							setMode("create")
							dispatch(clearInfo())
						}
					}
				},
			]
		}
	], [allCustomer])

	const saveData = useCallback(() => {
		save({
			data: info,
			onSubmit: async () => {
				const data = normalizeData(info)
				let uploadFiles = data?.fileItems?.map(e => Array.isArray(e?.filePath) ? e?.filePath[0] : []) || [];
				let dataFiles = []
				if (uploadFiles.length) {
					const result = await Promise.all(uploadFiles.map(e => upload({ prefix: `admin/customer`, file: e })))
					let files = data?.fileItems?.map(e => Array.isArray(e?.filePath) ? e?.filePath[0].name : data.fileItems) || null;
					if (result.every(e => e.status === 200 || e.status === 400)) {
						dataFiles = data.fileItems.map((item, i) => {
							let fileItem = Array.isArray(item?.filePath) ? item?.filePath[0].name : item.fileItems || null;
							if (files.includes(fileItem)) {
								return {
									...item,
									fileType: fileItem?.split(".").pop(),
									filePath: result.map(e => e.data.data)[i],
									bucketName: "admin/customer",
									parentType: "customer"
								};
							} else {
								return {
									...item,
									fileType: item.fileType || null,
									filePath: item.filePath || null,
									bucketName: item.bucketName || null,
									parentType: "customer"
								};

							}
						});
					}
				}

				let response
				if (mode === "create") {
					response = await post("/customer/createCustomer", {
						...data, active: true, fileItems: dataFiles, address: data?.address.map(item => ({ ...item, id: item.tempId ? null : item.id }))
					})
				} else if (mode === "edit") {
					response = await post("/customer/updateCustomer", {
						...data, // locationId: data.locationId, deliveryLocationId: data.deliveryLocationId,
						fileItems: dataFiles, address: data?.address.map(item => ({ ...item, id: item.tempId ? null : item.id }))
					})
				}
				if (response.status === 200) {
					setMode("initial")
					enqueueSnackbar('บันทึกสำเร็จ', { variant: "success" })
					fetchData(new AbortController())
				} else {
					enqueueSnackbar(warpUpFailResponse(response, 'บันทึกไม่สำเร็จ'), { variant: "error" })
				}
			},
			onCancel: () => {
				enqueueSnackbar('ยกเลิกรายการ', { variant: "info" })
			}
		})
	}, [info, mode])

	return (
		<Box>
			<Panel data={table}></Panel>
			<Modal open={mode === "create" || mode === "edit"} onClose={onCloseModal} className="form-modal">
				<Form title={"ลูกค้า " + (info.no || "")} name="user-form" data={inputForm}></Form>
			</Modal>
		</Box>
	)
}