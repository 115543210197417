import React, { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Modal from 'components/Modal'
import Form from 'components/Form'
import { useSnackbar } from 'notistack'
import { validation } from 'utilities/validator'
import usePopup from 'hooks/usePopup'
import useAxios from 'hooks/useAxios'
import { setInfoById, clearInfoById } from 'slices/infoSlice'
import useMultipleFileReader from 'hooks/useMultipleFileReader'
import { warpUpFailResponse } from 'utilities/utils'
import { memoizedUploadInfo } from 'redux/memorize'
import { format, parse } from 'date-fns'

export default function UploadOrderModal({ mode, refreshData }) {

	const dispatch = useDispatch()
	const info = useSelector(memoizedUploadInfo)
	const { confirmPopup } = usePopup();
	const { post, multiple } = useAxios()
	const [allUnit, setAllUnit] = useState([])
	const [allServiceType, setAllServiceType] = useState([])
	const [allSender, setAllSender] = useState([])
	const [allVehicleType, setAllVehicleType] = useState([])
	const { enqueueSnackbar } = useSnackbar()

	const uploadFields = [
		{ label: "ชื่อเรียก", value: "projectName", required: true },
		{ label: "อุณหภูมิ", value: "temperature" },
		{ label: "สินค้า", value: "detail" },
		{ label: "หน่วย", value: "unit" },
		{
			label: "จุดรับ", value: "pickupAddress", required: true,
			tooltip: <>
				<p className='font-bold'>คำแนะนำ</p>
				<p>ควรวาง format ให้ตรง</p>
				<p>{"[ชื่อย่อเส้นทาง/จำนวน]"}</p>
			</>
		},
		{
			label: "จุดส่ง", value: "shippingAddress", required: true,
			tooltip: <>
				<p className='font-bold'>คำแนะนำ</p>
				<p>ควรวาง format ให้ตรง</p>
				<p>{"[ชื่อย่อเส้นทาง/จำนวน]"}</p>
			</>
		},
		{ label: "ประเภทรถ", value: "vehicleType", required: true },
		{ label: "ประเภทบริการ", value: "serviceType", required: true },
		{ label: "วันจันทร์", value: "mon", required: true },
		{ label: "วันอังคาร", value: "tue", required: true },
		{ label: "วันพุธ", value: "wed", required: true },
		{ label: "วันพฤหัสบดี", value: "thu", required: true },
		{ label: "วันศุกร์", value: "fri", required: true },
		{ label: "วันเสาร์", value: "sat", required: true },
		{ label: "วันอาทิตย์", value: "sun", required: true },
		{ label: "หมายเหตุ 1", value: "remark" },
		{ label: "หมายเหตุ 2", value: "remark2" },
		{ label: "เวลาที่จัดส่ง", value: "timeToDeliver", required: true },
		{ label: "ชั่วโมงทำงานเริ่มต้น", value: "startWorkingHour", required: true },
		{ label: "ชั่วโมงทำงานสิ้นสุด", value: "stopWorkingHour", required: true },
	]
	const mappedHeaders = uploadFields.reduce((acc, cur) => {
		acc[cur.label] = cur.value;
		return acc;
	},
		{});

	const uploadData = (data, callBack, originData) => {
		confirmPopup({
			onSubmit: async () => {
				const data4ErrMsg = JSON.parse(JSON.stringify(originData))

				//map data to array
				const mappedData = data.map(([headers, ...rows]) => rows.map((row) => row.map((e, i) => ({ [mappedHeaders[headers[i]]]: e })).reduce((acc, curr) => ({ ...acc, ...curr }), {})))

				const transformedArray = mappedData.flatMap(innerArray => innerArray);
				const divisions = [...new Set(transformedArray.filter(item => item.projectName).map(item => allSender.find(e => e.label === item.projectName)?.value))];

				let validateLocations = {}

				const result = await post("/location/getAllValidateLocation", { divisions })
				if (result.status === 200) {
					const data = result.data.data
					validateLocations = data
				} else {
					return
				}

				//validatedata
				const requiredFields = uploadFields.filter(e => e.required).map(e => e.value)
				const formatData = mappedData.map((sheet, i) => {
					data4ErrMsg[i][0].push("Error message")
					return sheet.map((row, j) => {
						const invalid = requiredFields.some(e => !row[e] && row[e] !== 0)
						let error = false
						row.unitId = null

						if (invalid) {
							data4ErrMsg[i][j + 1].push("required fields must not be null")
							error = true
						}

						if (!error && row.projectName) {
							const senderId = allSender.find(e => e.label === row.projectName)?.value
							if (senderId) {
								row.senderId = senderId
							} else {
								data4ErrMsg[i][j + 1].push("no project found")
								error = true
							}
						}

						if (!error && row.unit) {
							const unitId = allUnit.find(e => e.label === row.unit)?.value
							if (unitId) {
								row.unitId = unitId
							} else {
								data4ErrMsg[i][j + 1].push("no unit found")
								error = true
							}
						}

						if (!error) {
							const regex = /\[([^\/]+)\/([\d.]+)\]/g;
							const matches = row.pickupAddress.matchAll(regex);
							let isError = false

							const result = [...matches].map(match => {
								const parts = match.slice(1);
								if (parts[0] && parts[1] && validateLocations[row.senderId]?.pickupLocations?.find(e => e.routeName === parts[0])) {
									return { locationId: validateLocations[row.senderId]?.pickupLocations?.find(e => e.routeName === parts[0]).id, quantity: parseFloat(parts[1]) };
								} else {
									isError = true
									return null;
								}
							}).filter(e => e);

							if (isError) {
								data4ErrMsg[i][j + 1].push("error in pickup address field")
								error = true
							}

							row.pickupLocations = result
						}

						if (!error) {
							const regex = /\[([^\/]+)\/([\d.]+)\]/g;
							const matches = row.shippingAddress.matchAll(regex);
							let isError = false

							const result = [...matches].map(match => {
								const parts = match.slice(1);
								if (parts[0] && parts[1] && validateLocations[row.senderId]?.shippingLocations?.find(e => e.routeName === parts[0])) {
									return { locationId: validateLocations[row.senderId]?.shippingLocations?.find(e => e.routeName === parts[0]).id, quantity: parseFloat(parts[1]) };
								} else {
									isError = true
									return null;
								}
							}).filter(e => e);

							if (isError) {
								data4ErrMsg[i][j + 1].push("error in shipping address field")
								error = true
							}

							row.shippingLocations = result
						}

						if (!error) {
							const totalPickup = row.pickupLocations?.reduce((total, item) => total + item.quantity, 0);
							const totalShipping = row.shippingLocations?.reduce((total, item) => total + item.quantity, 0);
							if (totalPickup !== totalShipping) {
								data4ErrMsg[i][j + 1].push("number of pickup and shipping does not match")
								error = true
							}
						}

						if (!error) {
							const vehicleTypeId = allVehicleType.find(e => e.label === row.vehicleType)?.value
							if (vehicleTypeId) {
								row.vehicleTypeId = vehicleTypeId
							} else {
								data4ErrMsg[i][j + 1].push("no vehicle type found")
								error = true
							}
						}

						if (!error && row.temperature) {
							if (isNaN(Number(row.temperature))) {
								data4ErrMsg[i][j + 1].push("invalid temperature format")
								error = true
							}
						}

						if (!error) {
							if (
								row.mon != 0
								&& row.mon != 1
								&& row.tue != 0
								&& row.tue != 1
								&& row.wed != 0
								&& row.wed != 1
								&& row.thu != 0
								&& row.thu != 1
								&& row.fri != 0
								&& row.fri != 1
								&& row.sat != 0
								&& row.sat != 1
								&& row.sun != 0
								&& row.sun != 1
							) {
								data4ErrMsg[i][j + 1].push("invalid routine day checking")
								error = true
							}
						}

						if (!error) {
							const parseDate = parse(row.startWorkingHour, 'HH:mm', new Date())
							if (isNaN(parseDate)) {
								data4ErrMsg[i][j + 1].push("invalid date format")
								error = true
							}
						}

						if (!error) {
							const parseDate = parse(row.stopWorkingHour, 'HH:mm', new Date())
							if (isNaN(parseDate)) {
								data4ErrMsg[i][j + 1].push("invalid date format")
								error = true
							}
						}

						if (!error) {
							const serviceTypeId = allServiceType.find(e => e.label?.toLowerCase() === row.serviceType?.toLowerCase())?.value
							if (serviceTypeId) {
								row.serviceTypeId = serviceTypeId
							} else {
								data4ErrMsg[i][j + 1].push("service type not match")
							}
						}

						if (!error) {
							data4ErrMsg[i][j + 1].push("insert fail")
							return row
						}
					}).filter(e => e)
				})
				const flatMap = formatData.flatMap(innerArray => innerArray)
				const orders = Object.values(
					flatMap.reduce((acc, obj) => {
						const { senderId, vehicleTypeId, serviceTypeId, mon, tue, wed, thu, fri, sat, sun, startWorkingHour, stopWorkingHour, ...rest } = obj;
						const key = `${senderId}_${vehicleTypeId}_${serviceTypeId}_${mon}_${tue}_${wed}_${thu}_${fri}_${sat}_${sun}_${startWorkingHour}_${stopWorkingHour}`;
						if (!acc[key]) {
							acc[key] = {
								senderId,
								vehicleTypeId,
								serviceTypeId,
								startWorkingHour,
								stopWorkingHour,
								day: { mon, tue, wed, thu, fri, sat, sun },
								items: [],
							};
						}

						acc[key].items.push({ ...rest });
						return acc;
					},
						{})
				);

				if (orders.length > 0 && data4ErrMsg.every(sheet => sheet.every(rows => ["Error message", "insert fail"].includes(rows[rows.length - 1])))) {
					let response = await post("/orderRoutine/uploadOrderRoutine", {
						orders
					})
					if (response.status === 200) {
						enqueueSnackbar('บันทึกสำเร็จ', { variant: "success" })
						callBack(data4ErrMsg.map(rows => {
							return rows.filter(row => row[row.length - 1] !== "insert fail")
						}))
						refreshData(new AbortController())
					} else {
						enqueueSnackbar(warpUpFailResponse(response, 'บันทึกไม่สำเร็จ'), { variant: "error" })
						callBack(data4ErrMsg)
					}
				} else {
					enqueueSnackbar('บันทึกไม่สำเร็จ', { variant: "error" })
					callBack(data4ErrMsg)
				}
			},
			onCancel: () => {
				enqueueSnackbar('ยกเลิกรายการ', { variant: "info" })
			}
		})
	}

	const onCloseModal = useCallback(() => {
		mode[1]("initial")
		dispatch(clearInfoById("uploadOrder"))
	}, [dispatch, mode])

	const { excelReader, renderExcelModal } = useMultipleFileReader({
		fields: uploadFields,
		submitData: uploadData,
		onClose: onCloseModal
	})

	const fetchData = async controller => {
		const result = await multiple([
			{ method: "get", url: "/division/getAllActiveDivision", config: { signal: controller.signal } },
			{ method: "get", url: "/unit/getAllActiveUnit", config: { signal: controller.signal } },
			{ method: "get", url: "/vehicleType/getAllActiveVehicleType", config: { signal: controller.signal } },
			{ method: "get", url: "/serviceType/getAllActiveServiceType", config: { signal: controller.signal } },
		])

		if (result[0].status === 200) {
			const data = result[0].data.data.records || []
			setAllSender(data.map(e => ({ label: (e.customerName ? `${e.customerName} / ` : "") + e.name, value: e.id })))
		}

		if (result[1].status === 200) {
			const data = result[1].data.data.records || []
			setAllUnit(data.map(e => ({ label: e.name, value: e.id })))
		}

		if (result[2].status === 200) {
			const data = result[2].data.data.records || []
			setAllVehicleType(data.map(e => ({ label: e.name, value: e.id })))
		}

		if (result[3].status === 200) {
			const data = result[3].data.data.records || []
			setAllServiceType(data.map(e => ({ label: e.name, value: e.id })))
		}

	}

	useEffect(() => {
		const controller = new AbortController()
		fetchData(controller)
		return () => {
			controller.abort()
		}
	}, [])

	useEffect(() => {
		if (mode[0] !== "initial") {
			dispatch(clearInfoById("uploadOrder"))
			dispatch(setInfoById({ id: "uploadOrder", payload: { mode: "create" } }))
		}
	}, [mode[0]])

	const uploadOrder = useCallback((callback) => {
		if (validation(info, "uploadModal")) {
			callback()
		}
	}, [info])

	const inputForm = [
		{
			rowData: [
				{
					type: "upload",
					props: {
						id: "files",
						label: "อัปโหลด",
						type: "button",
						maxFiles: 1,
						accept: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
						onClick: uploadOrder,
						onChange: e => {
							if (e.value?.length) {
								excelReader(e.value[0])
							}
						}
					}
				}
			]
		}
	]
	return (
		<Modal id="uploadModal" open={mode[0] !== "initial"} onClose={onCloseModal} className="form-modal" contentClassName="min-w-full md:!min-w-[750px]">
			<Form id={`uploadOrder`} title={"อัปโหลดออเดอร์"} name="user-form" data={inputForm}></Form>
			{renderExcelModal}
		</Modal>
	)
}