import React, { useMemo } from 'react'
import { v4 as uuidv4 } from 'uuid';
import { Box, Grid } from '@mui/material';
import Text from 'components/Text'
import Number from 'components/Number'
import Button from 'components/Button'
import Dropdown from 'components/Dropdown'
import Radio from 'components/Radio'
import DataTable from 'components/DataTable';
import DatePicker from 'components/DatePicker';
import DateTimePicker from 'components/DateTimePicker';
import Upload from 'components/Upload';
import ProfileUpload from 'components/ProfileUpload';
import AutoComplete from 'components/AutoComplete';
import Divider from 'components/Divider';
import MultiCheckbox from 'components/MultiCheckbox';
import Password from 'components/Password';
import List from 'components/List';
import Display from 'components/Display';
import Table from 'components/Table';
import TransferList from 'components/TransferList';
import TimePicker from 'components/TimePicker';
import Collapse from 'components/Collapse';
import Scheduler from 'components/Scheduler';
import Checkbox from 'components/Checkbox';
import Tag from 'components/Tag';
import Maps from 'components/Maps';
import Timeline from 'components/Timeline';

export default function RenderComponent({ formRef, formId, name = uuidv4(), data, isListData, listName, index, removeBoxPadding = false, editable = true }) {
	const elementCondition = (element) => {
		let returnElement = (<></>)
		switch (element.type) {
			case "text":
				returnElement = (<Text parentId={formId} key={element.type + '-' + (element.id || "") + '-' + (element.props?.readOnly ? "readOnly" : "")} {...element.props} required={element.required} readOnly={!editable || (editable && element.props?.readOnly)}></Text>)
				break
			case "password":
				returnElement = (<Password parentId={formId} key={element.type + '-' + (element.id || "") + '-' + (element.props?.readOnly ? "readOnly" : "")} {...element.props} required={element.required} readOnly={!editable || (editable && element.props?.readOnly)}></Password>)
				break
			case "number":
				returnElement = (<Number parentId={formId} key={element.type + '-' + (element.id || "") + '-' + (element.props?.readOnly ? "readOnly" : "")} {...element.props} required={element.required} readOnly={!editable || (editable && element.props?.readOnly)}></Number>)
				break
			case "map":
				returnElement = (<Maps parentId={formId} key={element.type + '-' + (element.id || "") + '-' + (element.props?.readOnly ? "readOnly" : "")} {...element.props} parentComponentRef={formRef} required={element.required} readOnly={!editable || (editable && element.props?.readOnly)}></Maps>)
				break
			case "button":
				returnElement = (<Button key={element.type + '-' + (element.id || "") + '-' + (element.props?.readOnly ? "readOnly" : "")} {...element.props} required={element.required} readOnly={!editable || (editable && element.props?.readOnly)}></Button>)
				break
			case "dropdown":
				returnElement = (<Dropdown parentId={formId} key={element.type + '-' + (element.id || "") + '-' + (element.props?.readOnly ? "readOnly" : "")} {...element.props} required={element.required} readOnly={!editable || (editable && element.props?.readOnly)}></Dropdown>)
				break
			case "multiCheckbox":
				returnElement = (<MultiCheckbox parentId={formId} key={element.type + '-' + (element.id || "") + '-' + (element.props?.readOnly ? "readOnly" : "")} {...element.props} required={element.required} readOnly={!editable || (editable && element.props?.readOnly)}></MultiCheckbox>)
				break
			case "radio":
				returnElement = (<Radio parentId={formId} key={element.type + '-' + (element.id || "") + '-' + (element.props?.readOnly ? "readOnly" : "")} {...element.props} required={element.required} readOnly={!editable || (editable && element.props?.readOnly)}></Radio>)
				break
			case "autocomplete":
				returnElement = (<AutoComplete parentId={formId} key={element.type + '-' + (element.id || "") + '-' + (element.props?.readOnly ? "readOnly" : "")} {...element.props} required={element.required} readOnly={!editable || (editable && element.props?.readOnly)}></AutoComplete>)
				break
			case "tag":
				returnElement = (<Tag parentId={formId} key={element.type + '-' + (element.id || "") + '-' + (element.props?.readOnly ? "readOnly" : "")} {...element.props} required={element.required} readOnly={!editable || (editable && element.props?.readOnly)}></Tag>)
				break
			case "upload":
				returnElement = (<Upload parentId={formId} key={element.type + '-' + (element.id || "") + '-' + (element.props?.readOnly ? "readOnly" : "")} {...element.props} required={element.required} readOnly={!editable || (editable && element.props?.readOnly)}></Upload>)
				break
			case "profileUpload":
				returnElement = (<ProfileUpload parentId={formId} key={element.type + '-' + (element.id || "") + '-' + (element.props?.readOnly ? "readOnly" : "")} {...element.props} required={element.required} readOnly={!editable || (editable && element.props?.readOnly)}></ProfileUpload>)
				break
			case "date":
				returnElement = (<DatePicker parentId={formId} key={element.type + '-' + (element.id || "") + '-' + (element.props?.readOnly ? "readOnly" : "")} {...element.props} required={element.required} readOnly={!editable || (editable && element.props?.readOnly)}></DatePicker>)
				break
			case "time":
				returnElement = (<TimePicker parentId={formId} key={element.type + '-' + (element.id || "") + '-' + (element.props?.readOnly ? "readOnly" : "")} {...element.props} required={element.required} readOnly={!editable || (editable && element.props?.readOnly)}></TimePicker>)
				break
			case "datetime":
				returnElement = (<DateTimePicker parentId={formId} key={element.type + '-' + (element.id || "") + '-' + (element.props?.readOnly ? "readOnly" : "")} {...element.props} required={element.required} readOnly={!editable || (editable && element.props?.readOnly)}></DateTimePicker>)
				break
			case "timeline":
				returnElement = (<Timeline parentId={formId} key={element.type + '-' + (element.id || "") + '-' + (element.props?.readOnly ? "readOnly" : "")} {...element.props} required={element.required} readOnly={!editable || (editable && element.props?.readOnly)}></Timeline>)
				break
			case "schedule":
				returnElement = (<Scheduler parentId={formId} key={element.type + '-' + (element.id || "") + '-' + (element.props?.readOnly ? "readOnly" : "")} {...element.props} required={element.required} readOnly={!editable || (editable && element.props?.readOnly)}></Scheduler>)
				break
			case "display":
				returnElement = (<Display parentId={formId} key={element.type + '-' + (element.id || "") + '-' + (element.props?.readOnly ? "readOnly" : "")} {...element.props} required={element.required} readOnly={!editable || (editable && element.props?.readOnly)}></Display>)
				break
			case "transferList":
				returnElement = (<TransferList parentId={formId} key={element.type + '-' + (element.id || "") + '-' + (element.props?.readOnly ? "readOnly" : "")} {...element.props} required={element.required} readOnly={!editable || (editable && element.props?.readOnly)}></TransferList>)
				break
			case "dataTable":
				returnElement = (<DataTable parentId={formId} key={element.type + '-' + (element.id || "") + '-' + (element.props?.readOnly ? "readOnly" : "")} {...element.props} required={element.required} readOnly={!editable || (editable && element.props?.readOnly)}></DataTable>)
				break
			case "table":
				returnElement = (<Table parentId={formId} key={element.type + '-' + (element.id || "") + '-' + (element.props?.readOnly ? "readOnly" : "")} {...element.props} required={element.required} readOnly={!editable || (editable && element.props?.readOnly)}></Table>)
				break
			case "list":
				returnElement = (<List parentId={formId} key={element.type + '-' + (element.id || "") + '-' + (element.props?.readOnly ? "readOnly" : "")} {...element.props} required={element.required} readOnly={!editable || (editable && element.props?.readOnly)}></List>)
				break
			case "collapse":
				returnElement = (<Collapse parentId={formId} key={element.type + '-' + (element.id || "") + '-' + (element.props?.readOnly ? "readOnly" : "")} {...element.props} required={element.required} readOnly={!editable || (editable && element.props?.readOnly)}></Collapse>)
				break
			case "custom":
				returnElement = element.component
				break
			case "divider":
				returnElement = (<Divider key={element.type + '-' + (element.id || "")} {...element.props} required={element.required}></Divider>)
				break
			case "checkbox":
				returnElement = (<Checkbox parentId={formId} key={element.type + '-' + (element.id || "") + '-' + (element.props?.readOnly ? "readOnly" : "")} {...element.props} required={element.required} readOnly={!editable || (editable && element.props?.readOnly)}></Checkbox>)
				break
		}
		return returnElement
	}

	const generateWeb = (element) => {
		if (Object.keys(element).length === 0 || element.show === false) {
			return (<></>)
		} else {
			const attr = !!element.validation ? { 'validate-input': element.validation } : {}
			return <Box id={element?.props?.id ? `${isListData ? `list-data-${listName}-${index}-${element.props.id}` : `data-${element.props.id}`}` : undefined} className={`form-group h-full ${element.required ? "required" : ""}`} {...attr}>{elementCondition(element)}</Box>
		}
	}

	const generateWebWithoutNewLine = (element, key) => {
		return element.show !== false ? (
			<div className='inline-block' key={key}>
				{
					elementCondition(element)
				}
			</div>
		) : null
	}

	const getRow = useMemo(() => {
		const rowBuilder = (rows) => {
			return rows?.filter(e => e).map((row, index) => {
				const key = `${name}-${index}`
				const { isCollapse = false, defaultCollapse = false, label, noCol, rowData, collapseData, className = "", ...other } = row
				const _rowData = (isCollapse ? collapseData : rowData) || []
				const length = _rowData.filter(e => e).length
				const autoAlignColumn = !noCol

				const columnBuilder = (columns) => {
					if (!autoAlignColumn) {
						return (
							<Grid item xs={12} className={`${removeBoxPadding ? "px-0" : "xs:!px-4"} ${className}`} {...other}>
								{columns.filter(e => e).map((column, _index) => generateWebWithoutNewLine(column, `${name}-${index}-${_index}`))}
							</Grid>
						)
					} else {
						return columns.filter(e => e).map((column, _index) => {
							const md = column.md || 12 / length
							return (
								<Grid paddingRight={{ xs: "16px", md: length > 1 && length === _index + 1 ? "16px" : "0px" }} align={column.align} className={`${removeBoxPadding ? "p-0" : (length === 1 ? "px-3" : "")} ${column.className || ""}`} item key={`${name}-${index}-${_index}`} xs={12} sm={length !== 1 ? 6 : 12} md={md}>
									{generateWeb(column)}
								</Grid>
							)
						})
					}
				}
				return (
					<Grid key={key} container spacing={2} columns={12} className="m-auto p-0 !w-full">
						{
							isCollapse ? <Collapse className="pb-0" label={label} defaultCollapse={defaultCollapse}>{rowBuilder(_rowData)}</Collapse> : columnBuilder(_rowData)
						}
					</Grid>
				)
			})
		}
		if (data) {
			return rowBuilder(data)

		}
	}, [data])

	return (
		<>
			{getRow}
		</>
	)
}

