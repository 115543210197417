import { Paper, Table as MuiTable, TableContainer, TableHead, TableRow, TableBody, TableCell } from '@mui/material'
import React, { useMemo, useState } from 'react'
import { v4 as uuidv4 } from 'uuid';
import format from 'date-fns/format';
import numeral from 'numeral';

export default function Table({ columns = [], rows = [], tableContainerClassName = "", size, spliter = " / ", ...other }) {

    const [formattedRows, setFormattedRows] = useState([]);

    const mapValue = (mapper, data) => {
        return mapper.filter((e) => `${e.value}` === data)[0]?.label
    }

    const createData = (_columns, _rows) => {
        let formatedRows = []
        _rows.map((elm) => {
            let row = {}
            _columns.forEach((element) => {
                let value = ""
                element.fields.map((e, i) => {
                    if (typeof e === "object") {
                        const concatValue = (e.prefix || "") + elm[e.field] + (e.postfix || "")
                        value += elm[e.field] ? concatValue : ""
                    } else {
                        const concatValue = elm[e] + (i < element.fields.length - 1 ? (elm[element.fields[i + 1]] ? spliter : "") : "")
                        value += elm[e] != undefined ? concatValue : ""
                    }
                })

                if (value.endsWith(spliter)) {
                    value = value.slice(0, -spliter.length)
                }

                if (element.valuesMap) {
                    value = mapValue(element.valuesMap, value)
                }

                if (element.type === "number") {
                    value = value !== 0 && value ? numeral(value).format('0,0[.]00') : " "
                } else if (element.type === "date") {
                    value = format(new Date(value), 'dd/MM/yyyy')
                } else if (element.type === "datetime") {
                    value = value ? format(new Date(value), 'dd/MM/yyyy HH:mm') : value
                }
                if (element.custom) {
                    row[element.id] = element.custom(value, elm)
                } else {
                    row[element.id] = value
                }
            });
            formatedRows.push({ data: elm, text: row })
        })

        return formatedRows;
    }

    const checkPropertiesRow = (data) => data.hasOwnProperty("hits") && data.hasOwnProperty("records")

    useMemo(() => {
        if (rows && (rows.length || rows.records)) {
            setFormattedRows(createData(columns, checkPropertiesRow(rows) ? rows.records : rows))
        } else {
            setFormattedRows([])
        }
    }, [rows])

    return (
        <TableContainer component={Paper} style={{ boxShadow: "none", border: "1px solid #DFDFDF", borderRadius: "5px" }} className={`scrollbar-thin scrollbar-thumb-bpTheme-scroll scrollbar-track-white grow ${tableContainerClassName}`}>
            <MuiTable stickyHeader size={size}>
                <TableHead>
                    <TableRow>
                        {columns.map(e => <TableCell align={(e.numeric || e.type === "number") ? 'right' : 'left'} className='whitespace-nowrap' key={uuidv4()}>{e.label}</TableCell>)}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {
                        formattedRows?.length ?
                            formattedRows.map((row, index) => (
                                <TableRow key={uuidv4()}>
                                    {
                                        Object.values(row.text).map((e, i) => <TableCell className={`${e?.length > 150 ? `overflow-hidden whitespace-normal !min-w-[300px]` : `whitespace-nowrap`} ${columns[i]?.type === "number" ? "!text-right" : ""}`} key={uuidv4()}>{e}</TableCell>)
                                    }
                                </TableRow>
                            ))
                            : (
                                <TableRow className='border-b-0'>
                                    <TableCell align="center" colSpan="100%">ไม่พบข้อมูล</TableCell>
                                </TableRow>
                            )}
                </TableBody>
            </MuiTable>
        </TableContainer>
    )
}
