import { Box, Button, InputAdornment, List, ListItem, ListItemIcon, ListItemText, Modal, Popover, TextField } from '@mui/material'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { setInfo, setInfoById } from 'slices/infoSlice'
import { useDropzone } from 'react-dropzone'
import { useSnackbar } from 'notistack'
import useAlertConfig from 'hooks/useAlertConfig'
import { extractInfo } from 'utilities/utils'
import UploadFileIcon from '@mui/icons-material/UploadFile';
import { v4 as uuidv4 } from 'uuid';

export default function Upload({ parentId, id, label, maxFiles = 1, maxSize, type = "input", required, readOnly, onChange, onClick, accept, menus, ...other }) {
    const [popoverEl, setPopoverEl] = useState(null);
    const popoverId = uuidv4();
    const filterOpen = Boolean(popoverEl);
    const inputRef = useRef(null)
    const { enqueueSnackbar } = useSnackbar();
    const warningConf = useAlertConfig("warning");
    const [openDropZone, setOpenDropZone] = useState(false)
    const value = useSelector(state => extractInfo(state, parentId, id))
    const FAIL_BROWSE_MSG = `คุณสามารถเลือกไฟล์ได้ไม่เกิน ${maxFiles} รายการ`
    const dispatch = useDispatch()
    const [fileName, setFileName] = useState();

    const onPopoverClick = (event) => {
        setPopoverEl(event.currentTarget);
    };

    const onPopoverClose = () => {
        setPopoverEl(null);
    };

    const changeValue = (e) => {
        if (parentId) {
            dispatch(setInfoById({ id: parentId, payload: { [id]: e } }))
        } else {
            dispatch(setInfo({ [id]: e }))
        }
        if (onChange) {
            onChange({ parentId, id, value: e })
        }
    }
    const browseFile = () => {
        inputRef.current.value = null;
        inputRef.current.click()
    }
    const handleOpen = useCallback((event) => {
        setOpenDropZone(true)
        event.stopPropagation()
    }, [])
    const handleClose = useCallback(() => {
        setOpenDropZone(false)
    }, [])

    const onFileChange = (e) => {
        const fileList = []
        if (e?.target?.files?.length > 0) {
            const files = [...e.target.files]
            if (files.length <= maxFiles) {
                files?.map(file => {
                    fileList.push(file)
                })
                changeValue(fileList)
            } else {
                enqueueSnackbar(FAIL_BROWSE_MSG, warningConf)
            }
        }
    }

    const onDrop = useCallback(files => {
        const fileList = []
        if (files.length && files.length <= maxFiles && !fileRejections.length) {
            files.map(file => {
                fileList.push(file)
            })
            changeValue(fileList)
            handleClose()
        } else {
            enqueueSnackbar(FAIL_BROWSE_MSG, warningConf)
        }
    }, [])
    const { getRootProps, getInputProps, fileRejections, isDragActive } = useDropzone({ onDrop, maxFiles, maxSize })

    useEffect(() => {
        if (typeof value === "string") {
            setFileName(value)
        } else if (typeof value === "object") {
            setFileName(value?.map(e => e?.name)?.join(", "))
        } else {
            setFileName("")
        }
    }, [value])
    return (
        <>
            {
                type === "input" ?
                    <TextField
                        className='w-full bg-white'
                        id={id}
                        label={label}
                        type="text"
                        value={fileName || "No file select."}
                        InputLabelProps={{
                            shrink: true
                        }}
                        InputProps={{
                            readOnly: true,
                            endAdornment: !readOnly ? (
                                <InputAdornment position='end'>
                                    <Button size="small" variant='outlined' sx={{ marginRight: 1 }} onClick={handleOpen}>DROP FILE</Button>
                                    <Button size="small" variant='outlined' onClick={onClick ? () => onClick(browseFile) : browseFile}>CHOOSE FILE</Button>
                                </InputAdornment>
                            ) : null,
                        }}
                        {...other}
                    /> : null
            }
            {
                type === "button" ?
                    <Button variant='outlined' onClick={onClick ? () => onClick(browseFile) : browseFile} {...other}>
                        <UploadFileIcon /> {label || "Upload"}
                    </Button> : null
            }
            {
                type === "multi-button" ?
                    <>
                        <Button variant='outlined' onClick={onPopoverClick} {...other}>
                            <UploadFileIcon /> {label || "Upload"}
                        </Button>
                        <Popover
                            id={popoverId}
                            open={filterOpen}
                            anchorEl={popoverEl}
                            onClose={onPopoverClose}
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'left',
                            }}
                        >
                            <List>
                                {menus.map((e, i) => {
                                    return (
                                        <ListItem key={`upload-${i}`} button onClick={() => {
                                            if (e.onClick) {
                                                e.onClick(browseFile)
                                            }
                                        }}>
                                            <ListItemIcon>
                                                {e.icon}
                                            </ListItemIcon>
                                            <ListItemText primary={e.label} />
                                        </ListItem>
                                    )
                                })}
                            </List>
                        </Popover>
                    </>
                    : null
            }
            <input ref={inputRef} type="file" id={`file-input-${id}`} onChange={onFileChange} hidden multiple={maxFiles > 1} accept={accept} />
            <Modal
                open={openDropZone}
                onClose={handleClose}
                className="flex justify-center items-center h-full"
            >
                <Box {...getRootProps()} width={{ sx: '100%', md: '75%' }} className='bg-white text-gray-500 overflow-hidden rounded-sm shadow max-w-full m-auto' onClick={handleOpen}>
                    <input {...getInputProps()} />
                    <div className='border-dashed border-2 border-bpTheme-menuBorder p-20 m-20 text-center'>
                        {
                            isDragActive ?
                                <p>วางไฟล์ที่นี่ ...</p> :
                                <p>ลากและวางไฟล์ที่นี่ สามารถอัปโหลดได้สูงสุด {maxFiles} รายการ</p>
                        }
                    </div>
                </Box>
            </Modal>
        </>
    )
}