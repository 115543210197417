import RenderComponent from "components/RenderComponent";
import FieldSet from 'components/FieldSet'
import { Box } from "@mui/material";
import { useEffect, useRef } from 'react';
import { useDispatch } from "react-redux";
import { setInfoById } from 'slices/infoSlice';
export default function Form({ id, title, name, data, className = "h-full !p-0 !pb-2 ", removeBoxPadding = false, editable }) {
	const dispatch = useDispatch()
	const formRef = useRef()

	useEffect(() => {
		if (id) {
			dispatch(setInfoById({ id, payload: {} }))
		}
	}, [id])
	return (
		<FieldSet legend={title} className={className}>
			<Box ref={formRef}  sx={{
				'&::-webkit-scrollbar-thumb': {
					borderRadius: "8px",
				}
			}} className="h-full !p-2 overflow-y-auto scrollbar-thin scrollbar-thumb-bpTheme-scroll scrollbar-track-white">
				<RenderComponent formRef={formRef} formId={id} name={name} data={data} removeBoxPadding={removeBoxPadding} editable={editable} />
			</Box>
		</FieldSet>
	);
}