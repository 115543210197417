import React, { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Modal from 'components/Modal'
import Form from 'components/Form'
import { useSnackbar } from 'notistack'
import { validation } from 'utilities/validator'
import usePopup from 'hooks/usePopup'
import useAxios from 'hooks/useAxios'
import { setInfo, setInfoById, clearInfoById } from 'slices/infoSlice'
import { v4 as uuidv4 } from 'uuid'
import Location from './location'

export default function Items({ mode, editable }) {
	const dispatch = useDispatch()
	const info = useSelector(state => state.info)
	const mode2 = useState("initial") //province
	const { confirmPopup } = usePopup();
	const { multiple, get } = useAxios()
	const [allUnit, setAllUnit] = useState([])
	const [locationType, setLocationType] = useState("pickup")

	const fetchData = async () => {
		const result = await multiple([
			{ method: "get", url: "/unit/getAllActiveUnit" },
		])

		if (result[0].status === 200) {
			const data = result[0].data.data.records || []
			setAllUnit(data.map(e => ({ label: e.name, value: e.id })))
		}
	}

	useEffect(() => {
		if (mode[0] === "initial") {
			dispatch(clearInfoById("items"))
		} else if (mode[0] === "create" || mode[0] === "edit") {
			fetchData()
		}
	}, [mode[0]])

	const onCloseModal = useCallback(() => {
		confirmPopup({
			title: "แจ้งเตือน",
			content: "ท่านต้องการยกเลิกการแก้ไขหรือไม่",
			onSubmit: () => {
				mode[1]("initial")
			}
		})
	}, [])

	const saveData = useCallback(() => {
		if (validation(info.items, "itemsModal")) {
			const items = info.ctx.items || []
			if (mode[0] === "create") {
				dispatch(setInfo({
					items: [...items, {
						...info.items,
						id: uuidv4(),
						pickupAddress: info.items.pickupLocations?.map(e => e.routeName).join(", ") || "",
						shippingAddress: info.items.shippingLocations?.map(e => e.routeName).join(", ") || "",
						totalPickup: info.items.pickupLocations?.reduce((total, item) => total + item.quantity, 0),
						totalShipping: info.items.shippingLocations?.reduce((total, item) => total + item.quantity, 0),
						recStatus: "new",
						status: "New"
					}]
				}))
			} else {
				const newItems = items.map(item => item.id === info.items.id ? {
					...info.items,
					pickupAddress: info.items.pickupLocations?.map(e => e.routeName).join(", ") || "",
					shippingAddress: info.items.shippingLocations?.map(e => e.routeName).join(", ") || "",
					totalPickup: info.items.pickupLocations?.reduce((total, item) => total + item.quantity, 0),
					totalShipping: info.items.shippingLocations?.reduce((total, item) => total + item.quantity, 0),
				} : item);
				dispatch(setInfo({ items: newItems }))
			}
			mode[1]("initial")
		}
	}, [info])

	const inputForm = [
		{
			rowData: [

				{
					type: "text",
					props: {
						id: "detail",
						label: "รายละเอียดสินค้า"
					}
				},
				{
					type: "number",
					props: {
						id: "temperature",
						label: "อุณหภูมิ"
					}
				},
				{
					type: "autocomplete",
					required: true,
					props: {
						id: "unitId",
						label: "หน่วย",
						options: allUnit
					}
				}
			]
		},
		{
			rowData: [
				{
					type: "text",
					props: {
						id: "remark",
						label: "หมายเหตุ",
						multiline: true,
						maxRows: 4
					}
				}
			]
		},
		{
			rowData: [
				{
					type: "text",
					props: {
						id: "remark2",
						label: "หมายเหตุ 2",
						multiline: true,
						maxRows: 4
					}
				}
			]
		},
		{
			isCollapse: true,
			label: "รายละเอียดจุดรับสินค้า",
			collapseData: [
				{
					rowData: [
						{
							type: "dataTable",
							props: {
								columns: [
									{
										id: 'address',
										label: 'ที่อยู่รับสินค้า',
										fields: ["address"]
									},
									{
										id: 'quantity',
										label: 'จำนวน',
										fields: ["quantity"],
										type: "number"
									},
									{
										id: 'remark',
										label: 'หมายเหตุ',
										fields: ["remark"]
									},
								],
								id: "selectedPickupLocations",
								rows: info.items?.pickupLocations || [],
								tableContainerClassName: "max-h-[30vh]",
								multiSelect: editable,
								onClick: editable ? (e) => {
									mode2[1]("edit")
									setLocationType("pickup")
									dispatch(setInfoById({ id: "pickupLocation", payload: e }))
								} : undefined,
								onDelete: (e, onSuccess) => {
									const ids = e.map(e => e.id) || []
									const removedLocations = info.items?.pickupLocations.filter(e => !ids.includes(e.id))
									dispatch(setInfoById({ id: "items", payload: { pickupLocations: removedLocations } }))
									onSuccess()
								},
								size: "small"
							}
						}
					]
				},
				editable && {
					rowData: [
						{
							type: "button",
							props: {
								label: "เพิ่มจุดรับ",
								onClick: () => {
									setLocationType("pickup")
									mode2[1]("create")
								}
							}
						}
					]
				},
			]
		},
		{
			isCollapse: true,
			label: "รายละเอียดจุดส่งสินค้า",
			collapseData: [
				{
					rowData: [
						{
							type: "dataTable",
							props: {
								columns: [
									{
										id: 'address',
										label: 'ที่อยู่ส่งสินค้า',
										fields: ["address"]
									},
									{
										id: 'quantity',
										label: 'จำนวน',
										fields: ["quantity"],
										type: "number"
									},
									{
										id: 'remark',
										label: 'หมายเหตุ',
										fields: ["remark"]
									},
								],
								id: "selectedShippingLocations",
								rows: info.items?.shippingLocations || [],
								tableContainerClassName: "max-h-[30vh]",
								multiSelect: editable,
								onClick: editable ? (e) => {
									mode2[1]("edit")
									setLocationType("shipping")
									dispatch(setInfoById({ id: "shippingLocation", payload: e }))
								} : undefined,
								onDelete: (e, onSuccess) => {
									const ids = e.map(e => e.id) || []
									const removedLocations = info.items?.shippingLocations.filter(e => !ids.includes(e.id))
									dispatch(setInfoById({ id: "items", payload: { shippingLocations: removedLocations} }))
									onSuccess()
								},
								size: "small"
							}
						}
					]
				},
				editable && {
					rowData: [
						{
							type: "button",
							props: {
								label: "เพิ่มจุดส่ง",
								onClick: () => {
									setLocationType("shipping")
									mode2[1]("create")
								}
							}
						}
					]
				},
			]
		},
		{
			noCol: true,
			align: "center",
			rowData: [
				{
					type: "button",
					props: {
						label: "ตกลง",
						onClick: saveData,
					}
				}
			]
		}
	]
	return (
		<>
			<Modal id="itemsModal" open={mode[0] === "create" || mode[0] === "edit"} onClose={onCloseModal} className="form-modal">
				<Form id={`items`} title={"ข้อมูลสินค้า"} name="user-form" data={inputForm} editable={editable}></Form>
			</Modal>
			<Location mode={mode2} type={locationType} />
		</>
	)
}