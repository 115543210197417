import React, { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Modal from 'components/Modal'
import Form from 'components/Form'
import { setInfo, clearInfoById, setInfoById } from 'slices/infoSlice'
import useAxios from 'hooks/useAxios'
import { memoizedSelectAdvanceInfo } from 'redux/memorize'
import { useSnackbar } from 'notistack'
import { validation } from 'utilities/validator'
import { cloneDeep } from 'lodash';
import { v4 as uuidv4 } from 'uuid';

export default function Invoices({ mode, customerId }) {

	const dispatch = useDispatch()
	const info = useSelector(state => state.info)
	const { multiple, get } = useAxios()
	const paging = useState({})
	const searchInfo = useSelector(memoizedSelectAdvanceInfo)
	const [allOrders, setAllOrders] = useState([])
	const [allVehicleType, setAllVehicleType] = useState([]);
	const [allAdditionalPrice, setAllAdditionalPrice] = useState([]);
	const { enqueueSnackbar } = useSnackbar();

	const fetchData = async () => {
		const result = await multiple([
			{ method: "get", url: "/vehicleType/getAllActiveVehicleType" },
			{ method: "get", url: "/additionalPrice/getAllActiveAdditionalPrice", params: { type: "job" } },
		])
		if (result[0].status === 200) {
			setAllVehicleType(result[0].data.data.records.map(data => ({ label: data.name, value: data.id })) || [])
		}
		if (result[1].status === 200) {
			const data = result[1].data.data.records || []
			setAllAdditionalPrice(data.map(data => ({ label: data.name, value: data.name })) || [])
		}
	}

	useEffect(() => {
		setAllOrders([])
		if (mode[0] === "initial") {
			dispatch(clearInfoById("orders"))
			dispatch(clearInfoById("advanceSearch"))
		} else {
			fetchData()
			if (mode[0] === "edit") {
				search()
			}
		}
	}, [mode[0]])

	const onCloseModal = useCallback(() => {
		mode[1]("initial")
	}, [])

	const saveData = useCallback(() => {
		if (validation(info.orders, "orderModal")
			&& (!info.orders?.isAdditionalPrice && info.orders?.invoiceOrders?.length || info.orders?.isAdditionalPrice)
		) {
			const orders = info.ctx.orders || []
			const filtered = cloneDeep(info.orders) || {};
			const startDate = filtered.startDate ? new Date(filtered.startDate) : new Date();
			const endDate = filtered.toDate ? new Date(filtered.toDate) : new Date();
			filtered.id = filtered.id || uuidv4()
			let dateRange;

			if (startDate.getFullYear() === endDate.getFullYear()) {
				if (startDate.getMonth() === endDate.getMonth()) {
					dateRange = `${startDate.getDate()}-${endDate.getDate()}/${startDate.getMonth() + 1}/${startDate.getFullYear()}`;
				} else {
					dateRange = `${startDate.getDate()}/${startDate.getMonth() + 1}-${endDate.getDate()}/${endDate.getMonth() + 1}/${endDate.getFullYear()}`;
				}
			} else {
				dateRange = `${startDate.getDate()}/${startDate.getMonth() + 1}/${startDate.getFullYear()}-${endDate.getDate()}/${endDate.getMonth() + 1}/${endDate.getFullYear()}`;
			}

			filtered.dateRange = dateRange
			filtered.vehicleTypeName = allVehicleType.find(e => e.value === info.orders?.vehicleTypeId)?.label
			if (filtered.invoiceOrders && !info.orders?.isAdditionalPrice) {
				filtered.qty = info.orders?.invoiceOrders?.length || 0
				filtered.amount = filtered.qty * filtered.tripPrice
				filtered.invoiceOrders = filtered.invoiceOrders.map(e => ({ ...e, amount: filtered.tripPrice }))
			}
			if (mode[0] === "create") {
				filtered.recStatus = "new"
				dispatch(setInfo({ orders: [...orders, filtered] }))
			} else {
				filtered.recStatus = filtered.recStatus === "new" ? "new" : "update"
				const updatedOrders = orders.map(order => {
					if (order.id === filtered.id) {
						return filtered;
					}
					return order;
				});
				dispatch(setInfo({ orders: updatedOrders }))
			}
			mode[1]("initial")
		} else if (!info.orders?.isAdditionalPrice && !info.orders?.length) {
			enqueueSnackbar('กรุณาเลือกรายการออเดอร์', { variant: "error" })
		}
	}, [info])

	const search = async () => {
		const result = await get("/order/getOrdersForInvoice", { customerId, vehicleTypeId: info.orders?.vehicleTypeId, startDate: info.orders?.startDate, toDate: info.orders?.toDate, invoiceId: info.ctx?.id, additionalPrice: info.orders?.isAdditionalPrice ? info.orders?.detail : null })
		if (result.status === 200) {
			const data = result.data.data || []
			const firstArray = data.records
			const secondArray = info.ctx.orders || []
			data.records = firstArray.filter((element) => info.orders.isAdditionalPrice || !secondArray.filter(e => !e.isAdditionalPrice && e.id !== info.orders?.id).some(item => item.invoiceOrders?.some(e => e.orderId === element.orderId)))
			setAllOrders(data)
		}
	}

	const inputForm = [
		{
			rowData:
				[
					info.orders?.isAdditionalPrice ? {
						type: "autocomplete",
						props: {
							id: "detail",
							label: "รายละเอียด",
							options: allAdditionalPrice,
						}
					} :
						{
							type: "text",
							required: true,
							props: {
								label: "รายละเอียด",
								id: "detail",
							}
						},
					{
						type: "checkbox",
						props: {
							label: "รายการค่าใช้จ่ายอื่นๆ",
							id: "isAdditionalPrice",
							onChange: e => {
								dispatch(setInfoById({ id: "orders", payload: { detail: null, amount: null, tripPrice: null, invoiceOrders: [], calculatedOrders: [] } }))
							}
						}
					},
					{}
				]
		},
		info.orders?.isAdditionalPrice ? {
			rowData: [
				{
					type: "number",
					required: true,
					props: {
						label: "ราคา",
						id: "amount",
					}
				},
				info.orders?.calculatedOrders?.length ? {
					type: "button",
					className: "flex self-center",
					props: {
						label: "คำนวนราคา",
						onClick: () => {
							dispatch(setInfoById({ id: "orders", payload: { amount: info.orders?.calculatedOrders?.reduce((total, itm) => total + (itm.additionalPrice || 0), 0) } }))
						}
					},
					md: 2
				} : {},
				{},
			]
		} :
			{
				rowData: [
					{
						type: "number",
						required: true,
						props: {
							label: "ราคา/เที่ยว",
							id: "tripPrice",
						}
					},
					{},
					{}
				]
			},
		{
			rowData: [
				{
					type: "autocomplete",
					required: true,
					props: {
						id: "vehicleTypeId",
						label: "ประเภทรถ",
						options: allVehicleType
					},
					md: 4
				},
				{
					type: "date",
					required: true,
					props: {
						label: "ตั้งแต่วันที่",
						id: "startDate",
						maxDate: searchInfo.toDate || new Date(),
					},
					md: 3
				},
				{
					type: "date",
					required: true,
					props: {
						label: "ถึงวันที่",
						id: "toDate",
						minDate: searchInfo.startDate,
						maxDate: new Date(),
					},
					md: 3
				},
				{
					type: "button",
					className: "flex self-center",
					props: {
						label: "ค้นหา",
						onClick: () => {
							if (
								info.orders?.vehicleTypeId && info.orders?.startDate && info.orders?.toDate
								&& (!info.orders?.isAdditionalPrice || info.orders?.detail)
							) {
								search()
							} else {
								enqueueSnackbar('กรุณาใส่รายละเอียดให้ครบ', { variant: "error" })
							}
						}
					},
					md: 2
				}
			]
		},
		{
			rowData: [
				{
					type: "dataTable",
					props: {
						columns: info.orders?.isAdditionalPrice ? [
							{
								id: 'no',
								label: 'เลขที่ออเดอร์',
								fields: ["no"]
							},
							{
								id: 'pickDate',
								label: 'วันที่เข้ารับสินค้า',
								fields: ["pickDate"],
								type: "datetime"
							},
							{
								id: 'route',
								label: 'ต้นทาง - ปลายทาง',
								fields: ["pickupAddress", "shippingAddress"],
								spliter: " - "
							},
							{
								id: 'additionalPrice',
								label: 'ค่าใช้จ่ายเพิ่มเติม',
								fields: ["additionalPrice"],
								type: "number"
							},
							{
								id: 'status',
								label: 'สถานะ',
								fields: ["status"]
							},
						] : [
							{
								id: 'no',
								label: 'เลขที่ออเดอร์',
								fields: ["no"]
							},
							{
								id: 'pickDate',
								label: 'วันที่เข้ารับสินค้า',
								fields: ["pickDate"],
								type: "datetime"
							},
							{
								id: 'route',
								label: 'ต้นทาง - ปลายทาง',
								fields: ["pickupAddress", "shippingAddress"],
								spliter: " - "
							},
							{
								id: 'status',
								label: 'สถานะ',
								fields: ["status"]
							},
						],
						rows: allOrders,
						multiSelect: true,
						title: "ออเดอร์",
						id: info.orders?.isAdditionalPrice ? `calculatedOrders` : `invoiceOrders`,
						state: { paging },
						tableContainerClassName: "max-h-[500px]",
						size: "small",
						spliter: ", ",
						hideSelected: true,
						compareField: "orderId"
					}
				}
			]
		},
		{
			noCol: true,
			align: "center",
			rowData: [
				{
					type: "button",
					props: {
						label: "ตกลง",
						onClick: saveData,
					}
				}
			]
		}
	]

	return (
		<Modal id="orderModal" open={mode[0] === "create" || mode[0] === "edit"} onClose={onCloseModal} className="form-modal">
			<Form id={`orders`} title={"รายการใบแจ้งหนี้"} name="user-form" data={inputForm}></Form>
		</Modal>
	)
}